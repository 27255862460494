import { t } from "i18next";

import { ActionsListItem } from "components/atoms";
import { IInvoice } from "components/types";

interface IInvoiceItemActions {
	sendByMail: (invoice: IInvoice) => void;
	handleInvoiceReadStatus: (item: IInvoice, setAsReaded: boolean) => void;
	handleContact: (invoice: IInvoice) => void;
	handleInvoiceDownload: (id: number) => void;
}

export const invoiceItemActions = ({
	sendByMail,
	handleInvoiceReadStatus,
	handleContact,
	handleInvoiceDownload,
}: IInvoiceItemActions) => {
	return [
		{
			label: t("file.download"),
			icon: "download",
			onClick: ({ id }: IInvoice) => handleInvoiceDownload(id),
		},
		{
			label: t("file.sendByMail"),
			icon: "mail",
			onClick: (item: IInvoice) => {
				sendByMail(item);
			},
		},
		{
			render: (item: IInvoice) => {
				return (
					<ActionsListItem
						props={item}
						action={{
							onClick: () =>
								handleInvoiceReadStatus(item, !item.isRead),
							icon: item.isRead ? "eye" : "eyeOff",
							label: t(
								!item.isRead
									? "global.markAsRead"
									: "global.markAsUnread"
							),
						}}
					/>
				);
			},
		},
		{
			label: t("global.contactAgency"),
			icon: "bubble",
			onClick: (item: IInvoice) => handleContact(item),
		},
	];
};
