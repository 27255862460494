import React from "react";

import { t } from "i18next";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { AuthRoutes, IRoute, UserMenuRoutes } from "router/Routes";

import useAppContext from "store/useAppContext";

import SVG from "components/atoms/SVG/SVG";

import { cn } from "utils";

import { APP_NAME } from "constants_globals";

import BgImage from "assets/illustrations/error.svg";

interface IPage404Props {
	className?: string;
	text?: string | any;
}

const isInRoutes = (routes: IRoute[], path: string) => {
	return routes.find((r) =>
		Array.isArray(r.path)
			? r.path.find((p) => {
					return path.indexOf(p) !== -1;
			  })
			: path.indexOf(r.path) !== -1
	);
};

export const Page404: React.FC<IPage404Props> = ({
	className,
	text = t("404.message"),
}) => {
	const { isLogged, isLoggedAs, isAdmin } = useAppContext();
	const location = useLocation();

	if (
		isLogged() &&
		location.pathname?.length > 1 &&
		(isInRoutes(AuthRoutes, location.pathname) !== undefined ||
			(isAdmin() &&
				!isLoggedAs &&
				isInRoutes(UserMenuRoutes, location.pathname) !== undefined))
	) {
		/*
			permet de rediriger vers la page d'accueil si l'utilisateur est connecté et qu'il tente d'accéder à une page non autorisée
			exemple: "/retrieve-password", ou "/timesheet" si il est admin mais non connecté en tant qu'un client
		*/
		window.location.replace("/");
		return false;
	}
	return (
		<>
			<Helmet>
				<title>
					{t("404.pageTitle")} - {APP_NAME}
				</title>
			</Helmet>
			<div
				className={cn([
					"relative w-full overflow-hidden h-[calc(100vh-72px)]",
					className,
				])}
			>
				<div
					className="relative z-10"
					style={{
						padding: "min(10vw, 10vh)",
					}}
				>
					<h1 className="text-4xl font-bold">
						<span className="text-primary-500">
							{t("404.oups")}
						</span>{" "}
						{t("404.title")}
					</h1>
					<p className="text-xl">{text}</p>
				</div>
				<div className="absolute bottom-0 h-2/3 max-h-[600px] max-w-full overflow-hiddden right-[-1svw] z-0">
					<SVG src={BgImage} className="h-full max-w-full w-fit" />
				</div>
			</div>
		</>
	);
};
