import React, { PropsWithChildren } from "react";

import { useQuery } from "@tanstack/react-query";

import ThreadService from "services/ThreadsService";

import { Picto } from "@zolteam/react-ras-library";

import { cn } from "utils/cn";

export interface IMessagingIconProps extends PropsWithChildren {
	countClassName?: string;
}

export const MessagingIcon: React.FC<IMessagingIconProps> = ({
	children,
	countClassName,
}) => {
	const fetchUnreadThreads = () => {
		return ThreadService.getThreadsCountUnread();
	};

	const { isLoading, data } = useQuery({
		queryKey: ["activeThreadsCount"],
		queryFn: () => fetchUnreadThreads(),
	});

	let count = data?.count || 0;
	if (count > 99) count = 99;

	return (
		<div className="relative">
			{children ? (
				children
			) : (
				<Picto
					icon="messageSquare"
					className="w-4 cursor-pointer text-neutral-500"
				/>
			)}
			{!isLoading && count > 0 && (
				<span
					className={cn([
						"absolute flex items-center justify-center w-4 h-4 p-2 text-[0.6rem] text-white border-2 rounded-full -top-3 -right-3 bg-primary-500 border-neutral-100",
						countClassName,
					])}
				>
					{count}
				</span>
			)}
		</div>
	);
};
