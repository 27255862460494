import React, { Fragment, useState } from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";
import { Trans } from "react-i18next";

import { Text, Tooltip } from "@zolteam/react-ras-library";
import { TimesheetTableRowTitleCell } from "components/atoms";
import { ConfirmModal } from "components/molecules";
import { ITimesheetEditFormErrors } from "components/types";

import { cn, formatHoursMinutes, formatHundredsToString } from "utils";

import { HUNDREDS_MODE } from "constants_globals";

export interface ITableWeekRowProps {
	title: string;
	subtitle?: string;
	name: string;
	days: any[];
	editable: boolean;
	total: string | number;
	mode?: string;
	className?: string;
	locked?: boolean;
}

export const TableWeekRow: React.FC<ITableWeekRowProps> = ({
	title,
	subtitle,
	name,
	days,
	editable,
	total,
	mode,
	locked,
}) => {
	const [confirmInputModal, toggleConfirmInputModal] = useState(false);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);

	const { values, setFieldValue, validateForm } = useFormikContext<any>();
	const { errors } = useFormikContext<ITimesheetEditFormErrors>();

	const hasNoWorkingDays = days.every((day) => !day.isWorkingDay);

	const handleConfirmWorkDay = () => {
		if (values.tsBonuses?.length) {
			values.tsBonuses.forEach((bonus: any, index: number) => {
				if (activeIndex && values.tsBonuses[index].days[activeIndex])
					setFieldValue(`tsBonuses[${index}][days][${activeIndex}]`, {
						...values.tsBonuses[index].days[activeIndex],
						value: undefined,
						isWorkingDay: true,
						activatedDay: true,
					});
			});
		}
		setFieldValue(`tsDays[${activeIndex}]`, {
			...(activeIndex ? values.tsDays[activeIndex] : {}),
			nightHours: undefined,
			workedHours: undefined,
			isWorkingDay: true,
			activatedDay: true,
		});
		setActiveIndex(null);
		toggleConfirmInputModal(false);
	};

	const inputClassName =
		"w-[3.5rem] text-xs text-center rounded-full border-2 border-neutral-200 dark:border-neutral-800 px-2 py-1 dark:bg-neutral-100";

	const errorInputClassName = "border-red-500 dark:border-red-500";

	const disabledInputClassName = "bg-neutral-200 dark:bg-neutral-800";

	return (
		<>
			<tr className="[&_td:not(:first-child)]:text-center [&_td:not(:first-child)]:align-middle">
				{(name === "workedHours" || name === "nightHours") && (
					<>
						<TimesheetTableRowTitleCell
							title={title}
							subtitle={subtitle}
							className={cn([
								name === "nightHours" && "[&>div]:ml-4",
							])}
						/>
						{days && days.length ? (
							days.map((day, index: number) => {
								const isWorkingDay =
									day.isWorkingDay || hasNoWorkingDays;

								const getError = (): any => {
									const error: any =
										(typeof errors.tsDays?.[index] ===
										"string"
											? errors.tsDays?.[index]
											: errors.tsDays?.[index]?.[name]) ||
										"";
									return error ? (
										<Trans i18nKey={error} />
									) : (
										""
									);
								};

								if (
									!day.dateIsInTimesheet ||
									day[name] ||
									day.workedHours ||
									isWorkingDay
								) {
									return (
										<td
											key={day.date}
											className="text-center"
										>
											<>
												<Tooltip
													placement="top"
													content={getError()}
												>
													<input
														onBlur={({
															target: { value },
														}) => {
															if (mode) {
																setFieldValue(
																	`tsDays[${index}].${name}`,
																	formatHundredsToString(
																		value,
																		/,|\.|:|e/,
																		mode ===
																			HUNDREDS_MODE
																			? ","
																			: ":"
																	)
																);
																setTimeout(
																	() => {
																		validateForm();
																	},
																	0
																);
															}
														}}
														value={day[name] || ""}
														name={`tsDays[${index}][${name}]`}
														type="text"
														onChange={({
															target: { value },
														}) => {
															setFieldValue(
																`tsDays[${index}].${name}`,
																value
															);
														}}
														disabled={
															locked ||
															(!day.isWorkingDay &&
																!(
																	hasNoWorkingDays &&
																	day.dateIsInTimesheet
																) &&
																!day[name] &&
																!day.workedHours) ||
															!editable
														}
														className={cn([
															inputClassName,
															((!day.isWorkingDay &&
																!(
																	hasNoWorkingDays &&
																	day.dateIsInTimesheet
																) &&
																!day[name] &&
																!day.workedHours) ||
																!day.dateIsInTimesheet ||
																!editable) &&
																disabledInputClassName,
															!day.isWorkingDay &&
																!(
																	hasNoWorkingDays &&
																	day.dateIsInTimesheet
																) &&
																day.isVacationDay &&
																!day[name] &&
																"invisible",

															(typeof errors
																.tsDays?.[
																index
															] === "string" ||
																errors.tsDays?.[
																	index
																]?.[name]) &&
																errorInputClassName,
														])}
													/>
												</Tooltip>
											</>
										</td>
									);
								}

								if (
									!isWorkingDay &&
									!day[name] &&
									day.dateIsInTimesheet
								)
									return (
										<td
											key={day.date}
											className="text-center"
										>
											<input
												value={""}
												className={cn([
													inputClassName,
													"bg-neutral-200",
													!locked &&
														editable &&
														"cursor-pointer",
												])}
												type="button"
												disabled={locked || !editable}
												onClick={() => {
													setActiveIndex(index);
													toggleConfirmInputModal(
														true
													);
												}}
											/>
										</td>
									);
								return <td />;
							})
						) : (
							<td />
						)}

						<td className="text-center">
							<Text
								tag="span"
								color="black"
								fontWeight="normal"
								className="dark:text-white"
							>
								{`${
									mode === HUNDREDS_MODE
										? total
										: formatHoursMinutes(total)
								} h`}
							</Text>
						</td>
						<td />
					</>
				)}
				{name === "notWorkedDay" &&
					days.find((item) => item.isVacationDay) && (
						<>
							<TimesheetTableRowTitleCell
								title={title}
								subtitle={subtitle}
							/>
							{days && days.length
								? days.map(({ date, isVacationDay }, index) => (
										<Fragment key={date}>
											{isVacationDay ? (
												<>
													<td className="">
														<input
															type="checkbox"
															name={`tsDays[${index}][${name}]`}
															checked={
																values.tsDays[
																	index
																][name]
															}
															onChange={() => {
																setFieldValue(
																	`tsDays[${index}][${name}]`,
																	!values
																		.tsDays[
																		index
																	][name]
																);
															}}
															disabled={
																locked ||
																!editable
															}
														/>
													</td>
												</>
											) : (
												<td />
											)}
										</Fragment>
								  ))
								: null}
							<td />
						</>
					)}
				<ConfirmModal
					isOpen={confirmInputModal}
					onClose={() => toggleConfirmInputModal(false)}
					onConfirm={handleConfirmWorkDay}
					title={t("global.warning")}
					text={t("timesheets.edition.activateCell")}
					size="s"
				/>
			</tr>
		</>
	);
};
