import React from "react";

import { ErrorMessage, useFormikContext } from "formik";
import { t } from "i18next";

import { InfoMessage, Input } from "@zolteam/react-ras-library";
import { IField } from "components/types";

import { cn } from "utils";

const Field: React.FC<IField> = ({
	type,
	name = "",
	label = "",
	readOnly = false,
	className,
	children = null,
	maxLength,
	valueLength,
	containerClassName,
	pictoName,
	disabled = false,
	required = false,
	...props
}) => {
	const { getFieldProps } = useFormikContext<any>();

	const value = getFieldProps(name).value;

	const currentValueLength = valueLength ?? value?.length ?? 0;

	return (
		<div className={cn(["relative", containerClassName])}>
			<Input
				onPictoClick={() => {}} // used to fix unused prop error from zol component
				type={type}
				name={name}
				label={label}
				className={cn([
					"relative [&>*>input]:placeholder:text-sm dark:[&>*:first-child]:!bg-neutral-800 dark:[&_input]:text-white",
					className,
					disabled
						? "[&>*]:!bg-neutral-100 opacity-70"
						: "[&>*:first-child]:bg-white rounded-full",
				])}
				readOnly={readOnly}
				maxLength={maxLength}
				pictoName={pictoName}
				disabled={disabled}
				{...props}
			>
				<ErrorMessage name={name}>
					{(msg: string) => (
						<InfoMessage color="error" withIcon>
							{msg}
						</InfoMessage>
					)}
				</ErrorMessage>

				{!!maxLength && (
					<span
						className={cn([
							"absolute text-xs top-2 right-3 text-neutral-400 dark:!bg-transparent",
						])}
					>
						{currentValueLength || 0}&nbsp;/&nbsp;{maxLength}
					</span>
				)}
			</Input>
			{required && (
				<span className="mt-1 ml-6 text-xs italic ml- text-neutral-400">
					{t("forms.field.mandatory")}
				</span>
			)}
			{children}
		</div>
	);
};
export default Field;
