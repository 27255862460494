import gsap from "gsap";

export const adminLayoutAnimations = () => {
	const IS_MOBILE = window.innerWidth < 640;

	gsap.fromTo(
		".header",
		{
			position: "relative",
			top: 100,
			zIndex: 2,
		},
		{ top: 0, duration: 1, zIndex: 4 }
	);

	if (IS_MOBILE) return;
	// menu & logo are not visible on mobile
	gsap.fromTo(
		".menu",
		{
			position: "relative",
			right: "-100%",
		},
		{ right: 0, duration: 1, delay: 0.3 }
	);

	gsap.fromTo(
		".logo",
		{
			opacity: 0,
		},
		{
			opacity: 1,
			duration: 2,
			delay: 0.6,
		}
	);
};
