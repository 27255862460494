// function used to find the first element from the top that has an error
export const treatErrors = (errors: any, setFieldTouched?: any) => {
	const errorKeys = Object.keys(errors);
	let higher: any = null;

	const findElem = (key: string) => {
		// if the error is an object, we need to iterate through it
		if (typeof errors[key] === "object") {
			const childErrors = errors[key];

			childErrors.forEach((childError: any, index: number) => {
				if (!childError) return;
				const keys = Object.keys(childError);
				// we iterate through the child errors
				keys.forEach((childKey) => {
					findElem(`${key}.${index}.${childKey}`);
				});
			});
			return;
		}
		const element =
			document.querySelector(`[name="${key}"]`) ||
			document.querySelector(`[data-testid="select-${key}"]`);
		if (element) {
			setFieldTouched?.(key, true);
			const elementTop = element.getBoundingClientRect().top;
			if (!higher || elementTop < higher.getBoundingClientRect().top)
				higher = element;
		}
	};

	// Find the first element from the top that has an error
	errorKeys.forEach(findElem);

	if (higher) {
		higher.scrollIntoView({
			behavior: "smooth",
			block: "center",
		});
	}
};
