import * as Sentry from "@sentry/react";

import api from "./api";
import { ICommercialProposition, TId } from "components/types";

import { CONFIG_EXPORT_PDF, CONFIG_EXPORT_XLSX } from "constants_globals";

class CommercialPropositionServiceClass {
	getAgencies = () =>
		api.get(`/commercial-proposition/agencies`).then((resp) => resp?.data);

	getClients = (tempoDirectory: any, tempoId: string) =>
		api
			.post(
				`/commercial-proposition/${tempoDirectory}/clients/${tempoId}`
			)
			.then((resp) => resp?.data);

	getClientsContact = (data: any) =>
		api
			.post(`/commercial-proposition/clients-contact`, data)
			.then((resp) => resp?.data);

	saveCommercialProposition = (data: any, id?: TId) =>
		api
			.post(`/commercial-proposition/save${id ? `/${id}` : ""}`, data)
			.then((resp) => resp?.data);

	fetchCommercialProposition = (id: TId) =>
		api.get(`/commercial-proposition/${id}`).then((resp) => resp?.data);

	fetchPricingConditionsType = (signal?: AbortSignal) =>
		api
			.get(`/commercial-proposition/pricing-types`, {
				signal,
			})
			.then((resp) => resp?.data);

	commercialPropositionsList = (data: any, signal: AbortSignal) =>
		api
			.post(`/commercial-proposition/search`, data, {
				signal,
			})
			.then((resp) => resp?.data);

	sendCommercialProposition = (id: TId, OS: ICommercialProposition) =>
		api.post(`/commercial-proposition/signature/sign/${id}`).then(
			(resp) => resp?.data,
			(error) => {
				Sentry.captureException(error, {
					tags: {
						section: "commercial-proposition",
					},
					extra: {
						...error,
						os: OS,
						rawOS: JSON.stringify(OS),
						rawError: JSON.stringify(error),
					},
				});
				throw error;
			}
		);

	downloadCommercialPropositionPDF = (id: TId) =>
		api.get(`/commercial-proposition/${id}/download`, CONFIG_EXPORT_PDF);

	commercialPropositionExport = (data: any) =>
		api.post(`/commercial-proposition/export`, data, CONFIG_EXPORT_XLSX);

	changeStatusNoActionTaken = (commercialPropositionId: TId, data: any) =>
		api
			.post(
				`/commercial-proposition/no-action-taken/${commercialPropositionId}`,
				data
			)
			.then((resp) => resp?.data);

	getQualifications = (data: any) =>
		api
			.post(`/commercial-proposition/qualifications`, data)
			.then((resp) => resp?.data);

	patchCommercialProposition = (id: TId, data: any) =>
		api
			.patch(`/commercial-proposition/${id}`, data)
			.then((resp) => resp?.data);
}

const CommercialPropositionService = new CommercialPropositionServiceClass();
export default CommercialPropositionService;
