import React from "react";

import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";

import { Button, Tag, Picto } from "@zolteam/react-ras-library";
import { UserStatusTag } from "components/atoms";
import { IUserDetails } from "components/types";

import { cn } from "utils/cn";

interface IAdminUserRowProps {
	addUser: (user: IUserDetails) => void;
	showUserProfile: (user: IUserDetails) => void;
	isSelected: (user: IUserDetails) => boolean;
	navigate: NavigateFunction;
	URLSearchParam: string;
}

export const AdminUsersTableColumns = [
	{
		name: "firstName",
		value: "fullName",
		render: (item: IUserDetails) => `${item.firstName} ${item.lastName}`,
		className: "pl-8",
	},
	{
		name: "email",
		value: "email",
	},
	{
		name: "status",
		value: "status",
		className: "text-center",
		render: (item: IUserDetails) => <UserStatusTag status={item.status} />,
	},
	{
		name: "action",
		value: "action",
		render: (user: IUserDetails, rowProps: IAdminUserRowProps) =>
			ActionColumnRender(user, rowProps),
	},
];

const ActionColumnRender = (
	user: IUserDetails,
	{ addUser, isSelected, navigate, URLSearchParam }: IAdminUserRowProps
) => {
	const selected = isSelected(user);
	const { active, isEmailValid } = user;
	if (active && isEmailValid)
		return (
			<Button
				color="transparentPrimary"
				size="s"
				type="button"
				onClick={() =>
					navigate(`/admin-users/profile/${user.id}`, {
						state: { URLSearchParam },
					})
				}
			>
				{t("adminUsers.showUserProfile")}
			</Button>
		);
	if (!active && isEmailValid)
		return (
			<Button
				color={selected ? "grey" : "primary"}
				size="s"
				onClick={() => addUser(user)}
				type="button"
			>
				{t(selected ? "adminUsers.removeUser" : "adminUsers.addUser")}
				<Picto
					icon={selected ? "close" : "plus"}
					className={cn([
						" ml-2 !-mr-1",
						selected
							? "!w-3 !h-3 [&_g]:!fill-neutral-500"
							: "!w-5 !h-5",
					])}
				/>
			</Button>
		);
	if (!isEmailValid)
		return <Tag color="transparent">{t("adminUsers.unvalidMail")}</Tag>;
	return null;
};
