import { t } from "i18next";

export const REVIEW_STATUS_WAITING = "waiting";
export const REVIEW_STATUS_RATED = "rated";
export const REVIEW_STATUS_NOT_RATED = "not_rated";
export const REVIEW_STATUS_LOCKED = "locked";

export const REVIEWS_STATUS = [
	{
		name: t("global.all"),
		value: undefined,
	},
	{
		name: t("reviews.status.waitingFilter"),
		value: REVIEW_STATUS_WAITING,
	},
	{
		name: t("reviews.status.rated"),
		value: REVIEW_STATUS_RATED,
	},
	{
		name: t("reviews.status.notRated"),
		value: REVIEW_STATUS_NOT_RATED,
	},
];
