import React from "react";

import { t } from "i18next";

import { Title, Text, Button, Picto } from "@zolteam/react-ras-library";

import { cn } from "utils";

import "./ActionResult.scss";

interface ISuccessPropsBox {
	type?: "success" | "error" | "warning" | "info";
	title?: string;
	text: string | React.ReactNode;
	button?: {
		text?: string;
		color?: "primary" | "grey" | "transparent";
		isLoading?: boolean;
	};
	onBack?: () => void;
	picto?: string;
}

export const ActionResult: React.FC<ISuccessPropsBox> = ({
	onBack,
	title,
	text,
	button,
	type = "success",
	picto,
}) => {
	return (
		<div className="items-center justify-center gap-4 ActionResult col">
			{!!title && (
				<Title
					tag="h2"
					className="!text-xl text-center dark:text-white"
					lineHeight="m"
				>
					{title}
				</Title>
			)}
			<Picto
				icon={
					picto ??
					(type === "success" ? "checkCircle" : "alertCircle")
				}
				className={cn([`w-16 h-16 my-4 ${type}`])}
			/>
			<Text
				tag="p"
				size="paragraph02"
				lineHeight="s"
				className="mb-2 text-center dark:text-white"
			>
				{text}
			</Text>
			{onBack && (
				<Button
					color={button?.color ?? "primary"}
					type="button"
					onClick={onBack}
					isLoading={button?.isLoading}
				>
					{button?.text ?? t("global.back")}
				</Button>
			)}
		</div>
	);
};
