import React, { useState } from "react";

import { Form, Formik } from "formik";
import { t } from "i18next";
import * as Yup from "yup";

import { Button, InfoMessage } from "@zolteam/react-ras-library";
import { PasswordField } from "components/molecules";

export interface IResetPasswordFormValues {
	password: string;
	passwordConfirm: string;
}

interface IResetPasswordFormProps {
	isCreate?: boolean;
	onSubmit: (
		values: IResetPasswordFormValues,
		formikHelpers: any
	) => Promise<any>;
}

const ResetPasswordFormValidation = () => {
	return Yup.object().shape({
		password: Yup.string()
			.required(t("forms.password.required"))
			.test(
				"password",
				t("forms.password.constraintsText"),
				(value) =>
					!!value &&
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*,/\\.;])/.test(
						value
					)
			)
			.min(8, t("forms.password.constraintsText")),
		passwordConfirm: Yup.string()
			.required(t("forms.password.required"))
			.oneOf(
				[Yup.ref("password")],
				t("forms.password.confirmPassInvalid")
			),
	});
};

export const ResetPasswordForm: React.FC<IResetPasswordFormProps> = ({
	isCreate,
	onSubmit,
}) => {
	const [SendError, setSendError] = useState("");
	return (
		<Formik
			initialValues={{
				password: "",
				passwordConfirm: "",
			}}
			validationSchema={ResetPasswordFormValidation}
			onSubmit={(values, formikHelpers) => {
				setSendError("");
				return onSubmit(values, formikHelpers).catch((error) =>
					setSendError(error)
				);
			}}
		>
			{({ isValid, dirty, isSubmitting }) => {
				const canSubmit = isValid && dirty;
				return (
					<Form className="gap-4 col">
						<PasswordField
							type="text"
							label={t("forms.password.label")}
							name="password"
							disabled={isSubmitting}
						/>
						<PasswordField
							type="text"
							label={t("forms.password.confirmPass")}
							name="passwordConfirm"
							disabled={isSubmitting}
						/>
						{!!SendError && (
							<div className="mt-2">
								<InfoMessage color="error" withIcon>
									{t("API_ERRORS.send")}
								</InfoMessage>
							</div>
						)}

						<Button
							type="submit"
							disabled={!canSubmit || isSubmitting}
							className="w-full mt-4"
							color="primary"
							isLoading={isSubmitting}
						>
							{t(
								isCreate
									? "createPassword.button"
									: "resetPassword.button"
							)}
						</Button>
					</Form>
				);
			}}
		</Formik>
	);
};
