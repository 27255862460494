import React, { useState } from "react";

import { t } from "i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import RatingService from "services/RatingsService";

import { useList } from "hooks/useList";
import useAppContext from "store/useAppContext";

import { REVIEWS_COLUMNS } from "./ReviewsColumns";
import { reviewsItemsActions } from "./ReviewsItemsActions";
import { Button, Picto } from "@zolteam/react-ras-library";
import {
	AsyncList,
	ContactAgencyModal,
	ReviewsFilters,
} from "components/organisms";
import { PageLayout } from "components/templates";
import { IReview } from "components/types";
import { REVIEW_STATUS_RATED } from "constants_globals/reviews";

import { cleanObject, handleFileSave } from "utils";

export interface IReviewsProps {}

export const Reviews: React.FC<IReviewsProps> = () => {
	const useListHook = useList<IReview>({
		defaultSort: "status",
		pagination: {
			pageSize: 50,
		},
	});

	const { getAgencies } = useAppContext();
	const activeAgenciesIds = getAgencies(true).map((agency) => agency.id);
	const [ReviewToSend, setReviewToSend] = useState<IReview | undefined>(
		undefined
	);

	const {
		Pagination,
		setPagintation,
		Filters,
		setFilter,
		changeFilters,
		getFiltersKeys,
		getPaginationKeys,
		IsExporting,
		setIsExporting,
		Selected,
	} = useListHook;

	const navigate = useNavigate();

	const fetchReviews = (abortSignal: AbortSignal) => {
		return RatingService.ratingSearch(
			{
				...cleanObject(Filters, true),
				agencyIds: activeAgenciesIds,
				freeSearch: Filters.search || undefined,
				page: Pagination.currentPage,
				limit: Pagination.pageSize,
			},
			abortSignal
		).then((resp) => {
			setPagintation({
				...Pagination,
				totalCount: resp.search.total,
			});
			return resp;
		});
	};

	const handleDownloadSelected = () => {
		var bodyFormData = new FormData();
		bodyFormData.append(
			"ids",
			JSON.stringify({ ids: Selected.map((s) => s.id.toString()) })
		);
		toast.loading(t("promiseToast.fetching"), {
			toastId: `reviews-download`,
		});
		setIsExporting(true);
		const prom = RatingService.ratingDownloadByIds(bodyFormData).finally(
			() => {
				setIsExporting(false);
				toast.dismiss(`reviews-download`);
			}
		);
		handleFileSave(prom, `reviews-${moment().format(t("dates.full"))}.zip`);
	};

	const handleExport = () => {
		setIsExporting(true);
		const prom = RatingService.ratingExportBySearch({
			...cleanObject(Filters),
			freeSearch: Filters.search || undefined,
			page: Pagination.currentPage,
			pageSize: Pagination.pageSize,
		}).finally(() => setIsExporting(false));

		handleFileSave(
			prom,
			`reviews-${moment().format(t("dates.full"))}.xlsx`
		);
	};

	const enableActions = [REVIEW_STATUS_RATED, undefined].includes(
		Filters.status
	);

	return (
		<PageLayout
			title={t("reviews.title")}
			headerProps={{
				className:
					"[&>div:last-child]:w-full [&>div:last-child]:xs:w-auto  [&>div:last-child]:ml-auto",
				children: (
					<Button
						outline
						color="primary"
						type="button"
						onClick={handleExport}
						className="w-full"
						isLoading={IsExporting}
					>
						<Picto icon="download" />
						{t("global.exportList")}
					</Button>
				),
			}}
		>
			<AsyncList
				filters={
					<ReviewsFilters
						filters={Filters}
						setFilter={setFilter}
						setDates={(dates) => {
							changeFilters({
								...dates,
							});
						}}
					/>
				}
				query={{
					queryKey: [
						"Reviews",
						activeAgenciesIds.join(","),
						...getPaginationKeys(),
						...getFiltersKeys(),
					],
					queryFn: ({ signal }) => fetchReviews(signal),
					retry: 0,
				}}
				columns={REVIEWS_COLUMNS}
				className="overflow-visible"
				itemsKey="results"
				selectable={
					!!enableActions
						? (item) => item.status === REVIEW_STATUS_RATED
						: false
				}
				scrollParentSelector="AdminLayout__header"
				actions={
					!!enableActions && [
						{
							label: t("reviews.download", {
								count: Selected?.length,
							}),
							icon: "download",
							onClick: handleDownloadSelected,
							disabled: !Selected?.length || IsExporting,
							isLoading: IsExporting,
						},
					]
				}
				itemActions={(item: IReview) =>
					reviewsItemsActions({
						item,
						navigate,
						handleContactAgency: () => setReviewToSend(item),
					})
				}
				useListHook={useListHook}
			/>
			<ContactAgencyModal
				initialValues={{
					theme: "rating",
					agencyId: ReviewToSend?.agencyId,
					tempWorker: ReviewToSend?.contract?.tempWorkerFullName,
					contractKey: `${ReviewToSend?.contract?.contractNumber}${
						ReviewToSend?.contract?.amendmentNumber !== "0"
							? `.${ReviewToSend?.contract?.amendmentNumber}`
							: ""
					}`,
					siteId: ReviewToSend?.contract?.siteId,
				}}
				isDisplayed={!!ReviewToSend}
				onClose={() => {
					setReviewToSend(undefined);
				}}
			/>
		</PageLayout>
	);
};
