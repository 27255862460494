import React from "react";

import { FieldArray, useFormikContext } from "formik";

import { IUsersConfigListFormValues } from "./UsersConfigListFormValidation";
import { UserSummary } from "components/organisms";
import { IUserDetailsForConfig } from "components/types";

export const UsersConfigListForm: React.FC = () => {
	const { values, setFieldValue } =
		useFormikContext<IUsersConfigListFormValues>();

	const handleSelect = (item: IUserDetailsForConfig, checked: boolean) => {
		const newUsers = values.Users.map((user: IUserDetailsForConfig) => {
			if (user.email === item.email) {
				return {
					...user,
					isSelected: checked,
				};
			}
			return user;
		});
		setFieldValue("Users", newUsers);
	};

	const handleUserUpdate = (user: IUserDetailsForConfig) => {
		const newUsers = values.Users.map((prevUser: IUserDetailsForConfig) => {
			if (prevUser.email === user.email) {
				return user;
			}
			return prevUser;
		});
		setFieldValue("Users", newUsers);
	};

	return (
		<FieldArray
			name="Users"
			render={() => {
				return (
					<div className="w-full text-sm col">
						{values.Users.map(
							(user: IUserDetailsForConfig, index: number) => {
								return (
									<React.Fragment key={user.email}>
										<UserSummary
											index={index}
											user={user}
											isSelected={user.isSelected}
											handleSelect={(checked) =>
												handleSelect(user, checked)
											}
											handleUpdate={handleUserUpdate}
										/>
										<hr />
									</React.Fragment>
								);
							}
						)}
					</div>
				);
			}}
		/>
	);
};
