import React from "react";

import { t } from "i18next";

import { Spinner } from "components/atoms";
import { ActionResult, ThreadListItem } from "components/molecules";
import { IThreadSummary } from "components/types";

import { STATUS_ARCHIVED } from "constants_globals";

export interface IThreadsListProps {
	isLoading: boolean;
	threads?: IThreadSummary[];
	activeThread?: IThreadSummary;
	onItemClick?: (thread: IThreadSummary) => void;
	handleNewThread?: () => void;
	activeState?: "active" | "archived";
}

export const ThreadsList: React.FC<IThreadsListProps> = ({
	isLoading,
	threads,
	activeThread,
	onItemClick,
	handleNewThread = () => {},
	activeState,
}) => {
	if (isLoading) return <Spinner />;
	if (!threads?.length)
		return (
			<div className="mt-4">
				<ActionResult
					title={
						activeState === STATUS_ARCHIVED
							? t("threads.noArchivedThread")
							: t("threads.noThread")
					}
					text={
						activeState !== STATUS_ARCHIVED &&
						t("threads.noThreadText")
					}
					type="info"
					picto="messageSquare"
					button={{
						text: t("threads.new"),
						color: "primary",
						isLoading: false,
					}}
					onBack={
						activeState !== STATUS_ARCHIVED
							? () => handleNewThread()
							: undefined
					}
				/>
			</div>
		);

	return (
		<div className="flex flex-col h-full w-full overflow-auto overflow-x-hidden [&>*:not(:last-child)]:border-b px-2">
			{threads.map((thread) => {
				const isActive = activeThread?.id === thread.id;
				return (
					<ThreadListItem
						key={thread.id}
						thread={thread}
						isActive={isActive}
						onClick={() => {
							onItemClick?.(thread);
						}}
					/>
				);
			})}
		</div>
	);
};
