import * as Yup from "yup";

import {
	CONTRACT_THEME,
	INVOICE_THEME,
	MAX_CONTACT_MESSAGE_LENGTH,
	MAX_CONTACT_SUBJECT_LENGTH,
	RATING_THEME,
	TIMESHEET_THEME,
} from "constants_globals";

export interface IContactAgencyFormValues {
	agencyId?: number;
	siteId?: number;
	theme?: string;
	tempWorker?: string;
	contractKey?: string;
	invoiceNumber?: string;
	subject?: string;
	content?: string;
}

export const ContactAgencyFormInitValues: IContactAgencyFormValues = {
	agencyId: 0,
	theme: "",
	tempWorker: "",
	contractKey: "",
	invoiceNumber: "",
	subject: "",
	content: "",
};

export const ContactAgencyFormValidation = (t: any) =>
	Yup.object().shape({
		agencyId: Yup.string().required(t("forms.field.required")),
		theme: Yup.string().required(t("forms.field.required")),
		siteId: Yup.number().nullable().required(t("forms.field.required")),
		tempWorker: Yup.string().when("theme", {
			is: (val: string) =>
				[CONTRACT_THEME, TIMESHEET_THEME, RATING_THEME].includes(val),
			then: () => Yup.string().required(t("forms.field.required")),
			otherwise: () => Yup.string().nullable(),
		}),
		contractKey: Yup.string().when("theme", {
			is: (val: any) =>
				[CONTRACT_THEME, TIMESHEET_THEME, RATING_THEME].includes(val),
			then: () =>
				Yup.string()
					.required(t("forms.field.required"))
					.max(15, t("forms.field.max", { max: 15 })),
			otherwise: () =>
				Yup.string()
					.nullable()
					.max(15, t("forms.field.max", { max: 15 })),
		}),
		invoiceNumber: Yup.string().when("theme", {
			is: (val: any) => [INVOICE_THEME].includes(val),
			then: () =>
				Yup.string()
					.required(t("forms.field.required"))
					.matches(/^[0-9]+$/, t("forms.field.digitOnly"))
					.max(15, t("forms.field.max", { max: 15 })),
			otherwise: () =>
				Yup.string()
					.nullable()
					.matches(/^[0-9]+$/, t("forms.field.digitOnly"))
					.max(15, t("forms.field.max", { max: 15 })),
		}),
		subject: Yup.string()
			.max(
				MAX_CONTACT_SUBJECT_LENGTH,
				t("forms.message.max", {
					max: MAX_CONTACT_SUBJECT_LENGTH,
				})
			)
			.required(t("forms.field.required")),
		content: Yup.string()
			.max(
				MAX_CONTACT_MESSAGE_LENGTH,
				t("forms.message.max", {
					max: MAX_CONTACT_MESSAGE_LENGTH,
				})
			)
			.required(t("forms.field.required")),
	});
