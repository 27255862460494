import React from "react";

import { Formik } from "formik";
import { t } from "i18next";
import { promiseToast } from "toastify";

import ThreadService from "services/ThreadsService";

import useAppContext from "store/useAppContext";

import { ModalV2 } from "@zolteam/react-ras-library";
import { ConfirmModal } from "components/molecules";
import { IAgency, ISite } from "components/types";
import {
	ContactAgencyForm,
	ContactAgencyFormInitValues,
	ContactAgencyFormValidation,
	IContactAgencyFormValues,
} from "forms";

import { CONTACT_THEMES, ROLE_AGENCY } from "constants_globals";

interface IContactAgencyModalProps {
	initialValues?: IContactAgencyFormValues;
	onSubmit?: (values: IContactAgencyFormValues, resetForm: any) => any;
	isDisplayed: boolean;
	onClose: (isSubmited?: boolean) => void;
	agencies?: IAgency[];
	sites?: ISite[];
	title?: string;
}

export const ContactAgencyModal: React.FC<IContactAgencyModalProps> = ({
	initialValues,
	onSubmit,
	isDisplayed,
	onClose,
	agencies,
	sites,
	title = t("global.contactAgencyModalTitle"),
}) => {
	const [isConfirmModalDisplayed, setIsConfirmModalDisplayed] =
		React.useState(false);
	const formRef = React.useRef<any>(null);
	const { appState, getAgencies, getSites } = useAppContext();

	const handleClose = () => {
		if (!formRef.current || formRef.current.isSubmitting) return;

		if (formRef.current.dirty) {
			setIsConfirmModalDisplayed(true);
			return;
		}
		onClose();
	};

	const handleSendContact = (
		values: IContactAgencyFormValues,
		resetForm: any
	) => {
		const prom = onSubmit
			? onSubmit(values, resetForm)
			: ThreadService.postThreadFromOther({
					...values,
			  }).then((resp) => {
					onClose(true);
			  });

		promiseToast(prom, {
			success: t("forms.message.success"),
			error: t("forms.message.error"),
		});

		return prom;
	};

	const sitesList = sites || getSites();

	return (
		<>
			<ModalV2
				isDisplayed={isDisplayed}
				onClose={handleClose}
				size="m"
				title={title}
				className="dark:[&>div:first-child]:bg-neutral-800 dark:[&>div:first-child]:text-white dark:bg-neutral-800"
			>
				<Formik
					initialValues={{
						...ContactAgencyFormInitValues,
						siteId: sitesList?.[0]?.id,
						...(initialValues ?? {}),
					}}
					enableReinitialize
					validationSchema={ContactAgencyFormValidation(t)}
					onSubmit={(values, { resetForm }) => {
						if (
							appState.auth &&
							appState.auth.roles.find(
								(role: string) => role === ROLE_AGENCY
							)
						)
							return;

						return handleSendContact(values, resetForm);
					}}
					innerRef={formRef}
				>
					{({ isSubmitting }) => (
						<div>
							{sitesList && (
								<ContactAgencyForm
									load={isSubmitting}
									agencies={agencies || getAgencies()}
									themes={CONTACT_THEMES}
									sites={sitesList}
									error=""
								/>
							)}
						</div>
					)}
				</Formik>
			</ModalV2>
			<ConfirmModal
				isOpen={isConfirmModalDisplayed}
				onClose={() => {
					setIsConfirmModalDisplayed(false);
				}}
				onConfirm={() => {
					onClose();
					setIsConfirmModalDisplayed(false);
				}}
				title={t("global.warning")}
				text={t("contact.closeConfirm")}
				size="s"
			/>
		</>
	);
};
