import React from "react";

import { t } from "i18next";

import { useQuery } from "@tanstack/react-query";

import CommercialPropositionService from "services/CommercialPropositionsService";

import { Select } from "@zolteam/react-ras-library";

import { cn } from "utils";

type TCategories =
	| "tariffConditionsDelegation"
	| "tariffConditionsManagement"
	| "feesInCaseOfHiring"
	| "billingMethod"
	| "deadline";
export interface IPricingTypesSelectProps {
	category?: TCategories;
	name: string;
	required?: boolean;
	[key: string]: any;
}

export const PricingTypesSelect: React.FC<IPricingTypesSelectProps> = ({
	category,
	name,
	required,
	...props
}) => {
	const fetchPricingConditionsType = (signal: AbortSignal) =>
		CommercialPropositionService.fetchPricingConditionsType(signal);

	const { isLoading, data } = useQuery({
		queryKey: ["CommercialProposition-PricingTypes"],
		queryFn: ({ signal }) => fetchPricingConditionsType(signal),
	});

	return (
		<div>
			<Select
				options={
					category
						? data?.[category]?.map((item: any) => {
								return {
									...item,
									name: t(
										`commercialPropositions.pricingTypes.${category}.${item.id}`
									),
								};
						  })
						: []
				}
				name={name}
				isLoading={isLoading}
				getOptionLabel={(option: any) => option.name}
				getOptionValue={(option: any) => option.id}
				className={cn([
					"[&_p:empty]:hidden",
					props.disabled && "disabledSelect",
				])}
				{...props}
				value={
					props.value &&
					typeof props.value !== "object" &&
					data &&
					category
						? data[category]?.find(
								(option: any) => option.id === props.value
						  )
						: props.value
				}
			/>
			{required && (
				<span className="ml-6 text-xs italic text-neutral-400">
					{t("forms.field.mandatory")}
				</span>
			)}
		</div>
	);
};
