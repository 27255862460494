import React, { useState } from "react";

import { t } from "i18next";
import moment from "moment";
import { promiseToast } from "toastify";

import TimesheetsService from "services/TimeSheetsService";

import useAppContext from "store/useAppContext";

import { Button, Picto, Title } from "@zolteam/react-ras-library";
import { ContractNumber, Spinner } from "components/atoms";
import { handleTimeSheetDownload } from "components/templates";
import { ITimeSheet } from "components/types";

import { cn, handleFileSave } from "utils";

import {
	TIMESHEET_DOWNLOAD_PERMISSION,
	TIMESHEET_STATUS_EDITABLE,
} from "constants_globals";

export interface ITimesheetHeadProps {
	timesheet: ITimeSheet;
	currentOrderReference?: string;
	handleOrderReference?: (value: string) => void;
	showTempWorkerName?: boolean;
	isLoading?: boolean;
	locked?: boolean;
}

export const TimesheetHead: React.FC<ITimesheetHeadProps> = ({
	timesheet,
	currentOrderReference,
	handleOrderReference,
	showTempWorkerName,
	isLoading,
	locked,
}) => {
	const [isDownloading, setIsDownloading] = useState(false);
	const [isDownloadingCalendar, setIsDownloadingCalendar] = useState(false);
	const [isDownloadingC1B, setIsDownloadingC1B] = useState(false);

	const { hasPermissions } = useAppContext();

	const handleDownload = () => {
		setIsDownloading(true);
		return handleTimeSheetDownload(timesheet.id).finally(() => {
			setIsDownloading(false);
		});
	};

	const handleCalendarDownload = () => {
		const count = timesheet.stsFilesCount;
		let promCall = TimesheetsService.downloadCalendar;
		if (count > 1) promCall = TimesheetsService.downloadCalendarsZip;

		const defaultTitle =
			count === 1
				? `releve-heure-${timesheet.id}${moment(
						new Date(),
						t("dates.full")
				  )}.pdf`
				: `calendars-${timesheet.id}${moment(
						new Date(),
						t("dates.full")
				  )}.zip`;

		setIsDownloadingCalendar(true);
		const prom = promCall(timesheet.id).finally(() => {
			setIsDownloadingCalendar(false);
		});

		handleFileSave(prom, defaultTitle);
		promiseToast(prom, {
			success: t("promiseToast.fetchSuccess"),
		});
		return prom;
	};

	const handleC1BFileType = () => {
		setIsDownloadingC1B(true);

		const promise = new Promise((resolve, reject) => {
			return TimesheetsService.timesheetC1bType(timesheet.id).then(
				(resp) => {
					let prom: any = Promise.resolve();
					if (resp.type === "zip")
						prom = TimesheetsService.timesheetC1bMultiDownload(
							timesheet.id
						);
					else
						prom = TimesheetsService.timesheetC1bDownload(
							timesheet.id
						);

					prom.catch(reject);
					prom.finally(() => {
						setIsDownloadingC1B(false);
					});
					const defaultExtendsion =
						resp.type === "zip" ? "zip" : "C1B";
					return handleFileSave(
						prom,
						`c1b-${timesheet.id}.${defaultExtendsion}`
					).then(() => {
						resolve(true);
					});
				},
				reject
			);
		});
		promise.finally(() => {
			setIsDownloadingC1B(false);
		});
		promiseToast(promise, {
			success: t("promiseToast.fetchSuccess"),
		});
		return promise;
	};

	const canDownload = hasPermissions([TIMESHEET_DOWNLOAD_PERMISSION]);

	return (
		<div
			className={cn([
				"flex-wrap items-center justify-between gap-4 pb-6 border-b row border-neutral-100 dark:text-white",
				isLoading && "pointer-events-none opacity-70",
			])}
		>
			<div>
				{showTempWorkerName && (
					<Title tag="h3" size="heading03">
						{timesheet.tempWorkerFullName}
					</Title>
				)}
				<div className="flex-wrap items-center gap-4 gap-y-2 row">
					<div className="col">
						<b>
							<ContractNumber
								number={timesheet.contractNumber}
								amendmentNumber={timesheet.amendmentNumber}
							/>
						</b>
						{!!currentOrderReference && (
							<span className="mr-2 text-neutral-400 max-w-[100px]">
								{currentOrderReference}
							</span>
						)}
					</div>
					<span>{timesheet.qualification}</span>
					<span>{timesheet.siteSocialReason}</span>
					<span className="text-xs text-neutral-400">
						{`${t("contracts.contract")} ${t("dates.fromTo", {
							from: moment(timesheet?.contractStartDate).format(
								t("dates.medium")
							),
							to: moment(timesheet?.contractEndDate).format(
								t("dates.medium")
							),
						}).toLocaleLowerCase()}`}
					</span>
				</div>
				{TIMESHEET_STATUS_EDITABLE.includes(timesheet.status) && (
					<div className="max-w-2xl text-xs flex flex-row items-center gap-2 text-primary-500 pt-2">
						<Picto icon="info" className="w-5" />
						{t("timesheets.infoMessage")}
					</div>
				)}
			</div>
			{isLoading ? (
				<Spinner />
			) : (
				<div className="flex-wrap items-center justify-end gap-4 ml-auto row">
					{canDownload && timesheet.status !== "waiting" && (
						<Button
							type="button"
							outline
							color="primary"
							onClick={(e: any) => {
								e.stopPropagation();
								handleDownload();
							}}
							size="s"
							isLoading={isDownloading}
						>
							<Picto icon="download" />
							{t("file.download")}
						</Button>
					)}
					{canDownload && timesheet.isFromSTS && (
						<>
							{!!timesheet.stsFilesCount && (
								<Button
									type="button"
									outline
									color="primary"
									onClick={(e: any) => {
										e.stopPropagation();
										handleCalendarDownload();
									}}
									size="s"
									isLoading={isDownloadingCalendar}
								>
									<Picto icon="download" />
									{t("timesheets.calendarDownload", {
										count: timesheet.stsFilesCount,
									})}
								</Button>
							)}
							<Button
								type="button"
								outline
								color="primary"
								onClick={(e: any) => {
									e.stopPropagation();
									handleC1BFileType();
								}}
								size="s"
								isLoading={isDownloadingC1B}
							>
								<Picto icon="download" />
								{t("timesheets.c1bDownload")}
							</Button>
						</>
					)}
					{handleOrderReference && (
						<OrderReference
							timesheet={timesheet}
							onSave={handleOrderReference}
							locked={locked}
						/>
					)}
				</div>
			)}
		</div>
	);
};

interface IOrderReferenceProps {
	timesheet: ITimeSheet;
	onSave: (value: any) => void;
	locked?: boolean;
}

const OrderReference = ({
	timesheet,
	onSave,
	locked,
}: IOrderReferenceProps) => {
	const isEditing = timesheet.status === "waiting";

	const handleOrderReference = (value: string) => {
		onSave(value);
	};

	if (!isEditing && !timesheet.orderReference?.length) return null;

	return (
		<div className="gap-1 col">
			<label
				htmlFor="orderReference"
				className={cn([
					"text-xs text-neutral-500",
					isEditing && "text-right",
				])}
			>
				{t("timesheets.edition.orderReference")}
			</label>
			{isEditing ? (
				<input
					type="text"
					name="orderReference"
					value={timesheet.orderReference ?? ""}
					placeholder={t(
						"timesheets.edition.orderReferencePlaceholder"
					)}
					disabled={locked}
					onChange={(e) => handleOrderReference(e.target.value)}
					className="w-full min-w-[245px] px-4 py-1 border-2 rounded-full border-neutral-200 text-neutral-600"
					onClick={(e) => e.stopPropagation()}
				/>
			) : (
				<span className="text-xs font-bold text-neutral-600">
					{timesheet.orderReference}
				</span>
			)}
		</div>
	);
};
