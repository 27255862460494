import { t } from "i18next";
import moment from "moment";
import { Link } from "react-router-dom";

import { Tag, Text } from "@zolteam/react-ras-library";
import { ContractNumber } from "components/atoms";
import { IColumn } from "components/types";

import {
	TIMESHEET_EDIT_PERMISSION,
	TIMESHEET_STATUS_WAITING,
	TIMESHEET_VALIDATE_PERMISSION,
} from "constants_globals";

export const TIMESHEETS_COLUMNS: IColumn[] = [
	{
		name: t("forms.tempWorker.label"),
		value: "tempWorker",
		render: (item) => {
			return (
				<div className="">
					<Text
						tag="span"
						fontWeight="bold"
						lineHeight="m"
						className="dark:text-white"
					>
						{item.tempWorkerFullName ?? ""}
					</Text>
					<p className="text-neutral-500">
						<ContractNumber
							number={`${t("contracts.contract")} ${
								item.contractNumber
							}`}
							amendmentNumber={item.amendmentNumber}
						/>
					</p>
					{!!item.orderReference && (
						<p className="text-xs text-neutral-500">
							{item.orderReference}
						</p>
					)}
				</div>
			);
		},
	},
	{
		name: t("forms.contract.contractNumber"),
		value: "contract_number",
		sortKey: "contract",
		hidden: true,
	},
	{
		name: t("forms.sites.label"),
		value: "siteSocialReason",
		sortKey: "client",
	},
	{ name: t("forms.qualification.label"), value: "qualification" },
	{
		name: t("dates.dateStart"),
		value: "date",
		render: (item) => moment(item.startDate).format(t("dates.medium")),
		className: "font-medium",
	},
	{
		name: t("dates.dateEnd"),
		value: "endDate",
		render: (item) => moment(item.endDate).format(t("dates.medium")),
		className: "font-medium",
		sortable: false,
	},
	{
		name: t("dates.week"),
		value: "week",
		render: (item) => `${t("dates.week")} ${item.weekNumber}`,
	},
	{ name: t("forms.agencies.label"), value: "agencyName", sortable: false },
	{
		name: t("global.status"),
		value: "status",
		className: "px-5 !opacity-100",
		headerClassName: "px-5",
		render: (item, { hasPermissions, navigate }) => {
			const route =
				item.status === TIMESHEET_STATUS_WAITING &&
				hasPermissions([
					TIMESHEET_EDIT_PERMISSION,
					TIMESHEET_VALIDATE_PERMISSION,
				])
					? `/timesheets/edit/${item.id}`
					: `/timesheets/view/${item.id}`;
			return (
				<Link
					to={route}
					state={{
						timesheet: item,
						search: window.location.search,
					}}
				>
					<Tag
						color={
							item.status === TIMESHEET_STATUS_WAITING
								? "primary"
								: item.status === "done"
								? "grey"
								: "greyDark"
						}
					>
						{t(`timesheets.status.${item.status}`)}
					</Tag>
				</Link>
			);
		},
	},
];
