import React from "react";

import useTimesheetsContext from "contexts/TimesheetsContext";
import { t } from "i18next";
import moment from "moment";
import { promiseToast } from "toastify";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import TimesheetsService from "services/TimeSheetsService";

import useAppContext from "store/useAppContext";

import { BlockNavigation } from "../BlockNavigation/BlockNavigation";
import { TimesheetHead } from "../TimesheetHead/TimesheetHead";
import { Spinner } from "components/atoms";
import { ActionResult } from "components/molecules";
import { ITimeSheet, IWeek } from "components/types";
import { TimesheetEditForm } from "forms";

import {
	convertDaysBonusesHundreds,
	convertDaysBonusesString,
	defineWeekDays,
} from "utils";

import {
	TIMESHEET_ENTRY_MODE_STORAGE_KEY,
	HUNDREDS_MODE,
} from "constants_globals";

export interface ITimesheetGroupEditProps {
	currentWeek: IWeek;
	timeMode: string;
	setTimeMode: (mode: string) => void;
}

export const TimesheetGroupEdit: React.FC<ITimesheetGroupEditProps> = ({
	currentWeek,
	timeMode,
	setTimeMode,
}) => {
	const formRefs = React.useRef<any>([]);

	const { setDirty } = useTimesheetsContext();

	const { getAgencies } = useAppContext();

	const [ActiveForm, setActiveForm] = React.useState<number | null>(0);

	const activeAgenciesIds = getAgencies(true).map((a) => a.id);

	const fetchTimesheets = (abortSignal: AbortSignal) => {
		return TimesheetsService.timesheetSearch({
			dateBetweenStart: moment(currentWeek.start).format(
				t("dates.apiFilter")
			),
			dateBetweenEnd: moment(currentWeek.end).format(
				t("dates.apiFilter")
			),
			limit: 9999,
			status: "waiting",
			agencyIds: activeAgenciesIds,
		}).then((resp) => {
			setDirty([...Array(resp?.results.length).fill(false)]);
			return resp?.results;
		});
	};

	const { isLoading, data } = useQuery<any>({
		queryKey: [
			"TimesheetGroupEdit",
			activeAgenciesIds,
			currentWeek.start,
			currentWeek.end,
		],
		queryFn: ({ signal }) => fetchTimesheets(signal),
	});

	if (isLoading) return <Spinner />;
	if (!data?.length)
		return (
			<div className="[&_svg]:h-[100px] [&_svg]:w-[100px]">
				<ActionResult
					title={t("dates.forWeek", {
						week: moment(currentWeek.date).format("W"),
					})}
					text={t("timesheets.noneLeft")}
				/>
			</div>
		);

	return (
		<div>
			{data.map((timesheet: any, index: number) => {
				return (
					<TimesheetEdit
						key={timesheet.id}
						formRef={formRefs.current[index]}
						timesheet={timesheet}
						timeMode={timeMode}
						setTimeMode={setTimeMode}
						index={index}
						setActiveForm={setActiveForm}
						ActiveForm={ActiveForm}
					/>
				);
			})}
			<BlockNavigation shouldPrompt={true} />
		</div>
	);
};

interface ITimesheetEdit {
	timesheet: ITimeSheet;
	timeMode: string;
	setTimeMode: (mode: string) => void;
	formRef: any;
	index: number;
	setActiveForm: (index: number | null) => void;
	ActiveForm: number | null;
}

const TimesheetEdit: React.FC<ITimesheetEdit> = ({
	timesheet,
	timeMode,
	setTimeMode,
	formRef,
	index,
	setActiveForm,
	ActiveForm,
}) => {
	const queryClient = useQueryClient();

	const fetchTimesheet = (abortSignal: AbortSignal) => {
		return TimesheetsService.getTimesheet(timesheet?.id, abortSignal).then(
			(resp) => {
				if (resp.isFromSTS) {
					setTimeMode(HUNDREDS_MODE);
					localStorage.setItem(
						TIMESHEET_ENTRY_MODE_STORAGE_KEY,
						HUNDREDS_MODE
					);
				}
				return {
					...resp,
					...convertDaysBonusesString(
						resp.days,
						resp.bonuses,
						resp.isFromSTS ? true : timeMode === HUNDREDS_MODE
					),
				};
			}
		);
	};

	const handleSave = (timesheet: ITimeSheet, type: string, values: any) => {
		const data = {
			...timesheet,
			...convertDaysBonusesHundreds(
				values.tsDays,
				values.tsBonuses,
				timeMode === HUNDREDS_MODE
			),
			comment: values.comment,
			orderReference: values.orderReference,
		};
		const prom = (
			type === "submit"
				? TimesheetsService.timesheetValidate
				: TimesheetsService.timesheetEdit
		)(timesheet.id, data).then(() => {
			queryClient.invalidateQueries({
				queryKey: ["timesheet", timesheet.id],
			});
			queryClient.invalidateQueries({
				queryKey: ["TimesheetGroupEdit", "fetchMonth"],
			});
		});
		promiseToast(prom);
		return prom;
	};

	const { isLoading, isFetching, data } = useQuery({
		queryKey: ["timesheet", timesheet.id],
		queryFn: ({ signal }) => fetchTimesheet(signal),
		enabled: !!timesheet.id,
		refetchOnWindowFocus: false,
		placeholderData: timesheet ?? {},
	});

	if (isLoading || isFetching)
		return (
			<TimesheetHead
				timesheet={timesheet}
				showTempWorkerName
				isLoading={isLoading || isFetching}
			/>
		);

	return (
		<TimesheetEditForm
			key={data.id}
			formRef={formRef}
			timesheet={data}
			weekDays={defineWeekDays(data.startDate, data.days)}
			onSubmit={(values) => handleSave(data, "submit", values)}
			onSave={(values) => handleSave(data, "save", values)}
			timeMode={timeMode}
			onTimeModeChange={setTimeMode}
			accordionHead={{
				onToggle: (state) => {
					setActiveForm(state ? index : null);
				},
				initialState: index === ActiveForm,
			}}
			hideTimeMode
			forcedTimeMode={timeMode}
			blockNavigation={false}
			index={index}
		/>
	);
};
