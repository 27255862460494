import i18n from "i18n/i18n";

const t = i18n.t.bind(i18n);

export interface IQuestionCategory {
	name: string;
	key: string;
	questions: number[];
}

export const QuestionsCategories: IQuestionCategory[] = [
	{
		name: t("faq.categories.contracts"),
		key: "contracts",
		questions: [1],
	},
	{
		name: t("faq.categories.timesheets"),
		key: "timesheets",
		questions: [2, 3, 4, 5, 6, 7, 8, 9],
	},
	{
		name: t("faq.categories.ratings"),
		key: "ratings",
		questions: [10],
	},
	{
		name: t("faq.categories.alerts"),
		key: "alerts",
		questions: [11, 12],
	},
	{
		name: t("faq.categories.profile"),
		key: "profils",
		questions: [13, 14],
	},
	{
		name: t("faq.categories.bugs"),
		key: "bugs",
		questions: [15],
	},
].sort((a, b) => (a.name < b.name ? -1 : 1));
