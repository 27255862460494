import React from "react";

import { Text } from "@zolteam/react-ras-library";

interface IContractInfosProps {
	contractNumber?: string;
	amendmentNumber?: string;
	orderReference?: string;
}

export const ContractInfos: React.FC<IContractInfosProps> = ({
	contractNumber,
	amendmentNumber,
	orderReference,
}) => (
	<>
		{contractNumber && (
			<Text tag="p" size="paragraph02" lineHeight="s" color="grey">
				{`Contrat ${contractNumber}${
					amendmentNumber && amendmentNumber !== "0"
						? `.${amendmentNumber}`
						: ""
				}`}
			</Text>
		)}
		{orderReference && (
			<Text tag="p" size="paragraph02" lineHeight="s" color="grey">
				{orderReference}
			</Text>
		)}
	</>
);
