import React from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";

import { ICommercialPropositionFormSectionProps } from "../CommercialPropositionForm";
import { mailValidation, phoneValidation } from "./sectionsValidation";
import { InfoMessage, Title } from "@zolteam/react-ras-library";
import { Toggle } from "components/atoms";
import { CPInterlocutor } from "components/molecules";

export interface ICPRisksSectionValues {
	isRisk: boolean;
	interlocutorRiskName: string;
	interlocutorRiskFunction: string;
	interlocutorRiskPhone: string;
	interlocutorRiskEmail: string;

	isDifficult: boolean;
	interlocutorDifficultName: string;
	interlocutorDifficultFunction: string;
	interlocutorDifficultPhone: string;
	interlocutorDifficultEmail: string;
}

export const CPRisksSectionInitialValues: ICPRisksSectionValues = {
	isRisk: false,
	interlocutorRiskName: "",
	interlocutorRiskFunction: "",
	interlocutorRiskPhone: "",
	interlocutorRiskEmail: "",

	isDifficult: false,
	interlocutorDifficultName: "",
	interlocutorDifficultFunction: "",
	interlocutorDifficultPhone: "",
	interlocutorDifficultEmail: "",
};

export const CPRisksSectionValidation = {
	interlocutorRiskPhone: phoneValidation(),
	interlocutorRiskEmail: mailValidation(),

	interlocutorDifficultPhone: phoneValidation(),
	interlocutorDifficultEmail: mailValidation(),
};

export const CPRisksSection: React.FC<
	ICommercialPropositionFormSectionProps
> = ({ locked }) => {
	const { values } = useFormikContext<ICPRisksSectionValues>();

	return (
		<div className="gap-8 col">
			<RiskAndHardshipFactor
				name="Risk"
				checked={values.isRisk}
				label={t(
					"commercialPropositions.form.sections.risks.risksLabel"
				)}
				message={t(
					"commercialPropositions.form.sections.risks.riskMessage"
				)}
				locked={locked}
			/>
			<RiskAndHardshipFactor
				name="Difficult"
				checked={values.isDifficult}
				label={t(
					"commercialPropositions.form.sections.risks.penibilityLabel"
				)}
				message={t(
					"commercialPropositions.form.sections.risks.penibilityMessage"
				)}
				locked={locked}
			/>
		</div>
	);
};

interface IRiskAndHardshipFactorProps {
	name: string;
	checked: boolean;
	label: string;
	message?: string;
	locked?: boolean;
}

const RiskAndHardshipFactor: React.FC<IRiskAndHardshipFactorProps> = ({
	name,
	checked,
	label,
	message,
	locked,
}) => {
	const { setFieldValue } = useFormikContext<ICPRisksSectionValues>();

	return (
		<div>
			<Toggle
				label={label}
				name={`is${name}`}
				checked={checked}
				onChange={(e: any) => {
					setFieldValue(`is${name}`, e.target.checked);
				}}
				disabled={locked}
			/>
			{checked && (
				<>
					{!!message?.length && (
						<div className="mb-4">
							<InfoMessage color="primary" withIcon>
								{message}
							</InfoMessage>
						</div>
					)}
					<Title
						tag="h3"
						size="heading03"
						className="mt-4 dark:text-white"
					>
						{t(
							"commercialPropositions.form.sections.risks.interlocutor"
						)}
					</Title>
					<CPInterlocutor
						className="gap-4 col max-w-[450px]"
						fields={{
							name: `interlocutor${name}Name`,
							function: `interlocutor${name}Function`,
							phone: `interlocutor${name}Phone`,
							email: `interlocutor${name}Email`,
						}}
						locked={locked}
					/>
				</>
			)}
		</div>
	);
};
