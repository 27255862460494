import { format } from "date-fns";
import { fr } from "date-fns/locale";

import {
	HOURS_MODE,
	HUNDREDS_MODE,
	MAX_HOURS,
	MAX_HUNDREDS,
	MAX_HUNDREDS_MINUTES,
	MAX_MINUTES,
} from "constants_globals/dates";

const locales: any = { fr };

// Repris de myTemp V1
//
// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export const formatDate = (date: any, formatStr = "PP") => {
	const localesLanguage =
		typeof navigator !== "undefined"
			? navigator.language.split("-")[0]
			: "fr";

	return format(new Date(date), formatStr, {
		locale: locales[localesLanguage],
	});
};

export const formatHundredsToString = (
	value: string | number,
	split: string | RegExp = ".",
	symbol = ","
) => {
	if (value || value === "0" || value === 0) {
		const formatStringMinutes = (minutes: string) => {
			if (minutes.length > 2)
				return parseFloat(`0.${minutes}`).toFixed(2).split(".")[1];

			if (minutes.length === 1) return `${minutes}0`;
			return minutes;
		};
		const explode = String(value).split(split);
		const hours = explode[0].padStart(2, "0");
		if (hours.length > 2) return value;
		const minutes = explode[1] ? formatStringMinutes(explode[1]) : "00";
		return `${hours}${symbol}${minutes}`;
	}
	return "";
};

export const convertHundredsToHours = (value: any) => {
	if (value || value === 0) {
		const decimalTimeString = `${value}`;
		const n = new Date(0, 0);
		n.setMinutes(Math.round(+decimalTimeString * 60));
		const result = n.toTimeString().slice(0, 5);
		return result;
	}
	return "";
};

export const formatHoursMinutes = (value: string | number) => {
	if (value || value === "0" || value === 0) {
		const hours = value.toString();
		const decimalTimeString = `${parseFloat(
			hours.replace(",", ".").replace(" ", "")
		)}`;
		const minutes = +decimalTimeString * 60;
		const hh = Math.floor(minutes / 60);
		const mm = minutes % 60;
		if (mm < 10) {
			return `${hh}:0${mm.toFixed(0)}`;
		}
		return `${hh}:${mm.toFixed(0)}`;
	}
	return "";
};

export const validateHhMm = (inputField: string) => {
	const isValid = /^([0-1]?[0-9]):([0-5][0-9])(:[0-5][0-9])?$/.test(
		inputField
	);

	return isValid;
};

export const validateHundreds = (inputField: string) => {
	const isValid = /^([0-1]?[0-9]),([0-9][0-9])(,[0-9][0-9])?$/.test(
		inputField
	);

	return isValid;
};

export const validateHourLimit = (value: string, mode: string = HOURS_MODE) => {
	const arr = value.split(mode === HUNDREDS_MODE ? "," : ":");
	const hours = parseInt(arr[0] || "0");
	const minutes = parseInt(arr[1] || "0");
	const maxHours = mode === HOURS_MODE ? MAX_HOURS : MAX_HUNDREDS;
	const maxMinutes = mode === HOURS_MODE ? MAX_MINUTES : MAX_HUNDREDS_MINUTES;
	if (
		hours > maxHours ||
		minutes > maxMinutes ||
		(hours === maxHours && minutes > maxMinutes)
	)
		return false;

	return true;
};
