import React from "react";

import { t } from "i18next";
import moment from "moment";

import { Picto, Text } from "@zolteam/react-ras-library";
import { BarChart } from "components/atoms";
import { ITileGaugeProps } from "components/types";

export const DashboardTileBarChart: React.FC<ITileGaugeProps> = ({ data }) => {
	const { xAxisKey, previousYearKey, currentYearKey } = {
		xAxisKey: "month",
		previousYearKey: "previousYear",
		currentYearKey: "currentYear",
	};

	if (
		!data?.invoiceAdvancement?.currentYear?.length &&
		!data?.invoiceAdvancement?.lastYear?.length
	)
		return null;

	const defaultInvoiceStats = () => {
		const current = new Date();
		const previous = moment(current).subtract(1, "months");
		const twoMonthAgo = moment(current).subtract(2, "months");

		const defaultValues = {
			totalCurrentYear: 0,
			totalLastYear: 0,
		};

		return [
			{
				month: moment(twoMonthAgo).format("MMM"),
				...defaultValues,
			},
			{
				month: moment(previous).format("MMM"),
				...defaultValues,
			},
			{
				month: moment(current).format("MMM"),
				...defaultValues,
			},
		];
	};

	const chartData: any[] = data.invoiceAdvancement?.currentYear.length
		? data.invoiceAdvancement.currentYear.map((item: any) => {
				const lastYearItem = data.invoiceAdvancement.lastYear.find(
					(candidate: any) => candidate.month === item.month
				);

				return {
					// Firefox doesnt support only providing the month number,
					// therefore we provide a fake year and day.
					month: moment(
						`2023-${item.month.toString().padStart(2, "0")}-01`
					).format("MMM"),
					totalCurrentYear: item.total,
					totalLastYear:
						lastYearItem && lastYearItem.total
							? lastYearItem.total
							: 0,
				};
		  })
		: defaultInvoiceStats();

	const maxValue = chartData.reduce((acc: any, curr: any) => {
		const localMax =
			parseFloat(curr[previousYearKey]) > parseFloat(curr[currentYearKey])
				? parseFloat(curr[previousYearKey])
				: parseFloat(curr[currentYearKey]);

		return localMax > acc ? localMax : acc;
	}, 0);

	const convertDataSymbol = (xAxisData: any) => {
		return `${xAxisData.toLocaleString()} H`;
	};

	return (
		<div className="min-w-[65%] overflow-hidden col [&_.recharts-legend-wrapper]:!w-full [&_.recharts-wrapper]:!w-full [&_.recharts-wrapper]:!min-h-[300px] relative">
			<BarChart
				maxValue={maxValue}
				xAxisKey={xAxisKey}
				previousYearKey="totalLastYear"
				currentYearKey="totalCurrentYear"
				data={chartData}
				tickFormatter={convertDataSymbol}
				legendFormatter={({ payload }: any) => {
					return (
						<div className="flex-wrap justify-center gap-x-4 gap-y-2 row">
							{payload.map((entry: any, index: number) => (
								<BarChartLegendItem
									key={`item-${index}`}
									entry={entry}
									data={data}
								/>
							))}
						</div>
					);
				}}
			/>
		</div>
	);
};

const BarChartLegendItem: React.FC<any> = ({ entry, data }) => {
	const getTwoYearsStatistics = (key: "currentYear" | "lastYear") => {
		const yearStats = data.invoiceAdvancement[key];

		const year = Array.from(
			// we use a Set to remove duplicates
			new Set(
				yearStats
					.sort((a: any, b: any) => a.year - b.year)
					.map((month: any) => month.year)
			)
		).join("-");

		// we sum all the total values
		const sum = (acc: any, curr: any) => acc + parseFloat(curr.total);

		return {
			year: year,
			total: yearStats.reduce(sum, 0),
		};
	};

	const stats = getTwoYearsStatistics(
		entry.dataKey === "totalCurrentYear" ? "currentYear" : "lastYear"
	);

	if (!stats.total) return null;

	return (
		<div>
			<div className="items-end gap-2 mb-2 row">
				<p className="text-3xl">{Math.round(stats.total)}</p>
				<p>
					<Text
						size="paragraph01"
						color="black"
						fontWeight="normal"
						tag="span"
						className="dark:text-white"
					>{`${t("invoices.billedHours")}`}</Text>
				</p>
			</div>
			<div className="flex items-center gap-2">
				<Picto
					icon={"circle"}
					className="w-3"
					style={{
						color: entry.color,
					}}
				/>
				<Text
					tag="p"
					size="paragraph02"
					lineHeight="m"
					className="!text-neutral-500 dark:!text-neutral-400"
				>
					{stats.year}
				</Text>
			</div>
		</div>
	);
};
