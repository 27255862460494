import React, { useState } from "react";

import { Picto, Tooltip } from "@zolteam/react-ras-library";
import { Field, CountDown } from "components/atoms";
import { IField } from "components/types";

import { cn } from "utils";

interface IPasswordField extends IField {
	show?: () => void;
	autoHideTimeout?: number; // in seconds
}

export const PasswordField: React.FC<IPasswordField> = ({
	show = true,
	children,
	autoHideTimeout = 10,
	...props
}) => {
	const [Type, setType] = useState("password");
	const [AutoHide, setAutoHide] = useState(false);

	const clearAutoHide = () => {
		setAutoHide(false);
	};

	const launchAutoHide = () => {
		clearAutoHide();
		if (Type === "password") return;
		setAutoHide(true);
	};

	const toggle = () => {
		clearAutoHide();
		setType((prev) => (prev === "password" ? "text" : "password"));
	};

	return (
		<span onMouseLeave={launchAutoHide} onMouseEnter={clearAutoHide}>
			<Field {...props} type={Type} autoComplete="true">
				<div className="flex absolute right-5 top-[1rem] gap-1 items-center w-[60px] justify-end">
					{children}
					{show && (
						<Tooltip
							placement="bottom"
							content={
								Type === "password"
									? "Afficher le mot de passe"
									: "Masquer le mot de passe"
							}
						>
							<button type="button" onClick={toggle}>
								<Picto
									icon={
										Type === "password" ? "eye" : "eyeOff"
									}
									className={cn([
										"pl-xs text-neutral-400 dark:text-neutral-300 cursor-pointer w-[24px] h-[24px]",
									])}
								/>
								{AutoHide && (
									<span className="absolute bottom-0 right-0 text-[8px] text-neutral-500 p-[2px] leading-[8px] rounded-40 bg-white dark:bg-neutral-800">
										<CountDown
											time={autoHideTimeout}
											text={(time) => time.toString()}
											handleEnd={() => {
												clearAutoHide();
												setType("password");
											}}
										/>
									</span>
								)}
							</button>
						</Tooltip>
					)}
				</div>
			</Field>
		</span>
	);
};
