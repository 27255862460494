import { t } from "i18next";
import { toast } from "react-toastify";

export const promiseToast = (
	promise: Promise<any>,
	messages?: {
		pending?: string;
		success?: string;
		error?: string;
		[key: string]: any;
	},
	options?: any
) => {
	if (options?.toastId) {
		toast.update(options.toastId, {
			render: messages?.pending ?? t("promiseToast.pending"),
			type: "default",
			isLoading: true,
		});
	}

	return toast.promise(
		promise,
		{
			pending: t("promiseToast.pending"),
			success: t("promiseToast.success"),
			error: t("promiseToast.error"),
			...messages,
		},
		options
	);
};
