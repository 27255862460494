import React from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";

import { useQuery } from "@tanstack/react-query";

import TimesheetsService from "services/TimeSheetsService";

import { BoxContent, InfoMessage } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import { TimesheetBonusesList } from "components/organisms";
import { ITimeSheetBonus } from "components/types";

export interface IAddTimesheetBonusMenuProps {
	locked?: boolean;
}

export interface ISortedBonuses {
	taxable: ITimeSheetBonus[];
	untaxable: ITimeSheetBonus[];
}

export const AddTimesheetBonusMenu: React.FC<IAddTimesheetBonusMenuProps> = ({
	locked,
}) => {
	const { values } = useFormikContext<any>();

	const sortBonuses = (a: ITimeSheetBonus, b: ITimeSheetBonus) => {
		if (a.isDefault && b.isDefault) return a.name > b.name ? 1 : -1;
		if (a.isDefault) return -1;
		if (b.isDefault) return 1;
		return 0;
	};

	const fetchContractBonuses = () =>
		TimesheetsService.getTimesheetContractBonuses(values.timesheet.id);

	const { data: fetchedContractsBonuses, isLoading } = useQuery({
		queryKey: ["contractBonuses", values.timesheet.id],
		queryFn: fetchContractBonuses,
	});

	const getSortedBonuses = (_bonuses: ITimeSheetBonus[]): ISortedBonuses => {
		const ret: ISortedBonuses = {
			taxable: [],
			untaxable: [],
		};

		_bonuses?.forEach((bonus: any) => {
			const isAlreadyAdded = values.tsBonuses.find(
				(b: any) => b.bonusId === bonus.id
			);
			(bonus.taxable ? ret.taxable : ret.untaxable).push({
				...(isAlreadyAdded || {}),
				...bonus,
				bonusId: bonus.id,
			});
		});
		ret.taxable.sort(sortBonuses);
		ret.untaxable.sort(sortBonuses);
		return ret;
	};

	const contractBonuses = getSortedBonuses(fetchedContractsBonuses);

	if (
		!isLoading &&
		!contractBonuses?.taxable.length &&
		!contractBonuses?.untaxable.length
	)
		return (
			<InfoMessage
				className="text-center text-neutral-400"
				withIcon
				color="warning"
			>
				{t("timesheets.bonuses.noBonuses")}
			</InfoMessage>
		);

	return (
		<BoxContent
			className="dark:!bg-neutral-800"
			borderBottomLeft
			borderBottomRight
			borderTopLeft
			borderTopRight
		>
			{isLoading ? (
				<Spinner />
			) : (
				<div className="flex-wrap gap-12 row">
					{!!contractBonuses?.taxable.length && (
						<TimesheetBonusesList
							title={t("timesheets.bonuses.taxable")}
							bonuses={contractBonuses?.taxable || []}
							locked={locked}
						/>
					)}
					{!!contractBonuses.untaxable.length && (
						<TimesheetBonusesList
							title={t("timesheets.bonuses.nonTaxable")}
							bonuses={contractBonuses?.untaxable || []}
							locked={locked}
						/>
					)}
				</div>
			)}
		</BoxContent>
	);
};
