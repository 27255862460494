import React, { useState } from "react";

import { t } from "i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { promiseToast } from "toastify";

import { useQuery } from "@tanstack/react-query";

import SecurityService from "services/SecurityService";

import { Title, Text, BackLink } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import { ActionResult } from "components/molecules";
import { ResetPasswordForm } from "forms";

interface IResetPasswordProps {
	isCreate?: boolean;
}

const ResetPassword: React.FC<IResetPasswordProps> = ({ isCreate = false }) => {
	const [ShowSuccess, setShowSuccess] = useState(false);

	const navigate = useNavigate();
	const [params] = useSearchParams();
	const resetToken = params.get("token");

	const handleResetPassword = (password: string) => {
		const prom = SecurityService.resetPassword({
			token: resetToken ?? "",
			password,
		}).then(() => setShowSuccess(true));
		promiseToast(prom);
		return prom;
	};

	const title = t(isCreate ? "createPassword.title" : "resetPassword.title");

	const { isLoading, error } = useQuery({
		queryKey: [isCreate ? "createPassword" : "resetPassword", resetToken],
		queryFn: () =>
			SecurityService.testResetToken(resetToken ?? "").then(
				(resp) => resp.isValid || Promise.reject("invalid_reset_token") // no need to translate
			),
		enabled: !!resetToken,
		retry: false,
	});

	if (error || !resetToken)
		return (
			<ActionResult
				type={"error"}
				onBack={() =>
					navigate(
						"/retrieve-password" +
							(isCreate ? "?fromCreate=true" : "")
					)
				}
				button={{
					text: t("forms.link.resend"),
				}}
				title={t(
					isCreate
						? "createPassword.invalidTokenTitle"
						: "resetPassword.invalidTokenTitle"
				)}
				text={t(
					isCreate
						? "createPassword.invalidToken"
						: "resetPassword.invalidToken"
				)}
			/>
		);

	return (
		<>
			{(isLoading || (error && !ShowSuccess)) && (
				<>
					{!isLoading && (
						<BackLink onClick={() => navigate("/")}>
							{t("global.back")}
						</BackLink>
					)}
					<Title
						tag="h2"
						className="!text-xl text-center dark:text-white"
						lineHeight="m"
					>
						{title}
					</Title>
				</>
			)}

			{isLoading && (
				<Spinner
					text={t(
						isCreate
							? "createPassword.tokenCheck"
							: "resetPassword.tokenCheck"
					)}
				/>
			)}

			{(ShowSuccess && (
				<ActionResult
					title={title}
					onBack={() => navigate("/login")}
					text={t(
						isCreate
							? "createPassword.sentText"
							: "resetPassword.sentText"
					)}
					button={{
						text: t("auth.loginButton"),
					}}
				/>
			)) ||
				(!isLoading && (
					<>
						<div className="mb-4 text-center">
							<Text
								tag="p"
								className="!text-sm mb-4 dark:text-white"
								lineHeight="m"
							>
								{t("forms.password.newPassword")}
							</Text>
							<Text
								tag="p"
								size="paragraph01"
								lineHeight="m"
								className="!text-xs dark:text-white"
							>
								{t("forms.password.constraintsText")}
							</Text>
						</div>
						<ResetPasswordForm
							onSubmit={({ password }) =>
								handleResetPassword(password)
							}
						/>
					</>
				))}
		</>
	);
};

export default ResetPassword;
