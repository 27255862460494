import React from "react";

import { useNavigate } from "react-router-dom";

import { Text } from "@zolteam/react-ras-library";
import { EmptyGauge, Gauge } from "components/atoms";
import { ITileGaugeProps } from "components/types";

export const DashboardTileGauge: React.FC<ITileGaugeProps> = ({
	chartProps,
	data,
}) => {
	const navigate = useNavigate();

	if (!chartProps || !data) return null;

	const { valueKey, totalKey, title, text, noValueText } = chartProps;

	const getGaugeValue = (key: string) => {
		if (!key) return 0;
		return data[key];
	};

	const getGaugeProps = () => {
		const value = getGaugeValue(valueKey);

		return {
			number: value,
			active: getGaugeValue(totalKey) - value,
			inactive: value,
			text: text,
			complete: getGaugeValue(totalKey) === value,
			onClickLabel:
				typeof chartProps.onClickLabel === "string"
					? () => navigate(chartProps.onClickLabel as string)
					: chartProps.onClickLabel,
		};
	};

	const gaugeValues = getGaugeProps();

	if (!getGaugeValue(totalKey) || !getGaugeValue(valueKey)) return false;

	return (
		<div className="flex flex-col items-center justify-center w-full gap-4 col">
			{!!title && (
				<Text
					tag="h3"
					size="paragraph01"
					lineHeight="m"
					fontWeight="bold"
					className="dark:text-white"
				>
					{title}
				</Text>
			)}
			{getGaugeValue(totalKey) > 0 ? (
				<Gauge {...gaugeValues} />
			) : (
				<EmptyGauge text={noValueText ?? text ?? ""} />
			)}
		</div>
	);
};
