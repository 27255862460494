import React from "react";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { promiseToast } from "toastify";

import UserAdminService from "services/UserAdminService";

import { Button } from "@zolteam/react-ras-library";
import { IUserInformationsProps } from "components/types";

const RESET = "reset";
const CHANGE = "change";

type resetType = typeof RESET | typeof CHANGE;

export const AdminUserPassword: React.FC<IUserInformationsProps> = ({
	user,
}) => {
	const [IsSending, setIsSending] = React.useState(false);
	const [NewPassword, setNewPassword] = React.useState("");
	if (!user) return null;

	const handlePassword = (type: resetType) => {
		setIsSending(true);
		setNewPassword("");

		const functionCall =
			type === RESET
				? UserAdminService.userAdminResetPassword
				: UserAdminService.userAdminGeneratePassword;

		const promise = functionCall(user.id)
			.then((resp) => {
				if (type === CHANGE) setNewPassword(resp.password);
			})
			.finally(() => {
				setIsSending(false);
			});
		promiseToast(
			promise,
			type === RESET
				? {
						success: t("userProfile.password.resetConfirmation"),
				  }
				: {}
		);
	};

	return (
		<div>
			<div className="flex-wrap gap-4 row">
				<Button
					color="primary"
					outline
					onClick={() => handlePassword(RESET)}
					type="button"
					size="s"
					disabled={IsSending}
					isLoading={IsSending}
				>
					{t("resetPassword.buttonLabel")}
				</Button>
				<Button
					color="primary"
					outline
					onClick={() => handlePassword(CHANGE)}
					type="button"
					size="s"
					disabled={IsSending}
					isLoading={IsSending}
				>
					{t("userProfile.actions.setNewPassword")}
				</Button>
			</div>
			{!!NewPassword && (
				<div className="mt-4 dark:text-white">
					<Trans
						i18nKey="userProfile.password.changeConfirmation"
						values={{ password: NewPassword }}
					/>
				</div>
			)}
		</div>
	);
};
