import React from "react";

import { t } from "i18next";

import { Picto, Popover, Text } from "@zolteam/react-ras-library";

const style: any = {};

interface IListFilterProps {
	items: any[];
	onSelect: (items: any[]) => void;
	title: string;
	label: string | ((count: number) => string);
}

export const ListFilter: React.FC<IListFilterProps> = ({
	items,
	onSelect,
	title,
	label,
}) => {
	const handleSelect = (selected: any[]) => {
		onSelect(selected);
	};

	const isAllSelected = items.every((item) => item.active);
	const selectedItems = items.filter((item) => item.active);
	const hasSelectedItems = selectedItems?.length;
	const isIndeterminate = !isAllSelected && !!hasSelectedItems;

	return (
		<Popover
			offset={[0, 10]}
			animation="perspective"
			content={
				<div className="p-2 px-4 text-sm [&_label]:w-fit">
					<div className="pb-4 mb-4 border-b border-neutral-200">
						<label className="items-center gap-2 cursor-pointer row">
							<input
								type="checkbox"
								id={title}
								checked={!!hasSelectedItems}
								onChange={() =>
									handleSelect(
										isAllSelected
											? []
											: items.map((item) => ({
													...item,
													active: true,
											  }))
									)
								}
								data-indeterminate={isIndeterminate}
							/>
							{t("global.selectAll")}
						</label>
					</div>
					<ul className={"col gap-2"}>
						{!!items?.length &&
							items.map((item) => (
								<li
									key={item.id}
									className={"w-full text-left"}
								>
									<label className="items-center gap-2 cursor-pointer row">
										<input
											type="checkbox"
											className={style.itemCheckbox}
											id={`${item.id}`}
											checked={item.active}
											onChange={() =>
												handleSelect([
													{
														...item,
														active: !item.active,
													},
												])
											}
										/>
										{item.name}
									</label>
								</li>
							))}
					</ul>
				</div>
			}
		>
			<div className="items-center gap-3 cursor-pointer row">
				<Text
					tag="div"
					size="paragraph01"
					fontWeight="normal"
					className={"!text-neutral-500 text-right !leading-4"}
				>
					{hasSelectedItems
						? typeof label === "function"
							? label(selectedItems.length)
							: `${selectedItems.length} ${label}`
						: title}
				</Text>
				<Picto
					icon="arrowDown"
					className={"!min-w-3 w-3 h-3 min-h-3"}
				/>
			</div>
		</Popover>
	);
};
