import React from "react";

import { NavLink } from "react-router-dom";
import { isRouteActive } from "router/Router";
import { IRoute } from "router/Routes";

import useAppContext from "store/useAppContext";

import { NavItem, NavItemIcon } from "components/atoms";
import { INavbarProps } from "components/types";

import { cn } from "utils";

export const NavBar: React.FC<INavbarProps> = ({ className, items }) => {
	const { hasPermissions } = useAppContext();
	return (
		<div
			className={cn([
				"py-2 px-2 row justify-evenly border bg-neutral-50 dark:bg-neutral-900 border-neutral-100 dark:border-neutral-950 rounded-t-2xl overflow-hidden",
				className,
			])}
		>
			{items.map((item: IRoute, index) => {
				if (item.hidden) return null;
				if (item.permissions && !hasPermissions(item.permissions))
					return null;
				const isActive = !!isRouteActive(item);

				return (
					<NavItem
						key={index}
						label={item.name}
						popoverProps={{
							placement: "top",
							followCursor: false,
						}}
					>
						<NavLink
							to={item.path[0]}
							className={cn([
								"p-2 rounded-full duration-300 border border-transparent",
								isActive
									? "bg-white shadow-md border-neutral-150 dark:bg-neutral-700 dark:border-transparent"
									: "bg-transparent",
							])}
						>
							<NavItemIcon item={item} isActive={isActive} />
						</NavLink>
					</NavItem>
				);
			})}
		</div>
	);
};
