import secureLocalStorage from "react-secure-storage";

import { initStore } from "store/AppContext";

export const initContext = () => {
	const context = getContextFromLocalStorage();

	const localContextV1 = getContextFromMyTempV1();

	// context in myTempV1 is not crypted, so we need to check if it exists and migrate it to secureLocalStorage
	// to keep the user connected when the app is updated to the new version

	if (!localContextV1) return context; // if there is no context in myTempV1, return the context from secureLocalStorage

	secureLocalStorage.setItem("context", JSON.stringify(localContextV1)); // set the new context
	localStorage.removeItem("context"); // remove the old context

	window.location.reload(); // reload the page to get the new context
};

export const getContextFromLocalStorage = () => {
	const localContextItem: any = secureLocalStorage.getItem("context");

	const localContext: any =
		typeof window !== "undefined"
			? JSON.parse(localContextItem || "{}") || initStore
			: null;

	return localContext;
};

export const getContextFromMyTempV1 = () => {
	const localContextItem: any = localStorage.getItem("context"); // get the context from myTempV1 (not crypted)

	if (!localContextItem) return false;
	return JSON.parse(localContextItem || "{}") || initStore;
};
