import React from "react";

import { Field as FormikField, useFormikContext } from "formik";
import { t } from "i18next";

import { PopoverCursor } from "@zolteam/react-ras-library";
import { Field } from "components/atoms";
import { IUserSummaryProps } from "components/organisms";

import { cn } from "utils";

export const UserSummaryHeader: React.FC<IUserSummaryProps> = ({
	user,
	index,
	hasError,
}) => {
	const { isSubmitting } = useFormikContext();

	return (
		<div className="items-center w-full py-2 mr-2 row 2md:[&>div]:basis-1/3 flex-wrap gap-4">
			<div className="w-full">
				<label
					className="z-10 items-center font-bold cursor-pointer row w-fit"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<FormikField
						type="checkbox"
						name={`Users.${index}.isSelected`}
						disabled={isSubmitting}
					/>
					<PopoverCursor
						followCursor={false}
						placement="top"
						content={
							!!hasError && (
								<div
									className={
										"p-1 px-2 text-center max-w-[200px] rounded-md shadow-md bg-white text-neutral-600 dark:bg-neutral-700 dark:text-white text-sm"
									}
								>
									{t("userConfiguration.missingInfos")}
								</div>
							)
						}
					>
						<span
							className={cn([
								"items-center gap-2 ml-4 overflow-hidden text-ellipsis row dark:text-white",
							])}
						>
							{user.email}
						</span>
					</PopoverCursor>
				</label>
			</div>
			<div className="2md:min-w-[300px] flex-wrap w-full gap-3 sm:ml-8 xl:items-center gap-y-1 2md:flex-nowrap col xl:flex-row">
				<div className="break-keep whitespace-nowrap text-neutral-500">
					{t("userConfiguration.fillFullName")}
				</div>

				<div className="gap-2 col xs:flex-row">
					<Field
						label={t("userProfile.infos.firstName")}
						name={`Users.${index}.firstName`}
						type="text"
						className="min-w-[calc(100%+1rem)] xs:min-w-[200px] xs:w-full sm:w-auto"
						onClick={(e: any) => {
							e.stopPropagation();
						}}
						disabled={isSubmitting}
					/>
					<Field
						label={t("userProfile.infos.lastName")}
						name={`Users.${index}.lastName`}
						type="text"
						className="min-w-[calc(100%+1rem)] xs:min-w-[200px] xs:w-full sm:w-auto"
						onClick={(e: any) => {
							e.stopPropagation();
						}}
						disabled={isSubmitting}
					/>
				</div>
			</div>
		</div>
	);
};
