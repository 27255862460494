import React from "react";

import { Range } from "@zolteam/react-ras-library";

import { cn } from "utils";

export interface IRatingRangeProps {
	value: number;
	text?: string;
	name: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	textClass?: string;
	active?: boolean;
	disabled?: boolean;
	min?: number;
	max?: number;
	step?: number;
}

const RatingColors: {
	[key: number]: string;
} = {
	"-1": "text-neutral-500",
	1: "text-rating-range-1",
	2: "text-rating-range-2",
	3: "text-rating-range-3",
	4: "text-rating-range-4",
};

export const RatingRange: React.FC<IRatingRangeProps> = ({
	value,
	onChange = () => {},
	text,
	textClass,
	active,
	disabled,
	name,
	min = 1,
	max = 4,
	step = 1,
}) => {
	return (
		<>
			<Range
				name={name}
				disabled={disabled}
				min={min}
				max={max}
				value={active ? value : (max + min) / 2}
				step={active ? step : 0.5}
				onChange={onChange}
			/>

			<span
				className={cn([
					"whitespace-nowrap w-[300px]",
					RatingColors[value],
					textClass,
				])}
			>
				{text}
			</span>
		</>
	);
};
