import React from "react";

import { Formik, Form } from "formik";
import { t } from "i18next";
import moment from "moment";

import { Popover, Button, Picto } from "@zolteam/react-ras-library";
import { DatePickerField } from "components/molecules";
import { IDatesFilter } from "components/types";

import { cn } from "utils";

interface ITableDateFilterProps {
	onChange?: (value: IDatesFilter) => void;
	buttonLabel?: string;
	dateBetweenName?: string;
	initialValues?: IDatesFilter;
}

interface ITimeRangeSelector {
	id?: string;
	label: string;
	onClick: (setFieldValue: any) => void;
}

export const TableDateFilter: React.FC<ITableDateFilterProps> = ({
	onChange,
	buttonLabel,
	dateBetweenName,
	initialValues,
}) => {
	const TimeRanges: ITimeRangeSelector[] = [
		{
			id: "week",
			label: t("dates.thisWeek"),
			onClick: (setValues) => {
				let date = moment();

				setValues({
					dateBetweenStart: date
						.startOf("week")
						.format(t("dates.default").toString()),
					dateBetweenEnd: date
						.endOf("week")
						.format(t("dates.default").toString()),
					dateBetweenName: "week",
				});
			},
		},
		{
			id: "month",
			label: t("dates.thisMonth"),
			onClick: (setValues) => {
				let date = moment();

				setValues({
					dateBetweenStart: date
						.startOf("month")
						.format(t("dates.default").toString()),
					dateBetweenEnd: date
						.endOf("month")
						.format(t("dates.default").toString()),
					dateBetweenName: "month",
				});
			},
		},
		{
			id: "lastMonth",
			label: t("dates.lastMonth"),
			onClick: (setValues) => {
				setValues({
					dateBetweenStart: moment()
						.subtract(1, "month")
						.startOf("month")
						.format(t("dates.default").toString()),
					dateBetweenEnd: moment()
						.subtract(1, "month")
						.endOf("month")
						.format(t("dates.default").toString()),
					dateBetweenName: "lastMonth",
				});
			},
		},
		{
			label: t("dates.all"),
			onClick: (setValues) => {
				setValues({
					dateBetweenStart: "",
					dateBetweenEnd: "",
					dateBetweenName: "",
				});
			},
		},
	];

	return (
		<Popover
			content={
				<Formik
					onSubmit={() => {}}
					initialValues={{
						dateBetweenStart: "",
						dateBetweenEnd: "",
						dateBetweenName: "",
						...initialValues,
					}}
					validateOnChange={true}
					validate={(values) => {
						onChange?.(values);
					}}
				>
					{({ values, setFieldValue, setValues }) => {
						return (
							<Form className="items-start gap-2 p-2 col">
								<DatePickerField
									label={t("dates.dateStart")}
									name="dateBetweenStart"
									onChange={(val) => {
										let date = moment(val).format(
											t("dates.default").toString()
										);
										setFieldValue("dateBetweenStart", date);
									}}
									value={values.dateBetweenStart}
								/>
								<DatePickerField
									label={t("dates.dateEnd")}
									name="dateBetweenEnd"
									onChange={(val) => {
										let date = moment(val).format(
											t("dates.default").toString()
										);
										setFieldValue("dateBetweenEnd", date);
									}}
									value={values.dateBetweenEnd}
								/>
								<hr className="w-full my-2" />
								<div className="items-start justify-start gap-2 text-left col">
									{TimeRanges.map((range, index) => {
										return (
											<Button
												key={index}
												type="button"
												color="transparent"
												className={cn([
													"!text-left !w-fit !items-start !justify-start !self-start",
													dateBetweenName ===
														range.id &&
														"!text-primary-500",
												])}
												onClick={() =>
													range.onClick(setValues)
												}
											>
												{range.label}
											</Button>
										);
									})}
								</div>
							</Form>
						);
					}}
				</Formik>
			}
			placement="bottom-start"
		>
			<Button
				color="grey"
				type="button"
				outline
				className="items-center !px-3 !py-1 gap-2 dark:!text-white dark:!border-neutral-900"
			>
				<Picto icon="calendar" className="!m-0" />
				{buttonLabel ?? t("dates.dateFilter")}
			</Button>
		</Popover>
	);
};
