import React from "react";

import { useFormikContext } from "formik";
import { Trans } from "react-i18next";

import { ICommercialPropositionFormSectionProps } from "../CommercialPropositionForm";
import { ICommercialPropositionFormValues } from "../CommercialPropositionFormValidation";
import { Toggle } from "components/atoms";

export interface ICPCommunicationSectionValues {
	isImageRight: boolean;
}

export const CPCommunicationSectionInitialValues: ICPCommunicationSectionValues =
	{
		isImageRight: true,
	};

export const CPCommunicationSection: React.FC<
	ICommercialPropositionFormSectionProps
> = ({ locked }) => {
	const { values, setFieldValue } =
		useFormikContext<ICommercialPropositionFormValues>();

	return (
		<Toggle
			name="isImageRight"
			label={
				<Trans
					i18nKey="commercialPropositions.form.sections.communication.label"
					components={{
						purple: (
							<pre
								className="inline font-bold underline text-primary-500"
								style={{
									fontFamily: "DM Sans",
								}}
							/>
						),
					}}
					values={{
						company: values.clientCompany?.length
							? values.clientCompany
							: `                        `,
					}}
				/>
			}
			checked={values.isImageRight}
			onChange={(e: any) => {
				setFieldValue("isImageRight", e.target.checked);
			}}
			className="items-start"
			disabled={locked}
		/>
	);
};
