export const APP_NAME = "MyTemp";

export const APP_ENV = process.env.REACT_APP_ENV;

export const IS_DEV = APP_ENV === "development";
export const IS_PROD = APP_ENV === "production";

export const PROXY_URL = process.env.REACT_APP_PROXY_URL;
export const API_URL = (PROXY_URL ?? "") + process.env.REACT_APP_API_URL;

export const FRONT_URL = process.env.REACT_APP_FRONT_URL ?? "";

export const FRONT_V1_URL = process.env.REACT_APP_FRONT_V1_URL ?? "";
