import React from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";
import moment from "moment";

import { Title, Text, Button } from "@zolteam/react-ras-library";
import { WeekDay } from "components/molecules";
import { ITimeSheetDay } from "components/types";

import { cn } from "utils/cn";

export interface ITimesheetTableHeadProps {
	weekNumber: string;
	weekDays: ITimeSheetDay[];
	showToggle?: boolean;
	isToggled?: boolean;
	toggleEntryModeModal?: () => void;
}

export const TimesheetTableHead: React.FC<ITimesheetTableHeadProps> = ({
	weekNumber,
	showToggle,
	isToggled,
	toggleEntryModeModal,
}) => {
	const { values } = useFormikContext<any>();

	return (
		<thead>
			<tr>
				<th className="text-left w-[12rem]">
					{weekNumber && (
						<Title
							tag="span"
							size="heading03"
							fontWeight="bold"
							lineHeight="m"
							className="dark:text-white"
						>{`${t("dates.week")} ${weekNumber}`}</Title>
					)}
				</th>
				{values.weekDays.map(
					({ date, isVacationDay }: ITimeSheetDay) => (
						<th key={`header_${date}`}>
							<WeekDay
								day={moment(date).format("ddd")}
								month={moment(date).format("DD MMM")}
								sub={
									isVacationDay
										? t("timesheets.edition.vacation")
										: ""
								}
							/>
						</th>
					)
				)}
				<th style={{ width: showToggle ? "130px" : "90px" }}>
					<Text
						tag="span"
						color="black"
						fontWeight="normal"
						className="dark:text-white"
					>
						{t("timesheets.edition.total")}
					</Text>
				</th>
				{showToggle ? (
					<th style={{ minWidth: "75px" }}>
						<div className="gap-2 col">
							<Button
								className={cn([
									"w-full max-w-[130px] !p-0 dark:!text-white",
									!isToggled && "pointer-events-none",
								])}
								outline
								size="s"
								type="button"
								onClick={toggleEntryModeModal}
								color={!isToggled ? "primary" : "grey"}
							>
								{t("timesheets.edition.minuteMode")}
							</Button>
							<Button
								className={cn([
									"w-full max-w-[130px] !p-0 dark:!text-white",
									isToggled && "pointer-events-none",
								])}
								outline
								size="s"
								type="button"
								onClick={toggleEntryModeModal}
								color={isToggled ? "primary" : "grey"}
							>
								{t("timesheets.edition.hourMode")}
							</Button>
						</div>
					</th>
				) : (
					<th />
				)}
			</tr>
		</thead>
	);
};
