export const CP_STATUS_ALL = "all";
export const CP_STATUS_DRAFT = "draft";
export const CP_STATUS_SENT = "sent";
export const CP_STATUS_SIGNED = "signed";
export const CP_STATUS_SIGNED_OUTSIDE = "signed_out_of_cloud";
export const CP_STATUS_NO_ACTION_TAKEN = "no_action_taken";
export const CP_STATUS_VALIDATED = "validated";
export const CP_STATUS_REFUSED = "refused";

export const CP_STATUS = [
	CP_STATUS_ALL,
	CP_STATUS_DRAFT,
	CP_STATUS_SENT,
	CP_STATUS_SIGNED,
	CP_STATUS_SIGNED_OUTSIDE,
	CP_STATUS_NO_ACTION_TAKEN,
	CP_STATUS_VALIDATED,
	CP_STATUS_REFUSED,
];

export const CP_TYPE_PARTNER = "partner";
export const CP_TYPE_SEASONAL = "seasonal";
export const CP_TYPE_DIFFERENT = "different";

export const CP_TYPES = [CP_TYPE_PARTNER, CP_TYPE_SEASONAL, CP_TYPE_DIFFERENT];

export const EDITABLE_CP_STATUS = [CP_STATUS_DRAFT, CP_STATUS_SENT];

export const DEFAULT_FILE_COMMERCIAL_PROPOSITION = [
	"Présentation R.A.S Intérim.pdf",
	"AGQ DAQ 004.25_Eléments de rémunération et de facturation.pdf",
	"Liste postes a risques et facteurs penibilites.pdf",
];
