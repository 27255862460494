import React from "react";

import { t } from "i18next";
import { NavLink, useLocation } from "react-router-dom";

import useAppContext from "store/useAppContext";

import { Picto } from "@zolteam/react-ras-library";

import { cn } from "utils";

import { PERMISSIONS_ALERTS } from "constants_globals";

export interface IUserProfileNavProps {}

export const UserProfileNav: React.FC = () => {
	const { hasPermissions } = useAppContext();

	const location = useLocation();

	return (
		<div className="flex flex-row gap-6 lg:gap-4 lg:flex-col text-neutral-700 dark:text-neutral-100">
			<NavLink
				to="/user-profile/profile"
				className={({ isActive }) => {
					return cn([
						"flex items-center gap-2",
						(isActive || location.pathname === "/user-profile") &&
							"font-semibold",
					]);
				}}
			>
				<Picto className="w-4" icon="user" />
				<div>{t("profile.myProfile")}</div>
			</NavLink>
			{hasPermissions(PERMISSIONS_ALERTS) && (
				<NavLink
					to="/user-profile/notifications"
					className={({ isActive }) => {
						return cn([
							"flex items-center gap-2",
							isActive && "font-semibold",
						]);
					}}
				>
					<Picto className="w-4" icon="notification" />
					<div>{t("profile.mailAlerts")}</div>
				</NavLink>
			)}
		</div>
	);
};
