import React from "react";

import { Form, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import { IContactAgencyFormValues } from "./ContactAgencyFormValidation";
import { Button, Select, Picto } from "@zolteam/react-ras-library";
import { Field, Textarea } from "components/atoms";
import { IAgency, ISite } from "components/types";

// Forms
import {
	MAX_AGENCY_CONTACT_MESSAGE_LENGTH,
	MAX_CONTACT_SUBJECT_LENGTH,
} from "constants_globals";

interface IType {}

interface IContactAgencyInputProps {
	agencies: IAgency[];
	themes: IType[];
	sites: ISite[];
	values: IContactAgencyFormValues;
	setFieldValue: (field: string, value: any) => void;
}

export const ContactAgencyInput: React.FC<IContactAgencyInputProps> = ({
	agencies,
	themes,
	sites,
	values,
	setFieldValue,
}) => {
	const selectStyle = "[&_p:empty]:hidden"; // nécessaire car la lib RAS rajoute des élements vides dans le DOM qui cassent le style (surement pour la gestion des erreurs)

	const { t } = useTranslation();

	const activeAgency =
		agencies?.find((agency) => agency.id === values.agencyId) || null;

	const initialActiveSite =
		sites?.filter(
			(site) => site.tempoDirectory === activeAgency?.tempoDirectory
		)[0] || null;

	return (
		<div className="gap-4 col">
			<Select
				className={selectStyle}
				options={agencies}
				onChange={(option: IAgency) =>
					setFieldValue("agencyId", option.id)
				}
				name="agencyId"
				label={
					!values.agencyId
						? t("forms.agencies.placeholder")
						: t("forms.agencies.label")
				}
				value={activeAgency}
				getOptionLabel={(option: IAgency) => option.name}
				getOptionValue={(option: IAgency) => option.id}
			/>
			{activeAgency && activeAgency.phone && (
				<div>
					<a href={`tel:${activeAgency.phone}`}>
						<Button type="button" color="primary">
							<Picto
								icon="phone"
								style={{ marginRight: "10px" }}
							/>
							<span>{activeAgency.phone}</span>
						</Button>
					</a>
				</div>
			)}
			<Select
				className={selectStyle}
				options={
					activeAgency
						? sites.filter(
								(site) =>
									activeAgency.tempoDirectory ===
									site.tempoDirectory
						  )
						: sites
				}
				onChange={(option: ISite) => setFieldValue("siteId", option.id)}
				name="siteId"
				label={t(
					values.siteId
						? "forms.sites.label"
						: "forms.sites.placeholder"
				)}
				value={
					sites && values.siteId
						? sites.find(
								(option: ISite) => option.id === values.siteId
						  )
						: initialActiveSite || null
				}
				getOptionLabel={(option: ISite) => option.socialReason}
				getOptionValue={(option: ISite) => option.id}
			/>
			<Select
				className={selectStyle}
				options={themes}
				onChange={(option: ISite) => setFieldValue("theme", option.id)}
				name="theme"
				label={t("forms.theme.label")}
				value={
					themes && values.theme
						? themes.find(
								(option: any) => option.id === values.theme
						  )
						: null
				}
				getOptionLabel={(option: ISite) => option.name}
				getOptionValue={(option: ISite) => option.id}
			/>
			<Field
				type="text"
				name="tempWorker"
				label={t("forms.tempWorker.name")}
			/>
			<Field
				type="text"
				name="contractKey"
				label={t("forms.contract.label")}
				maxLength={15}
			/>
			<Field
				type="text"
				name="invoiceNumber"
				label={t("forms.invoice.label")}
				maxLength={15}
			/>
			<Field
				type="text"
				name="subject"
				label={t("forms.object.label")}
				maxLength={MAX_CONTACT_SUBJECT_LENGTH}
				required
			/>
			<Textarea
				name="content"
				className="dark:[&_textarea]:bg-transparent dark:[&_textarea]:text-white dark:[&_label]:text-neutral-300 mb-6"
				label={t("forms.message.label")}
				maxLength={MAX_AGENCY_CONTACT_MESSAGE_LENGTH}
				required
			/>
		</div>
	);
};

interface IContactAgencyFormProps {
	load: boolean;
	error: string;
	agencies: IAgency[];
	themes: IType[];
	sites: ISite[];
}

export const ContactAgencyForm: React.FC<IContactAgencyFormProps> = ({
	load,
	error,
	agencies,
	themes,
	sites,
}) => {
	const { t } = useTranslation();
	const { isValid, dirty, ...props } = useFormikContext();

	return (
		<div>
			<Form>
				{error ? <div>{t("contact.error")}</div> : null}
				<ContactAgencyInput
					agencies={agencies}
					themes={themes}
					sites={sites}
					{...props}
					values={props.values as IContactAgencyFormValues}
				/>
				<Button
					type="submit"
					isLoading={load}
					color="primary"
					disabled={!(isValid && dirty)}
					size="l"
				>
					{t("forms.message.send")}
				</Button>
			</Form>
		</div>
	);
};
