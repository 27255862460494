import React, { PropsWithChildren } from "react";

import { Title, Text } from "@zolteam/react-ras-library";

export interface IRatingRangeItemProps extends PropsWithChildren {
	title: string | React.ReactNode;
	text: string;
}

export const RatingRangeItem: React.FC<IRatingRangeItemProps> = ({
	title,
	text,
	children,
}) => {
	return (
		<div className="w-full">
			{typeof title === "string" ? (
				<Title
					tag="h3"
					size="heading03"
					lineHeight="s"
					className="dark:text-white"
				>
					{title}
				</Title>
			) : (
				title
			)}
			<Text
				tag="p"
				size="paragraph01"
				lineHeight="l"
				color="grey"
				className="dark:text-neutral-400"
			>
				{text}
			</Text>
			<div className="items-center justify-start gap-4 mt-4 whitespace-pre-wrap row flex-nowrap">
				{children}
			</div>
		</div>
	);
};
