import i18n from "i18n/i18n";

import { IFilterStatus } from "components/types";

const { t } = i18n;

export const CONTRACT_STATUS_WAITING = "waiting";
export const CONTRACT_STATUS_WAITING_OUT_OF_CLOUD = "waiting_out_of_cloud";
export const CONTRACT_STATUS_SIGNED = "signed";
export const CONTRACT_STATUS_CANCELLED = "cancelled";

export const CONTRACTS_STATUS: IFilterStatus[] = [
	{ name: t("global.all"), value: undefined },
	{ name: t("contracts.waiting"), value: CONTRACT_STATUS_WAITING },
	{
		name: t("contracts.waiting_out_of_cloud"),
		value: CONTRACT_STATUS_WAITING_OUT_OF_CLOUD,
	},
	{ name: t("contracts.signed"), value: CONTRACT_STATUS_SIGNED },
	{ name: t("contracts.cancelled"), value: CONTRACT_STATUS_CANCELLED },
];
