import React from "react";

import {
	MemoizedBodyRow,
	MemoizedHeaderRow,
} from "components/atoms/TableRow/TableRow";
import { ITable } from "components/types";

import { cn } from "utils";

export const Table: React.FC<ITable> = ({
	columns,
	children,
	style,
	className,
	selectable,
	sortableHeader,
	sortOptions,
	...props
}) => {
	return (
		<table
			style={style}
			className={cn(["bg-white dark:bg-black", className])}
		>
			{props.header !== false && (
				<thead>
					<MemoizedHeaderRow
						row={props.rowProps}
						columns={columns}
						props={props}
						sortableHeader={sortableHeader}
						sortOptions={sortOptions}
					/>
				</thead>
			)}

			<tbody className={cn(["text-sm", props.bodyClassName])}>
				{children ??
					(!!props.items?.length &&
						props.items?.map((item, key) => {
							let groupDiff = true;
							if (item.rowGroup)
								groupDiff =
									item.rowGroup &&
									props.items?.[key + 1]?.rowGroup !==
										item.rowGroup;
							return (
								<MemoizedBodyRow
									className={cn([
										!groupDiff && "!border-none",
									])}
									key={key}
									columns={columns}
									row={item}
									onRowClick={props.onRowClick}
									props={props}
								/>
							);
						}))}
			</tbody>
		</table>
	);
};
