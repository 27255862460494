import React from "react";

import { Button, Textarea } from "@zolteam/react-ras-library";

import { cn } from "utils/cn";

import style from "./InputMessaging.module.scss";

export interface IInputMessagingProps {
	placeHolder: string;
	labelButton: string;
	onClick: (value: string) => void;
	name?: string;
	isLoading?: boolean;
	isDisabled?: boolean;
	isButtonDisabled?: boolean;
}

export const InputMessaging: React.FC<IInputMessagingProps> = ({
	placeHolder,
	labelButton,
	onClick,
	name,
	isLoading,
	isDisabled,
	isButtonDisabled,
}) => {
	return (
		<div
			className="flex gap-2 p-4 pl-4 bg-white rounded-full dark:bg-neutral-800"
			style={{
				boxShadow: "0 0px 10px 0 rgba(38, 36, 38, 0.15)",
			}}
		>
			<Textarea
				className={cn([
					style.inputMessaging,
					"dark:[&_textarea]:bg-transparent dark:[&_textarea]:text-white dark:[&_label]:text-neutral-300",
				])}
				name={name}
				placeholder={placeHolder}
				disabled={isDisabled}
			/>
			<Button
				className=""
				color="primary"
				onClick={onClick}
				type="button"
				disabled={isDisabled || isButtonDisabled}
				isLoading={isLoading}
			>
				{labelButton}
			</Button>
		</div>
	);
};
