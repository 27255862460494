import { t } from "i18next";
import moment from "moment";

import { CommercialPropositionStatus } from "components/molecules";
import { IColumn, ICommercialProposition } from "components/types";

export const CommercialPropositionsColumns: IColumn[] = [
	{
		name: t("global.status"),
		value: "status",
		render: (item: ICommercialProposition) => {
			return (
				<CommercialPropositionStatus
					status={item.status}
					signatureDate={item.signatureDate}
				/>
			);
		},
	},
	{
		name: t("commercialPropositions.columns.clientName"),
		value: "clientName",
		sortKey: "client_name",
		className: "w-fit max-w-[200px] whitespace-break-spaces",
	},
	{
		name: t("commercialPropositions.columns.clientCode"),
		value: "clientCode",
		sortKey: "client_code",
	},
	{
		name: t("commercialPropositions.columns.siret"),
		value: "clientSiret",
		sortKey: "client_siret",
	},
	{
		name: t("commercialPropositions.columns.postalCode"),
		value: "clientPostalCode",
		sortKey: "client_postal_code",
	},
	{
		name: t("commercialPropositions.columns.creationDate"),
		value: "creationDate",
		hidden: true,
		sortKey: "creation_date",
	},
	{
		name: t("commercialPropositions.columns.sendingDate"),
		value: "sendingDate",
		sortKey: "sending_date",
		render: (item: ICommercialProposition) =>
			!!item.sendingDate &&
			moment(item.sendingDate).format(t("dates.medium")),
	},
	{
		name: t("commercialPropositions.columns.osType"),
		value: "type",
		className: "w-fit max-w-[180px] whitespace-break-spaces leading-4",
	},
	{
		name: t("commercialPropositions.columns.agencyName"),
		value: "agencyName",
		sortKey: "agency_name",
		className: "w-fit max-w-[200px] whitespace-break-spaces leading-4",
		render: (item: ICommercialProposition) => {
			return (
				<>
					{item.agencyName}
					<span className="block text-xs text-neutral-400">
						{t("commercialPropositions.createdThe", {
							date: moment(item.creationDate).format(
								t("dates.medium")
							),
						})}
					</span>
				</>
			);
		},
	},
];
