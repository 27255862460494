import React, { useCallback, useState } from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";

import { Button, Picto } from "@zolteam/react-ras-library";
import { Field } from "components/atoms";
import { ClientInfosFileModal } from "components/molecules";
import { ICommercialPropositionFormValues } from "forms/CommercialPropositionForm/CommercialPropositionFormValidation";

import { cn, phoneNumberFormat } from "utils";

import {
	MAX_CONTACT_EMAIL_LENGTH,
	MAX_CONTACT_NAME_LENGTH,
	MAX_CONTACT_PHONE_LENGTH,
} from "constants_globals";

interface IInterlocutorFields {
	name: string;
	function: string;
	phone: string;
	email: string;
}

interface ICPInterlocutorProps {
	index?: number;
	arrayName?: string;
	onRemove?: (index: number) => void;
	className?: string;

	fields?: IInterlocutorFields;
	locked?: boolean;
	maxNameLength?: number;
}

export const CPInterlocutor: React.FC<ICPInterlocutorProps> = ({
	index = 0,
	arrayName,
	onRemove,
	className,
	fields,
	locked,
	maxNameLength = MAX_CONTACT_NAME_LENGTH,
}) => {
	const { values, setFieldValue, setFieldTouched } =
		useFormikContext<ICommercialPropositionFormValues>();
	const [isClientFileModalOpen, setIsClientFileModalOpen] =
		useState<any>(false);

	const joinPhoneNumber = (key: string, value: string) => {
		setFieldValue(key, value?.replaceAll(" ", "") ?? "");
	};

	const reformatPhoneNumber = (key: string, value: string) => {
		setFieldTouched(key, true);
		setFieldValue(key, phoneNumberFormat(value?.trim() ?? ""));
	};

	const handleSelect = useCallback(
		(option: any) => {
			Object.entries(isClientFileModalOpen ?? {}).forEach(
				(value: any) => {
					setFieldValue(
						value[0],
						option[value[1]] ? option[value[1]].trim() : "",
						true
					).then(() => {
						if (option[value[1]]) setFieldTouched(value[0], true);
					});
				}
			);

			setIsClientFileModalOpen(false);
		},
		[isClientFileModalOpen, setFieldTouched, setFieldValue]
	);

	const phoneField = fields?.phone
		? (values as any)[fields?.phone] ?? ""
		: arrayName
		? (values as any)[arrayName]?.[index]?.phone ?? ""
		: "";

	return (
		<div
			className={
				className ??
				cn([
					"gap-4 px-6 rounded-3xl pb-6 bg-neutral-150 dark:bg-neutral-900 col max-w-[450px]",
					index === 0 || !onRemove ? "py-8" : "pt-6",
				])
			}
		>
			{index > 0 && onRemove && (
				<Button
					type="button"
					color="transparent"
					onClick={onRemove}
					className="self-end"
					disabled={locked}
				>
					<Picto icon="trash" className="!w-6 !h-6 !mr-0" />
				</Button>
			)}
			<div>
				<Field
					label={t(
						"commercialPropositions.form.sections.contact.name"
					)}
					name={
						fields?.name ?? `${arrayName}.${index}.interlocutorName`
					}
					type="text"
					maxLength={maxNameLength}
					disabled={locked}
				/>
				<div className="items-center justify-end gap-2 px-6 mt-2 row">
					<button
						type="button"
						className="text-xs font-medium text-primary-500 dark:text-primary-300"
						onClick={() => {
							setIsClientFileModalOpen({
								[fields?.name ??
								`${arrayName}.${index}.interlocutorName`]:
									"fullName",
								[fields?.phone ??
								`${arrayName}.${index}.phone`]: "phone",
								[fields?.function ??
								`${arrayName}.${index}.function`]: "function",
								[fields?.email ??
								`${arrayName}.${index}.email`]: "email",
							});
						}}
						disabled={locked}
					>
						{t(
							"commercialPropositions.form.sections.company.interlocutors"
						)}
					</button>
				</div>
			</div>
			<Field
				label={t(
					"commercialPropositions.form.sections.contact.function"
				)}
				name={fields?.function ?? `${arrayName}.${index}.function`}
				type="text"
				maxLength={19}
				disabled={locked}
			/>
			<Field
				label={t("commercialPropositions.form.sections.contact.phone")}
				name={fields?.phone ?? `${arrayName}.${index}.phone`}
				type="tel"
				maxLength={MAX_CONTACT_PHONE_LENGTH}
				onFocus={() =>
					joinPhoneNumber(
						fields?.phone ?? `${arrayName}.${index}.phone`,
						phoneField
					)
				}
				onBlur={() =>
					reformatPhoneNumber(
						fields?.phone ?? `${arrayName}.${index}.phone`,
						phoneField
					)
				}
				valueLength={phoneField?.replaceAll(" ", "").length}
				disabled={locked}
			/>
			<Field
				label={t("commercialPropositions.form.sections.contact.email")}
				name={fields?.email ?? `${arrayName}.${index}.email`}
				type="email"
				maxLength={MAX_CONTACT_EMAIL_LENGTH}
				disabled={locked}
			/>
			<ClientInfosFileModal
				isOpen={!!isClientFileModalOpen}
				onClose={() => setIsClientFileModalOpen(false)}
				onSelect={handleSelect}
			/>
		</div>
	);
};
