import React from "react";

import { PieChart, Pie, Cell, Label, Text } from "recharts";

// Utils
import { cn } from "utils";

import style from "./Gauge.module.css";

interface IGaugeLabelTextProps {
	viewBox?: {
		cx: number;
		cy: number;
	};
	number?: number;
	text?: string;
	onClickLabel?: () => void;
}

type TextAnchor = "end" | "middle" | "start" | undefined;

const GaugeLabelText: React.FC<IGaugeLabelTextProps> = ({
	viewBox = { cx: 0, cy: 0 },
	number,
	text,
	onClickLabel,
}) => {
	const { cx, cy } = viewBox;

	let numberOffset = 0;
	let textOffset = 0;
	const numberAnchor = "end";
	let textAnchor: TextAnchor = "middle";

	if (number !== null && text !== null) {
		numberOffset = -10;
		textOffset = 10;
		textAnchor = "start";
	}

	return (
		<>
			{number !== null && (
				<Text
					x={cx}
					y={cy + numberOffset}
					textAnchor="middle"
					dominantBaseline="middle"
					className={cn([style.gaugeNumber, "dark:fill-white"])}
					verticalAnchor={numberAnchor}
				>
					{number}
				</Text>
			)}
			{text !== null && (
				<g onClick={onClickLabel}>
					<Text
						width={200}
						x={cx}
						y={cy + textOffset}
						textAnchor="middle"
						dominantBaseline="middle"
						className={cn([
							style.gaugeText,
							onClickLabel && style.gaugeLink,
							"dark:fill-neutral-200",
						])}
						verticalAnchor={textAnchor}
					>
						{text}
					</Text>
				</g>
			)}
		</>
	);
};

interface IGaugeProps {
	active?: number;
	inactive?: number;
	complete?: boolean;
	completeColor?: "white" | "grey" | "primary";
	number?: number;
	text?: string;
	onClickLabel?: () => void;
}

export const Gauge: React.FC<IGaugeProps> = ({
	active,
	inactive,
	complete,
	completeColor = "white",
	number,
	text,
	onClickLabel,
}) => {
	const data = complete
		? [{ value: 1 }, { value: 0 }]
		: [{ value: active }, { value: inactive }];

	const completeClass = style[`complete--${completeColor}`];

	return (
		<PieChart width={200} height={200}>
			<Pie
				dataKey="value"
				data={data}
				legendType="none"
				innerRadius={85}
				outerRadius={100}
				startAngle={90}
				endAngle={450}
			>
				<Label
					position="center"
					content={
						<GaugeLabelText
							number={number}
							text={text}
							onClickLabel={onClickLabel}
						/>
					}
				/>
				<Cell
					className={cn(
						complete
							? [style.complete, completeClass]
							: [style.active, "fill-primary-500"]
					)}
				/>
				<Cell
					className={cn(
						complete
							? [style.complete, completeClass]
							: [
									style.inactive,
									"fill-neutral-300 dark:fill-white",
							  ]
					)}
				/>
			</Pie>
		</PieChart>
	);
};

export const EmptyGauge = ({ text }: { text: string }) => (
	<Gauge active={0} inactive={1} number={0} text={text} />
);
