import React, { useEffect, useState } from "react";

interface ICountDownProps {
	time?: number;
	text?: string | ((val: number) => string);
	handleEnd: () => void;
}

const CountDown: React.FC<ICountDownProps> = ({ time, text, handleEnd }) => {
	const [Time, setTime] = useState(time ?? 15);

	useEffect(() => {
		let intervalId: any;

		if (Time > 0) {
			intervalId = setInterval(() => {
				if (Time === 1) {
					handleEnd();
				}
				setTime((time) => time - 1);
			}, 1000);
		}

		return () => clearInterval(intervalId);
	}, [Time, handleEnd]);

	const generateText = () => {
		if (typeof text === "string") return text;
		if (typeof text === "function") return text(Time);
		return Time;
	};

	return generateText();
};

export default CountDown;
