import React, { PropsWithChildren, useEffect } from "react";

import { Picto } from "@zolteam/react-ras-library";

import { cn } from "utils";

export interface IAccordionProps extends PropsWithChildren {
	className?: string;
	headClassName?: string;
	title?: string | React.ReactNode;
	initialState?: boolean;
	onToggle?: (isOpen: boolean) => void;
	hideOnlyBody?: boolean;
}

export const Accordion: React.FC<IAccordionProps> = ({
	title,
	className,
	headClassName,
	children,
	initialState,
	onToggle,
	hideOnlyBody,
}) => {
	const [IsOpen, setIsOpen] = React.useState(initialState ?? false);

	useEffect(() => {
		setIsOpen(initialState ?? false);
	}, [initialState]);

	return (
		<div className={className}>
			<div
				className={cn([
					"items-center justify-between cursor-pointer row gap-2",
					headClassName,
				])}
				onClick={(e) => {
					onToggle?.(!IsOpen);
					setIsOpen(!IsOpen);
				}}
			>
				{title}
				<Picto
					icon="arrowDown"
					className={cn([
						"w-4 h-4 duration-300 -rotate-90 min-w-[1rem] dark:text-white",
						IsOpen && "rotate-0",
					])}
				/>
			</div>
			{hideOnlyBody ? (
				<div className={cn([IsOpen ? "block" : "hidden"])}>
					{children}
				</div>
			) : (
				IsOpen && <div>{children}</div>
			)}
		</div>
	);
};
