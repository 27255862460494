import moment from "moment";

import { IDatesFilter } from "components/types";

export const getDatesFromParams = (params: any): IDatesFilter | undefined => {
	const dateBetweenStart = params.get("dateBetweenStart");
	const dateBetweenEnd = params.get("dateBetweenEnd");
	const dateBetweenName = params.get("dateBetweenName");

	if (
		!moment(dateBetweenStart).isValid() ||
		!moment(dateBetweenEnd).isValid()
	)
		return undefined;

	return {
		dateBetweenStart,
		dateBetweenEnd,
		dateBetweenName,
	};
};

export const getPageNumberFromParams = (
	params: any,
	default_value: number = 1
) => {
	const page = params.get("page") || default_value;
	if (isNaN(Number(page))) return default_value;
	return Math.floor(Number(page));
};
