import React, { PropsWithChildren } from "react";

import { Helmet } from "react-helmet-async";

import { IPageHeadProps, PageHead } from "components/molecules";

import { cn } from "utils";

import { APP_NAME } from "constants_globals";

interface IPageLayoutProps extends PropsWithChildren {
	title: string | React.ReactNode;
	tabTitle?: string;
	header?: React.ReactNode;
	sideContent?: React.ReactNode;
	sideContentPosition?: "left" | "right";
	headerProps?: IPageHeadProps;
	className?: string;
	bodyClassName?: string;
}

export const PageLayout: React.FC<IPageLayoutProps> = ({
	title,
	tabTitle,
	children,
	header,
	headerProps,
	sideContent,
	sideContentPosition = "right",
	className,
	bodyClassName,
}) => {
	const navigatorTabTitle =
		typeof title === "string" && !tabTitle ? title : tabTitle;
	return (
		<>
			<Helmet>
				<title>
					{navigatorTabTitle
						? `${navigatorTabTitle} - ${APP_NAME}`
						: APP_NAME}
				</title>
			</Helmet>
			<div
				className={cn([
					"relative w-full h-full gap-8 pt-8 row",
					className,
				])}
			>
				{sideContent && sideContentPosition === "left" && sideContent}
				<div
					className={cn([
						"flex-1 min-h-[50vh] overflow-x-hidden col",
						bodyClassName,
					])}
				>
					{header ?? (
						<PageHead
							title={title}
							{...headerProps}
							className={cn([
								(!sideContent ||
									sideContentPosition === "right") &&
									"pr-4 sm:pr-8",
								headerProps?.className,
							])}
						/>
					)}
					{children}
				</div>
				{sideContent && sideContentPosition === "right" && sideContent}
			</div>
		</>
	);
};
