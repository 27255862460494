import { t } from "i18next";
import { toast } from "react-toastify";

export const displayErrorMessage = (err: any) => {
	const doestTradExist =
		t(`API_ERRORS.${encodeURIComponent(err.response.data.message)}`) !==
		`API_ERRORS.${encodeURIComponent(err.response.data.message)}`;

	if (
		err.response &&
		err.response.data &&
		err.response.data.message &&
		doestTradExist
	) {
		toast.error(t(`API_ERRORS.${err.response.data.message}`));
	} else {
		toast.error(t("API_ERRORS.default"));
	}

	return err;
};
