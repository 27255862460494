import React from "react";

import { ErrorMessage, FieldArray, useFormikContext } from "formik";
import { t } from "i18next";
import * as Yup from "yup";

import { ICommercialPropositionFormSectionProps } from "../CommercialPropositionForm";
import { ICommercialPropositionFormValues } from "../CommercialPropositionFormValidation";
import { Title, InfoMessage, Button } from "@zolteam/react-ras-library";
import { PricingTypesSelect } from "components/molecules";
import { Prestation } from "components/organisms";
import { IQualification } from "components/types";

import { cn } from "utils";

import {
	CP_STATUS_NO_ACTION_TAKEN,
	CP_STATUS_SIGNED,
	CP_TYPE_PARTNER,
} from "constants_globals";

export const defaultQualification: IQualification = {
	qualification: undefined,
	qualificationName: "",
	referenceSalary: "",
	accordingToAgreement: false,
	subjectToTaxation: "",
	delegationCoefficient: "",
	managementCoefficient: "",
	feesAppliedCoefficient: "",
	weeklyFee: "",
};

export interface ICPOurCommercialPropositionValues {
	weeksNumber?: number;
	delegationType?: any;
	managementType?: any;
	hiringFees?: any;
	qualifications?: IQualification[];
}

export const CPOurCommercialPropositionInitialValues: ICPOurCommercialPropositionValues =
	{
		weeksNumber: undefined,
		delegationType: undefined,
		managementType: undefined,
		hiringFees: undefined,
		qualifications: [
			{
				...defaultQualification,
			},
		],
	};

export const CPOurCommercialPropositionValidation = {
	delegationType: Yup.object().required(t("forms.field.required")),
	managementType: Yup.object().required(t("forms.field.required")),
	hiringFees: Yup.object().required(t("forms.field.required")),
	weeksNumber: Yup.number().required(t("forms.field.required")),
	qualifications: Yup.array().of(
		Yup.object().shape({
			qualificationName: Yup.string().required(t("forms.field.required")),
			referenceSalary: Yup.string().when("accordingToAgreement", {
				is: (val: boolean) => val === false,
				then: () => Yup.string().required(t("forms.field.required")),
				otherwise: () => Yup.string().nullable(),
			}),
			accordingToAgreement: Yup.boolean(),
			subjectToTaxation: Yup.string().when(
				"cpType",
				(_: any, __: any, form: any) => {
					return form.context.cpType !== CP_TYPE_PARTNER
						? Yup.string().required(t("forms.field.required"))
						: Yup.string().nullable();
				}
			),
			delegationCoefficient: Yup.string().required(
				t("forms.field.required")
			),
			managementCoefficient: Yup.string().required(
				t("forms.field.required")
			),
			feesAppliedCoefficient: Yup.string().required(
				t("forms.field.required")
			),
			weeklyFee: Yup.string().required(t("forms.field.required")),
		})
	),
};

export const CPOurCommercialProposition: React.FC<
	ICommercialPropositionFormSectionProps
> = ({ locked, commercialPropositionStatus, cpType }) => {
	const { values, setFieldValue, errors, touched } =
		useFormikContext<ICommercialPropositionFormValues>();

	return (
		<div className="dark:text-white">
			<Title tag="h3" size="heading03" className="dark:text-white">
				{t(
					"commercialPropositions.form.sections.commercialProposition.conditionsType"
				)}
			</Title>
			<PricingTypesSelect
				name="delegationType"
				label={t(
					"commercialPropositions.form.sections.commercialProposition.delegation"
				)}
				category="tariffConditionsDelegation"
				required
				onChange={(value: any) =>
					setFieldValue("delegationType", value)
				}
				value={values.delegationType}
				disabled={locked}
			/>
			<PricingTypesSelect
				name="managementType"
				label={t(
					"commercialPropositions.form.sections.commercialProposition.management"
				)}
				category="tariffConditionsManagement"
				required
				className="mt-2"
				onChange={(value: any) =>
					setFieldValue("managementType", value)
				}
				value={values.managementType}
				disabled={locked}
			/>
			<Title tag="h3" size="heading03" className="dark:text-white">
				{t(
					"commercialPropositions.form.sections.commercialProposition.hiringFees"
				)}
			</Title>
			<PricingTypesSelect
				name="hiringFees"
				label={t(
					"commercialPropositions.form.sections.commercialProposition.feesSelect"
				)}
				category="feesInCaseOfHiring"
				required
				className="mt-2"
				onChange={(value: any) => setFieldValue("hiringFees", value)}
				value={values.hiringFees}
				disabled={locked}
			/>
			<div className="mt-2">
				{t(
					"commercialPropositions.form.sections.commercialProposition.weeklyDeduction"
				)}
			</div>
			<div className="[&_*]:!text-sm">
				<InfoMessage color="primary" withIcon>
					{t(
						"commercialPropositions.form.sections.commercialProposition.weeklyDeductionInfo"
					)}
				</InfoMessage>
			</div>
			<div className="p-4 mt-4 border-2 rounded-3xl border-neutral-300">
				{t(
					"commercialPropositions.form.sections.commercialProposition.weeksDelay"
				)}
				<div className="inline-block">
					<div className="mx-2 col">
						<input
							className={cn([
								"px-2 text-center border-2 rounded-full dark:bg-neutral-800 dark:border-neutral-900 appearance-textfield w-full min-w-[200px]",
								errors.weeksNumber &&
									touched.weeksNumber &&
									"border-red-500",
							])}
							placeholder={t(
								"commercialPropositions.form.sections.commercialProposition.weekNumber"
							)}
							value={values.weeksNumber || ""}
							onChange={(e: any) => {
								return (
									e.target.value < 100 &&
									setFieldValue(
										"weeksNumber",
										e.target.value < 0
											? Math.abs(e.target.value)
											: e.target.value
									)
								);
							}}
							min={0}
							max={100}
							name="weeksNumber"
							type="number"
							disabled={locked}
						/>
						<ErrorMessage name={"weeksNumber"}>
							{(msg: string) => (
								<InfoMessage withIcon color="error">
									{msg}
								</InfoMessage>
							)}
						</ErrorMessage>
					</div>
				</div>
				{t(
					"commercialPropositions.form.sections.commercialProposition.weeksDelayInfo"
				)}
			</div>
			<div className="mt-2 mb-8 ml-4 text-xs italic text-neutral-400">
				{t("forms.field.mandatory")}
			</div>
			<FieldArray name="qualifications">
				{({ remove, push }) => (
					<div className="gap-4 col">
						{values.qualifications?.map((qualification, index) => {
							return (
								<Prestation
									index={index}
									key={index}
									onRemove={remove}
									qualification={qualification}
									cpType={cpType}
									locked={locked}
								/>
							);
						})}
						{values.qualifications &&
							values.qualifications?.length < 8 && (
								<Button
									disabled={
										locked ||
										[
											CP_STATUS_SIGNED,
											CP_STATUS_NO_ACTION_TAKEN,
										].includes(commercialPropositionStatus)
									}
									color="primary"
									size="m"
									type="button"
									className="self-start"
									onClick={() =>
										push({
											...defaultQualification,
										})
									}
								>
									{t(
										"commercialPropositions.form.sections.commercialProposition.addQualification"
									)}
								</Button>
							)}
					</div>
				)}
			</FieldArray>
		</div>
	);
};
