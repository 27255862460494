// Hooks
import api from "./api";

import { FRONT_URL } from "constants_globals";

class UserServiceClass {
	getUserInformation() {
		return api.get(`/user/me`).then((resp) => resp.data);
	}

	getUserNotificationSettings() {
		return api
			.get(`/user/me/notification-setting`)
			.then((resp) => resp.data);
	}

	setUserNotificationSettings(data: any) {
		return api
			.put(`/user/me/notification-setting`, data)
			.then((resp) => resp.data);
	}

	updateUserInformation(data: any) {
		return api.patch(`/user/me`, data).then((resp) => resp.data);
	}

	setUserMainAgency(data: any) {
		return api.put(`/user/me/main-agency`, data).then((resp) => resp.data);
	}

	changeUserPassword(data: any) {
		return api
			.post(`/user/me/change-password`, data)
			.then((resp) => resp.data);
	}

	getUserGlobalPermissions(token: string, email?: string) {
		return api
			.get(`/user/me/global-permissions`, {
				headers: { "BGP-Authorization": `${token}` },
				params: email
					? {
							_switch_user: `${email}`,
					  }
					: {},
			})
			.then((resp) => resp.data);
	}

	getChangeLogDisplay() {
		return api.get(`/user/me/display-changelog`).then((resp) => resp.data);
	}

	async getMetaVersion() {
		const response = await fetch(`${FRONT_URL}/meta.json`, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		});
		const myJson = await response.json();
		return myJson;
	}
}

const UserService = new UserServiceClass();
export default UserService;
