import saveAs from "file-saver";
import { t } from "i18next";
import { toast } from "react-toastify";

export const extractFilename = (response: any) => {
	const filename = response.headers["content-disposition"]
		.split(";")
		.find((n: string) => n.includes("filename="))
		.replace("filename=", "")
		.trim()
		.replace(/"/g, "");

	return filename;
};

export const handleFileSave = (prom: Promise<any>, defaultTitle: string) => {
	return prom
		.then((res: any) => {
			if (!res?.data) return;

			const fileData = new Blob([res.data]);
			const filename = extractFilename(res);

			saveAs(fileData, filename || defaultTitle);
		})
		.catch((err: any) => {
			toast.error(t("API_ERRORS.download"));
		});
};

export const getFile = (file: Blob) => {
	const prom = new Promise((resolve, reject) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			if (typeof reader.result !== "string") {
				reject();
				return;
			}
			const base64 = reader.result?.includes(
				"data:application/pdf;base64,"
			)
				? reader.result?.replace("data:application/pdf;base64,", "")
				: reader.result;
			resolve(base64);
		};
		reader.onerror = function (error) {
			toast.error(
				"Une erreur est survenue lors de la génération du fichier."
			);
		};
	});

	return prom;
};
