import * as Yup from "yup";

import { IUserDetailsForConfig } from "components/types";

import { EMAIL_REGEX } from "constants_globals";

export interface IUsersConfigListFormValues {
	Users: IUserDetailsForConfig[];
}

export const UsersConfigListFormInitialValues = {};

export const NO_USER_SELECTED_ERROR = "noUserSelected";

export const UsersConfigListFormValidation = (t: any) =>
	Yup.object().shape({
		Users: Yup.array()
			.of(
				Yup.object().shape({
					isSelected: Yup.boolean(),
					firstName: Yup.string().when("isSelected", {
						is: (value: boolean) => value,
						then: () =>
							Yup.string().required(t("forms.field.required")),
						otherwise: () => Yup.string().nullable(),
					}),
					lastName: Yup.string().when("isSelected", {
						is: (value: boolean) => value,
						then: () =>
							Yup.string().required(t("forms.field.required")),
						otherwise: () => Yup.string().nullable(),
					}),
					email: Yup.string()
						.required(t("forms.email.required"))
						.test("email", t("forms.email.invalid"), (value) =>
							value ? EMAIL_REGEX.test(value) : true
						),
					sites: Yup.array()
						.of(
							Yup.object().shape({
								active: Yup.bool(),
							})
						)
						.when("isSelected", {
							is: (value: boolean) => value,
							then: () =>
								Yup.array()
									.of(
										Yup.object().shape({
											active: Yup.bool(),
										})
									)
									.test(
										"hasActiveSite",
										t("userConfiguration.noSelectedSite"),
										(sites: any) =>
											sites.some(
												(site: any) => site.active
											)
									)
									.required(
										t("userConfiguration.noSelectedSite")
									),
							otherwise: () => Yup.array().notRequired(),
						}),
				})
			)
			.test(
				NO_USER_SELECTED_ERROR,
				NO_USER_SELECTED_ERROR,
				(users: any) => users.some((user: any) => user.isSelected)
			)
			.required(NO_USER_SELECTED_ERROR),
	});
