import React, { Fragment, useLayoutEffect } from "react";

import { Form, Formik } from "formik";
import { t } from "i18next";
import moment from "moment";
import * as Yup from "yup";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import ThreadService from "services/ThreadsService";

import useAppContext from "store/useAppContext";

import { ChatMessaging } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import { ActionResult, InputMessaging } from "components/molecules";
import { ThreadHead } from "components/organisms";
import { IMessage, IThreadSummary } from "components/types";

import { defineUserName, findLastMessageAuthor } from "utils";

export interface IThreadProps {
	thread?: IThreadSummary;
	onStatusChange?: (newStatus: string) => void;
}

const threadMessageValidation = () => {
	return Yup.object().shape({
		message: Yup.string().required("").min(1).max(500),
	});
};

export const Thread: React.FC<IThreadProps> = ({ thread, onStatusChange }) => {
	const { appState } = useAppContext();
	const hasSended = React.useRef(false);
	const queryClient = useQueryClient();
	const scrollLimit = React.useRef<HTMLDivElement>(null);

	const scrollToBottom = (behavior: ScrollBehavior = "instant") => {
		if (scrollLimit.current)
			scrollLimit.current.scrollIntoView({
				behavior: behavior,
			});
	};

	const handleSend = (message: string, resetForm: any) => {
		if (!thread?.id) return Promise.resolve();
		hasSended.current = true;
		return ThreadService.postThread(thread?.id, {
			content: message,
		}).then(() => {
			queryClient.invalidateQueries({
				queryKey: ["thread", thread?.id],
			});

			resetForm();
		});
	};

	useLayoutEffect(() => {
		scrollToBottom(hasSended.current ? "smooth" : "instant");
	});

	const { data, isLoading, error } = useQuery({
		queryKey: ["thread", thread?.id],
		queryFn: () => {
			if (!thread?.id) return;
			return ThreadService.getThread(thread?.id);
		},
		enabled: !!thread?.id,
		refetchInterval: 60000,
	});

	if (isLoading) return <Spinner />;
	if (!thread?.id || error)
		return <ActionResult type="error" text={t("API_ERRORS.default")} />;

	const messages = data?.messages || [];

	return (
		<div className="relative h-full col">
			<div className="px-8 py-4 border-b shadow-sm">
				<ThreadHead thread={data} onStatusUpdate={onStatusChange} />
			</div>
			<div className="flex flex-col px-8 pt-4 pb-10 overflow-auto flew-wrap">
				{messages.map((message: IMessage, index: number) => {
					const subtitle =
						message.sender === "client" || !message.author
							? moment(message.createdAt).format(
									t("dates.mediumAt")
							  )
							: t("threads.sendBy", {
									date: moment(message.createdAt).format(
										t("dates.mediumAt")
									),
									user: message.author,
							  });
					const lastMessageAuthor = findLastMessageAuthor(
						messages,
						defineUserName(appState.connectAs, appState.auth.user)
					);
					return (
						<Fragment key={message.id}>
							<ChatMessaging
								side={
									message.sender === "agency"
										? "left"
										: "right"
								}
								text={message.content}
								date={
									messages[index + 1]?.sender !==
										message.sender ||
									// time between messages > 3 minutes
									(Date.parse(
										messages[index + 1]?.createdAt
									) -
										Date.parse(message.createdAt)) /
										1000 /
										60 >
										3
										? subtitle
										: undefined
								}
							/>

							{(message.id === lastMessageAuthor?.id ||
								message.sender === "client") &&
								index === messages.length - 1 && (
									<p className="-mt-2 text-xs text-right text-neutral-500 dark:text-neutral-300">
										{t(
											!thread.readByAgency
												? "threads.noRead"
												: "threads.read"
										)}
									</p>
								)}
						</Fragment>
					);
				})}
				<div ref={scrollLimit} />
			</div>
			<Formik
				initialValues={{ message: "" }}
				onSubmit={(values, { resetForm }) => {
					handleSend(values.message, resetForm);
				}}
				validationSchema={threadMessageValidation()}
				validateOnMount={true}
				validateOnBlur={false}
			>
				{({ handleSubmit, isSubmitting, isValid, dirty }) => {
					return (
						<Form
							onSubmit={handleSubmit}
							className="sticky bottom-[20px] mx-8 mt-auto rounded-full"
						>
							<InputMessaging
								placeHolder={t("threads.inputPlaceholder")}
								labelButton={t("global.send")}
								onClick={() => {
									handleSubmit();
								}}
								name="message"
								isLoading={isSubmitting}
								isDisabled={isSubmitting}
								isButtonDisabled={!dirty || !isValid}
							/>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
