import { t } from "i18next";

export const MAX_CONTACT_LOGIN_MESSAGE_LENGTH = 1000;

export const MAX_CONTACT_SUBJECT_LENGTH = 200;
export const MAX_AGENCY_CONTACT_MESSAGE_LENGTH = 1000;
export const MAX_CONTACT_MESSAGE_LENGTH = 2000;

export interface IContactTheme {
	id: string;
	name: string;
	picto?: string;
}

export const CONTRACT_THEME = "contract";
export const TIMESHEET_THEME = "time_sheet";
export const INVOICE_THEME = "invoice";
export const RATING_THEME = "rating";
export const OTHER_THEME = "other";

export const CONTACT_THEMES: IContactTheme[] = [
	{
		id: CONTRACT_THEME,
		name: t("contracts.title"),
		picto: "menuContracts",
	},
	{
		id: TIMESHEET_THEME,
		name: t("timesheets.title"),
		picto: "menuTimesheets",
	},
	{
		id: INVOICE_THEME,
		name: t("invoices.title"),
		picto: "menuInvoices",
	},
	{
		id: RATING_THEME,
		name: t("reviews.title"),
		picto: "menuEvaluations",
	},
	{
		id: OTHER_THEME,
		name: t("global.others"),
		picto: "menuAdminUsers",
	},
];
