import { t } from "i18next";
import moment from "moment";

import { Picto } from "@zolteam/react-ras-library";
import { IColumn } from "components/types";

export const INVOICES_COLUMNS: IColumn[] = [
	{
		name: t("global.readed"),
		value: "isRead",
		className: "pl-1 w-4",
		headerClassName: "invisible",
		sortable: false,
		render: ({ isRead }) =>
			!isRead && (
				<Picto icon="circle" className="w-3 h-3 text-primary-500" />
			),
	},
	{
		name: t("invoices.invoiceNumberTitle"),
		value: "invoice_number",
		render: ({ number, type }) => (
			<div className="gap-1 col">
				<b>
					{type === "invoice"
						? t("invoices.invoice")
						: t("invoices.asset")}
				</b>
				<p className="text-neutral-500">{number}</p>
			</div>
		),
	},
	{
		name: t("invoices.billingDate"),
		value: "date",
		render: ({ invoiceDate }) =>
			moment(invoiceDate).format(t("dates.medium")),
	},
	{
		name: t("invoices.dueDate"),
		value: "dueDate",
		sortable: false,
		render: ({ dueDate }) => moment(dueDate).format(t("dates.medium")),
	},
	{
		name: t("invoices.totalHT"),
		value: "totalHT",
		sortable: false,
		render: ({ totalHT }) =>
			t("invoices.HTvalue", {
				value: totalHT,
				currency: "€",
			}),
	},
	{
		name: t("invoices.totalTTC"),
		value: "totalTTC",
		sortable: false,
		render: ({ totalTTC, totalTVA }) => (
			<div className="gap-1 col">
				<p>
					{t("invoices.TTCvalue", { value: totalTTC, currency: "€" })}
				</p>
				<p className="text-xs text-neutral-500">
					{t("invoices.TVApart", { tva: totalTVA, currency: "€" })}
				</p>
			</div>
		),
	},
];
