import { FC, useState } from "react";

import { t } from "i18next";
import { promiseToast } from "toastify";
import { TBanner, TBannerEdit } from "types/TBanners";

import { useQueryClient } from "@tanstack/react-query";

import BannersService from "services/BannersService";

import { useList } from "hooks/useList";

import { BannersViewColumns } from "./BannersViewColumns";
import { bannersItemsActions } from "./BannersViewItemActions";
import { IActionsListItem } from "@components/types";
import { Button, ModalV2 } from "@zolteam/react-ras-library";
import { AsyncList } from "components/organisms";
import { PageLayout } from "components/templates";
import { EditBannerForm } from "forms/index";

export const BannersView: FC = () => {
	const [isEditingBanner, setIsEditingBanner] = useState<
		TBannerEdit | undefined
	>(undefined);
	const useListHook = useList({
		pagination: {
			pageSize: 20,
		},
	});
	const queryClient = useQueryClient();
	const { Pagination, getPaginationKeys, getFiltersKeys, setPagintation } =
		useListHook;

	const fetchBanners = () => {
		return BannersService.getBanners(
			Pagination.currentPage,
			Pagination.pageSize
		).then((res) => {
			setPagintation({
				...Pagination,
				totalCount: res.total,
			});
			return res;
		});
	};

	const handleCloseModal = (refresh = false) => {
		if (refresh)
			queryClient.invalidateQueries({
				queryKey: ["banners"],
			});
		setIsEditingBanner(undefined);
	};

	const handleDisable = (selected: TBanner[]) => {
		const ids = selected
			.filter((banner) => {
				return !banner.deletedAt;
			})
			.map((banner) => banner.id);
		const proms: Promise<any>[] = [];

		ids.forEach((id) => {
			if (!id) return;
			proms.push(BannersService.deleteBanner(id));
		});

		const prom = Promise.all(proms).then(() => {
			queryClient.invalidateQueries({
				queryKey: ["banners"],
			});
		});

		return promiseToast(prom);
	};

	return (
		<PageLayout
			title={t("banners.title")}
			headerProps={{
				className:
					"[&>div:last-child]:w-full [&>div:last-child]:xs:w-auto [&>div:last-child]:ml-auto",
				children: (
					<Button
						color="primary"
						type="button"
						className="w-full"
						onClick={() => setIsEditingBanner({} as TBannerEdit)}
					>
						Nouveau
					</Button>
				),
			}}
		>
			<AsyncList
				query={{
					queryKey: [
						"banners",
						...getPaginationKeys(),
						...getFiltersKeys(),
					],
					queryFn: () => fetchBanners(),
				}}
				filters={false}
				columns={BannersViewColumns}
				useListHook={useListHook}
				itemsKey="items"
				sortable={false}
				actions={[
					{
						key: "trash",
						icon: "trash",
						label: (selected: TBanner[]) =>
							t("banners.disableCount", {
								count: selected.length,
							}),
						onClick: (_: IActionsListItem, selected: TBanner[]) =>
							handleDisable(selected),
						disabled: (selected: TBanner[]) => !selected.length,
					},
				]}
				itemActions={bannersItemsActions({
					onEdit: (item) => setIsEditingBanner(item as TBannerEdit),
					onDisable: (item) => handleDisable([item]),
				})}
			/>
			<ModalV2
				isDisplayed={!!isEditingBanner}
				onClose={() => handleCloseModal()}
				title={t("banners.title")}
				size="s"
			>
				<EditBannerForm
					initialValues={isEditingBanner}
					onClose={() => handleCloseModal()}
					onSuccess={() => {
						handleCloseModal(true);
					}}
				/>
			</ModalV2>
		</PageLayout>
	);
};
