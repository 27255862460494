import React from "react";

import { t } from "i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { promiseToast } from "toastify";
import * as Yup from "yup";

import { useQueryClient } from "@tanstack/react-query";

import CommercialPropositionService from "services/CommercialPropositionsService";

import { useList } from "hooks/useList";

import { commercialPropositionItemActions } from "./CommercialPropositionItemActions";
import { CommercialPropositionsColumns } from "./CommercialPropositionsColumns";
import { Button, Picto } from "@zolteam/react-ras-library";
import { ConfirmModal } from "components/molecules";
import {
	AsyncList,
	ChangeCommercialPropositionStatusModal,
	CommercialPropositionsFilters,
} from "components/organisms";
import { PageLayout } from "components/templates";
import { IColumn, ICommercialProposition } from "components/types";

import { handleFileSave } from "utils";

import {
	CP_STATUS_NO_ACTION_TAKEN,
	CP_STATUS_SENT,
	CP_STATUS_SIGNED_OUTSIDE,
} from "constants_globals";

export interface ICommercialPropositionsProps {}

interface ICommercialPropositionChangeStatusProps
	extends ICommercialProposition {
	newStatus: string;
}

export const CommercialPropositions: React.FC<
	ICommercialPropositionsProps
> = () => {
	const [changingStatusFor, setChangingStatusFor] =
		React.useState<ICommercialPropositionChangeStatusProps>();

	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const useListHook = useList<ICommercialProposition>({
		defaultSort: "creation_date",
		additionalFilters: [
			{
				key: "agencyId",
				getValue: (value) => parseInt(value),
			},
			{
				key: "sortOrder",
				getValue: (value) => value,
			},
		],
	});

	const {
		Pagination,
		setPagintation,
		Filters,
		setFilter,
		changeFilters,
		getFiltersKeys,
		getPaginationKeys,
		IsExporting,
		setIsExporting,
	} = useListHook;

	const handleExport = () => {
		setIsExporting(true);
		const prom = CommercialPropositionService.commercialPropositionExport({
			...Filters,
			freeSearch: Filters.search || undefined,
		}).finally(() => setIsExporting(false));

		handleFileSave(
			prom,
			`commercial-propositions-${moment().format(t("dates.full"))}.xlsx`
		);
	};

	const fetchCommercialPropositions = (signal: AbortSignal) => {
		return CommercialPropositionService.commercialPropositionsList(
			{
				page: Pagination.currentPage,
				limit: Pagination.pageSize,
				...Filters,
				freeSearch: Filters.search || undefined,
				sortOrder: Filters.sortOrder || "desc",
			},
			signal
		).then((resp) => {
			setPagintation({
				...Pagination,
				totalCount: resp.search.total,
			});

			return resp;
		});
	};

	const refetch = () => {
		queryClient.invalidateQueries({
			queryKey: [
				"commercial-propositions",
				...getPaginationKeys(),
				...getFiltersKeys(),
				Filters.agencyId ? Filters.agencyId : "allAgencies",
			],
		});
	};

	const handleStatusChange = (
		item: ICommercialProposition,
		data: any,
		fromConfirm = false
	) => {
		if (!fromConfirm) {
			setChangingStatusFor({ ...item, newStatus: data.status });

			return Promise.resolve();
		}

		const prom =
			data.status === CP_STATUS_SENT
				? CommercialPropositionService.sendCommercialProposition(
						item.id,
						item
				  )
				: CommercialPropositionService.patchCommercialProposition(
						item.id,
						data
				  );

		prom.then(() => {
			if (item.status !== CP_STATUS_NO_ACTION_TAKEN)
				setChangingStatusFor(undefined);
			else setChangingStatusFor({ ...item, newStatus: data.status });
			refetch();
		});

		promiseToast(prom, {
			success: t("promiseToast.fetchSuccess"),
			pending: t("promiseToast.fetching"),
		});

		return prom;
	};

	const handleNoActionTaken = (item: {
		noActionTakenComment: string;
		noActionTakenReminderDate: string;
		id: number;
	}) => {
		const prom = CommercialPropositionService.changeStatusNoActionTaken(
			item.id,
			{
				noActionTakenComment: item.noActionTakenComment,
				noActionTakenReminderDate: !!item.noActionTakenReminderDate
					? moment(
							item.noActionTakenReminderDate,
							t("dates.default")
					  ).format("DD/MM/YYYY")
					: "",
			}
		);

		prom.then(() => {
			setChangingStatusFor(undefined);
			refetch();
		});

		promiseToast(prom, {
			success: t("promiseToast.fetchSuccess"),
			pending: t("promiseToast.fetching"),
		});

		return prom;
	};

	return (
		<PageLayout
			title={t("commercialPropositions.pageTitle")}
			headerProps={{
				className:
					"[&>div:last-child]:w-full [&>div:last-child]:xs:w-auto [&>div:last-child]:ml-auto",
				children: (
					<Button
						outline
						color="primary"
						type="button"
						onClick={handleExport}
						className="w-full"
						isLoading={IsExporting}
					>
						<Picto icon="download" />
						{t("global.exportList")}
					</Button>
				),
			}}
		>
			<AsyncList
				query={{
					queryKey: [
						"commercial-propositions",
						...getPaginationKeys(),
						...getFiltersKeys(),
						Filters.agencyId ? Filters.agencyId : "allAgencies",
					],
					queryFn: ({ signal }) =>
						fetchCommercialPropositions(signal),
				}}
				filters={
					<CommercialPropositionsFilters
						filters={Filters}
						setFilter={setFilter}
					/>
				}
				columns={CommercialPropositionsColumns}
				itemsKey="results"
				itemActions={commercialPropositionItemActions({
					handleStatusChange,
				})}
				selectable={false}
				useListHook={useListHook}
				onItemClick={(item) => {
					navigate(`/commercial-proposition/${item.id}`);
				}}
				header={false}
				sortableHeader
				onColumnClick={(column: IColumn) => {
					const columnName = column.sortKey || column.value;
					changeFilters(
						{
							sortOrder:
								Filters.sortOrder === "desc" ||
								Filters.sortType !== columnName
									? "asc"
									: "desc",
							sortType: columnName,
						},
						false
					);
				}}
				sortOptions={{
					sortBy: Filters.sortType,
					sortOrder: Filters.sortOrder,
				}}
			/>

			{!!changingStatusFor && (
				<>
					<ChangeCommercialPropositionStatusModal
						isOpen={
							changingStatusFor?.newStatus ===
							CP_STATUS_NO_ACTION_TAKEN
						}
						onClose={() => {
							refetch();
							setChangingStatusFor(undefined);
						}}
						item={changingStatusFor}
						onSubmit={(values) => {
							return handleNoActionTaken({
								...changingStatusFor,
								...values,
							});
						}}
						title={t(
							"commercialPropositions.changeStatusModal.title"
						)}
						fields={{
							comment: {
								name: "noActionTakenComment",
								label: t(
									"commercialPropositions.changeStatusModal.comment"
								),
								validationSchema: Yup.string().required(
									t(
										"commercialPropositions.changeStatusModal.requiredComment"
									)
								),
							},
							date: {
								name: "noActionTakenReminderDate",
								label: t(
									"commercialPropositions.changeStatusModal.reminderDate"
								),
								validationSchema: Yup.string().required(
									t(
										"commercialPropositions.changeStatusModal.requiredDate"
									)
								),
							},
						}}
					>
						<p>
							-&nbsp;
							{t(
								"commercialPropositions.changeStatusModal.infoText1"
							)}
						</p>
						<p className="mt-3">
							-&nbsp;
							{t(
								"commercialPropositions.changeStatusModal.infoText2"
							)}
						</p>
					</ChangeCommercialPropositionStatusModal>
					<ConfirmModal
						title={t(
							"commercialPropositions.removedNoActionTakenModal.title"
						)}
						isOpen={
							changingStatusFor?.status ===
								CP_STATUS_NO_ACTION_TAKEN &&
							changingStatusFor?.newStatus === CP_STATUS_SENT
						}
						onClose={() => {
							setChangingStatusFor(undefined);
							refetch();
						}}
						onConfirm={() => {}}
						confirmText={t("global.close")}
						buttons={["confirm"]}
					>
						<p>
							{t(
								"commercialPropositions.removedNoActionTakenModal.infoText1"
							)}
						</p>
						<p className="mt-3">
							<b>
								{t(
									"commercialPropositions.removedNoActionTakenModal.infoText2",
									{
										date: moment(
											changingStatusFor?.sendingDate
										).format(t("dates.default")),
									}
								)}
							</b>
						</p>
					</ConfirmModal>

					<ChangeCommercialPropositionStatusModal
						isOpen={
							changingStatusFor?.newStatus ===
							CP_STATUS_SIGNED_OUTSIDE
						}
						onClose={() => {
							setChangingStatusFor(undefined);
							refetch();
						}}
						item={changingStatusFor}
						onSubmit={(values) => {
							return handleStatusChange(
								changingStatusFor as ICommercialProposition,
								{
									...values,
									status: CP_STATUS_SIGNED_OUTSIDE,
									signatureDate: moment(
										values.signatureDate,
										t("dates.default")
									).format(t("dates.apiDefault")),
								},
								true
							);
						}}
						title={t(
							"commercialPropositions.changeToSignedHorsDematModal.title"
						)}
						fields={{
							date: {
								name: "signatureDate",
								label: t(
									"commercialPropositions.changeToSignedHorsDematModal.signatureDate"
								),
								validationSchema: Yup.string().required(
									t(
										"commercialPropositions.changeToSignedHorsDematModal.requiredDate"
									)
								),
								maxDate: moment().format("YYYY-MM-DD"),
							},
						}}
					>
						{t(
							"commercialPropositions.changeToSignedHorsDematModal.infoText"
						)}
					</ChangeCommercialPropositionStatusModal>
					<ConfirmModal
						title={t(
							"commercialPropositions.removeSignedHorsDematModal.title"
						)}
						text={t(
							"commercialPropositions.removeSignedHorsDematModal.infoText"
						)}
						isOpen={
							changingStatusFor?.status ===
								CP_STATUS_SIGNED_OUTSIDE &&
							changingStatusFor?.newStatus === CP_STATUS_SENT
						}
						onClose={() => {
							setChangingStatusFor(undefined);
							refetch();
						}}
						onConfirm={() => {
							handleStatusChange(
								changingStatusFor as ICommercialProposition,
								{ status: CP_STATUS_SENT, signatureDate: "" },
								true
							);
						}}
						size="s"
					/>
				</>
			)}
		</PageLayout>
	);
};
