import React from "react";

import { t } from "i18next";

import { BackLink, InfoMessage } from "@zolteam/react-ras-library";
import { Accordion, Spinner } from "components/atoms";
import { SidePanel } from "components/molecules";
import { ISiteDetails, IUserWithSitePermissions } from "components/types";

interface IPersmissionSidePanelProps {
	users: IUserWithSitePermissions[];
	onBack?: () => void;
	backText?: string;
	onSiteSelection?: (
		siteId: number | "ALL",
		targetUser: IUserWithSitePermissions,
		active: boolean
	) => void;
	isLoading?: boolean;
}

export const PermissionSidePanel: React.FC<IPersmissionSidePanelProps> = ({
	users,
	onBack,
	backText,
	onSiteSelection,
	isLoading,
}) => {
	return (
		<div className="z-10 mt-4 mb-8 col">
			{onBack && (
				<BackLink
					onClick={onBack}
					className="hidden mb-12 ml-5 lg:flex"
				>
					{backText || t("global.back")}
				</BackLink>
			)}

			<SidePanel
				position="left"
				className="bg-neutral-300 dark:bg-black text-neutral-600 text"
				title={
					isLoading
						? ""
						: t("userPermissions.userCount", {
								count: users.length,
						  })
				}
				titleClassName="text-neutral-600 dark:!text-neutral-200 !text-2xl"
			>
				{(isLoading && <Spinner />) || (
					<div className="gap-2 pr-2 -mr-2 overflow-auto col">
						{users.map((user, index) => (
							<Accordion
								key={index + "-" + user.id}
								title={`${user.firstName} ${user.lastName}`}
								initialState={true}
								className="text-neutral-600 dark:text-neutral-300"
								headClassName="p-2 rounded-md bg-neutral-200 dark:bg-neutral-900 font-bold text-sm"
							>
								<div className="relative p-3 text-neural-500 -top-1 bg-neutral-100 dark:bg-neutral-800 rounded-b-md">
									<UserSummary
										user={user}
										onSiteSelection={(siteId, state) => {
											onSiteSelection &&
												onSiteSelection(
													siteId,
													user,
													state
												);
										}}
									/>
								</div>
							</Accordion>
						))}
					</div>
				)}
			</SidePanel>
		</div>
	);
};

interface IUserSummaryProps {
	user: IUserWithSitePermissions;
	onSiteSelection?: (siteId: number | "ALL", state: boolean) => void;
}

const UserSummary: React.FC<IUserSummaryProps> = ({
	user,
	onSiteSelection,
}) => {
	const { sites } = user;

	const hasAllSelected = sites?.every((site) => site.active);
	const hasSelected = sites?.some((site) => site.active);
	const isIndeterminate = hasSelected && !hasAllSelected;

	const handleSiteChange = (site: ISiteDetails, state: boolean) => {
		onSiteSelection && onSiteSelection(site.id, state);
	};

	const handleSelectAll = (state: boolean) => {
		let newState = state;

		if (!state && isIndeterminate) newState = true;

		onSiteSelection && onSiteSelection("ALL", newState);
	};

	return (
		<div className="col gap-y-3">
			{!!sites?.length ? (
				<label className="cursor-pointer">
					<input
						type="checkbox"
						checked={hasSelected}
						data-indeterminate={isIndeterminate}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleSelectAll(e.target.checked)
						}
					/>
					<span className="ml-4">{t("global.selectAll")}</span>
				</label>
			) : (
				<InfoMessage color="error" withIcon>
					{t("userPermissions.noLinkedSite")}
				</InfoMessage>
			)}
			{sites?.map((site, siteIndex) => (
				<label
					className="items-center text-base cursor-pointer row"
					key={siteIndex}
				>
					<input
						type="checkbox"
						checked={site.active}
						onChange={() => handleSiteChange(site, !site.active)}
					/>
					<span className="ml-4 text-sm">{`${site.agency?.tempoDirectory}-${site.clientId}`}</span>
				</label>
			))}
		</div>
	);
};
