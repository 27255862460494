import React from "react";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { promiseToast } from "toastify";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import UserAdminService from "services/UserAdminService";

import { PermissionSidePanel } from "./AdminUserPermissionsSidePanel";
import {
	convertUserSitesPermissions,
	convertUsersSitesPermissionsToAPI,
	getActivesPermissions,
	updateUsersPermissions,
} from "./AdminUserPermissions_utils";
import { BackLink, Title, Button } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import {
	ActionResult,
	ConfirmModal,
	PermissionsTable,
} from "components/molecules";
import { PageLayout } from "components/templates";
import { IPermission, IUserWithSitePermissions } from "components/types";

interface ISendConfirmation {
	isDisplayed: boolean;
	isLoading?: boolean;
}

export const AdminUserPermissions: React.FC = () => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const hasFetched = React.useRef(false);
	const [Users, setUsers] = React.useState<IUserWithSitePermissions[]>([]);
	const [SendConfirmation, setSendConfirmation] =
		React.useState<ISendConfirmation>({
			isDisplayed: false,
			isLoading: false,
		});

	const handleBacknavigation = () => {
		queryClient.resetQueries({
			queryKey: ["Users"],
		});
		queryClient.resetQueries({
			queryKey: ["AdminUser", "profile"],
		});
		navigate(state?.backUrl || "/admin-users", {
			state: { ...state, users: [] },
			replace: true,
		});
	};

	const { fromPage } = state;

	const fetchPermissions = () => {
		const ids = state.users.map(
			(user: IUserWithSitePermissions) => user.id
		);

		return UserAdminService.usersPermissionsIds(ids).then((res) => {
			if (!res) return;
			const dup: IUserWithSitePermissions[] = JSON.parse(
				JSON.stringify(res)
			);
			dup.forEach((user) => {
				user.sites = convertUserSitesPermissions(user);
			});
			hasFetched.current = true;
			setUsers(dup);
			return dup;
		});
	};

	const handleSendPermissions = () => {
		setSendConfirmation({ ...SendConfirmation, isLoading: true });
		const sendUsers = convertUsersSitesPermissionsToAPI(Users);

		const requests = sendUsers.map((user) =>
			UserAdminService.usersPermissions(user.id, { sites: user.sites })
		);

		const prom = Promise.all(requests)
			.then(() => {
				setSendConfirmation({
					isDisplayed: false,
					isLoading: false,
				});
				handleBacknavigation();
			})
			.catch((e) => {
				setSendConfirmation({
					isDisplayed: false,
					isLoading: false,
				});
				throw e;
			});

		promiseToast(prom);

		return prom;
	};

	const handleSiteSelection = (
		siteId: number | "ALL",
		targetUser: IUserWithSitePermissions,
		active: boolean
	) => {
		if (!targetUser || !siteId) return;
		const updatedUsers = [...Users].map((user) => {
			if (user.id !== targetUser.id) return user;

			user.sites = user.sites.map((userSite) => {
				if (siteId === "ALL" || userSite.id === siteId)
					userSite.active = active;
				return userSite;
			});
			return user;
		});
		setUsers(updatedUsers);
	};

	const handlePermissionSelection = (changedPerms: IPermission[]) => {
		const newUsersPerms = updateUsersPermissions(Users, changedPerms);
		setUsers(newUsersPerms);
	};

	const { isLoading } = useQuery({
		queryKey: ["permissions", Users],
		queryFn: fetchPermissions,
		enabled: !hasFetched.current,
		refetchOnWindowFocus: false,
	});

	if (!state?.users) {
		handleBacknavigation();
		return;
	}

	const hasActiveSites = Users.some((user) =>
		user.sites?.some((site) => site.active)
	);
	const activePermissions = getActivesPermissions(Users);

	return (
		<>
			<PageLayout
				title={
					<div>
						<BackLink
							onClick={handleBacknavigation}
							className="flex mb-2 lg:hidden"
						>
							{fromPage === "profile"
								? t("userProfile.profileBack")
								: t("userProfile.back")}
						</BackLink>
						<Title
							tag="h1"
							size="display02"
							className="dark:text-white"
						>
							{t("userPermissions.title")}
						</Title>
					</div>
				}
				tabTitle={t("userPermissions.title")}
				sideContent={
					<PermissionSidePanel
						isLoading={isLoading}
						users={Users}
						onBack={handleBacknavigation}
						backText={
							fromPage === "profile"
								? t("userProfile.profileBack")
								: t("userProfile.back")
						}
						onSiteSelection={handleSiteSelection}
					/>
				}
				sideContentPosition="left"
				className="!gap-0 lg:!gap-8 flex-1"
				bodyClassName="!overflow-visible"
			>
				<div className="z-0 h-full p-4 !pb-[2rem] bg-white dark:bg-black shadow-2xl sm:pb-0 lg:rounded-tl-3xl col">
					{(isLoading && <Spinner />) ||
						(hasActiveSites ? (
							<PermissionsTable
								permissions={activePermissions}
								onSelect={handlePermissionSelection}
							/>
						) : (
							<ActionResult
								type="warning"
								text={t("userPermissions.noSelectedSite")}
							/>
						))}
				</div>
				<div className="sticky bottom-0 px-2 py-2 text-right bg-white border-t dark:bg-black pb-14 lg:pb-2 border-neutral-200 dark:border-neutral-900">
					<Button
						type="button"
						onClick={() =>
							setSendConfirmation({ isDisplayed: true })
						}
						color="primary"
						disabled={
							isLoading ||
							!hasActiveSites ||
							!activePermissions.length
						}
					>
						{t("userPermissions.save")}
					</Button>
				</div>
			</PageLayout>
			<ConfirmModal
				isOpen={SendConfirmation.isDisplayed}
				onClose={() =>
					!SendConfirmation.isLoading &&
					setSendConfirmation({
						isDisplayed: false,
					})
				}
				title={t("userPermissions.confirmTitle")}
				onConfirm={handleSendPermissions}
				size="s"
			>
				<div className="text-neutral-600">
					<Trans
						i18nKey="userPermissions.confirm"
						values={{
							count: Users.length,
							user: `${Users[0]?.firstName} ${Users[0]?.lastName}`,
						}}
					/>
				</div>
			</ConfirmModal>
		</>
	);
};
