import React from "react";

import { Form, Formik } from "formik";
import { t } from "i18next";
import * as Yup from "yup";

import { Button, InfoMessage } from "@zolteam/react-ras-library";
import { Field } from "components/atoms";
import { PasswordField } from "components/molecules";

import { EMAIL_REGEX } from "constants_globals";

const LoginFormValidation = () => {
	return Yup.object().shape({
		email: Yup.string()
			.trim()
			.required(t("forms.email.required"))
			.test("email", t("forms.email.invalid"), (value) =>
				value ? EMAIL_REGEX.test(value) : true
			),
		password: Yup.string().required(t("forms.password.required")),
	});
};

export interface ILoginFormValues {
	email: string;
	password: string;
}

interface ILoginFormProps {
	className?: string;
	email?: string;
	onSubmit: (values: ILoginFormValues, formikHelpers: any) => Promise<any>;
	onPasswordForgot?: () => void;
}

export const LoginForm: React.FC<ILoginFormProps> = ({
	className,
	onSubmit,
	email = "",
	onPasswordForgot,
}) => {
	return (
		<Formik
			className={className}
			initialValues={{
				email,
				password: "",
				submitError: "",
			}}
			validationSchema={LoginFormValidation()}
			onSubmit={onSubmit}
		>
			{({ isValid, dirty, isSubmitting, setFieldValue, errors }) => {
				const canSubmit = isValid && dirty;
				return (
					<Form className="w-full gap-4 col">
						<Field
							type="text"
							label={t("forms.email.label")}
							name="email"
							disabled={isSubmitting}
							onBlur={(
								e: React.ChangeEvent<HTMLInputElement>
							) => {
								setFieldValue("email", e.target.value.trim());
							}}
						/>
						<PasswordField
							type="password"
							label={t("forms.password.label")}
							name="password"
							disabled={isSubmitting}
							onBlur={(
								e: React.ChangeEvent<HTMLInputElement>
							) => {
								//TODO: Verifier s'il y a un espace dans le mail de changement de mot de passe
								setFieldValue(
									"password",
									e.target.value.trim()
								);
							}}
						/>
						<Button
							color="transparent"
							onClick={onPasswordForgot}
							size="s"
							type="button"
							className="!text-xs ml-7 !text-primary-500 dark:!text-primary-300 !items-start self-start font-light"
						>
							{t("forms.password.forgotPass")}
						</Button>
						{!!errors?.submitError && (
							<div className="[&>div]:w-full mb-m">
								<InfoMessage color="error" withIcon>
									{errors.submitError}
								</InfoMessage>
							</div>
						)}
						<Button
							type="submit"
							color={"primary"}
							disabled={!canSubmit}
							className="w-full"
							isLoading={isSubmitting}
						>
							{t("auth.loginButton")}
						</Button>
					</Form>
				);
			}}
		</Formik>
	);
};
