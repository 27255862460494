import React from "react";

import { Form, Formik } from "formik";
import { t } from "i18next";

import useAppContext from "store/useAppContext";

import {
	ChangePasswordFormInitValues,
	ChangePasswordFormValidation,
	IChangePasswordFormValues,
} from "./ChangePasswordFormValidation";
import { Button, Text } from "@zolteam/react-ras-library";
import { Field } from "components/atoms";
import { PasswordField } from "components/molecules";

interface IChangePasswordFormProps {
	onSubmit: (
		values: IChangePasswordFormValues,
		formikHelpers: any
	) => Promise<any>;
}

export const ChangePasswordForm: React.FC<IChangePasswordFormProps> = ({
	onSubmit,
}) => {
	const { user } = useAppContext();

	return (
		<Formik
			initialValues={ChangePasswordFormInitValues}
			onSubmit={onSubmit}
			validationSchema={ChangePasswordFormValidation(t)}
		>
			{({ isValid, dirty, isSubmitting }) => {
				return (
					<Form className="max-w-[400px] col gap-4 !text-left">
						<Field
							type="hidden"
							name="email"
							value={user.email}
							label={t("forms.email.label")}
							className="hidden"
							autoComplete="email"
						/>
						<PasswordField
							label={t("forms.password.currentPassword")}
							type="password"
							name="previousPassword"
							autoComplete="current-password"
							disabled={isSubmitting}
						/>
						<Text tag="p" className="!text-neutral-500">
							{t("forms.password.constraintsText")}
						</Text>
						<PasswordField
							label={t("forms.password.newPasswordLabel")}
							type="password"
							name="newPassword"
							autoComplete="new-password"
							disabled={isSubmitting}
						/>
						<PasswordField
							label={t("forms.password.confirmNewPass")}
							type="password"
							name="newPasswordConfirmation"
							autoComplete="new-password"
							disabled={isSubmitting}
						/>
						<Button
							type="submit"
							color="primary"
							className="w-full mt-4"
							disabled={!dirty || !isValid || isSubmitting}
							isLoading={isSubmitting}
						>
							{t("resetPassword.buttonLabel")}
						</Button>
					</Form>
				);
			}}
		</Formik>
	);
};
