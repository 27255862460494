import React from "react";

import i18n from "i18n/i18n";
import { Link } from "react-router-dom";

import { IAdminLayoutProps } from "components/templates";
import {
	AdminUserConfig,
	AdminUserPermissions,
	AdminUserProfile,
	AdminUsers,
	Auth,
	CommercialPropositionEdit,
	CommercialPropositions,
	Contact,
	Contracts,
	Dashboard,
	FAQ,
	Invoices,
	Messagerie,
	Page404,
	Profile,
	Review,
	Reviews,
	TimeSheets,
	TimesheetEdit,
	BannersView,
} from "views";

import {
	CONTRACT_LIST_PERMISSION,
	INVOICE_LIST_PERMISSION,
	RATING_LIST_PERMISSION,
	TIMESHEET_LIST_PERMISSION,
} from "constants_globals";

const t = i18n.t;

interface IMenuItemIcon {
	default?: string;
	active: string;
	className?: string;
}

export interface IRoute {
	name: string;
	path: string | string[];
	element?: React.ReactNode;
	icon?: string | IMenuItemIcon;
	exact?: boolean;
	active?: boolean;
	childrens?: IRoute[];
	extern?: boolean;
	hidden?: boolean;
	props?: IAdminLayoutProps;
	permissions?: string[];
}

const DefaultRoutes: IRoute[] = [
	{
		name: "404Error",
		element: <Page404 />,
		path: "*",
	},
	{
		name: "Files",
		element: <Link to="/iles" target="_self" />,
		path: ["/files", "/files/*", "/files/:id"],
	},
	{
		name: t("contact.title"),
		path: ["/contact"],
		element: <Contact />,
	},
];

export const AuthRoutes: IRoute[] = [
	{
		name: "Login",
		path: ["*", "/", "/login", "/retrieve-password", "/reset-password"],
		element: <Auth />,
		exact: true,
	},
];

export const UserMenuRoutes: IRoute[] = [
	{
		name: t("dashboard.title"),
		path: ["/", "/dashboard"],
		icon: {
			default: "home",
			active: "menuHome",
			className: "text-neutral-600 dark:text-neutral-500",
		},
		element: <Dashboard />,
	},
	{
		name: t("contracts.title"),
		path: ["/contracts"],
		icon: "menuContracts",
		element: <Contracts />,
		permissions: [CONTRACT_LIST_PERMISSION],
	},
	{
		name: t("timesheets.title"),
		path: ["/timesheets", "/timesheets/list"],
		icon: "menuTimesheets",
		element: <TimeSheets />,
		permissions: [TIMESHEET_LIST_PERMISSION],
	},

	{
		name: t("reviews.title"),
		path: ["/reviews"],
		icon: "menuEvaluations",
		element: <Reviews />,
		permissions: [RATING_LIST_PERMISSION],
	},
	{
		name: t("invoices.title"),
		path: ["/invoices"],
		icon: "menuInvoices",
		element: <Invoices />,
		permissions: [INVOICE_LIST_PERMISSION],
	},
];

export const AdminMenuRoutes: IRoute[] = [
	{
		name: t("adminUsers.title"),
		path: ["/", "/admin-users"],
		icon: {
			default: "users",
			active: "menuAdminUsers",
			className: "dark:text-neutral-500",
		},
		element: <AdminUsers />,
	},
	{
		name: t("commercialPropositions.title"),
		path: ["/commercial-propositions", "/commercial-proposition"],
		icon: "menuContracts",
		element: <CommercialPropositions />,
	},
	{
		name: t("banners.title"),
		path: ["/banners"],
		icon: "menuMessage",
		element: <BannersView />,
	},
];

const ProfileRoutes: IRoute[] = [
	{
		name: t("userProfile.title"),
		path: ["/user-profile/*"],
		element: <Profile />,
	},
];

const PublicRoutes: IRoute[] = [...AuthRoutes];

const PrivatesRoutes: IRoute[] = [...DefaultRoutes, ...ProfileRoutes];

const AdminRoutes: IRoute[] = [
	...AdminMenuRoutes,
	...PrivatesRoutes,
	{
		name: t("userProfile.title"),
		path: ["/admin-users/profile/:id"],
		element: <AdminUserProfile />,
		exact: true,
	},
	{
		name: t("userPermissions.title"),
		path: ["/admin-users/permissions"],
		element: <AdminUserPermissions />,
	},
	{
		name: t("userConfiguration.title"),
		path: ["/admin-users/config"],
		element: <AdminUserConfig />,
	},
	{
		name: t("commercialPropositions.new"),
		path: ["/commercial-proposition/:id"],
		element: <CommercialPropositionEdit />,
		props: {
			scrollable: false,
			className:
				"[&_#AdminLayoutBody]:flex [&_#AdminLayoutBody]:flex-col",
		},
	},
];

const UserRoutes: IRoute[] = [
	...UserMenuRoutes,
	...PrivatesRoutes,
	{
		name: t("userProfile.title"),
		path: ["/user-profile"],
		element: <AdminUserProfile />,
		exact: true,
	},
	{
		name: t("reviews.review"),
		path: ["/reviews/edit/:id"],
		element: <Review edition={true} />,
	},
	{
		name: t("reviews.view"),
		path: ["/reviews/view/:id"],
		element: <Review />,
	},
	{
		name: t("timesheets.edit"),
		path: [
			"/timesheets/edit/*",
			"/timesheets/edit/:id",
			"/timesheets/view/:id",
		],
		permissions: [TIMESHEET_LIST_PERMISSION],
		element: <TimesheetEdit />,
	},
	{
		name: t("timesheets.title"),
		path: ["/timesheets/group"],
		icon: "menuTimesheets",
		element: <TimeSheets viewType="week" />,
		permissions: [TIMESHEET_LIST_PERMISSION],
	},
	{
		name: t("faq.title"),
		path: ["/faq", "/faq/:category", "/faq/:category/:questionId"],
		element: <FAQ />,
	},
	{
		name: t("threads.title"),
		path: ["/messaging", "/messaging/actives"],
		element: <Messagerie />,
		props: {
			childClassName: "h-[calc(100vh-72px)]",
		},
	},
	{
		name: t("threads.archived"),
		path: ["/messaging/archives"],
		element: <Messagerie archived={true} />,
		props: {
			childClassName: "h-[calc(100vh-72px)]",
		},
	},
];

export { PublicRoutes, AdminRoutes, UserRoutes };
