import React from "react";

import { Form, Formik } from "formik";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import CommercialPropositionService from "services/CommercialPropositionsService";

import { Popover, Button, Picto } from "@zolteam/react-ras-library";
import { AgencySelect, InputSearch, ActionsList } from "components/molecules";
import { IAgency, IFilters } from "components/types";

import { cn } from "utils";

import {
	CP_STATUS,
	CP_STATUS_ALL,
	CP_TYPE_DIFFERENT,
	CP_TYPE_PARTNER,
	CP_TYPE_SEASONAL,
} from "constants_globals";

export interface ICommercialPropositionsFiltersProps {
	filters: IFilters;
	setFilter: (key: string, value: any) => void;
}

export const CommercialPropositionsFilters: React.FC<
	ICommercialPropositionsFiltersProps
> = ({ filters, setFilter }) => {
	const navigate = useNavigate();

	const handleCreate = (type: string = CP_TYPE_PARTNER) => {
		navigate(`/commercial-proposition/new`, {
			state: { type },
		});
	};

	return (
		<Formik
			initialValues={{
				search: "",
			}}
			onSubmit={() => {}}
		>
			{() => {
				return (
					<Form className="flex-wrap items-center justify-between gap-4 pr-4 sm:pr-0 row">
						<div className="flex-wrap items-center justify-between w-full gap-4 lg:w-fit row">
							<InputSearch
								label={t(
									"commercialPropositions.searchPlaceholder"
								)}
								promiseOptions={(inputValue) => {
									return setFilter("search", inputValue);
								}}
								onChange={(value) => {
									if (!value.length)
										setFilter("search", value);
								}}
								minLength={3}
								delay={500}
								name="search"
								value={filters.search}
								className="min-w-[300px] w-full sm:w-fit"
							/>
							<div className="relative w-full sm:w-fit">
								<AgencySelect
									query={{
										key: [
											"agencies",
											"commercialPropositions",
										],
										fn: () =>
											CommercialPropositionService.getAgencies(),
									}}
									className={cn([
										"min-w-[300px] z-10",
										filters.agencyId &&
											"[&_label]:opacity-100 [&_label]:!top-2",
									])}
									placeholder={t(
										"commercialPropositions.agencyFilter"
									)}
									onChange={(value) => {
										setFilter("agencyId", value.id);
									}}
									value={filters.agencyId}
									getOptionLabel={(option: IAgency) =>
										`${option.name} (${option.tempoDirectory})`
									}
								/>
								{!!filters.agencyId && (
									<Button
										type="button"
										color="transparent"
										onClick={() => {
											setFilter("agencyId", null);
										}}
										className="absolute flex items-center justify-center w-5 h-full right-5 top-[0.155rem] !m-0 !p-0 z-20"
									>
										<Picto
											icon="close"
											className="!m-0 !p-0 !bg-white"
										/>
									</Button>
								)}
							</div>
							<div
								className={
									"relative flex items-center h-6 bg-primary-200 w-[150px] rounded-md"
								}
							>
								<select
									className={
										"w-[150px] border-none bg-primary-200  rounded-md pl-2 text-primary-400 text-xs appearance-none outline-none"
									}
									name="commercialPropositionStatus"
									id="commercialPropositionStatus"
									onChange={(e) => {
										setFilter(
											"status",
											e.target.value || null
										);
									}}
									value={filters.status || ""}
								>
									{CP_STATUS.map((status) => (
										<option
											value={
												status !== CP_STATUS_ALL
													? status
													: ""
											}
											key={status}
										>
											{t(
												`commercialPropositions.status.${status}`
											)}
										</option>
									))}
								</select>
								<Picto
									icon="arrowDown"
									className={
										"absolute pointer-events-none right-0 px-2 w-[1.7rem] border-l border-primary-300 h-full"
									}
								/>
							</div>
						</div>
						<div className="ml-auto">
							<Popover
								content={
									<ActionsList
										actions={[
											{
												label: t(
													"commercialPropositions.types.partner"
												),
												onClick: () => handleCreate(),
											},
											{
												label: t(
													"commercialPropositions.types.seasonal"
												),
												onClick: () =>
													handleCreate(
														CP_TYPE_SEASONAL
													),
											},
											{
												label: t(
													"commercialPropositions.types.different"
												),
												onClick: () =>
													handleCreate(
														CP_TYPE_DIFFERENT
													),
											},
										]}
										item={{}}
									/>
								}
							>
								<Button
									color="primary"
									type="button"
									className="w-fit min-w-[250px]"
									onClick={() => {}}
								>
									<Picto icon="arrowDown" className="w-3" />
									{t("commercialPropositions.new")}
								</Button>
							</Popover>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
