import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useState,
} from "react";

export interface ITimesheetsContext {
	dirtyTimesheets: boolean[];
	setDirty: (dirty: boolean[]) => void;
	updateDirty: (index: number, state: boolean) => void;
}

export const TimesheetsContext = createContext<ITimesheetsContext>({
	dirtyTimesheets: [],
	setDirty: () => {},
	updateDirty: () => {},
});

export const useTimesheetsContext = () => {
	return useContext(TimesheetsContext);
};

export const TimesheetsProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [dirtyTimesheets, setDirty] = useState<boolean[]>([]);

	const updateDirty = (index: number, state: boolean) => {
		const newDirty = [...dirtyTimesheets];
		newDirty[index] = state;
		setDirty(newDirty);
	};

	return (
		<TimesheetsContext.Provider
			value={{ dirtyTimesheets, setDirty, updateDirty }}
		>
			{children}
		</TimesheetsContext.Provider>
	);
};

export default useTimesheetsContext;
