import i18n from "i18next";
import moment from "moment";

import { Text } from "@zolteam/react-ras-library";
import { ContractNumber } from "components/atoms";
import { ReviewStatus } from "components/molecules";
import { IColumn } from "components/types";

const t = i18n.t.bind(i18n);

export const REVIEWS_COLUMNS: IColumn[] = [
	{
		name: t("forms.contract.label"),
		value: "contract",
		sortKey: "contract",
		sortLabel: t("forms.contract.contractNumber"),
		render: ({ contract, ...item }) => {
			return (
				<div className="">
					<Text
						tag="span"
						fontWeight="bold"
						lineHeight="m"
						className="dark:text-white"
					>
						{contract.tempWorkerFullName}
					</Text>
					<p className="text-neutral-500">
						<ContractNumber
							number={`${t("contracts.contract")} ${
								contract.contractNumber
							}`}
							amendmentNumber={contract.amendmentNumber}
						/>
					</p>
					{!!contract.orderReference && (
						<p className="text-xs text-neutral-500">
							{contract.orderReference}
						</p>
					)}
				</div>
			);
		},
	},

	{
		name: t("forms.sites.label"),
		value: "siteSocialReason",
		sortable: false,
		render: ({ contract }) => {
			return contract.siteSocialReason;
		},
	},
	{
		name: t("forms.qualification.label"),
		value: "qualification",
		sortable: false,
		render: ({ contract }) => {
			return contract.qualification;
		},
	},
	{
		name: t("dates.dateStart"),
		value: "date",
		render: (item) => moment(item.startDate).format(t("dates.medium")),
		className: "font-medium",
	},
	{
		name: t("dates.dateEnd"),
		value: "endDate",
		render: (item) => moment(item.endDate).format(t("dates.medium")),
		className: "font-medium",
		sortable: false,
	},
	{ name: t("forms.agencies.label"), value: "agencyName", sortable: false },
	{
		name: t("global.status"),
		value: "status",
		render: (item) => <ReviewStatus item={item} />,
	},
];
