import React from "react";

import { t } from "i18next";
import { useBlocker } from "react-router-dom";

import { ConfirmModal } from "components/molecules";

export interface IBlockNavigationProps {
	title?: string;
	text?: string;
	shouldPrompt: boolean;
	allowFor?: string[];
	forceConfirm?: boolean; // used for weird cases where the blocker enter in a loop
	onConfirm?: (blocker: any) => void;
}

export const BlockNavigation: React.FC<IBlockNavigationProps> = ({
	title,
	text,
	shouldPrompt,
	allowFor,
	forceConfirm,
	onConfirm,
}) => {
	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		return (
			currentLocation.pathname !== nextLocation.pathname &&
			shouldPrompt &&
			!allowFor?.includes(nextLocation.pathname)
		);
	});

	return (
		<ConfirmModal
			title={title ?? t("blockNavigation.title")}
			text={text ?? t("blockNavigation.text")}
			isOpen={blocker.state === "blocked"}
			onClose={() => blocker.reset?.()}
			onCancel={() => blocker.reset?.()}
			onConfirm={
				onConfirm
					? () => onConfirm(blocker)
					: () => {
							if (forceConfirm && blocker.location?.pathname) {
								blocker.reset?.();
								window.location.assign(
									blocker.location?.pathname
								);
							} else blocker.proceed?.();
					  }
			}
			confirmText={t("blockNavigation.leave")}
			cancelText={t("blockNavigation.stay")}
			size="s"
		/>
	);
};
