import React from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";

import { InfoMessage } from "@zolteam/react-ras-library";
import { Accordion } from "components/atoms";
import { UserSummaryHeader } from "components/molecules";
import { List } from "components/organisms";
import { ISite, IUserDetailsForConfig } from "components/types";

export interface IUserSummaryProps {
	user: IUserDetailsForConfig;
	index: number;
	isSelected: boolean;
	handleSelect: (checked: boolean) => void;
	handleUpdate: (user: IUserDetailsForConfig) => void;
	hasError?: boolean;
}

interface IUserSummaryFormErrors {
	Users: {
		[index: number]: {
			sites?: string;
		};
	};
}

export const UserSummary: React.FC<IUserSummaryProps> = ({
	user,
	index,
	isSelected,
	handleSelect,
	handleUpdate,
}) => {
	const { errors, isSubmitting } = useFormikContext<IUserSummaryFormErrors>();

	const selectedSites = user.sites.filter((site) => site.active);

	const handleSelectAllSites = () => {
		const newSites = user.sites.map((site) => ({
			...site,
			active: !(selectedSites.length === user.sites.length),
		}));
		handleUpdate({
			...user,
			sites: newSites,
		});
	};

	const handleSelectSites = (item: ISite, checked: boolean) => {
		const sites = user.sites.map((site) => {
			if (
				site.id === item.id &&
				site.tempoDirectory === item.tempoDirectory
			) {
				return {
					...site,
					active: checked,
				};
			}
			return site;
		});
		handleUpdate({
			...user,
			sites,
		});
	};

	return (
		<>
			<Accordion
				title={
					<UserSummaryHeader
						index={index}
						user={user}
						isSelected={isSelected}
						handleSelect={handleSelect}
						handleUpdate={handleUpdate}
						hasError={!!errors?.Users?.[index]}
					/>
				}
				className=""
				headClassName="px-4 sm:px-8 py-2 !items-start 2md:!items-center [&_svg]:mt-3 2md:[&_svg]:mt-0"
				initialState={true}
			>
				<div className="px-4 bg-white dark:bg-black sm:px-8">
					{errors?.Users?.[index]?.sites && (
						<InfoMessage
							color="error"
							withIcon
							className="pb-4 text-sm text-red-500"
						>
							{errors.Users[index]?.sites}
						</InfoMessage>
					)}
					<List
						header={false}
						items={user.sites}
						className="w-full"
						selected={user.sites.filter((site) => site.active)}
						onSelected={(items: ISite[], state: boolean) =>
							handleSelectSites(items[0], state)
						}
						isSelectionDisabled={() => isSubmitting}
						tableHeader={{
							before: (
								<>
									<th className="pl-8 max-w-[1rem]">
										<label className="flex items-center gap-4 cursor-pointer">
											<input
												type="checkbox"
												onChange={handleSelectAllSites}
												data-indeterminate={
													selectedSites.length > 0 &&
													selectedSites.length <
														user.sites.length
												}
												checked={!!selectedSites.length}
												disabled={isSubmitting}
											/>
											{t("global.selectAll")}
										</label>
									</th>
								</>
							),
						}}
						columns={[
							{
								name: "",
								value: "id",
							},
							{
								name: t("userProfile.sites.agencyCode"),
								value: "tempoDirectory",
							},
							{
								name: t("userProfile.sites.socialReason"),
								value: "socialReason",
							},
							{
								name: t("userProfile.sites.siret"),
								value: "siret",
							},
						]}
					/>
				</div>
			</Accordion>
		</>
	);
};
