import React from "react";

import { TableFilters } from "components/organisms";
import { IInvoicesFiltersProps } from "components/types";

import { INVOICES_STATUS } from "constants_globals";

export const InvoicesFilters: React.FC<IInvoicesFiltersProps> = ({
	filters,
	setFilter,
	setDates,
}) => {
	return (
		<TableFilters
			status={INVOICES_STATUS}
			statusKey="type"
			filters={filters}
			onChange={(filter, value) => {
				if (filter === "dates") return setDates(value);
				setFilter(filter, value);
			}}
			searchPlaceholder="Rechercher une facture"
		/>
	);
};
