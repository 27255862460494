import React from "react";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { promiseToast } from "toastify";

import { useQueryClient } from "@tanstack/react-query";

import UserAdminService from "services/UserAdminService";

import { Button } from "@zolteam/react-ras-library";
import { ConfirmModal } from "components/molecules";
import { IUserInformationsProps } from "components/types";

import { cn } from "utils";

type ModalType = "delete" | "disable";

export const AdminUserProfileFooter: React.FC<IUserInformationsProps> = ({
	user,
}) => {
	const queryClient = useQueryClient();
	const [IsSending, setIsSending] = React.useState<boolean>(false);
	const [ShowConfirmModalType, setShowConfirmModalType] =
		React.useState<string>("");

	const handleConfirm = (forcedType?: ModalType) => {
		const actionType = forcedType ?? ShowConfirmModalType;

		let promCall = null;
		if (actionType === "delete")
			promCall = UserAdminService.userAdminDelete;
		else if (actionType === "disable")
			promCall = user.disable
				? UserAdminService.userAdminActivate
				: UserAdminService.userAdminDeactivate;

		if (!promCall) return;

		setIsSending(true);

		let prom = promCall(user.id).then(
			() => {
				setShowConfirmModalType("");
				setIsSending(false);
				queryClient.invalidateQueries({
					queryKey: ["AdminUser", "profile", user.id.toString()],
				});
			},
			(e) => {
				setIsSending(false);
				return Promise.reject(e);
			}
		);

		promiseToast(prom, {
			success: t(
				actionType === "delete"
					? "userProfile.remove.success"
					: !user.disable
					? "userProfile.disable.success"
					: "userProfile.enable.success"
			),
		});
	};

	const classNames = cn(["!text-xs w-full", "xs:w-auto"]);
	return (
		<>
			<div className="sticky bottom-0 flex-wrap items-center justify-center gap-2 px-4 py-2 text-xs bg-white border-t dark:bg-black sm:gap-4 xs:justify-start sm:flex-nowrap sm:px-10 row border-neutral-200 dark:border-neutral-600">
				<Button
					color="primary"
					outline
					onClick={() => setShowConfirmModalType("delete")}
					type="button"
					className={classNames}
				>
					{t("userProfile.actions.delete")}
				</Button>
				<Button
					color="grey"
					outline
					onClick={() =>
						user.disable
							? handleConfirm("disable")
							: setShowConfirmModalType("disable")
					}
					type="button"
					isLoading={IsSending}
					className="dark:!text-white"
				>
					{user.disable
						? t("userProfile.actions.enable")
						: t("userProfile.actions.disable")}
				</Button>
			</div>

			<ConfirmModal
				isOpen={!!ShowConfirmModalType}
				title={t(
					ShowConfirmModalType === "delete"
						? "userProfile.remove.title"
						: "userProfile.disable.title"
				)}
				onClose={() => setShowConfirmModalType("")}
				onConfirm={() => handleConfirm()}
				confirmText={
					ShowConfirmModalType === "delete"
						? t("userProfile.actions.delete")
						: t("userProfile.actions.disable")
				}
			>
				<Trans
					i18nKey={
						ShowConfirmModalType === "delete"
							? "userProfile.remove.text"
							: "userProfile.disable.text"
					}
					values={{
						user: `${user?.firstName} ${user?.lastName}`,
					}}
				/>
			</ConfirmModal>
		</>
	);
};
