import React from "react";

import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import SessionService from "services/SessionService";

import useAppContext from "store/useAppContext";

import { PopoverItemIcon } from "@zolteam/react-ras-library";

interface ILogoutButtonProps {
	children?: (props: { handleLogout: () => void }) => JSX.Element;
}

export const LogoutButton: React.FC<ILogoutButtonProps> = ({ children }) => {
	const { dispatch } = useAppContext();
	const navigate = useNavigate();

	const handleLogout = () => {
		SessionService.disconnect().then(() => {
			dispatch({ type: "CLEAR_AUTH" });
			navigate("/");
		});
	};

	if (children) return children({ handleLogout });
	return (
		<PopoverItemIcon reverseIcon icon="logout" onClick={handleLogout}>
			{t("auth.logout")}
		</PopoverItemIcon>
	);
};
