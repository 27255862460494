import { t } from "i18next";
import moment from "moment";
import { toast } from "react-toastify";

import TimeSheetsService from "services/TimeSheetsService";

import { ITimeSheet } from "components/types";

import { handleFileSave } from "utils";

import {
	TIMESHEET_DOWNLOAD_PERMISSION,
	TIMESHEET_EDIT_PERMISSION,
	TIMESHEET_STATUS_WAITING,
	TIMESHEET_VALIDATE_PERMISSION,
} from "constants_globals";

interface ITimeSheetItemActions {
	handleContact: (timesheet: ITimeSheet) => void;
	navigate: (path: string, options: any) => void;
	hasPermissions: (permissions: string[], requiereAll?: boolean) => boolean;
}

export const handleTimeSheetDownload = (id: number) => {
	toast.loading(t("promiseToast.fetching"), {
		toastId: `timesheet-download-${id}`,
	});
	const prom = TimeSheetsService.timesheetDownload(id).finally(() =>
		toast.dismiss(`timesheet-download-${id}`)
	);
	handleFileSave(
		prom,
		`timesheet-${id}${moment().format(t("dates.full"))}.zip`
	);
	return prom;
};

export const timesheetItemActions = ({
	handleContact,
	hasPermissions,
}: ITimeSheetItemActions) => {
	return [
		{
			label: t("timesheets.edit"),
			icon: "edit",

			linkTo: (item: ITimeSheet) => ({
				pathname: `/timesheets/edit/${item.id}`,
				state: { timesheet: item, search: window.location.search },
			}),
			render: (item: ITimeSheet, defaultRender: React.ReactNode) => {
				if (
					item.status !== TIMESHEET_STATUS_WAITING ||
					!hasPermissions([
						TIMESHEET_EDIT_PERMISSION,
						TIMESHEET_VALIDATE_PERMISSION,
					])
				)
					return;

				return defaultRender;
			},
		},
		{
			label: t("timesheets.view"),
			icon: "eye",
			linkTo: (item: ITimeSheet) => ({
				pathname: `/timesheets/view/${item.id}`,
				state: { timesheet: item, search: window.location.search },
			}),
			render: (item: ITimeSheet, defaultRender: React.ReactNode) => {
				if (item.status === TIMESHEET_STATUS_WAITING) return;
				return defaultRender;
			},
		},
		{
			label: t("file.download"),
			icon: "download",
			onClick: ({ id }: ITimeSheet) => handleTimeSheetDownload(id),
			render: (item: ITimeSheet, defaultRender: React.ReactNode) => {
				if (
					item.status !== TIMESHEET_STATUS_WAITING &&
					hasPermissions([TIMESHEET_DOWNLOAD_PERMISSION])
				)
					return defaultRender;
				return false;
			},
		},

		{
			label: t("global.contactAgency"),
			icon: "bubble",

			onClick: (item: ITimeSheet) => handleContact(item),
		},
	];
};
