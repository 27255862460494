import React, { useEffect } from "react";

import { Picto } from "@zolteam/react-ras-library";
import { ICustomComponentWithChildren } from "components/types";

import { APP_NAME } from "constants_globals";

import BG_IMAGE from "assets/auth.jpg";

interface IAuthLayoutProps extends ICustomComponentWithChildren {}

const AuthLayout: React.FC<IAuthLayoutProps> = ({ children }) => {
	useEffect(() => {
		document.title = APP_NAME;
	}, []);

	return (
		<div className="relative flex w-full min-h-full h-fit">
			<div className="col relative z-[2] w-[97%] sm:w-[80%] lg:w-[60%] 2xl:w-1/2 p-4 sm:p-6 md:p-10 bg-white dark:bg-neutral-900 shadow-2xl rounded-r-[3rem] duration-300">
				<Picto
					icon="logoMyTemp"
					className="min-w-[250px] -left-4 relative w-[40svw] max-w-[300px] sm:mb-4 md:mb-8 lg:mb-10"
				/>
				<div className="h-full p-2 md:p-0 md:pl-12">{children}</div>
			</div>
			<div
				className="absolute z-[1] w-[55%] right-0 h-full bg-center bg-no-repeat bg-cover"
				style={{
					backgroundImage: `url(${BG_IMAGE})`,
				}}
			/>
		</div>
	);
};

export default AuthLayout;
