import React from "react";

import { Formik, Form } from "formik";
import { t } from "i18next";

import {
	ContactFormValidation,
	MAX_CONTACT_MESSAGE_LENGTH,
} from "./ContactFormValidation";
import { Button, InfoMessage } from "@zolteam/react-ras-library";
import { Field, Textarea } from "components/atoms";
import { AgencySelect } from "components/molecules";

export interface IContactFormValues {
	agencies: string;
	lastName: string;
	firstName: string;
	company: string;
	siret: string;
	email: string;
	phone: string;
	message: string;
	submitError?: string;
}

const contactInitialValues: IContactFormValues = {
	agencies: "",
	lastName: "",
	firstName: "",
	company: "",
	siret: "",
	email: "",
	phone: "",
	message: "",
	submitError: "",
};

interface IContactFormProps {
	initialValues?: IContactFormValues;
	onSubmit: (values: IContactFormValues, formikHelpers?: any) => Promise<any>;
}

const ContactForm: React.FC<IContactFormProps> = ({
	onSubmit,
	initialValues,
}) => {
	return (
		<Formik
			initialValues={{ ...contactInitialValues, ...initialValues }}
			validationSchema={ContactFormValidation(t)}
			onSubmit={(values, formikHelpers) =>
				onSubmit(
					{
						...values,
						email: values.email || "",
						phone: values.phone || "",
						siret: values.siret || "",
					},
					formikHelpers
				).catch((error) =>
					formikHelpers.setFieldError("submitError", error)
				)
			}
		>
			{({ isValid, dirty, isSubmitting, errors, setFieldValue }) => {
				const canSubmit = isValid && dirty;

				return (
					<Form className="w-full gap-4 col">
						<AgencySelect
							name="tempoDirectory"
							onChange={(val) => {
								setFieldValue(
									"tempoDirectory",
									val?.tempoDirectory
								);
							}}
						/>
						<Field
							name="firstName"
							label={t("forms.firstName.label")}
							disabled={isSubmitting}
						/>
						<Field
							name="lastName"
							label={t("forms.lastName.label")}
							disabled={isSubmitting}
						/>
						<Field
							name="company"
							label={t("forms.company.label")}
							disabled={isSubmitting}
						/>
						<Field
							name="siret"
							label={t("forms.siret.label")}
							disabled={isSubmitting}
						/>
						<Field
							name="email"
							label={t("forms.email.label")}
							disabled={isSubmitting}
						/>
						<Field
							name="phone"
							label={t("forms.phone.label")}
							disabled={isSubmitting}
						/>
						<Textarea
							name="message"
							label={t("forms.message.label")}
							disabled={isSubmitting}
							maxLength={MAX_CONTACT_MESSAGE_LENGTH}
							className="dark:[&_textarea]:bg-transparent dark:[&_textarea]:text-white dark:[&_label]:text-neutral-300"
						/>
						{!!errors.submitError && (
							<InfoMessage color="error" withIcon>
								{errors.submitError}
							</InfoMessage>
						)}
						<Button
							type="submit"
							color={"primary"}
							disabled={!canSubmit || isSubmitting}
							isLoading={isSubmitting}
						>
							{t("forms.message.send")}
						</Button>
					</Form>
				);
			}}
		</Formik>
	);
};

export default ContactForm;
