import React, { useState } from "react";

import { t } from "i18next";
import { Trans } from "react-i18next";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import ThreadService from "services/ThreadsService";

import { Title, Button } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import {
	ActionResult,
	InputSearch,
	MessagingIcon,
	ThreadsList,
} from "components/molecules";
import { ContactAgencyModal, Thread } from "components/organisms";
import { PageLayout } from "components/templates";
import { IThreadSummary } from "components/types";

import { cn } from "utils";

import {
	STATUS_ACTIVE,
	STATUS_ARCHIVED,
	STATUS_INACTIVE,
	STATUS_READ,
	STATUS_UNREAD,
} from "constants_globals";

export interface IMessagerieProps {
	archived?: boolean;
}

const MIN_SEARCH_LENGTH = 3;

export const Messagerie: React.FC<IMessagerieProps> = ({
	archived = false,
}) => {
	const [Search, setSearch] = React.useState("");
	const [ActiveTab, setActiveTab] = React.useState(
		archived ? STATUS_INACTIVE : STATUS_ACTIVE
	);
	const activeTreadRef = React.useRef<IThreadSummary | undefined>(); // used to fix weird bug where ActiveThread is undefined when it shouldn't
	const [ActiveThread, setActiveThread] = React.useState<
		IThreadSummary | undefined
	>();

	const queryClient = useQueryClient();
	const [ShowContactAgencyModal, setShowContactAgencyModal] = useState(false);

	const changeActiveThread = (thread: IThreadSummary | undefined) => {
		activeTreadRef.current = thread;
		setActiveThread(thread);
	};

	const handleThreadsUpdate = (props: any = []) => {
		queryClient.invalidateQueries({
			queryKey: ["threads", ...props],
		});
	};

	const fetchThreads = (signal: AbortSignal) => {
		if (Search?.length >= MIN_SEARCH_LENGTH)
			return ThreadService.postThreadSearch(
				{
					freeSearch: Search,
					status: ActiveTab,
				},
				signal
			);

		if (ActiveTab === STATUS_INACTIVE)
			return ThreadService.getThreadArchives(signal);

		return ThreadService.getThreadActives({}, signal);
	};

	const treatResults = (resp: any) => {
		const results = Search ? resp.results : resp;
		const activeThread = activeTreadRef.current;

		const sorted = [results.activeThread, ...results.otherThreads]
			.filter((a) => a)
			.sort((a: IThreadSummary, b: IThreadSummary) => {
				return (
					new Date(b.lastMessageCreatedAt).getTime() -
					new Date(a.lastMessageCreatedAt).getTime()
				);
			});

		if (Search?.length || !activeThread || activeThread?.id === -1)
			changeActiveThread(sorted[0]);

		// Update active threads count
		if (ActiveTab === STATUS_ACTIVE) {
			queryClient.invalidateQueries({
				queryKey: ["activeThreadsCount"],
			});
		}

		return sorted;
	};

	const handleTabChange = (status: string) => {
		setActiveTab(status);
		changeActiveThread(undefined);
	};

	const handleThreadChange = (thread: IThreadSummary) => {
		if (thread.status === STATUS_UNREAD)
			ThreadService.postThreadStatus(thread?.id, {
				status: STATUS_READ,
			}).then(() => {
				handleThreadsUpdate([ActiveTab]);
			});
		changeActiveThread({
			...thread,
			status:
				thread.status === STATUS_UNREAD ? STATUS_READ : thread.status,
		});
	};

	const { isLoading, data, error } = useQuery<IThreadSummary[]>({
		queryKey: ["threads", ActiveTab, Search],
		queryFn: ({ signal }) => fetchThreads(signal).then(treatResults),
	});

	return (
		<PageLayout
			header={
				<PageHeader
					activeTab={ActiveTab}
					setActiveTab={handleTabChange}
					handleNewThread={() => setShowContactAgencyModal(true)}
				/>
			}
			title={t("threads.title")}
			tabTitle={t("threads.title")}
			className="overflow-hidden"
		>
			{error ? (
				<ActionResult
					type="error"
					title={t("API_ERRORS.title")}
					text={t("API_ERRORS.default")}
				/>
			) : (
				<div className="justify-between w-full h-full gap-8 overflow-hidden row">
					<div className="bg-white dark:bg-black rounded-r-3xl h-[90%] p-4 relative col gap-4 min-w-[300px] w-full max-w-[400px]">
						<InputSearch
							name="thread-search"
							label={t("forms.search.label")}
							promiseOptions={(value) => {
								if (value?.length < MIN_SEARCH_LENGTH) return;
								setSearch(value);
							}}
							onChange={(value) => {
								if (
									Search.length &&
									value?.length < MIN_SEARCH_LENGTH
								)
									setSearch("");
							}}
							delay={500}
							minLength={MIN_SEARCH_LENGTH}
							resetValueProps={() => {
								setSearch("");
							}}
						/>

						{Search.length >= MIN_SEARCH_LENGTH &&
						!isLoading &&
						!data?.length ? (
							<ActionResult
								type="info"
								title={""}
								picto="messages"
								text={
									<Trans
										i18nKey="threads.noResultsText"
										values={{ search: Search }}
									/>
								}
							/>
						) : (
							<ThreadsList
								isLoading={isLoading}
								threads={data}
								activeThread={ActiveThread}
								onItemClick={(thread) => {
									handleThreadChange(thread);
								}}
								handleNewThread={() => {
									setShowContactAgencyModal(true);
								}}
								activeState={
									ActiveTab === STATUS_INACTIVE
										? STATUS_ARCHIVED
										: STATUS_ACTIVE
								}
							/>
						)}
					</div>
					<div className="relative w-full h-full overflow-hidden bg-white dark:bg-black col rounded-tl-3xl">
						{isLoading ? (
							<Spinner />
						) : ActiveThread ? (
							<Thread
								key={ActiveThread.id}
								thread={ActiveThread}
								onStatusChange={(newStatus) => {
									if (
										newStatus === STATUS_ARCHIVED ||
										newStatus === STATUS_READ
									)
										changeActiveThread(undefined);
									handleThreadsUpdate([ActiveTab]);
								}}
							/>
						) : (
							<div className="my-auto opacity-60">
								<ActionResult
									type="info"
									title={t("threads.noneSelectedTitle")}
									text={t("threads.noneSelectedText")}
									picto="messages"
								/>
							</div>
						)}
					</div>
				</div>
			)}
			<ContactAgencyModal
				isDisplayed={ShowContactAgencyModal}
				onClose={(isSubmited) => {
					if (isSubmited) {
						setActiveTab(STATUS_ACTIVE);
						handleThreadsUpdate();
					}
					setShowContactAgencyModal(false);
				}}
				initialValues={{
					theme: "other",
				}}
			/>
		</PageLayout>
	);
};

interface IPageHeaderProps {
	activeTab: string;
	setActiveTab: (tab: string) => void;
	handleNewThread: () => void;
}

const PageHeader: React.FC<IPageHeaderProps> = ({
	activeTab = STATUS_ACTIVE,
	setActiveTab,
	handleNewThread,
}) => {
	const buttonClass =
		"text-sm text-neutral-500 dark:text-neutral-300 hover:text-neutral-600 border-b-2 border-transparent duration-300 pb-1 ";

	const activeButtonClass =
		"!text-neutral-800 !border-primary-500 font-semibold dark:!text-white dark:border-primary-500";

	return (
		<div className="flex flex-row flex-wrap items-center justify-between w-full gap-4 px-8 pb-8">
			<Title
				tag="h1"
				size="display02"
				className="max-md:!text-[2.25rem] max-md:!leading-10 dark:text-white"
			>
				{t("threads.title")}
			</Title>

			<div className="justify-center order-2 w-full gap-12 lg:w-auto lg:order-1 row">
				<button
					className={cn([
						buttonClass,
						activeTab === STATUS_ACTIVE && activeButtonClass,
					])}
					onClick={() => setActiveTab(STATUS_ACTIVE)}
				>
					<MessagingIcon countClassName="-right-5">
						{t("threads.inbox")}
					</MessagingIcon>
				</button>
				<button
					className={cn([
						buttonClass,
						activeTab === STATUS_INACTIVE && activeButtonClass,
					])}
					onClick={() => setActiveTab(STATUS_INACTIVE)}
				>
					{t("threads.archived")}
				</button>
			</div>
			<div className="order-1 w-full ml-auto sm:w-auto lg:ml-0">
				<Button
					outline
					color="primary"
					onClick={() => handleNewThread()}
					type="button"
					className="w-full"
				>
					{t("threads.new")}
				</Button>
			</div>
		</div>
	);
};
