import { IMessage, IUser } from "components/types";

import { isObjectEmpty } from "utils";

/**
 *
 */
export const findLastMessageAuthor = (
	listMessages: IMessage[],
	author: string
) => {
	const userMessages = listMessages.reduce((acc: IMessage[], item) => {
		if (item.author === author) acc.push(item);
		return acc;
	}, []);
	return userMessages[userMessages.length - 1];
};

/**
 *
 * @param {*} isConnectAs
 */
export const defineUserName = (connectAs: IUser, user: IUser) =>
	!isObjectEmpty(connectAs)
		? `${connectAs.firstName} ${connectAs.lastName}`
		: `${user.firstName} ${user.lastName}`;
