import React, { useContext } from "react";

import { t } from "i18next";

import { ThemeContextType } from "./ThemeContext";
import { ThemeContext } from "./ThemeContext";
import { Toggle } from "components/atoms";

interface IThemeSwitcher {
	className?: string;
}

const ThemeSwitcher: React.FC<IThemeSwitcher> = (props: IThemeSwitcher) => {
	const { isDarkMode, toggleDarkMode } =
		useContext<ThemeContextType>(ThemeContext);

	return (
		<Toggle
			checked={isDarkMode}
			onChange={toggleDarkMode}
			className={props.className}
			label={t("theme.dark")}
		/>
	);
};

export default ThemeSwitcher;
