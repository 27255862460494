import React from "react";

import { t } from "i18next";
import moment from "moment";

import { Text } from "@zolteam/react-ras-library";

interface ITimesheetInfosProps {
	startDate: string;
	endDate: string;
}

export const TimesheetInfos: React.FC<ITimesheetInfosProps> = ({
	startDate,
	endDate,
}) => {
	return (
		<Text tag="div" color="grey" size="paragraph02" lineHeight="s">
			{t("dates.fromTo", {
				from: moment(new Date(startDate)).format(t("dates.medium")),
				to: moment(new Date(endDate)).format(t("dates.medium")),
			})}
		</Text>
	);
};
