import React from "react";

import { FieldArray, useFormikContext } from "formik";
import { t } from "i18next";
import { Trans } from "react-i18next";
import * as Yup from "yup";

import {
	Title,
	InfoMessage,
	ModalV2,
	Picto,
	Button,
} from "@zolteam/react-ras-library";
import { Field, Spinner, Textarea } from "components/atoms";
import { ICommercialPropositionFormValues } from "forms/CommercialPropositionForm/CommercialPropositionFormValidation";

import { cn } from "utils";

import { CP_STATUS_SENT, MAX_CONTACT_MESSAGE_LENGTH } from "constants_globals";

export interface ISubmitCPFormModalProps {
	isDisplayed: boolean;
	onClose: () => void;
}

const MAX_PJ = 10;

export const SubmitCPFormModalInitialValues = {
	subject: "",
	body: "",
	commercialPropositionPjs: [],
};

export const SubmitCPFormModalValidation = {
	subject: Yup.string().required(t("forms.field.required")),
	body: Yup.string().required(t("forms.field.required")),
};

export const SubmitCPFormModal: React.FC<ISubmitCPFormModalProps> = ({
	isDisplayed,
	onClose,
}) => {
	const [IsLoadingFiles, setIsLoadingFiles] = React.useState(false);
	const { values, setFieldValue, isValid, isSubmitting, submitForm } =
		useFormikContext<ICommercialPropositionFormValues>();

	const PJCount = values.commercialPropositionPjs?.length ?? 0;

	const handleFileSave = (event: any) => {
		const files = event.target.files;

		if (!files?.length) {
			event.target.value = "";
			return;
		}

		setIsLoadingFiles(true);
		const promises = [];
		for (let i = 0; i < files.length; i++) {
			if (PJCount + i >= MAX_PJ) {
				break;
			}
			const prom = new Promise((resolve, reject) => {
				const file = files[i];
				const reader = new FileReader();
				reader.onload = (e) => {
					const fileData = {
						pjName: file.name,
						pjFile: (e.target?.result as string)?.split(",")[1],
					};

					resolve(fileData);
				};
				reader.readAsDataURL(file);
			});

			promises.push(prom);
		}

		Promise.all(promises).then((filesData) => {
			setFieldValue("commercialPropositionPjs", [
				...(values.commercialPropositionPjs ?? []),
				...filesData,
			]);
			setIsLoadingFiles(false);
			event.target.value = "";
		});
	};

	return (
		<ModalV2
			onClose={() => !isSubmitting && onClose()}
			isDisplayed={isDisplayed}
			title={
				values.status === CP_STATUS_SENT
					? t("commercialPropositions.form.reSend")
					: t("commercialPropositions.form.send")
			}
			className="dark:[&>div:first-child]:bg-neutral-800 dark:[&>div:first-child]:text-white dark:bg-neutral-800 dark:text-white"
		>
			<Title tag="h3" className="w-full !text-xl text-center mt-8 mb-2">
				{t(
					"commercialPropositions.form.sections.attachments.mailContent"
				)}
			</Title>

			<div className="space-y-4">
				<div className="[&_*]:!text-sm [&_*]:!leading-4">
					<InfoMessage color="primary" withIcon>
						{t(
							"commercialPropositions.form.sections.attachments.mailContentInfo"
						)}
					</InfoMessage>
				</div>
				<Field
					name="subject"
					label={t("commercialPropositions.form.subjectPH")}
					required
					className="!mt-6"
					disabled={isSubmitting}
				/>
				<Textarea
					name="body"
					label={t("commercialPropositions.form.bodyPH")}
					required
					disabled={isSubmitting}
					maxLength={MAX_CONTACT_MESSAGE_LENGTH}
					className="dark:[&_textarea]:bg-transparent dark:[&_textarea]:text-white dark:[&_label]:text-neutral-300"
				/>
			</div>

			<Title tag="h3" className="w-full !text-xl text-center mt-8 mb-2">
				{t("commercialPropositions.form.sections.attachments.sended")}
			</Title>
			<div
				className={cn([
					"[&_*]:!text-sm [&>*]:w-full",
					PJCount >= MAX_PJ && "[&_*]:!text-accent-500",
				])}
			>
				<InfoMessage
					color={PJCount >= MAX_PJ ? "warning" : "primary"}
					withIcon
				>
					{PJCount >= MAX_PJ ? (
						<Trans
							i18nKey={
								"commercialPropositions.form.sections.attachments.max"
							}
							values={{
								count: MAX_PJ,
							}}
						/>
					) : (
						<Trans
							i18nKey={
								"commercialPropositions.form.sections.attachments.count"
							}
							values={{
								count: MAX_PJ - PJCount,
							}}
						/>
					)}
				</InfoMessage>
			</div>
			<FieldArray
				name="commercialPropositionPjs"
				render={({ remove }) => (
					<div className="mt-4 space-y-4">
						{values.commercialPropositionPjs?.map((pj, index) => {
							return (
								<FileRow
									file={pj}
									key={index}
									onRemove={() => {
										remove(index);
									}}
								/>
							);
						})}
					</div>
				)}
			/>
			{IsLoadingFiles && (
				<Spinner
					className="mt-4 text-neutral-500"
					text={t(
						"commercialPropositions.form.sections.attachments.loading"
					)}
				/>
			)}
			{PJCount < MAX_PJ && (
				<div
					className={cn([
						"p-8 border-dashed border-[3px] bg-primary-50 rounded-xl border-primary-500 relative mt-8",
						(isSubmitting || IsLoadingFiles) &&
							"opacity-70 pointer-events-none",
					])}
				>
					<input
						type="file"
						className="absolute top-0 left-0 w-full h-full opacity-0"
						onChange={handleFileSave}
						multiple
						accept="application/pdf, image/*"
						disabled={isSubmitting}
					/>
					<div className="w-full h-full space-y-4 text-center">
						<Button
							color="primary"
							onClick={() => {}}
							type="button"
							disabled={isSubmitting}
						>
							{t(
								"commercialPropositions.form.sections.attachments.select"
							)}
						</Button>
						<div className="text-sm opacity-70">
							{t(
								"commercialPropositions.form.sections.attachments.drag"
							)}
						</div>
					</div>
				</div>
			)}
			<div className="items-center justify-between w-full row">
				<Button
					color="primary"
					type="button"
					className="mt-8"
					outline
					onClick={onClose}
					disabled={isSubmitting}
				>
					{t("commercialPropositions.form.back")}
				</Button>
				<Button
					color="primary"
					type="submit"
					className="mt-8"
					onClick={() =>
						submitForm().then((resp) => {
							onClose();
						})
					}
					disabled={!isValid}
					isLoading={isSubmitting}
				>
					{values.status === CP_STATUS_SENT
						? t("commercialPropositions.form.reSend")
						: t("commercialPropositions.form.send")}
				</Button>
			</div>
		</ModalV2>
	);
};

interface IFileRowProps {
	file: any;
	onRemove: () => void;
}
const FileRow: React.FC<IFileRowProps> = ({ file, onRemove }) => {
	const { isSubmitting } =
		useFormikContext<ICommercialPropositionFormValues>();
	return (
		<div
			className={cn([
				"items-center justify-between px-6 py-2 text-sm rounded-full bg-neutral-100 row",
				isSubmitting && "opacity-70 pointer-events-none",
			])}
		>
			{file.pjName}
			<button onClick={onRemove} disabled={isSubmitting}>
				<Picto icon="trash" />
			</button>
		</div>
	);
};
