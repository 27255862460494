import React from "react";

import { t } from "i18next";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import UserAdminService from "services/UserAdminService";

import {
	AdminUserProfileFooter,
	AdminUserProfileHeader,
	AdminUserProfileTable,
} from "./components";
import { BackLink } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import { ActionResult } from "components/molecules";

import { APP_NAME } from "constants_globals";

export const AdminUserProfile: React.FC = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	// Get id from state from previous page in react
	const { state } = useLocation();
	const URLSearchParam = state?.URLSearchParam;

	const handleBacknavigation = () => {
		navigate(
			`/admin-users${URLSearchParam ? `?search=${URLSearchParam}` : ""}`
		);
	};

	const {
		isLoading,
		data: user,
		error,
	} = useQuery({
		queryKey: ["AdminUser", "profile", id],
		queryFn: () => UserAdminService.userAdminInformations(id),
		enabled: !!id,
	});

	return (
		<>
			<Helmet>
				<title>
					{user ? `${user.firstName} ${user.lastName} - ` : ""}
					{t("userProfile.title")} - {APP_NAME}
				</title>
			</Helmet>
			<div className="">
				<div className="pb-10">
					<BackLink
						className="flex items-center p-10 pb-4"
						onClick={handleBacknavigation}
					>
						{t("userProfile.back")}
					</BackLink>
					{(isLoading && <Spinner />) ||
						(error && (
							<ActionResult
								type="error"
								text={
									<div className="text-sm">
										{t("userProfile.fetchError")}
									</div>
								}
								onBack={handleBacknavigation}
							/>
						)) || (
							<AdminUserProfileHeader
								user={user}
								className="px-[4rem]"
							/>
						)}
				</div>
				{!!user && (
					<>
						<AdminUserProfileTable user={user} />
						<AdminUserProfileFooter user={user} />
					</>
				)}
			</div>
		</>
	);
};
