import React from "react";

import { TimesheetsProvider } from "contexts/TimesheetsContext";

import {
	TimeSheetsListLayout,
	TimesheetsWeekLayout,
} from "components/templates";

export interface ITimeSheetsProps {
	viewType?: string;
}

export const TimeSheets: React.FC<ITimeSheetsProps> = ({
	viewType = "list",
}) => {
	return (
		<TimesheetsProvider>
			{viewType === "week" ? (
				<TimesheetsWeekLayout />
			) : (
				<TimeSheetsListLayout />
			)}
		</TimesheetsProvider>
	);
};
