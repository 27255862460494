import React, { PropsWithChildren } from "react";

import { Title, Picto } from "@zolteam/react-ras-library";

import { cn } from "utils";

type Position = "left" | "right";

interface ISidePanelProps extends PropsWithChildren {
	title?: string;
	className?: string;
	titleClassName?: string;
	position?: Position;
}

export const SidePanel: React.FC<ISidePanelProps> = ({
	title,
	children,
	position,
	className,
	titleClassName,
}) => {
	const [ShowSideContent, setShowSideContent] = React.useState(false);

	return (
		<div
			className={cn([
				"flex-1 pt-3 w-full bottom-[3.7rem] ",
				ShowSideContent
					? "!h-fit lg:!h-auto sm:!bottom-0 lg:top-0"
					: "h-12 overflow-hidden",
				"fixed duration-300 bottom-2  rounded-t-3xl bg-neutral-200 dark:bg-black p-6 text-white col max-h-[calc(100svh-8rem)]",
				"sm:-bottom-[3rem] sm:w-[calc(100vw-6rem)] sm:h-[6rem]",
				"lg:h-auto lg:sticky lg:top-[4rem] lg:rounded-t-none lg:w-auto lg:min-w-[300px] lg:max-w-[340px] lg:pt-6",
				position === "left"
					? "lg:!rounded-r-[2.5rem]"
					: "lg:!rounded-l-[2.5rem]",
				className,
			])}
		>
			<div
				className="mb-4 cursor-pointer text-neutral-500"
				onClick={() => setShowSideContent(!ShowSideContent)}
			>
				<div
					className={cn([
						"justify-between row",
						!ShowSideContent ? "pb-2 lg:pb-0" : "pb-0",
					])}
				>
					{!!title && (
						<Title
							tag="h2"
							className={cn([
								"!leading-7 dark:text-white",
								titleClassName,
							])}
						>
							{title}
						</Title>
					)}
					<Picto
						icon="arrowDown"
						className={`w-4 duration-300 ${
							!ShowSideContent && "-rotate-90"
						} lg:hidden`}
					/>
				</div>
			</div>
			{children}
		</div>
	);
};
