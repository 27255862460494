import React from "react";

import { t } from "i18next";

import useAppContext from "store/useAppContext";

import { Button, Picto, Title } from "@zolteam/react-ras-library";
import { ActionResult } from "components/molecules";
import { ContactAgencyModal } from "components/organisms";
import { PageLayout } from "components/templates";

export interface IContactProps {}

export const Contact: React.FC<IContactProps> = () => {
	const { getAgencies } = useAppContext();
	const [ModalOpenFor, setModalOpenFor] = React.useState<any>(false);

	const agencies = getAgencies();

	return (
		<PageLayout title="Nous contacter">
			<div className="px-8 text-sm dark:text-white">
				{t("contact.intro")}
			</div>
			<div className="flex-wrap gap-8 px-8 mt-8 row">
				{!!agencies?.length ? (
					agencies.map((agency: any) => (
						<AgencyCard
							key={agency.id}
							agency={agency}
							onContact={() => {
								setModalOpenFor(agency);
							}}
						/>
					))
				) : (
					<div className="w-full">
						<ActionResult
							type="info"
							title={t("contact.noAgencyTitle")}
							text={t("contact.noAgencyText")}
						/>
					</div>
				)}
			</div>
			<ContactAgencyModal
				isDisplayed={!!ModalOpenFor}
				onClose={() => setModalOpenFor(false)}
				initialValues={{
					agencyId: ModalOpenFor.id,
					theme: "other",
				}}
			/>
		</PageLayout>
	);
};

const AgencyCard: React.FC<{ agency: any; onContact: () => void }> = ({
	agency,
	onContact,
}) => {
	return (
		<div className="items-center flex-auto md:flex-1 gap-4 p-8 min-w-0 bg-white dark:bg-black dark:text-white rounded-3xl col max-w-[600px] md:min-w-[400px]">
			<Title
				tag="h2"
				size="heading03"
				className="!leading-5 text-center dark:text-white"
			>
				{agency.name}
			</Title>
			<div>{agency.address}</div>
			<div>
				{agency.postalCode} {agency.city}
			</div>
			{!!agency.phone?.length && (
				<Button
					color="primary"
					type="button"
					onClick={() => {
						window.location.href = `tel:${agency.phone}`;
					}}
				>
					<Picto icon="phone" />
					{agency.phone}
				</Button>
			)}
			<Button color="primary" type="button" onClick={onContact} outline>
				{t("contact.sendMessage")}
			</Button>
		</div>
	);
};
