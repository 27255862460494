import React from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";

import { Field, IToggleProps, Toggle } from "components/atoms";
import { IField } from "components/types";
import { ICPPrestationSectionValues } from "forms/CommercialPropositionForm/Sections/CPPrestationsSection";

export interface IPrestationRowChildrenProps {
	toggleProps?: IToggleProps;
	values: ICPPrestationSectionValues;
}

interface ICPPrestationRowProps {
	title: string;
	description: string;
	toggleProps?: IToggleProps;
	fieldProps: IField;
	fieldChildren?: React.ReactNode;
	children?: (props: IPrestationRowChildrenProps) => React.ReactNode;
	disabled?: boolean;
}

export const CPPrestationRow: React.FC<ICPPrestationRowProps> = ({
	title,
	description,
	toggleProps,
	fieldProps,
	fieldChildren,
	children,
	disabled,
}) => {
	const { values } = useFormikContext<ICPPrestationSectionValues>();
	return (
		<div className="gap-4 dark:text-white col">
			<div className="gap-8 row">
				<div className="w-[60%] max-w-[500px]">
					<h3>
						{toggleProps ? (
							<Toggle
								label={title}
								className="[&>span]:font-light [&>span]:text-base"
								disabled={disabled}
								{...toggleProps}
							/>
						) : (
							title
						)}
					</h3>
					<p className="text-xs text-neutral-400">{description}</p>
				</div>
				<div className="w-[30%] gap-2 col min-w-[250px]">
					<Field
						maxLength={15}
						required={!fieldProps.disabled}
						label={t(
							"commercialPropositions.form.sections.services.package"
						)}
						disabled={disabled}
						{...fieldProps}
					/>
					{fieldChildren}
				</div>
			</div>
			{children &&
				children({
					toggleProps,
					values,
				})}
		</div>
	);
};
