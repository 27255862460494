import axios from "axios";
import { t } from "i18next";
import secureLocalStorage from "react-secure-storage";

import { QueryFunction, QueryKey } from "@tanstack/react-query";

import SessionService from "./SessionService";

import { API_URL } from "constants_globals";

export interface IQuery {
	key: QueryKey; // key to use for the query
	fn: QueryFunction<any, QueryKey>; // function to fetch items
	opts?: any; // options to pass to the query
}

const Axios = axios.create({
	baseURL: API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

Axios.interceptors.request.use(
	(config) => {
		const isConnectedAs = SessionService.isConnectedAs();
		const token = SessionService.getAccessToken();

		if (token) {
			config.headers["BGP-Authorization"] = token;
		}

		if (isConnectedAs) {
			config.params = {
				...config.params,
				_switch_user: `${isConnectedAs.email}`,
			};
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

Axios.interceptors.response.use(
	(res) => {
		return res;
	},
	async (err) => {
		const originalConfig = err.config ?? {};
		const resp = err.response;

		// disconnect if token is invalid
		// there is no refresh token process
		if (
			resp?.status === 401 &&
			!originalConfig._retry &&
			!["/security/login"].includes(originalConfig.url ?? "")
		) {
			originalConfig._retry = true;
			secureLocalStorage.removeItem("context");
			SessionService.disconnect().then(() => {
				sessionStorage.clear();
				window.location.assign("/");
			});
		}

		// Axians maintenance mode,
		// When app receive 503 we reload the page
		// Serveur then do a redirect to a maintenance page
		if (resp?.status === 503 && resp.data.maintenance) {
			secureLocalStorage.removeItem("context");
			SessionService.disconnect().then(() => {
				sessionStorage.clear();
				window.location.assign("/");
			});
		}

		return Promise.reject(err);
	}
);

const checkAvailableTranslation = (key: string, fallback: string) => {
	const i18nKey = "API_ERRORS." + key;
	const trad = t(i18nKey);
	return trad !== i18nKey ? trad : fallback;
};

export const handleApiErrors = (err: any) =>
	checkAvailableTranslation(
		err?.response?.status ?? "default",
		err?.response?.data?.message ?? "default"
	);

export default Axios;
