import React from "react";

import { Picto, Text } from "@zolteam/react-ras-library";
import { DashboardListItem } from "components/molecules";
import { ITileList, ITileListItem } from "components/types";

import { cn } from "utils";

export const DashboardTileList: React.FC<ITileList> = ({
	data,
	tile,
	itemsProps,
}) => {
	if (!data || !tile.itemsKey) return null;

	const items = data[tile.itemsKey]?.slice(0, tile.maxItems) ?? [];
	const { listItem = {} as ITileListItem } = tile;

	// permet de récupérer la valeur d'une propriété a partir d'une clé,
	// si celle-ci est une fonction, on l'appelle avec l'item et les props
	// sinon on récupère la valeur de l'item a partir de la clé

	const getProp = (item: any, key: string) => {
		const itemProp: any = (listItem as any)[key];
		if (!itemProp) return null;
		return typeof itemProp === "function"
			? itemProp(item, itemsProps) // on passe l'item et les props
			: item[itemProp];
	};

	if (!items.length) {
		return (
			<DashboardListPlaceholder
				text={listItem.noValueText ?? ""}
				picto={listItem.noValuePicto ?? "dashboardNoData-contracts"}
			/>
		);
	}

	return (
		<div className="w-full">
			<ul>
				{items.map((item: any, index: number) => (
					<DashboardListItem
						key={item.id ?? index}
						className={cn(["py-2", getProp(item, "className")])}
						main={getProp(item, "main")}
						secondary={getProp(item, "secondary")}
						info={() => getProp(item, "info")}
						onClick={() => listItem.onClick?.(item, itemsProps)}
					/>
				))}
			</ul>
		</div>
	);
};

interface IDashboardListPlaceholderProps {
	text: string;
	picto: string;
}

const DashboardListPlaceholder: React.FC<IDashboardListPlaceholderProps> = ({
	text,
	picto,
}) => {
	return (
		<div className={"w-full col items-center gap-4 mb-4"}>
			<Text
				tag="p"
				size="paragraph02"
				lineHeight="m"
				className="dark:text-neutral-200"
			>
				{text}
			</Text>
			<Picto icon={picto} />
		</div>
	);
};
