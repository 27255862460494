import saveAs from "file-saver";
import { t } from "i18next";
import moment from "moment";
import { toast } from "react-toastify";

import InvoicesService from "services/InvoicesService";

import { IInvoice, ITileListItemProps } from "components/types";

import { extractFilename } from "utils";

import {
	INVOICE_DOWNLOAD_PERMISSION,
	INVOICE_LIST_PERMISSION,
} from "constants_globals";

export const DashboardInvoicesTile = () => {
	const handleInvoiceDownload = (
		invoice: IInvoice,
		{ appState }: ITileListItemProps
	) => {
		if (
			!appState.auth?.permissions?.find(
				(permission: string) =>
					permission === INVOICE_DOWNLOAD_PERMISSION
			)
		)
			return;

		const { id } = invoice;
		toast.loading(t("promiseToast.fetching"), {
			toastId: `invoice-download-${id}`,
		});
		InvoicesService.invoiceDownload(id).then(
			(res) => {
				if (!res?.data) return;

				const pdfData = new Blob([res.data]);
				const filename = extractFilename(res);

				toast.dismiss(`invoice-download-${id}`);
				saveAs(
					pdfData,
					filename ||
						`invoice-${id}${moment(new Date()).format(
							t("dates.full")
						)}.pdf`
				);
			},
			(err) => {
				toast.dismiss(`invoice-download-${id}`);

				toast.error(t("API_ERRORS.default"));
			}
		);
	};

	return {
		title: t("invoices.title"),
		subtitle: t("invoices.tileSubtitle"),
		icon: "menuInvoices",
		linkTo: "/invoices",
		itemsKey: "invoices",
		listItem: {
			main: (item: IInvoice) =>
				t("invoices.invoiceNumber", {
					number: item.number,
				}),
			noValuePicto: "dashboardNoData-invoices",
			noValueText: t("invoices.noPending"),
			onClick: handleInvoiceDownload,
		},
		chart: {
			totalKey: "invoiceAdvancement.totalInvoiceNumber",
			valueKey: "invoiceAdvancement.pendingInvoiceNumber",
			type: "bar",
		},
		permissions: [INVOICE_LIST_PERMISSION],
	};
};
