import React from "react";

import { Form, Formik } from "formik";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { promiseToast } from "toastify";

import { useQuery } from "@tanstack/react-query";

import UserAdminService from "services/UserAdminService";

import { Button, InfoMessage } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import { BlockNavigation } from "components/organisms";
import { PageLayout } from "components/templates";
import { IUserDetailsForConfig } from "components/types";
import {
	UsersConfigListForm,
	NO_USER_SELECTED_ERROR,
	UsersConfigListFormValidation,
} from "forms";
import { treatErrors } from "utils/forms";

export const AdminUserConfig: React.FC = () => {
	const { state } = useLocation();
	const { users, backUrl } = state;
	const formRef = React.useRef<any>(null);
	const isSubmitted = React.useRef(false);
	const navigate = useNavigate();

	const handleBacknavigation = () => {
		navigate(backUrl || "/admin-users", {
			state: { ...state },
			replace: true,
		});
	};

	const fetchUsersInfos = () => {
		const emails = users.map((user: IUserDetailsForConfig) => user.email);
		return UserAdminService.userDetailsFromBC({
			emails,
		}).then((resp) => {
			const newUsers = users.map((user: IUserDetailsForConfig) => {
				const tempoUser = resp.find(
					(respTempoUser: IUserDetailsForConfig) =>
						respTempoUser.email === user.email
				);
				return {
					...user,
					firstName: "",
					lastName: "",
					sites: tempoUser?.clients?.length
						? tempoUser.clients.map((client: any) => {
								return {
									...client,
									active: true,
								};
						  })
						: [],
					isSelected: true,
				};
			});
			return newUsers;
		});
	};

	const handleSubmitForm = (values: any) => {
		const formValues = values.Users.reduce((acc: any, item: any) => {
			if (item.isSelected)
				acc.push({
					...item,
					clients: [...item.sites],
				});
			return acc;
		}, []);

		isSubmitted.current = true;
		const prom = UserAdminService.userCreateBC({ users: formValues }).then(
			(res) => {
				if (!res?.length) throw new Error("No user created in the BC");
				navigate("/admin-users/permissions", {
					state: {
						users: res,
						filter: { ...state.filter },
					},
					replace: true,
				});

				return res;
			},
			(e) => {
				isSubmitted.current = false;

				if (
					e?.response?.data?.errors?.email === "User already exists"
				) {
					toast.error(
						<Trans
							i18nKey="userConfiguration.userAlreadyExists"
							values={{
								count: formValues.length,
								user: formValues[0].email,
							}}
						/>
					);
					throw new Error(t("userConfiguration.userAlreadyExists"));
				}
				throw e;
			}
		);

		promiseToast(
			prom,
			{},
			{
				toastId: "userConfig",
			}
		);

		return prom;
	};

	const { isLoading, data } = useQuery({
		queryKey: ["Permissions", users],
		queryFn: fetchUsersInfos,
		enabled: !!users.length,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	return (
		<>
			<PageLayout
				title={t("userConfiguration.title", {
					count: users.length,
				})}
				headerProps={{
					onBack: handleBacknavigation,
					backLabel: t("userConfiguration.back"),
				}}
				className="[&>div]:overflow-visible"
			>
				{(isLoading && <Spinner />) ||
					(data?.length && (
						<Formik
							initialValues={{
								Users: data,
							}}
							onSubmit={handleSubmitForm}
							validationSchema={UsersConfigListFormValidation(t)}
							innerRef={formRef}
						>
							{({
								values,
								setFieldValue,
								dirty,
								errors,
								isValid,
								isSubmitting,
								validateForm,
								submitForm,
								setFieldTouched,
							}) => {
								const selectedUsers = values.Users.filter(
									(user: IUserDetailsForConfig) =>
										user.isSelected
								);

								const handleSelectAllUsers = () => {
									const newUsers = values.Users.map(
										(user: IUserDetailsForConfig) => ({
											...user,
											isSelected: !(
												selectedUsers.length ===
												values.Users.length
											),
										})
									);
									setFieldValue("Users", newUsers);
								};

								return (
									<Form className="relative">
										<p className="pb-6 pl-8 text-neutral-500">
											{t("userConfiguration.subtitle")}
										</p>
										{errors.Users ===
											NO_USER_SELECTED_ERROR && (
											<div className="mx-8 mb-8">
												<InfoMessage
													color="error"
													withIcon
												>
													{t(
														"userConfiguration.noSelectedUser"
													)}
												</InfoMessage>
											</div>
										)}
										<div className="px-4 py-2 text-sm border-t sm:px-8 bg-neutral-100 dark:bg-neutral-700 border-neutral-200 dark:border-neutral-900 dark:text-neutral-200">
											<label className="items-center cursor-pointer row">
												<input
													type="checkbox"
													checked={
														!!selectedUsers?.length
													}
													data-indeterminate={
														selectedUsers?.length >
															0 &&
														selectedUsers?.length <
															values.Users.length
													}
													onChange={(e) => {
														handleSelectAllUsers();
													}}
													disabled={isSubmitting}
												/>
												<span className="ml-4">
													{t("global.selectAll")}
												</span>
											</label>
										</div>
										<UsersConfigListForm />
										<div className="sticky bottom-0 flex justify-end p-3 mt-auto bg-white border-t dark:bg-black border-neutral-300 dark:border-neutral-800">
											<Button
												type="button"
												color="primary"
												className="w-full xs:w-auto"
												isLoading={isSubmitting}
												onClick={() => {
													validateForm().then(
														(errors) => {
															if (isValid) {
																submitForm();
															} else {
																setTimeout(
																	() => {
																		treatErrors(
																			errors,
																			setFieldTouched
																		);
																	},
																	100
																);
															}
														}
													);
												}}
											>
												{t("userConfiguration.save")}
											</Button>
										</div>
										<BlockNavigation
											shouldPrompt={
												isSubmitted.current === false
											}
											allowFor={[
												"/admin-users/permissions",
											]}
											forceConfirm
											onConfirm={(blocker) => {
												blocker.proceed?.();
												if (
													blocker.location
														?.pathname ===
													"/admin-users"
												) {
													handleBacknavigation();
												}
											}}
										/>
									</Form>
								);
							}}
						</Formik>
					))}
			</PageLayout>
		</>
	);
};
