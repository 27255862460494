import qs from "query-string";

import api from "./api";

class DashboardServiceClass {
	getDashboardData(agenciesIds: number[]) {
		return api
			.get("/dashboard", {
				params: {
					agencyIds: agenciesIds,
				},
				paramsSerializer: (params) => {
					return qs.stringify(params, {
						arrayFormat: "bracket",
					});
				},
			})
			.then((resp) => resp.data);
	}
}

const DashboardService = new DashboardServiceClass();
export default DashboardService;
