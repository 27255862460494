import React from "react";

import { t } from "i18next";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";

import UserAdminService from "services/UserAdminService";

import useAppContext from "store/useAppContext";

import {
	Picto,
	Popover,
	PopoverItemIcon,
	Button,
} from "@zolteam/react-ras-library";
import { LogoutButton } from "components/atoms";
import { HeaderProfile, MessagingIcon } from "components/molecules";
import { ListFilter } from "components/organisms";
import { IAgency } from "components/types";

import { cn, handleFileSave } from "utils";

interface IHeaderProps {
	className?: string;
	id?: string;
}

export const Header: React.FC<IHeaderProps> = ({ className, id }) => {
	const { isAdmin, isLoggedAs } = useAppContext();
	return (
		<div
			id={id}
			className={cn([
				"items-center justify-between flex-wrap sm:flex-nowrap pl-2 py-4 row pr-4 sm:px-8 gap-4",
				className,
			])}
		>
			<div className="flex items-center gap-2">
				<Picto icon="logo" className="w-10 h-10 ml-2 sm:hidden" />
				<Picto icon="logoMyTemp" className="h-10" />
			</div>
			<div className="ml-auto z-30 flex items-end gap-4 text-sm gap-y-2 sm:flex-row sm:gap-10 dark:[&_*]:!text-neutral-300">
				<AdminLinks />
				<AgenciesMenu />
				{(!isAdmin() || isLoggedAs) && (
					<>
						<NavLink
							color="transparent"
							type="button"
							className={({ isActive }) =>
								cn([
									"leading-4 font-semibold text-neutral-500 ",
									isActive && "!text-primary-500",
								])
							}
							to="/faq"
						>
							{t("faq.title")}
						</NavLink>
						<Popover
							clickInside
							placement="bottom-end"
							animation="perspective"
							className="z-30 text-sm [&_svg]:!p-0 [&_svg]:ml-2 [&_button]:!text-sm"
							offset={[-10, 20]}
							content={
								<>
									<PopoverItemIcon
										reverseIcon
										icon="messageSquare"
									>
										<Link to="/messaging/actives">
											{t("threads.title")}
										</Link>
									</PopoverItemIcon>
									<PopoverItemIcon reverseIcon icon="mail">
										<Link to="/contact">
											{t("contact.title")}
										</Link>
									</PopoverItemIcon>
								</>
							}
						>
							<MessagingIcon />
						</Popover>
					</>
				)}
				<Popover
					clickInside
					placement="bottom-end"
					animation="perspective"
					className="z-30 text-sm [&_svg]:!p-0 [&_svg]:ml-2 [&_button]:!text-sm"
					offset={[-10, 20]}
					content={
						<>
							<PopoverItemIcon reverseIcon icon="user">
								<Link to="/user-profile/profile">
									{t("profile.title")}
								</Link>
							</PopoverItemIcon>
							<LogoutButton />

							<div className="text-xs text-neutral-400">
								<hr className="mb-3" />
								Version {process.env.REACT_APP_VERSION}
							</div>
						</>
					}
				>
					<HeaderProfile />
				</Popover>
			</div>
		</div>
	);
};

const AdminLinks: React.FC = () => {
	const [IsLoadingUsers, setIsLoadingUsers] = React.useState(false);
	const [IsLoadingContracts, setIsLoadingContracts] = React.useState(false);

	const { isAdmin, isLoggedAs } = useAppContext();

	if (!isAdmin() || isLoggedAs) return;

	const handleUserExport = () => {
		setIsLoadingUsers(true);
		const prom = UserAdminService.usersAdminExport().finally(() => {
			setIsLoadingUsers(false);
		});
		prom.catch(() => {
			toast.error(t("users.exportError"));
		});

		handleFileSave(prom, "export-users.xlsx");
	};

	const handleImportsDownload = () => {
		setIsLoadingContracts(true);
		const prom = UserAdminService.usersContractsAdminExport().finally(
			() => {
				setIsLoadingContracts(false);
			}
		);

		prom.catch(() => {
			toast.error(t("contracts.exportError"));
		});

		handleFileSave(prom, "export-contracts.xlsx");
	};

	return (
		<>
			<Button
				color="transparent"
				type="button"
				className="leading-4"
				onClick={handleImportsDownload}
				isLoading={IsLoadingContracts}
			>
				{t("contracts.technicalImports")}
			</Button>
			<Button
				color="transparent"
				type="button"
				className="leading-4"
				onClick={handleUserExport}
				isLoading={IsLoadingUsers}
			>
				{t("users.export")}
			</Button>
		</>
	);
};

const AgenciesMenu: React.FC = () => {
	const { isAdmin, getAgencies, dispatch, isLoggedAs } = useAppContext();

	if (isAdmin() && !isLoggedAs) return;

	const handleSelect = (items: IAgency[]) => {
		const newAgencies = getAgencies().map((agency) => {
			if (items.length === 0) {
				return { ...agency, active: false };
			}
			const agencyFound = items.find((item) => item.id === agency.id);
			return agencyFound || agency;
		});

		dispatch({
			type: "SET_USER_AGENCIES",
			payload: newAgencies,
		});
	};

	if (getAgencies()?.length <= 1) return null;

	return (
		<div>
			<ListFilter
				items={getAgencies()}
				onSelect={handleSelect}
				title={t("agencies.menuTitle")}
				label={(count) => t("agencies.selected", { count })}
			/>
		</div>
	);
};
