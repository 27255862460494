import React from "react";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import UserAdminService from "services/UserAdminService";

import { useList } from "hooks/useList";

import { AdminUsersTableColumns } from "./AdminUsersColumns";
import { AdminUsersSideBox } from "./AdminUsersSideBox";
import { InputSearch } from "components/molecules";
import { AsyncList } from "components/organisms";
import { PageLayout } from "components/templates";
import { IFilterStatus, IUserDetails } from "components/types";

const DEFAULT_SORT: IFilterStatus = {
	name: "A-Z",
	value: "asc",
};

export const AdminUsers: React.FC = () => {
	const { state } = useLocation();

	const useListHook = useList<IUserDetails>({
		defaultSort: "date",
		pagination: {
			pageSize: 20,
		},
		selected: state?.users || [],
	});

	const {
		Pagination,
		setPagintation,
		Filters,
		setFilter,
		getFiltersKeys,
		getPaginationKeys,
		Selected,
		setSelected,
	} = useListHook;

	const [SortBy, setSortBy] = React.useState(DEFAULT_SORT);

	const SearchTM = React.useRef<any>(null);
	const navigate = useNavigate();

	const fetchUsers = async (abortSignal: AbortSignal) => {
		if (Filters.search?.length < 3) return;
		//delay the search

		clearTimeout(SearchTM.current);
		return new Promise((resolve) => {
			SearchTM.current = setTimeout(() => {
				resolve(true);
			}, 1000);
		}).then(() => {
			return UserAdminService.userSearch(
				{
					freeSearch: Filters.search,
					total: Pagination.totalCount,
					limit: Pagination.pageSize,
					page: Pagination.currentPage,
				},
				abortSignal
			).then(
				(resp) => {
					setPagintation({
						...Pagination,
						totalCount: resp.total,
					});
					return resp;
				},
				() => {
					return new Error(t("API_ERRORS.default"));
				}
			);
		});
	};

	const handleSelect = (selected: IUserDetails) => {
		const newSelected = [...Selected];
		const selectedIndex = newSelected.indexOf(selected);
		if (selectedIndex === -1) {
			newSelected.push(selected);
		} else {
			newSelected.splice(selectedIndex, 1);
		}
		setSelected(newSelected);
	};

	const handleSearch = (inputValue: string) => {
		setFilter("search", inputValue, false);
		setPagintation({
			...Pagination,
			pageSize: 20,
			currentPage: 1,
		});
	};

	return (
		<PageLayout
			className="flex-1"
			title={t("adminUsers.title")}
			sideContent={
				<AdminUsersSideBox
					selected={Selected}
					setSelected={handleSelect}
					clearSelection={() => setSelected([])}
				/>
			}
		>
			<AsyncList
				header={false}
				filters={
					<PageFilters
						handleSearch={handleSearch}
						sortBy={SortBy}
						setSortBy={setSortBy}
						search={Filters.search}
					/>
				}
				selectable={false}
				tableHeader={false}
				itemsKey="items"
				// itemIdKey="email"
				className="pb-[3rem] lg:pb-0"
				tableClassName="rounded-tr-[2rem]"
				columns={AdminUsersTableColumns}
				rowProps={{
					addUser: handleSelect,
					isSelected: (user: IUserDetails) =>
						Selected.findIndex((u) => u.email === user.email) !==
							-1 || Selected.includes(user),
					navigate,
					URLSearchParam: Filters.search,
				}}
				scrollParentSelector="AdminLayout__header"
				query={{
					queryKey: [
						"Users",
						...getPaginationKeys(),
						...getFiltersKeys(),
					],
					queryFn: ({ signal }) => fetchUsers(signal),
					retry: 0,
					enabled: Filters.search?.length >= 3,
				}}
				noDataMessage={
					!Filters.search || Filters.search?.length < 3 ? (
						""
					) : (
						<Trans
							i18nKey={"adminUsers.noUserFound"}
							values={{ search: Filters.search }}
						/>
					)
				}
				onSelected={() => {
					/*
						besoin de cette fonction vide pour prevenir le fait que la liste des
						éléments sélectionnés ne se vide pas quand on change de page
					*/
				}}
				preventPageChange={false}
				useListHook={useListHook}
			/>
		</PageLayout>
	);
};

interface IPageFilters {
	sortBy: IFilterStatus;
	setSortBy: (option: IFilterStatus) => void;
	search?: string;
	handleSearch?: (inputValue: string) => void;
}

const PageFilters: React.FC<IPageFilters> = ({
	search,
	// sortBy,
	// setSortBy,
	handleSearch,
}) => {
	return (
		<div className="flex-wrap items-center gap-4 pr-4 lg:pr-0 row">
			<InputSearch
				value={search}
				type="text"
				name="admin-user-search"
				label={t("adminUsers.searchPlaceholder")}
				className="flex-1 min-w-[250px]"
				promiseOptions={handleSearch}
			/>
			<div className="flex-1">
				{/*
					DISABLED BECAUSE THE API DON'T HANDLE THIS
					IN THE V1, THIS WAS HANDLED BY THE FRONT BUT THE RESULTS ARE NOT GOOD	
				*/}
				{/* <PopoverCursor
					content={
						<Tag color="accent">Non pris en charge par l'API</Tag>
					}
				>
					<div className="ml-auto w-fit">
						<SelectLabel
							className="!w-[100px] !min-w-0 ml-2 opacity-50 pointer-events-none cursor-not-allowed"
							name="sortType"
							label={`${t("global.sortBy")}:`}
							options={[
								{
									name: "A-Z",
									value: "asc",
								},
								{
									name: "Z-A",
									value: "desc",
								},
							]}
							onChange={(option: IFilterStatus) =>
								setSortBy(option)
							}
							value={sortBy ?? null}
						/>
					</div>
				</PopoverCursor> */}
			</div>
		</div>
	);
};
