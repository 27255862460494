import React, { useState } from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";

import { Title } from "@zolteam/react-ras-library";
import { Toggle } from "components/atoms";
import {
	RemoveTimesheetBonusModal,
	getBonusSubtitle,
} from "components/organisms";
import { ITimeSheetBonus } from "components/types";

import { initBonusValues } from "utils";

interface ITimesheetBonusesListProps {
	title: string;
	bonuses: ITimeSheetBonus[];
	locked?: boolean;
}

export const TimesheetBonusesList: React.FC<ITimesheetBonusesListProps> = ({
	title,
	bonuses,
	locked,
}) => {
	const [LastUpdate, setLastUpdate] = useState(Date.now()); // Used to force re-render
	const [isOpened, setIsOpened] = useState(false);
	const currentBonus = React.useRef<any>(null);

	const { values, setFieldValue } = useFormikContext<any>();

	const handleRemoveBonus = (bonus: ITimeSheetBonus) => {
		setFieldValue(
			`tsBonuses`,
			values.tsBonuses.filter((b: any) => b.bonusId !== bonus.id)
		);
	};

	return (
		<div>
			<Title
				tag="h3"
				size="heading03"
				className="!leading-5 dark:text-white"
			>
				{title}
			</Title>
			<div className="gap-4 mt-6 col">
				{bonuses.map((bonus) => {
					const isChecked = values.tsBonuses.find(
						(b: any) => b.bonusId === bonus.id
					);

					return (
						<Toggle
							key={`${bonus.bonusId ?? bonus.id}-${LastUpdate}`}
							label={
								<span className="col dark:text-white">
									{t(
										`timesheets.bonuses.${bonus.bonusId}`,
										bonus.name
									)}
									<span className="text-xs text-neutral-400">
										{getBonusSubtitle(bonus.type)}
									</span>
								</span>
							}
							checked={
								bonus.isDefault || isChecked ? true : false
							}
							disabled={locked || bonus.isDefault}
							onChange={(e) => {
								if (e.target.checked) {
									setFieldValue(`tsBonuses`, [
										...values.tsBonuses,
										{
											...initBonusValues(
												bonus,
												values.tsDays,
												values.weekDays
											),
										},
									]);
									return e;
								}
								e.preventDefault();
								const checked = e.target.checked;
								currentBonus.current = {
									target: e.target,
									bonus: bonus,
								};

								const isBonusUsed = values.tsBonuses.find(
									(b: any) => {
										if (b.bonusId === bonus.id) {
											return b.days.find((day: any) => {
												return !!day.value;
											});
										}
										return false;
									}
								);

								if (!checked) {
									if (isBonusUsed) setIsOpened(true);
									else {
										currentBonus.current.target.checked =
											false;
										handleRemoveBonus(bonus);
										setLastUpdate(Date.now());
									}
								}
							}}
						/>
					);
				})}
			</div>
			<RemoveTimesheetBonusModal
				onConfirm={() => {
					setIsOpened(false);
					currentBonus.current.target.checked = false;
					handleRemoveBonus(currentBonus.current.bonus);
				}}
				bonusName={t(
					`timesheets.bonuses.${currentBonus.current?.bonus.bonusId}`,
					currentBonus.current?.bonus.name
				)}
				isOpen={isOpened}
				onClose={() => setIsOpened(false)}
			/>
		</div>
	);
};
