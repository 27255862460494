import React, { PropsWithChildren } from "react";

import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { Title } from "@zolteam/react-ras-library";

import { cn } from "utils";

interface ITimesheetsPageHeadProps extends PropsWithChildren {
	viewType?: string;
}

export const TimesheetsPageHead: React.FC<ITimesheetsPageHeadProps> = ({
	viewType,
	children,
}) => {
	const navigate = useNavigate();
	const buttonClass =
		"text-sm text-neutral-500 hover:text-neutral-600 border-b-2 border-transparent duration-300 pb-1";

	const activeButtonClass =
		"!text-neutral-800 !border-primary-500 font-medium dark:!text-white";

	return (
		<div
			className={cn([
				"flex flex-wrap items-center justify-between gap-4 pb-8 px-8",
			])}
		>
			<div>
				<Title
					tag="h1"
					size="display02"
					className="max-md:!text-[2.25rem] max-md:!leading-10 dark:text-white"
				>
					{t("timesheets.title")}
				</Title>
			</div>
			<div className="flex flex-wrap items-center justify-center order-3 w-full gap-12 lg:w-auto lg:order-1 row">
				<button
					className={cn([
						buttonClass,
						viewType === "list" && activeButtonClass,
					])}
					onClick={() => navigate("/timesheets")}
				>
					{t("timesheets.listView")}
				</button>
				<button
					className={cn([
						buttonClass,
						viewType === "week" && activeButtonClass,
					])}
					onClick={() => navigate("/timesheets/group")}
				>
					{t("timesheets.weekView")}
				</button>
			</div>

			<div className="flex flex-wrap order-2 gap-4 ml-auto lg:ml-0">
				{children}
			</div>
		</div>
	);
};
