import React, { useRef } from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";
import { Trans } from "react-i18next";

import CommercialPropositionService from "services/CommercialPropositionsService";

import { Title, Picto } from "@zolteam/react-ras-library";
import { Field, Toggle } from "components/atoms";
import { ConfirmModal, AutocompleteInput } from "components/molecules";
import { IQualification } from "components/types";
import {
	ICPOurCommercialPropositionValues,
	defaultQualification,
} from "forms/CommercialPropositionForm/Sections/CPOurCommercialProposition";

import { cn } from "utils/cn";

import { CP_TYPE_PARTNER, CP_TYPE_SEASONAL } from "constants_globals";

import "./Prestation.scss";

interface IPrestationProps {
	index: number;
	onRemove: (index: number) => void;
	qualification: IQualification;
	cpType?: string;
	locked?: boolean;
}

const MAX_QUALIFICATION_LENGTH = 70;

export const Prestation: React.FC<IPrestationProps> = ({
	index,
	onRemove,
	qualification,
	cpType,
	locked,
}) => {
	const [isModalOpen, setIsModalOpen] = React.useState(false);

	const { values, setFieldValue, setValues } =
		useFormikContext<ICPOurCommercialPropositionValues>();

	const fetchTM = useRef<any>(undefined);

	const fetchQualification = (search: string) =>
		CommercialPropositionService.getQualifications({
			search,
		}).then((resp) => {
			if (resp?.length === 0) {
				// If no results, create a new qualification
				const newValue = {
					...defaultQualification,
					qualificationName: search,
					title: search,
					autoSelect: true,
					id: crypto.randomUUID(),
				};

				setValues({
					...values,
					qualifications: values.qualifications?.map((i, iIndex) => {
						if (iIndex === index) {
							return newValue;
						}
						return i;
					}),
				});
				return [];
			}
			return resp;
		});

	const handleFetchQualification = (value: string) => {
		clearTimeout(fetchTM.current);
		const prom = new Promise<any[]>((resolve, reject) => {
			if (value?.length < 3) return resolve([]);
			fetchTM.current = setTimeout(() => {
				return fetchQualification(value).then(resolve, reject);
			}, 500);
		});
		return prom;
	};

	const dynamicPlaceholder = (type: string) => {
		if (!type) return "";
		if (type.includes("Coefficient"))
			return t(
				"commercialPropositions.form.sections.commercialProposition.coefficient"
			);
		if (type.includes("Taux") || type.includes("%"))
			return t(
				"commercialPropositions.form.sections.commercialProposition.amount"
			);
		if (type.includes("Forfait"))
			return t("commercialPropositions.form.sections.services.package");
		return "";
	};

	return (
		<div
			className={cn([
				"px-4 pb-4 bg-neutral-100 dark:bg-neutral-900 rounded-xl",
			])}
		>
			<div className="items-center justify-between mb-4 row">
				<Title tag="h3" size="heading03" className="dark:text-white">
					{t(
						"commercialPropositions.form.sections.commercialProposition.recruitmentPartner"
					)}
				</Title>
				{index > 0 && (
					<button
						type="button"
						onClick={() => setIsModalOpen(true)}
						className="duration-300 text-neutral-500 hover:text-primary-400"
						disabled={locked}
					>
						<Picto
							icon="trash"
							className="w-6 h-6 pointer-events-none"
						/>
					</button>
				)}
			</div>

			<AutocompleteInput
				inputType="text"
				inputName={`qualifications.${index}.qualificationName`}
				inputLabel={t(
					"commercialPropositions.form.sections.commercialProposition.qualification"
				)}
				getOptionLabel={(option) => option.title}
				handleChangeInput={(value) => {
					if (value?.length < 3)
						setValues({
							...values,
							qualifications: values.qualifications?.map(
								(i, iIndex) => {
									if (iIndex === index) {
										return {
											...defaultQualification,
										};
									}
									return i;
								}
							),
						});
					return handleFetchQualification(value);
				}}
				onClickOption={(option) =>
					setValues({
						...values,
						qualifications: values.qualifications?.map(
							(i, iIndex) => {
								if (iIndex === index) {
									return {
										...i,
										qualificationName: option?.title,
										qualification: option,
									};
								}
								return i;
							}
						),
					})
				}
				inputClassName="w-full"
				className="!w-full"
				maxLength={MAX_QUALIFICATION_LENGTH}
				disabled={locked}
				required
			/>

			<div className="items-center gap-4 mt-2 row">
				<Field
					maxLength={16}
					name={`qualifications.${index}.referenceSalary`}
					value={
						!qualification.accordingToAgreement
							? qualification?.referenceSalary
							: t(
									"commercialPropositions.form.sections.commercialProposition.accordingToAgreement"
							  )
					}
					disabled={locked || qualification.accordingToAgreement}
					label={t(
						"commercialPropositions.form.sections.commercialProposition.referenceSalary"
					)}
					className="w-[300px]"
				/>
				<Toggle
					label={t(
						"commercialPropositions.form.sections.commercialProposition.accordingToAgreement"
					)}
					name={`qualifications.${index}.accordingToAgreement`}
					checked={qualification?.accordingToAgreement}
					onChange={(e: any) => {
						setFieldValue(
							`qualifications.${index}.accordingToAgreement`,
							e.target.checked
						);
						if (e.target.checked)
							setFieldValue(
								`qualifications.${index}.referenceSalary`,
								""
							);
					}}
					disabled={locked}
				/>
			</div>

			{cpType && cpType !== CP_TYPE_PARTNER && (
				<Field
					maxLength={14}
					name={`qualifications.${index}.subjectToTaxation`}
					value={qualification?.subjectToTaxation}
					label={t(
						cpType === CP_TYPE_SEASONAL
							? "commercialPropositions.form.sections.commercialProposition.subjectToTaxationSeasonal"
							: "commercialPropositions.form.sections.commercialProposition.subjectToTaxation"
					)}
					required
					className="w-[300px] mt-8"
					disabled={locked}
				/>
			)}

			<Title tag="h3" size="heading03" className="mt-4 dark:text-white">
				{t(
					"commercialPropositions.form.sections.commercialProposition.conditionsType"
				)}
			</Title>

			<div className="gap-4 row">
				<div className="w-full row items-center px-4 py-1 text-sm rounded-full h-[52px] bg-neutral-200 dark:bg-neutral-800 dark:border-neutral-800 border-2 mt-px border-neutral-200">
					{values.delegationType?.name}
				</div>
				<Field
					maxLength={16}
					name={`qualifications.${index}.delegationCoefficient`}
					value={qualification?.delegationCoefficient}
					label={dynamicPlaceholder(values.delegationType?.name)}
					required
					className="w-[200px]"
					disabled={locked}
				/>
			</div>
			<div className="gap-4 mt-2 row">
				<div className="w-full row items-center px-4 py-1 text-sm rounded-full h-[52px] bg-neutral-200 dark:bg-neutral-800 dark:border-neutral-800 border-2 mt-px border-neutral-200">
					{values.managementType?.name}
				</div>
				<Field
					maxLength={16}
					name={`qualifications.${index}.managementCoefficient`}
					value={qualification?.managementCoefficient}
					label={dynamicPlaceholder(values.managementType?.name)}
					required
					className="w-[200px]"
					disabled={locked}
				/>
			</div>

			<Title tag="h3" size="heading03" className="mt-4 dark:text-white">
				{t(
					"commercialPropositions.form.sections.commercialProposition.hiringFees"
				)}
			</Title>
			<div className="gap-4 mt-2 row">
				<div className="w-full row items-center px-4 py-1 text-sm rounded-full h-[52px] bg-neutral-200 dark:bg-neutral-800 dark:border-neutral-800 border-2 mt-px border-neutral-200">
					{values.hiringFees?.name}
				</div>
				<Field
					maxLength={16}
					name={`qualifications.${index}.feesAppliedCoefficient`}
					value={qualification?.feesAppliedCoefficient}
					label={dynamicPlaceholder(values.hiringFees?.name)}
					required
					className="w-[200px]"
					disabled={locked}
				/>
			</div>

			<Title tag="h3" size="heading03" className="mt-4 dark:text-white">
				{t(
					"commercialPropositions.form.sections.commercialProposition.weeklyDeduction"
				)}
			</Title>
			<Field
				maxLength={16}
				name={`qualifications.${index}.weeklyFee`}
				value={qualification?.weeklyFee}
				label={t(
					"commercialPropositions.form.sections.commercialProposition.weeklyDeductionPH"
				)}
				required
				className="w-[200px]"
				disabled={locked}
			/>
			<ConfirmModal
				onConfirm={() => onRemove(index)}
				title={t(
					"commercialPropositions.form.sections.commercialProposition.removeTitle"
				)}
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				size="s"
			>
				<Trans
					i18nKey={
						"commercialPropositions.form.sections.commercialProposition.removeConfirm"
					}
					values={{
						qualification:
							values.qualifications?.[index]?.qualificationName ??
							values.qualifications?.[index]?.qualification
								?.title ??
							values.qualifications?.[index]?.qualification
								?.qualificationName ??
							index + 1,
					}}
				/>
			</ConfirmModal>
		</div>
	);
};
