import React from "react";

import { t } from "i18next";
import { Route, Routes } from "react-router-dom";

import useAppContext from "store/useAppContext";

import { Button } from "@zolteam/react-ras-library";
import { LogoutButton } from "components/atoms";
import { UserProfileNav } from "components/molecules";
import { PageLayout } from "components/templates";
import { UserEditPassword, UserNotifications, UserProfile } from "views";

import { PERMISSIONS_ALERTS } from "constants_globals";

export interface IProfileProps {}

export const Profile: React.FC<IProfileProps> = () => {
	const { hasPermissions } = useAppContext();

	return (
		<PageLayout
			title={t("profile.title")}
			className="min-h-[calc(100vh-72px)]"
		>
			<div className="flex flex-col h-full lg:gap-8 lg:flex-row">
				<div className="h-auto lg:h-[50vh] sticky flex-wrap gap-4 top-0 p-8 bg-neutral-100 dark:bg-black lg:bg-neutral-200 row md:col lg:rounded-r-3xl min-w-[200px] justify-between text-sm">
					<UserProfileNav />
					<LogoutButton>
						{({ handleLogout }) => (
							<Button
								type="button"
								color="transparent"
								className="items-start self-start mt-auto font-light dark:!text-neutral-100"
								onClick={handleLogout}
							>
								{t("auth.logout")}
							</Button>
						)}
					</LogoutButton>
				</div>
				<div className="w-full h-full gap-8 p-8 text-sm bg-white shadow-xl dark:bg-black lg:rounded-tl-3xl col">
					<Routes>
						<Route path="/" element={<UserProfile />} />
						<Route path="/profile" element={<UserProfile />} />
						{hasPermissions(PERMISSIONS_ALERTS) && (
							<Route
								path="/notifications"
								element={<UserNotifications />}
							/>
						)}
						<Route
							path="/edit-password"
							element={<UserEditPassword />}
						/>
					</Routes>
				</div>
			</div>
		</PageLayout>
	);
};
