import React from "react";

import { Button } from "@zolteam/react-ras-library";
import { IFilterStatus } from "components/types";

import { cn } from "utils";

interface ITableStatusFilterProps {
	status: IFilterStatus[];
	onClick: (value?: string) => void;
	activeFilter?: string;
}

export const TableStatusFilter: React.FC<ITableStatusFilterProps> = ({
	status,
	onClick,
	activeFilter,
}) => {
	return (
		<div className="gap-4 row">
			{status.length
				? status.map((type) => {
						return (
							<Button
								key={type.name}
								type="button"
								color="primary"
								onClick={() => onClick(type.value)}
								className={cn([
									type.value !== activeFilter
										? "!bg-transparent text-black hover:!bg-neutral-200 dark:hover:!bg-neutral-700 dark:!text-white"
										: "!bg-primary-500",
									" !px-4 !py-1 font-light text-balance leading-4",
								])}
								data-testid={type.value || "all"}
							>
								{type.name}
							</Button>
						);
				  })
				: null}
		</div>
	);
};

export default TableStatusFilter;
