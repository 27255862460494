import React from "react";

import useAppContext from "store/useAppContext";

import { Text, Picto } from "@zolteam/react-ras-library";

export const HeaderProfile: React.FC = (ref) => {
	const { user } = useAppContext();

	let name = [user.firstName, user.lastName].join(" ").trim();

	return (
		<div className="flex flex-row items-center gap-3 text-right cursor-pointer">
			<Text
				tag="div"
				size="paragraph01"
				className="!leading-4 !font-[500]"
			>
				{name || "Menu"}
			</Text>
			<Picto icon="arrowDown" className="w-3 h-3" />
		</div>
	);
};
