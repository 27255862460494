import moment from "moment";
import MomentLocaleFR from "moment/moment-fr";

import { capitalize } from "utils";

// gen week days using capitalize and moment and i18n
export const weekDays = () => {
	moment.locale("fr", MomentLocaleFR);
	const list: string[] = moment.weekdays().map(capitalize);
	const first: string = list.shift() || "";
	if (first) list.push(first);
	return list;
};

export const daysList = weekDays().map((value, index) => ({
	value: index + 1,
	label: value,
}));
