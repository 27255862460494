import React, { useEffect, useRef, useState } from "react";

// Components
import { Picto } from "@zolteam/react-ras-library";

// Utils & misc
import { cn } from "utils";

// Style
import style from "./InputSearch.module.css";

interface IInputSearchProps {
	value?: string;
	type?: string;
	name: string;
	label: string;
	className?: string;
	onClick?: (
		value: string,
		e:
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
			| React.KeyboardEvent<HTMLInputElement>
	) => void;
	disabled?: boolean;
	promiseOptions?: (inputValue: string) => void;
	onChange?: (value: string) => void;
	resetValueProps?: (
		name: string,
		e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => void;
	minLength?: number;
	delay?: number;
}

class IInputSearchProps {}

export const InputSearch: React.FC<IInputSearchProps> = (
	props: IInputSearchProps
) => {
	const {
		type = "text",
		name,
		label,
		onClick = () => {},
		className,
		disabled = false,
		promiseOptions,
		value = "",
		resetValueProps,
		minLength = 0,
		delay = 0,
		onChange = () => {},
	} = props;

	const SearchTM = useRef<any>(null);

	const [inputValue, setInputValue] = useState(value);

	const handleSearch = (inputValue: string) => {
		if (minLength && inputValue.length < minLength) return;

		if (!delay) return promiseOptions?.(inputValue);

		return new Promise((resolve) => {
			if (SearchTM.current) clearTimeout(SearchTM.current);
			SearchTM.current = setTimeout(() => {
				resolve(promiseOptions && promiseOptions(inputValue));
			}, delay);
		});
	};

	useEffect(() => {
		setInputValue(value || "");
	}, [value]);

	return (
		<div className={cn([className])}>
			<div
				className={cn([
					style.field,
					inputValue.length <= 0 ? style.empty : null,
					disabled && style.disabled,
					disabled &&
						"dark:!opacity-30 [&_*]:cursor-not-allowed cursor-not-allowed",
					"dark:bg-neutral-900 dark:border-neutral-800 [&_*]:dark:text-neutral-300",
				])}
			>
				<input
					id={name}
					data-testid={name}
					type={type}
					name={name}
					placeholder={label}
					value={inputValue}
					onChange={(e) => {
						setInputValue(e.target.value);
						onChange(e.target.value);
						handleSearch(e.target.value);
					}}
					onKeyPress={(event) =>
						event.key === "Enter" && onClick(inputValue, event)
					}
					autoComplete={"off"}
				/>
				{!!inputValue?.length && (
					<button
						type="button"
						className={cn([
							style.buttonPicto,
							"duration-200",
							inputValue?.length > 0
								? "opacity-100"
								: "opacity-0 pointer-events-none",
						])}
						onClick={(e) => {
							setInputValue("");
							promiseOptions && promiseOptions("");
							resetValueProps && resetValueProps(name, e);
						}}
					>
						<Picto icon="close" className="text-neutral-500" />
					</button>
				)}

				<button
					type="button"
					className={style.buttonPicto}
					onClick={(e) => onClick(inputValue, e)}
				>
					<Picto icon="search" style={{ height: "18px" }} />
				</button>
			</div>
		</div>
	);
};
