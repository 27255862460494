import React from "react";

// need to import like this because i18n does not always work when used outside of a component
import i18n from "i18n/i18n";
import { Trans } from "react-i18next";
import { promiseToast } from "toastify";

import { useQueryClient } from "@tanstack/react-query";

import UserAdminService from "services/UserAdminService";

import { ConfirmModal } from "components/molecules";
import { List } from "components/organisms";
import { ISite, IUserInformationsProps } from "components/types";

const t = i18n.t.bind(i18n);

const buildSitePermissionsArray = (globalPermissions: string[]) => {
	const itemsOrdered: string[] = [];
	if (!globalPermissions?.length) return itemsOrdered;
	const theOrder = ["CONTRACT", "TIMESHEET", "RATING", "INVOICE"];
	for (let i = 0; i < theOrder.length; i += 1) {
		if (globalPermissions.indexOf(theOrder[i]) > -1) {
			itemsOrdered.push(theOrder[i]);
		}
	}
	return itemsOrdered;
};

const COLS = [
	{
		name: t("userProfile.sites.agency"),
		value: "agency",
		render: ({ agency: { tempoDirectory }, clientId }: ISite) => (
			<>
				{tempoDirectory}-{clientId}
			</>
		),
	},
	{
		name: t("userProfile.sites.agencyCode"),
		value: "agencyCode",
		render: ({ agency: { tempoDirectory } }: ISite) => tempoDirectory,
	},
	{
		name: t("userProfile.sites.name"),
		value: "name",
		render: ({ agency: { name } }: ISite) => name,
	},
	{
		name: t("userProfile.permissions.title"),
		value: "globalPermissions",
		render: ({ globalPermissions }: ISite) => (
			<ul className="text-xs list-disc text-neutral-500">
				{globalPermissions?.length ? (
					buildSitePermissionsArray(globalPermissions).map((perm) => (
						<li key={perm}>
							{t("userProfile.permissions." + perm)}
						</li>
					))
				) : (
					<li>{t("userProfile.permissions.none")}</li>
				)}
			</ul>
		),
	},
];

export const AdminUserProfileTable: React.FC<IUserInformationsProps> = ({
	user,
}) => {
	const queryClient = useQueryClient();
	const [SiteBeingDeleted, setSiteBeingDeleted] = React.useState<
		ISite | undefined
	>(undefined);
	const [Sites, setSites] = React.useState<ISite[]>(user.sites);
	const [IsSending, setIsSending] = React.useState<boolean>(false);

	const handleSelected = (site?: ISite, fromConfirm?: boolean) => {
		if (!site) return;

		const newSites: ISite[] = JSON.parse(JSON.stringify(Sites));
		const index = newSites.findIndex((s) => s.id === site.id);

		if (!newSites[index]) return;
		// if site is active, we want to have a confirmation modal before removing it
		if (newSites[index].active && !fromConfirm)
			return setSiteBeingDeleted(newSites[index]);

		// if site is not active or if we are coming from the confirmation modal, we can toggle it
		newSites[index].active = !newSites[index].active;

		if (IsSending) return;
		setIsSending(true);
		const prom = UserAdminService.userAdminSites(user.id, {
			sites: newSites,
		}).then(
			() => {
				setIsSending(false);
				setSites(newSites);
				if (fromConfirm) setSiteBeingDeleted(undefined);
				queryClient.refetchQueries({
					queryKey: ["AdminUser", "profile"],
				});
			},
			(err) => {
				setIsSending(false);
				throw err;
			}
		);

		return promiseToast(
			prom,
			{},
			{
				toastId: "userSites",
			}
		);
	};

	return (
		<div className="bg-white dark:bg-black">
			<p className="z-10 px-8 py-4 text-lg font-bold bg-white border-b dark:text-white dark:bg-black border-neutral-100 dark:border-neutral-900">
				{t("userProfile.sites.title")}
			</p>
			<List
				header={false}
				columns={COLS}
				items={Sites}
				selected={Sites.filter((site) => site.active)}
				onSelected={([selected]) => handleSelected(selected)}
				className="[&_td]:py-[0.7rem]"
			/>
			<ConfirmModal
				isOpen={!!SiteBeingDeleted}
				title={t("global.warning")}
				onClose={() => setSiteBeingDeleted(undefined)}
				onCancel={() => setSiteBeingDeleted(undefined)}
				onConfirm={() => handleSelected(SiteBeingDeleted, true)}
				size="s"
			>
				<Trans
					i18nKey={"userProfile.sites.removeConfirm"}
					values={{
						clientId: SiteBeingDeleted?.clientId,
					}}
				/>
			</ConfirmModal>
		</div>
	);
};
