import secureLocalStorage from "react-secure-storage";

const initStore = {
	auth: null,
	connectAs: null,
	lastContextUpdateTimeStamp: Date.now(),
};

const clearAuth = (state) => {
	const isDarkMode = localStorage.getItem("isDarkMode");
	secureLocalStorage.clear();
	localStorage.setItem("isDarkMode", isDarkMode);
	return initStore;
};

const clearAuthConnectAs = (state, action) => {
	sessionStorage.removeItem("isConnectedAs");
	return {
		...state,
		...initStore,
		auth: action.payload,
	};
};

const setAuth = (state, action) => ({
	...state,
	auth: action.payload,
});

const setUserSites = (state, action) => ({
	...state,
	auth: {
		...state.auth,
		sites: action.payload,
	},
});

const setUserAgencies = (state, action) => ({
	...state,
	auth: {
		...state.auth,
		agencies: action.payload,
	},
});

const setConnectAs = (state, action) => {
	sessionStorage.setItem("isConnectedAs", JSON.stringify(action.payload));
	return {
		...state,
		connectAs: action.payload,
	};
};

const appReducer = (state, action) => {
	const reducers = {
		CLEAR_AUTH: clearAuth,
		CLEAR_AUTH_CONNECT_AS: clearAuthConnectAs,
		SET_AUTH: setAuth,
		SET_USER_SITES: setUserSites,
		SET_USER_AGENCIES: setUserAgencies,
		SET_CONNECT_AS: setConnectAs,
	};

	if (reducers[action.type]) {
		return reducers[action.type](state, action);
	} else {
		throw new Error(`Unhandled action type: ${action.type}`);
	}
};

export { appReducer, initStore };
