import React from "react";

import { flatten } from "flat";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import DashboardService from "services/DashboardService";

import useAppContext from "store/useAppContext";

import DashboardTiles from "./tiles";
import { ActionResult } from "components/molecules";
import { DashboardTile } from "components/organisms";
import { PageLayout } from "components/templates";
import { IDashboardData } from "components/types";

export const Dashboard: React.FC = () => {
	const { appState, getAgencies } = useAppContext();
	const navigate = useNavigate();

	const activeAgenciesIds = getAgencies(true).map((agency) => agency.id);

	const fetchDashboard = (): Promise<IDashboardData> =>
		DashboardService.getDashboardData(activeAgenciesIds || []).then(
			(resp) => {
				return {
					data: resp,
					flatten: flatten(resp),
				};
				/*
				 **		le "flatten" permet d'applatir l'objet pour pouvoir récupérer une valeure de la manière suivante:
				 **		-> flatten["contractAdvancement.totalContractNumber"]
				 **
				 **		ce qui permet de n'avoir a définir que des valeurs dynamiques dans le fichier des tuiles ("DashboardTiles.tsx"), par exemple:
				 **		{
				 **			valueKey: "contractAdvancement.pendingContractNumber",
				 **			totalKey: "contractAdvancement.totalContractNumber",
				 **			text: "Contrats à signer",
				 **			onClickLabel: "/contracts?sortType=contract_number&status=waiting",
				 **		},
				 */
			}
		);

	const { isLoading, data, error } = useQuery({
		queryKey: [
			"Dashboard",
			appState.connectAs?.id ?? appState.auth?.id,
			activeAgenciesIds,
		],
		queryFn: fetchDashboard,
	});

	const listItemsProps = {
		appState,
		navigate,
	};

	const DisplayedTiles = DashboardTiles.filter((tile) => {
		if (!tile.permissions) return true;
		return tile.permissions.some((permission) =>
			appState.auth.permissions.includes(permission)
		);
	});

	return (
		<PageLayout title={t("dashboard.title")}>
			{(!!error && (
				<div className="px-8">
					<ActionResult
						type="error"
						text={t("dashboard.loadingError")}
					/>
				</div>
			)) ||
				(!DisplayedTiles.length && (
					<div className="w-full px-8 text-center">
						<ActionResult
							type="error"
							text={t("dashboard.permissionsError")}
						/>
					</div>
				)) || (
					<div className="grid grid-cols-1 gap-10 px-8 pb-8 2md:grid-cols-2">
						{DisplayedTiles.map((tile) => {
							return (
								<DashboardTile
									key={tile.title}
									{...tile}
									data={data}
									isLoading={isLoading}
									listItemsProps={listItemsProps}
								/>
							);
						})}
					</div>
				)}
			<div className="pb-2 pr-8 text-xs text-right text-neutral-500">
				{t("dashboard.footer")}
			</div>
		</PageLayout>
	);
};
