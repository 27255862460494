import i18n from "i18n/i18n";

import { IPermission } from "components/types";

const t = i18n.t.bind(i18n);

export const CONTRACT_GLOBAL_PERMISSION = "CONTRACT";
export const CONTRACT_LIST_PERMISSION = "CONTRACT_LIST";
export const CONTRACT_DOWNLOAD_PERMISSION = "CONTRACT_DOWNLOAD";
export const CONTRACT_SIGNATURE_PERMISSION = "CONTRACT_SIGNATURE";

export const INVOICE_GLOBAL_PERMISSION = "INVOICE";
export const INVOICE_DOWNLOAD_PERMISSION = "INVOICE_DOWNLOAD";
export const INVOICE_LIST_PERMISSION = "INVOICE_LIST";

export const RATING_GLOBAL_PERMISSION = "RATING";
export const RATING_DOWNLOAD_PERMISSION = "RATING_DOWNLOAD";
export const RATING_LIST_PERMISSION = "RATING_LIST";
export const RATING_VALIDATE_PERMISSION = "RATING_VALIDATE";

export const TIMESHEET_GLOBAL_PERMISSION = "TIMESHEET";
export const TIMESHEET_LIST_PERMISSION = "TIMESHEET_LIST";
export const TIMESHEET_DOWNLOAD_PERMISSION = "TIMESHEET_DOWNLOAD";
export const TIMESHEET_VALIDATE_PERMISSION = "TIMESHEET_VALIDATE";
export const TIMESHEET_EDIT_PERMISSION = "TIMESHEET_EDIT";

export const PERMISSIONS_LIST: IPermission[] = [
	{
		code: CONTRACT_GLOBAL_PERMISSION,
		icon: "menuContracts",
		label: t("userPermissions.contracts"),
		parentOf: [
			CONTRACT_LIST_PERMISSION,
			CONTRACT_DOWNLOAD_PERMISSION,
			CONTRACT_SIGNATURE_PERMISSION,
		],
		childrens: [
			{
				code: CONTRACT_LIST_PERMISSION,
				label: t("userPermissions.contractsList"),
			},
			{
				code: CONTRACT_DOWNLOAD_PERMISSION,
				label: t("userPermissions.contractsDownload"),
			},
			{
				code: CONTRACT_SIGNATURE_PERMISSION,
				label: t("userPermissions.contractsSign"),
			},
		],
	},
	{
		code: TIMESHEET_GLOBAL_PERMISSION,
		icon: "menuTimesheets",
		label: t("userPermissions.timesheet"),
		parentOf: [
			TIMESHEET_LIST_PERMISSION,
			TIMESHEET_DOWNLOAD_PERMISSION,
			TIMESHEET_EDIT_PERMISSION,
			TIMESHEET_VALIDATE_PERMISSION,
		],
		childrens: [
			{
				code: TIMESHEET_LIST_PERMISSION,
				label: t("userPermissions.timesheetsList"),
			},
			{
				code: TIMESHEET_DOWNLOAD_PERMISSION,
				label: t("userPermissions.timesheetsDownload"),
			},
			{
				code: TIMESHEET_EDIT_PERMISSION,
				label: t("userPermissions.timesheetsEdit"),
			},
			{
				code: TIMESHEET_VALIDATE_PERMISSION,
				label: t("userPermissions.timesheetsValidate"),
			},
		],
	},
	{
		code: RATING_GLOBAL_PERMISSION,
		icon: "menuEvaluations",
		label: t("userPermissions.rating"),
		parentOf: [
			RATING_LIST_PERMISSION,
			RATING_VALIDATE_PERMISSION,
			RATING_DOWNLOAD_PERMISSION,
		],
		childrens: [
			{
				code: RATING_LIST_PERMISSION,
				label: t("userPermissions.ratingsList"),
			},
			{
				code: RATING_VALIDATE_PERMISSION,
				label: t("userPermissions.ratingsValidate"),
			},
			{
				code: RATING_DOWNLOAD_PERMISSION,
				label: t("userPermissions.ratingsDownload"),
			},
		],
	},
	{
		code: INVOICE_GLOBAL_PERMISSION,
		icon: "menuInvoices",
		label: t("userPermissions.invoice"),
		parentOf: [INVOICE_LIST_PERMISSION, INVOICE_DOWNLOAD_PERMISSION],
		childrens: [
			{
				code: INVOICE_LIST_PERMISSION,
				label: t("userPermissions.invoicesList"),
			},
			{
				code: INVOICE_DOWNLOAD_PERMISSION,
				label: t("userPermissions.invoicesDownload"),
			},
		],
	},
];

export const API_PERMISSIONS_LIST: IPermission[] = PERMISSIONS_LIST.map(
	(permission) =>
		permission.childrens?.map((a) => ({
			...a,
			active: false,
		})) ?? []
).flat();

export const PERMISSIONS_ALERTS = [
	CONTRACT_SIGNATURE_PERMISSION,
	INVOICE_DOWNLOAD_PERMISSION,
	INVOICE_LIST_PERMISSION,
	TIMESHEET_EDIT_PERMISSION,
	TIMESHEET_VALIDATE_PERMISSION,
	RATING_VALIDATE_PERMISSION,
];
