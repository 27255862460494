import React, { PropsWithChildren } from "react";

import { Form, Formik } from "formik";
import { t } from "i18next";
import moment from "moment";
import * as Yup from "yup";

import { Textarea } from "components/atoms";
import { ConfirmModal, DatePickerField } from "components/molecules";
import { ICommercialProposition } from "components/types";

import { MAX_CONTACT_MESSAGE_LENGTH } from "constants_globals";

export type TFormField = {
	name: string;
	label: string;
	required?: boolean;
	validationSchema?: Yup.StringSchema<string | undefined>;
	minDate?: string;
	maxDate?: string;
};

export type TFields = {
	comment?: TFormField;
	date?: TFormField;
};

export type TFormValues = {
	date?: string;
	comment?: string;
};

export interface IChangeCommercialPropositionStatusModalProps
	extends PropsWithChildren {
	isOpen: boolean;
	onClose: () => void;
	item?: ICommercialProposition;
	onSubmit?: (values: any) => Promise<any>;
	title: string;
	fields: TFields;
	validationSchema?: Yup.ObjectSchema<TFormValues>;
}

export const ChangeCommercialPropositionStatusModal: React.FC<
	IChangeCommercialPropositionStatusModalProps
> = ({
	isOpen,
	onClose,
	item,
	children,
	title,
	fields,
	onSubmit = () => {
		return Promise.resolve();
	},
}) => {
	const [ShowConfirmClose, setShowConfirmClose] = React.useState(false);

	const handleSubmit = (values: any) => {
		return onSubmit({
			[fields?.date?.name ?? "date"]: values.date,
			[fields?.comment?.name ?? "comment"]: values.comment,
		});
	};

	if (!isOpen || !item) return null;
	return (
		<Formik
			initialValues={{
				date: "",
				comment: "",
			}}
			onSubmit={(values, formikHelpers) => {
				return handleSubmit(values).then(() => {
					formikHelpers.resetForm();
				});
			}}
			validationSchema={Yup.object().shape({
				date: fields?.date?.validationSchema ?? Yup.string(),
				comment: fields?.comment?.validationSchema ?? Yup.string(),
			})}
		>
			{({ setFieldValue, values, dirty, submitForm }) => {
				return (
					<Form>
						<ConfirmModal
							isOpen={!!item && isOpen}
							onClose={() => {
								if (!dirty) return onClose();
								setShowConfirmClose(true);
							}}
							title={title}
							onConfirm={() => {
								return submitForm();
							}}
							closeOnConfirm={false}
						>
							{!!fields?.comment && (
								<Textarea
									name="comment"
									label={
										fields?.comment?.label ??
										t(
											"commercialPropositions.changeStatusModal.commentPH"
										)
									}
									maxLength={MAX_CONTACT_MESSAGE_LENGTH}
									className="mt-4 dark:[&_textarea]:bg-transparent dark:[&_textarea]:text-white dark:[&_label]:text-neutral-300"
								/>
							)}
							{!!fields?.date && (
								<div className="flex-wrap items-center gap-4 mt-4 gap-y-1 row">
									<div>
										{fields?.date?.label ??
											t("dates.dateFilter")}
									</div>
									<div className="xs:max-w-[240px] w-full">
										<DatePickerField
											name="date"
											label={
												fields?.date?.label ??
												t("dates.dateFilter")
											}
											onChange={(date) => {
												let formated = moment(
													date
												).format(
													t(
														"dates.default"
													).toString()
												);
												setFieldValue("date", formated);
											}}
											value={values.date}
											pickerProps={{
												minDate: fields?.date?.minDate,
												maxDate: fields?.date?.maxDate,
											}}
										/>
									</div>
								</div>
							)}
							{!!children && (
								<div className="my-10 text-sm">{children}</div>
							)}
						</ConfirmModal>
						<ConfirmModal
							isOpen={ShowConfirmClose}
							onClose={() => setShowConfirmClose(false)}
							onConfirm={onClose}
							title={t("global.warning")}
							confirmText={t("blockNavigation.leave")}
							cancelText={t("blockNavigation.stay")}
							size="s"
						>
							{t("blockNavigation.text")}
						</ConfirmModal>
					</Form>
				);
			}}
		</Formik>
	);
};
