export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const PHONE_REGEX = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/g;

export const DATE_REGEX =
	/^((0|)[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

export const PASSWORD_REGEX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*,/\\.;])/;

export const NAME_REGEX = /^([A-Za-zÀ-ÖØ-öø-ÿ-]+\s[A-Za-zÀ-ÖØ-öø-ÿ-]+)+$/g;
