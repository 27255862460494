import React, { useState } from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";
import { Trans } from "react-i18next";

import { isWeekend } from "date-fns";

import { Button, Picto, Text, Tooltip } from "@zolteam/react-ras-library";
import { TimesheetTableRowTitleCell } from "components/atoms";
import { ConfirmModal } from "components/molecules";
import { ITimeSheetBonus, ITimesheetEditFormErrors } from "components/types";

import {
	cn,
	formatHoursMinutes,
	formatHundredsToString,
	roundNumber,
} from "utils";

import { HUNDREDS_MODE } from "constants_globals";

export interface ITableBonusRowProps {
	field: string;
	indexField: number;
	bonus: ITimeSheetBonus;
	editable: boolean;
	onRemove: () => void;
	className?: string;
	total: string | number;
	mode?: string;
	locked?: boolean;
}

export const getBonusSubtitle = (type: string) => {
	if (["H", "G", "S", "D", "M", "T", "U", "W"].includes(type))
		return t(`timesheets.bonuses.types.${type}`);
	else return t("timesheets.bonuses.types.other");
};

export const TableBonusRow: React.FC<ITableBonusRowProps> = ({
	field,
	indexField,
	bonus,
	editable,
	onRemove,
	total,
	mode,
	locked,
}) => {
	const [confirmInputModal, toggleConfirmInputModal] = useState(false);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);

	const { values, setFieldValue, validateForm } = useFormikContext<any>();
	const { errors } = useFormikContext<ITimesheetEditFormErrors>();

	const hasNoWorkingDays = bonus.days.every((day) => !day.isWorkingDay);

	const handleConfirmWorkDay = () => {
		setFieldValue(`${field}[${indexField}][days][${activeIndex}`, {
			...(activeIndex ? values[field][indexField].days[activeIndex] : {}),
			value: undefined,
			isWorkingDay: true,
		});

		setActiveIndex(null);
		toggleConfirmInputModal(false);
	};

	const inputClassName =
		"w-[3.5rem] text-xs text-center rounded-full border-2 border-neutral-200 dark:border-neutral-800 px-2 py-1 dark:bg-neutral-100";

	const disabledInputClassName = "bg-neutral-200 dark:bg-neutral-800";
	const errorInputClassName = "border-red-500 dark:border-red-500";

	return (
		<>
			<tr className="[&_td:not(:first-child)]:text-center">
				<TimesheetTableRowTitleCell
					title={t(
						`timesheets.bonuses.${bonus.bonusId ?? ""}`,
						bonus.name ?? ""
					)}
					subtitle={getBonusSubtitle(bonus.type)}
					className="py-1"
				/>
				{!!bonus.days?.length ? (
					bonus.days.map((day, index) => {
						const isWorkingDay =
							day.isWorkingDay || hasNoWorkingDays;

						const getError = (): any => {
							const error: any =
								(typeof errors.tsBonuses?.[indexField]?.days?.[
									index
								] === "string"
									? errors.tsBonuses?.[indexField]?.days?.[
											index
									  ]
									: errors.tsBonuses?.[indexField]?.days?.[
											index
									  ]?.value) || "";
							return error ? <Trans i18nKey={error} /> : "";
						};
						if (
							!day.dateIsInTimesheet ||
							day.value !== "" ||
							isWorkingDay
						) {
							const roundedValue = roundNumber(
								Number(day.value || 0),
								true
							);
							if (["H", "G", "S", "D"].includes(bonus.type)) {
								return (
									<td key={day.id}>
										<Tooltip
											placement="top"
											content={getError()}
										>
											<input
												className={cn([
													inputClassName,
													((!isWorkingDay &&
														day.value === "") ||
														!day.dateIsInTimesheet ||
														!editable) &&
														disabledInputClassName,

													errors.tsBonuses?.[
														indexField
													]?.days?.[index] &&
														errorInputClassName,
												])}
												onBlur={({
													target: { value },
												}) => {
													if (mode) {
														setFieldValue(
															`${field}[${indexField}][days][${index}][value]`,
															formatHundredsToString(
																value,
																/,|\.|:|e/,
																mode ===
																	HUNDREDS_MODE
																	? ","
																	: ":"
															)
														);
														setTimeout(() => {
															validateForm();
														}, 0);
													}
												}}
												value={day.value || ""}
												name={`${field}[${indexField}][days][${index}][value]`}
												type="text"
												onChange={({
													target: { value },
												}) => {
													setFieldValue(
														`${field}[${indexField}][days][${index}][value]`,
														value
													);
												}}
												disabled={
													locked ||
													!values[field][indexField]
														.days[index]
														.dateIsInTimesheet ||
													!editable
												}
											/>
										</Tooltip>
									</td>
								);
							}

							if (bonus.type === "W") {
								return (
									<td key={day.id}>
										<input
											className={cn([
												inputClassName,
												"appearance-textfield",
												((!isWorkingDay &&
													!day.value) ||
													!day.dateIsInTimesheet ||
													!isWeekend(day.date) ||
													!editable) &&
													disabledInputClassName,
											])}
											name={`${field}[${indexField}][days][${index}][value]`}
											type="number"
											min="0"
											max="1"
											step="1"
											value={
												(roundedValue as number) > 0
													? roundedValue
													: ""
											}
											onChange={(e) => {
												setFieldValue(
													`${field}[${indexField}][days][${index}][value]`,
													parseInt(e.target.value, 10)
												);
											}}
											disabled={
												locked ||
												!day.dateIsInTimesheet ||
												!isWeekend(day.date) ||
												!editable
											}
										/>
									</td>
								);
							}

							// all other bonus types
							// "M", "T", "U", ...

							if (!["W", "H", "G", "S", "D"].includes(bonus.type))
								return (
									<td key={day.id}>
										{bonus.type !== "M" && (
											<input
												className={cn([
													inputClassName,
													"appearance-textfield",
													((!isWorkingDay &&
														!day.value) ||
														!day.dateIsInTimesheet ||
														!editable) &&
														disabledInputClassName,
												])}
												name={`${field}[${indexField}][days][${index}][value]`}
												type="number"
												min="0"
												max="20"
												step="1"
												value={
													(roundedValue as number) > 0
														? roundedValue
														: ""
												}
												onChange={(e) => {
													setFieldValue(
														`${field}[${indexField}][days][${index}][value]`,
														parseInt(
															e.target.value,
															10
														)
													);
												}}
												disabled={
													locked ||
													!values[field][indexField]
														.days[index]
														.dateIsInTimesheet ||
													!editable
												}
											/>
										)}
									</td>
								);
						}

						if (
							!isWorkingDay &&
							day.dateIsInTimesheet &&
							!day.value
						)
							return (
								<td key={day.id}>
									<input
										value={""}
										className={cn([
											inputClassName,
											"bg-neutral-200",
											!locked &&
												editable &&
												"cursor-pointer",
										])}
										type="button"
										disabled={locked || !editable}
										onClick={() => {
											setActiveIndex(index);
											toggleConfirmInputModal(true);
										}}
									/>
								</td>
							);
						return <td />;
					})
				) : (
					<td />
				)}
				<td>
					{bonus.type === "M" ? (
						<div>
							<input
								type="checkbox"
								name={`${field}[${indexField}][value]`}
								checked={values[field][indexField].value === 1}
								onChange={() => {
									setFieldValue(
										`${field}[${indexField}][value]`,
										values[field][indexField].value === 1
											? 0
											: 1
									);
								}}
								disabled={locked || !editable}
							/>
						</div>
					) : (
						<Text
							tag="span"
							color="black"
							fontWeight="normal"
							className="dark:text-white"
						>
							{["H", "G", "S", "D"].includes(bonus.type)
								? `${
										mode === HUNDREDS_MODE
											? bonus.value || total
											: formatHoursMinutes(
													bonus.value || total
											  )
								  } h`
								: parseInt((bonus.value || total) as string)}
						</Text>
					)}
				</td>
				<td>
					{editable && !bonus.isDefault ? (
						<Button type="button" color="transparentPrimary">
							<Picto
								icon="trash"
								onClick={onRemove}
								className="!w-5 !h-5"
							/>
						</Button>
					) : null}
				</td>
				<ConfirmModal
					isOpen={confirmInputModal}
					onClose={() => toggleConfirmInputModal(false)}
					onConfirm={handleConfirmWorkDay}
					title="Attention"
					text="Voulez-vous saisir des heures sur cette journée déclarée en repos ?"
					size="s"
				/>
			</tr>
		</>
	);
};
