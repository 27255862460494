import { t } from "i18next";
import moment from "moment";
import { toast } from "react-toastify";

import ContractsService from "services/ContractsService";

import { ActionsListItem } from "components/atoms";
import { IContract } from "components/types";

import { handleFileSave } from "utils";

import {
	CONTRACT_SIGNATURE_PERMISSION,
	CONTRACT_STATUS_CANCELLED,
	CONTRACT_STATUS_SIGNED,
} from "constants_globals";

interface IContractItemActions {
	handleContact: (invoice: IContract) => void;
}

export const checkPermission = (perms: string[], permission: string) =>
	perms?.find((p: string) => p === permission);

export const contractItemActions = ({
	handleContact,
}: IContractItemActions) => {
	const handleDownload = ({ id }: IContract) => {
		toast.loading(t("promiseToast.fetching"), {
			toastId: `contract-download-${id}`,
		});
		const prom = ContractsService.contractDownload(id).finally(() =>
			toast.dismiss(`contract-download-${id}`)
		);
		handleFileSave(
			prom,
			`contrat-${id}${moment().format(t("dates.full"))}.pdf`
		);
	};

	const handleViewSignature = (item: IContract) => {
		window.open(item.link, "_blank");
	};

	return [
		{
			render: (item: IContract) => {
				if (
					!checkPermission(
						item.permissions,
						CONTRACT_SIGNATURE_PERMISSION
					) ||
					item.status === CONTRACT_STATUS_CANCELLED ||
					item.format !== "zoe"
				)
					return false;
				const isSigned = item.status === CONTRACT_STATUS_SIGNED;
				return (
					<ActionsListItem
						props={item}
						action={{
							onClick: handleViewSignature,
							icon: isSigned ? "eye" : "edit",
							label: isSigned
								? t("contracts.viewSign")
								: t("contracts.sign"),
						}}
					/>
				);
			},
		},
		{
			render: (item: IContract) => {
				if (item.format !== "zoe") return false;
				return (
					<ActionsListItem
						props={item}
						action={{
							onClick: handleDownload,
							icon: "download",
							label: t("contracts.download_one"),
						}}
					/>
				);
			},
		},
		{
			label: t("global.contactAgency"),
			icon: "bubble",
			onClick: (item: IContract) => handleContact(item),
		},
	];
};
