import React, { useState } from "react";

import { ErrorMessage, useFormikContext } from "formik";
import { t } from "i18next";

import { Input, InfoMessage } from "@zolteam/react-ras-library";

import { cn } from "utils/cn";

import style from "./AutocompleteInput.module.css";

interface IAutocompleteInputProps {
	onClickOption: (option: any) => void;
	handleChangeInput: (value: string) => Promise<any[]>;
	showOptions?: (value: string) => boolean;
	getOptionLabel: (option: any) => string;
	inputType?: string;
	inputLabel?: string;
	inputName: string;
	inputClassName?: string;
	className?: string;
	minChar?: number;
	maxLength?: number;
	locked?: boolean;
	disabled?: boolean;
	required?: boolean;
	value?: any;
}

export const AutocompleteInput: React.FC<IAutocompleteInputProps> = ({
	onClickOption,
	handleChangeInput,
	showOptions,
	getOptionLabel,
	inputType,
	inputLabel,
	inputName,
	inputClassName,
	minChar = 2,
	locked,
	className,
	maxLength,
	required,
	...props
}) => {
	const [list, setList] = useState<any>([]);
	const [show, setShow] = useState(false);

	const { setFieldValue, setFieldTouched, touched, getFieldProps } =
		useFormikContext<any>();

	const hideOnBlur = () => {
		if (!touched[inputName]) {
			setFieldTouched(inputName, true);
		}

		setList([]);
		setShow(false);
	};

	const handleChange = async (value: string) => {
		setFieldValue(inputName, value);
		if (value.length <= minChar) {
			setList([]);
			return;
		}

		const items = await handleChangeInput(value);
		setList(items);
	};

	const handleClickOnOption = (option: object) => {
		onClickOption(option);
		setList([]);
	};

	return (
		<div
			className={cn([
				style.autocompleteInterlocutorsParent,
				className,
				props.disabled && "[&>*>*]:!bg-neutral-100 opacity-70",
			])}
		>
			<Input
				{...props}
				onFocus={() => setShow(true)}
				onBlur={hideOnBlur}
				onChange={(e: any) => handleChange(e.target.value)}
				type={inputType}
				label={inputLabel}
				name={inputName}
				className={inputClassName}
				autoComplete="OFF"
				locked={locked}
			>
				<ErrorMessage name={inputName}>
					{(msg: string) => (
						<InfoMessage color="error" withIcon>
							{msg}
						</InfoMessage>
					)}
				</ErrorMessage>
			</Input>

			{!!maxLength && (
				<span className="absolute text-xs top-1 right-4 text-neutral-400 dark:text-neutral-500">
					{`${getFieldProps(inputName).value?.length}/${maxLength}`}
				</span>
			)}
			{required && (
				<span className="mb-8 ml-4 text-xs italic text-neutral-400">
					{t("forms.field.mandatory")}
				</span>
			)}
			{list.length > 0 && show && (
				<div className={style.autocompleteListWrapper}>
					<div className={style.autocompleteList}>
						{list.map((option: any, key: number) => (
							<span
								key={key}
								onMouseDown={() => handleClickOnOption(option)}
								className={style.autocompleteOption}
							>
								{getOptionLabel(option)}
							</span>
						))}
					</div>
				</div>
			)}
		</div>
	);
};
