import React from "react";

import { Form, useFormikContext } from "formik";
import { t } from "i18next";

import { Button, InfoMessage } from "@zolteam/react-ras-library";
import { Field, Textarea } from "components/atoms";

import {
	MAX_CONTACT_MESSAGE_LENGTH,
	MAX_CONTACT_SUBJECT_LENGTH,
} from "constants_globals";

interface ISendMailForm {
	load: boolean;
	error: string;
}

export const SendMailForm: React.FC<ISendMailForm> = ({ load, error }) => {
	const { isValid, dirty } = useFormikContext();

	return (
		<Form className="gap-6 col">
			{!!error && (
				<InfoMessage color="error" withIcon>
					{t("forms.message.error")}
				</InfoMessage>
			)}

			<Field
				type="email"
				name="email"
				label={t("forms.email.label")}
				disabled={load}
			/>
			<Field
				type="text"
				name="subject"
				label={t("forms.object.label")}
				maxLength={MAX_CONTACT_SUBJECT_LENGTH}
				required
				disabled={load}
			/>
			<Textarea
				name="content"
				label={t("forms.message.label")}
				maxLength={MAX_CONTACT_MESSAGE_LENGTH}
				required
				disabled={load}
				className="dark:[&_textarea]:bg-transparent dark:[&_textarea]:text-white dark:[&_label]:text-neutral-300"
			/>
			<Button
				type="submit"
				isLoading={load}
				color="primary"
				disabled={!(isValid && dirty)}
				size="l"
			>
				{t("forms.message.send")}
			</Button>
		</Form>
	);
};
