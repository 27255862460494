export const MAX_CONTACT_NAME_LENGTH = 46;
export const MAX_CONTACT_PHONE_LENGTH = 12;
export const MAX_CONTACT_EMAIL_LENGTH = 60;

export const MAX_COMPANY_LENGTH = 52;
export const MAX_SOCIAL_REASON_LENGTH = 100;
export const MAX_ADDRESS_LENGTH = 440;
export const MAX_CITY_LENGTH = 80;
export const MAX_ZIP_CODE_LENGTH = 12;
export const MAX_GORUP_NAME_LENGTH = 75;
