import React from "react";

import { Link } from "react-router-dom";

import { Picto } from "@zolteam/react-ras-library";
import { IActionsListItem } from "components/types";

export interface IActionsListItemProps {
	action: IActionsListItem;
	props?: any;
}

export const ActionsListItem: React.FC<IActionsListItemProps> = ({
	props,
	action,
}) => {
	const isDisabled =
		typeof action.disabled === "function"
			? action.disabled(props)
			: action.disabled;

	if (action.linkTo && !isDisabled)
		return <LinkActionItem props={props} action={action} />;

	return (
		<button
			type="button"
			className="flex items-center w-full max-w-full text-sm text-left"
			onClick={(event) => action.onClick?.(props, props.selected, event)}
			disabled={isDisabled}
		>
			{action.icon && (
				<Picto className="w-5 mr-3 min-w-5" icon={action.icon} />
			)}
			<span className="break-words whitespace-normal">
				{typeof action.label === "function"
					? action.label(props)
					: action.label}
			</span>
		</button>
	);
};

export const LinkActionItem: React.FC<IActionsListItemProps> = ({
	props,
	action,
}) => {
	const link =
		typeof action.linkTo === "function"
			? action.linkTo(props)
			: action.linkTo || "";

	const state = typeof link === "object" ? link.state : {};

	return (
		<Link
			to={typeof link === "string" ? link : link.pathname}
			state={state}
			className="flex items-center w-full text-sm text-left"
		>
			{action.icon && <Picto className="w-5 mr-3" icon={action.icon} />}
			<span>
				{typeof action.label === "function"
					? action.label(props)
					: action.label}
			</span>
		</Link>
	);
};
