import api from "./api";

interface getAgenciesParams {
	token?: string;
	email?: string;
}

class AgencyServiceClass {
	getPublicAgencies = () => api.get("/public/agency").then((res) => res.data);

	getAgencies = (props?: getAgenciesParams) =>
		api
			.get(
				"/agency",
				!!props?.token?.length
					? { headers: { "BGP-Authorization": `${props?.token}` } }
					: !!props?.email?.length
					? { params: { _switch_user: `${props?.email}` } }
					: {}
			)
			.then((res) => res.data);

	postPublicContactAgency = (data: unknown) =>
		api.post("/public/contact", data).then((res) => res.data);
}

const AgencyService = new AgencyServiceClass();

export default AgencyService;
