import React, { useRef } from "react";

import { t } from "i18next";
import moment from "moment";
import { toast } from "react-toastify";

import {
	InputSearch,
	TableDateFilter,
	TableStatusFilter,
} from "components/molecules";
import { ITableFiltersProps } from "components/types";

const MIN_SEARCH_LENGTH = 3;

export const TableFilters: React.FC<ITableFiltersProps> = ({
	status,
	statusKey = "status",
	filters,
	onChange,
	search = true,
	searchPlaceholder = t("forms.search.label"),
}) => {
	const searchTM = useRef<any>();

	const handleSearch = (value: string) => {
		// delay search
		clearTimeout(searchTM.current);
		const prom = new Promise((resolve) => {
			searchTM.current = setTimeout(() => {
				resolve(value);
			}, 1000);
		}).then(() => {
			onChange("search", value);
		});
		return prom;
	};

	return (
		<div className="flex-wrap justify-between gap-4 pr-4 sm:pr-0 row">
			<div className="flex-wrap items-center gap-4 row">
				{!!status?.length && (
					<TableStatusFilter
						status={status}
						onClick={(status) => onChange(statusKey, status)}
						activeFilter={filters[statusKey]}
					/>
				)}
				<TableDateFilter
					buttonLabel={
						!!filters?.dateBetweenStart && !!filters?.dateBetweenEnd
							? t("dates.fromTo", {
									from: moment(
										filters?.dateBetweenStart,
										t("dates.apiFilter")
									).format(t("dates.default")),
									to: moment(
										filters?.dateBetweenEnd,
										t("dates.apiFilter")
									).format(t("dates.default")),
							  })
							: "Date"
					}
					dateBetweenName={filters?.dateBetweenName}
					onChange={(dates) => {
						if (!dates?.dateBetweenStart || !dates?.dateBetweenEnd)
							return onChange("dates", {
								dateBetweenName: undefined,
								dateBetweenStart: undefined,
								dateBetweenEnd: undefined,
							});

						onChange("dates", {
							dateBetweenName: dates.dateBetweenName ?? "",
							dateBetweenStart: moment(
								dates.dateBetweenStart,
								t("dates.default")
							).format(t("dates.apiFilter")),
							dateBetweenEnd: moment(
								dates.dateBetweenEnd,
								t("dates.default")
							).format(t("dates.apiFilter")),
						});
					}}
					initialValues={{
						dateBetweenStart:
							filters?.dateBetweenStart &&
							moment(filters?.dateBetweenStart).format(
								t("dates.default")
							),
						dateBetweenEnd:
							filters?.dateBetweenEnd &&
							moment(filters?.dateBetweenEnd).format(
								t("dates.default")
							),
						dateBetweenName:
							filters?.dateBetweenName &&
							moment(filters?.dateBetweenName).format(
								t("dates.default")
							),
					}}
				/>
			</div>
			{search && (
				<InputSearch
					value={filters.search}
					promiseOptions={handleSearch}
					label={searchPlaceholder}
					name="search"
					className="w-full ml-auto xs:w-[300px]"
					onClick={(value, e) => {
						if (value?.length < MIN_SEARCH_LENGTH)
							toast.error(
								t("forms.search.minLengthError", {
									count: MIN_SEARCH_LENGTH,
								}),
								{
									toastId: "search-min-length",
								}
							);
					}}
				/>
			)}
		</div>
	);
};
