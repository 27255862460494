import React, { PropsWithChildren } from "react";

import { Text } from "@zolteam/react-ras-library";

export interface IThreadInfoProps extends PropsWithChildren {
	label: string;
}

export const ThreadInfo: React.FC<IThreadInfoProps> = ({ label, children }) => {
	return (
		<div className="items-center text-sm row">
			<span className="mr-4">{label}:</span>
			<Text
				color="black"
				tag="span"
				size="paragraph01"
				fontWeight="bold"
				className="dark:text-white"
			>
				{children}
			</Text>
		</div>
	);
};
