import { t } from "i18next";

import { ICommercialPropositionFormSectionProps } from "./CommercialPropositionForm";
import {
	CPBillingSection,
	CPBillingSectionInitialValues,
	CPBillingSectionValidation,
} from "./Sections/CPBillingSection";
import {
	CPCommunicationSection,
	CPCommunicationSectionInitialValues,
} from "./Sections/CPCommunicationSection";
import {
	CPConditionSection,
	CPConditionSectionInitialValues,
	CPConditionSectionValidation,
} from "./Sections/CPConditionsSection";
import {
	CPDateSection,
	CPDateSectionInitialValues,
	CPDateSectionValidation,
} from "./Sections/CPDateSection";
import {
	CPOurCommercialProposition,
	CPOurCommercialPropositionInitialValues,
	CPOurCommercialPropositionValidation,
} from "./Sections/CPOurCommercialProposition";
import {
	CPPreferedContact,
	CPPreferedContactInitialValues,
	CPPreferedContactValidation,
} from "./Sections/CPPreferedContactSection";
import {
	CPPrestationSection,
	CPPrestationSectionInitialValues,
	CPPrestationSectionValidation,
} from "./Sections/CPPrestationsSection";
import {
	CPRisksSection,
	CPRisksSectionInitialValues,
	CPRisksSectionValidation,
} from "./Sections/CPRisksSection";
import {
	CPCompany,
	CPCompanyInitialValues,
	CPCompanyValidation,
} from "./Sections/CPcompanySection";

export interface ICommercialPropositionFormSection {
	title: string;
	component: React.FC<ICommercialPropositionFormSectionProps>;
	validationSchema?: any;
	initialValues?: any;
}

export const CommercialPropositionFormSection: ICommercialPropositionFormSection[] =
	[
		{
			title: t("commercialPropositions.form.sections.date.title"),
			component: CPDateSection,
			validationSchema: CPDateSectionValidation,
			initialValues: CPDateSectionInitialValues,
		},
		{
			title: t("commercialPropositions.form.sections.contact.title"),
			component: CPPreferedContact,
			validationSchema: CPPreferedContactValidation,
			initialValues: CPPreferedContactInitialValues,
		},
		{
			title: t("commercialPropositions.form.sections.company.title"),
			component: CPCompany,
			validationSchema: CPCompanyValidation,
			initialValues: CPCompanyInitialValues,
		},
		{
			title: t(
				"commercialPropositions.form.sections.commercialProposition.title"
			),
			component: CPOurCommercialProposition,
			validationSchema: CPOurCommercialPropositionValidation,
			initialValues: CPOurCommercialPropositionInitialValues,
		},
		{
			title: t("commercialPropositions.form.sections.services.title"),
			component: CPPrestationSection,
			validationSchema: CPPrestationSectionValidation,
			initialValues: CPPrestationSectionInitialValues,
		},
		{
			title: t("commercialPropositions.form.sections.terms.title"),
			component: CPConditionSection,
			validationSchema: CPConditionSectionValidation,
			initialValues: CPConditionSectionInitialValues,
		},
		{
			title: t("commercialPropositions.form.sections.billing.title"),
			component: CPBillingSection,
			validationSchema: CPBillingSectionValidation,
			initialValues: CPBillingSectionInitialValues,
		},
		{
			title: t("commercialPropositions.form.sections.risks.title"),
			component: CPRisksSection,
			validationSchema: CPRisksSectionValidation,
			initialValues: CPRisksSectionInitialValues,
		},
		{
			title: t(
				"commercialPropositions.form.sections.communication.title"
			),
			component: CPCommunicationSection,
			validationSchema: {},
			initialValues: CPCommunicationSectionInitialValues,
		},
	];
