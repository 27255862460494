import React, { useEffect } from "react";

import { t } from "i18next";
import { toast } from "react-toastify";

import { startOfDay } from "date-fns";

import { useQuery } from "@tanstack/react-query";

import { Picto, Title, Button } from "@zolteam/react-ras-library";

/*

	Composant masqué pour le moment car le comportement n'est pas encore défini à 100%

	Il était prévu qu'une valeur "showChangelogs" soit stockée en base pour ne plus afficher le toast
	à chaque nouvelle version de l'application, sauf qu'il faudrait reset cette valeur manuellement en back à chaque nouvelle version (pas pratique)
	requete back: GET /user/me/display-changelog (UserService.getChangeLogDisplay)

*/

const IS_CHANGLOGS_ENABLED = false;

interface IChangeLogToastRenderProps {
	onClose: (showAgain: boolean) => void;
}

const oldVersion = JSON.parse(localStorage.getItem("changelogs") || "false");

const newestVersion = process.env.REACT_APP_VERSION;

const ChangeLogToastRender: React.FC<IChangeLogToastRenderProps> = ({
	onClose,
}) => {
	const [isChecked, setIsChecked] = React.useState(false);

	const handleFetch = async () => {
		const response = await fetch("/files/mytemp-changelogs-file.pdf");
		return response.blob().then((resp) => {
			if (resp.type !== "application/pdf")
				throw new Error("Invalid file type or file not found.");
			return resp;
		});
	};

	const handleDownload = (data?: Blob) => {
		if (!data) return;
		const url = window.URL.createObjectURL(data);
		const a = document.createElement("a");
		a.href = url;
		a.download = `mytemp-changelogs-${newestVersion}.pdf`;
		a.click();
		window.URL.revokeObjectURL(url);
	};

	const { isLoading, data } = useQuery({
		queryKey: ["changelogs"],
		queryFn: handleFetch,

		retry: 0,
	});

	return (
		<div className="items-center col">
			<Picto icon="logoMyTemp" className="h-10" />
			<Title tag="h2" size="heading03" className="text-center">
				{t("changelogs.title")}
			</Title>
			<p className="text-center">{t("changelogs.description")}</p>
			<div className="gap-2 mt-4 col">
				{(isLoading || !!data) && (
					<Button
						onClick={() => handleDownload(data)}
						color="primary"
						type="button"
						isLoading={isLoading}
						size="s"
					>
						{t("changelogs.learnMore")}
					</Button>
				)}
				<div className="gap-4 row">
					<label className="items-center row">
						<input
							type="checkbox"
							checked={isChecked}
							onChange={() => setIsChecked(!isChecked)}
							className="mr-2"
						/>
						{t("changelogs.dontShowAgain")}
					</label>
					<Button
						onClick={() => onClose(!isChecked)}
						color="primary"
						type="button"
						size="s"
					>
						{t("global.close")}
					</Button>
				</div>
			</div>
		</div>
	);
};

export const ChangeLogToast = () => {
	const daysSinceLastShown =
		(oldVersion?.firstShown
			? startOfDay(new Date()).getTime() -
			  startOfDay(new Date(oldVersion.firstShown)).getTime()
			: 0) /
		(1000 * 60 * 60 * 24);

	const handleClose = (showAgain: boolean) => {
		toast.dismiss("Changelogs");

		localStorage.setItem(
			"changelogs",
			JSON.stringify({
				version: newestVersion,
				show: showAgain,
				firstShown:
					oldVersion?.version !== newestVersion ||
					!oldVersion.firstShown
						? new Date().toISOString()
						: oldVersion.firstShown,
			})
		);
	};

	useEffect(() => {
		if (
			!oldVersion ||
			(oldVersion.version === newestVersion && !oldVersion.show)
		) {
			return localStorage.setItem(
				"changelogs",
				JSON.stringify({
					version: newestVersion,
					show: false,
				})
			);
		}

		if (
			(oldVersion.version === newestVersion &&
				(!oldVersion.show || daysSinceLastShown > 4)) ||
			!IS_CHANGLOGS_ENABLED
		)
			return;

		toast(<ChangeLogToastRender onClose={handleClose} />, {
			toastId: "Changelogs",
			autoClose: false,
			closeOnClick: false,
		});
	}, [daysSinceLastShown]);

	return false;
};
