import React, { useState } from "react";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { promiseToast } from "toastify";

import SecurityService from "services/SecurityService";
import SessionService from "services/SessionService";

import { Title, Text, BackLink } from "@zolteam/react-ras-library";
import { ActionResult } from "components/molecules";
import { RetrievePasswordForm, IRetrievePasswordFormValues } from "forms";

const RetrievePassword: React.FC = () => {
	const [params] = useSearchParams();
	const isCreate = params.get("fromCreate") === "true";

	const [ShowSuccess, setShowSuccess] = useState(false);
	const navigate = useNavigate();

	const handleRetrievePassword = ({ email }: IRetrievePasswordFormValues) => {
		SessionService.lastTriedEmail(email);
		const prom = SecurityService.forgotPassword(email).then((resp) => {
			setShowSuccess(true);
		});

		promiseToast(prom);

		return prom;
	};

	return (
		<>
			{!ShowSuccess && (
				<BackLink onClick={() => navigate("/")}>
					{t("global.back")}
				</BackLink>
			)}

			<Title
				tag="h2"
				className="!text-xl text-center dark:text-neutral-100"
				lineHeight="m"
			>
				{t(
					isCreate
						? "createPassword.newLink"
						: "passwordRetrieve.title"
				)}
			</Title>
			{ShowSuccess ? (
				<ActionResult
					onBack={() => navigate("/login")}
					text={t("passwordRetrieve.sended")}
				/>
			) : (
				<>
					<Text
						tag="p"
						className="mb-4 !text-sm dark:text-neutral-200"
					>
						<Trans
							i18nKey={
								isCreate
									? "createPassword.newLinkText"
									: "passwordRetrieve.text"
							}
						/>
					</Text>
					<RetrievePasswordForm
						isCreate={isCreate}
						onSubmit={handleRetrievePassword}
					/>
				</>
			)}
		</>
	);
};

export default RetrievePassword;
