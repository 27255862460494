import api from "./api";
// Adjust the import path as needed
import { IReview, IReviewsFilters, TId } from "components/types";

import {
	CONFIG_EXPORT_PDF,
	CONFIG_EXPORT_XLSX,
	CONFIG_EXPORT_ZIP,
} from "constants_globals";

class RatingServiceClass {
	ratingAdvancement = () =>
		api.get(`/rating/advancement`).then((resp) => resp?.data);

	ratingSearch = (data: IReviewsFilters, signal: AbortSignal) =>
		api
			.post(`/rating/search`, data, {
				signal,
			})
			.then((resp) => resp?.data);

	ratingSave = (id: TId, data: IReview) =>
		api.post(`/rating/${id}/save`, data).then((resp) => resp?.data);

	ratingValidate = (id: TId) =>
		api.post(`/rating/${id}/validate`).then((resp) => resp?.data);

	ratingDetails = (id: TId, signal: AbortSignal) =>
		api
			.get(`/rating/${id}`, {
				signal,
			})
			.then((resp) => resp?.data);

	ratingDownload = (id: TId) =>
		api.get(`/rating/${id}/download`, CONFIG_EXPORT_PDF);

	// pass a form data with ids
	// example: const data = new FormData(); data.append("ids", [1,2,3]);
	ratingDownloadByIds(data: FormData) {
		return api.post("/rating/download-by-ids", data, {
			...CONFIG_EXPORT_ZIP,
			headers: {
				...CONFIG_EXPORT_ZIP.headers,
				"Content-Type": "application/form-data",
			},
		});
	}

	ratingExportBySearch = (data: IReviewsFilters) =>
		api.post(`/rating/export-xlsx-by-search`, data, CONFIG_EXPORT_XLSX);
}

const RatingService = new RatingServiceClass();
export default RatingService;
