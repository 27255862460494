import { useContext } from "react";

import { AppDispatchContext, AppStateContext } from "./AppContext";
import { IAgency } from "components/types";

import { ROLE_ADMIN, ROLE_AGENCY, ROLE_BETA_TESTER } from "constants_globals";

// Hook
const useAppContext = () => {
	const AppState = useContext(AppStateContext);
	const AppDispatch = useContext(AppDispatchContext);

	const getUser = () =>
		AppState?.connectAs ? AppState.connectAs : AppState?.auth?.user;

	const getRoles = () => AppState?.auth?.roles;

	const getPermissions = () => AppState?.auth?.permissions;

	const hasPermissions = (permissions: string[], requiereAll?: boolean) => {
		const myPermissions = getPermissions();

		if (!myPermissions?.length) return false;
		if (!permissions?.length) return true;

		let permissionCondition = false;
		if (requiereAll) {
			permissionCondition = permissions.every((permission: string) =>
				myPermissions.includes(permission)
			);
		} else {
			permissionCondition = permissions.some((permission: string) =>
				myPermissions.includes(permission)
			);
		}

		if (permissionCondition) return true;
		return false;
	};

	const getAccessToken = () => AppState?.auth?.token;

	const getAgencies = (actives?: boolean): IAgency[] => {
		let agencies = AppState?.auth?.agencies || [];
		if (actives)
			return agencies?.filter((agency: IAgency) => agency.active);
		return agencies;
	};

	const getSites = () => AppState?.auth?.sites || [];

	const isAdmin = () => hasRoles([ROLE_ADMIN, ROLE_AGENCY, ROLE_BETA_TESTER]);

	const isLogged = () => AppState?.auth && AppState?.auth?.token;

	const hasRoles = (roles: string[]) => {
		const auth = AppState?.auth;
		if (!auth) return false;
		return (
			auth.user &&
			auth.roles &&
			auth.roles.length &&
			auth.roles.some((role: string) => roles.includes(role))
		);
	};

	return {
		appState: AppState,
		dispatch: AppDispatch,
		user: getUser(),
		roles: getRoles(),
		permissions: getPermissions(),
		hasPermissions,
		accessToken: getAccessToken(),
		getAgencies,
		getSites,
		isAdmin,
		isLogged,
		isLoggedAs: AppState?.connectAs,
	};
};

export default useAppContext;
