import React from "react";

import {
	Route,
	createBrowserRouter,
	RouterProvider,
	createRoutesFromChildren,
} from "react-router-dom";

import useAppContext from "store/useAppContext";

import { IRoute, PublicRoutes, UserRoutes, AdminRoutes } from "./Routes";
import { AdminLayout } from "components/templates";
import { Page404 } from "views";

export const isRouteActive = (tab: IRoute) => {
	let path = window.location.pathname;
	if (typeof tab.path === "string" && path === tab.path) return true;
	if (Array.isArray(tab.path))
		return (
			tab.path.indexOf(path) !== -1 ||
			tab.path.find((p) => p !== "/" && path.indexOf(p) !== -1)
		);
	if (tab.childrens?.length) return tab.childrens.some(isRouteActive);
	return path === tab.path;
};

const Router: React.FC = () => {
	const { appState, isLogged, isAdmin, hasPermissions } = useAppContext();
	const IS_LOGGED_IN = isLogged();
	const IS_ADMIN = IS_LOGGED_IN && isAdmin() && !appState.connectAs;

	const genElement = (forRoute: any) =>
		IS_LOGGED_IN ? (
			<AdminLayout {...forRoute.props}>
				{forRoute.element ?? <Page404 />}
			</AdminLayout>
		) : (
			forRoute.element ?? <Page404 />
		);

	const genRoute = (route: IRoute) => {
		if (
			!route.path ||
			route.hidden ||
			(route.permissions && !hasPermissions(route.permissions))
		)
			return null;

		if (typeof route.path === "string")
			return (
				<Route
					key={route.path}
					{...route}
					path={route.path}
					element={genElement(route)}
				/>
			);

		if (Array.isArray(route.path)) {
			return route.path.map((path: string) => (
				<Route key={path} path={path} element={genElement(route)} />
			));
		}
	};

	const genRoutes = () => {
		if (IS_ADMIN) return AdminRoutes.map(genRoute);
		return UserRoutes.map(genRoute);
	};

	const router = createBrowserRouter(
		createRoutesFromChildren(
			IS_LOGGED_IN ? genRoutes() : PublicRoutes.map(genRoute)
		)
	);

	return <RouterProvider router={router} />;
};

export default Router;
