import * as Yup from "yup";

import { PASSWORD_REGEX } from "constants_globals";

export interface IChangePasswordFormValues {
	previousPassword: string;
	newPassword: string;
	newPasswordConfirmation: string;
}

export const ChangePasswordFormInitValues: IChangePasswordFormValues = {
	previousPassword: "",
	newPassword: "",
	newPasswordConfirmation: "",
};

export const ChangePasswordFormValidation = (t: any) =>
	Yup.object().shape({
		previousPassword: Yup.string()
			.min(8, t("forms.password.constraintsText"))
			.required(t("forms.password.required")),
		newPassword: Yup.string()
			.min(8, t("forms.password.constraintsText"))
			.matches(PASSWORD_REGEX, t("forms.password.constraintsText"))
			.required(t("forms.password.required")),
		newPasswordConfirmation: Yup.string()
			.required(t("forms.password.required"))
			.oneOf(
				[Yup.ref("newPassword")],
				t("forms.password.confirmPassInvalid")
			),
	});
