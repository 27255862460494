import React from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";
import moment from "moment";
import * as Yup from "yup";

import { ICommercialPropositionFormSectionProps } from "../CommercialPropositionForm";
import { DatePickerField } from "components/molecules";

export interface ICPDateSectionValues {
	creationDate: string;
}

export const CPDateSectionInitialValues: ICPDateSectionValues = {
	creationDate: moment().format(t("dates.default")?.toString()),
};

export const CPDateSectionValidation = {
	creationDate: Yup.string().required(t("forms.field.required")),
};

export const CPDateSection: React.FC<
	ICommercialPropositionFormSectionProps
> = ({ locked }) => {
	const { values, setFieldValue } = useFormikContext<ICPDateSectionValues>();

	return (
		<div className="max-w-[250px]">
			<DatePickerField
				name="creationDate"
				label="Date"
				onChange={(value: any) => {
					let date = moment(value).format(
						t("dates.default")?.toString()
					);
					setFieldValue("creationDate", date);
				}}
				value={values.creationDate}
				disabled={locked}
			/>
		</div>
	);
};
