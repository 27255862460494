import { FC, useLayoutEffect } from "react";

import gsap from "gsap";
import { TBanner } from "types/TBanners";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import BannersService from "services/BannersService";

import { InfoMessage, PictoAction } from "@zolteam/react-ras-library";

import { cn } from "utils/cn";

export const BannersList: FC = () => {
	const { data, isLoading } = useQuery({
		queryKey: ["banners", "user"],
		queryFn: () => BannersService.getUserBanners(),
	});

	if (isLoading || !data?.length) return null;

	return (
		<div className="sticky top-0 z-10 p-2 pt-0 overflow-hidden min-h-fit bg-neutral-100">
			{data?.slice(0, 5).map((banner: TBanner) => (
				<Banner key={banner.id} {...banner} />
			))}
		</div>
	);
};

const Banner = (banner: TBanner) => {
	const queryClient = useQueryClient();

	const handleDismiss = () => {
		if (!banner.id) return Promise.resolve();

		return BannersService.readBanner(banner.id).then(() => {
			gsap.to(`#banner-${banner.id}`, {
				duration: 0.7,
				opacity: 0,
				height: 0,
				onComplete: () => {
					queryClient.refetchQueries({
						queryKey: ["banners", "user"],
					});
				},
			});
		});
	};

	const getBannerColor = (type: string) => {
		if (type === "information") return "success";
		if (type === "advertisement") return "warning";
		if (type === "alert") return "error";
	};

	const getBannerPicto = (type: string) => {
		if (type === "information") return "info";
		if (type === "advertisement") return "info";
		if (type === "alert") return "alertCircle";
	};

	const dismissMutation = useMutation({
		mutationFn: handleDismiss,
	});

	// show the banner using gsap
	useLayoutEffect(() => {
		gsap.fromTo(
			`#banner-${banner.id}`,
			{
				left: "100%",
				height: 0,
			},
			{
				duration: 0.5,
				height: "auto",
				left: 0,
			}
		);
	}, [banner.id]);

	return (
		<div
			id={`banner-${banner.id}`}
			className={cn([
				"relative w-full [&>div]:w-full [&>div]:py-1 [&>div]:pb-2 [&>div>span]:w-full",
				dismissMutation.isPending && "!opacity-70 pointer-events-none",
			])}
		>
			<InfoMessage
				color={getBannerColor(banner.type)}
				withIcon
				customPicto={{
					icon: getBannerPicto(banner.type),
				}}
			>
				<div className="justify-between w-full row">
					<div className="max-w-full pr-2 break-words whitespace-pre-wrap">
						<p className="text-base font-bold">{banner.title}</p>
						<p className="text-[0.8rem]">{banner.content}</p>
					</div>
					<PictoAction
						picto="close"
						className="self-start w-3 mt-1"
						onClick={() => dismissMutation.mutate()}
						pictoSize={{
							width: "0.75rem",
							height: "0.75rem",
						}}
					/>
				</div>
			</InfoMessage>
		</div>
	);
};
