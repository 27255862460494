import React from "react";

import { t } from "i18next";
import moment from "moment";
import { Trans } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { promiseToast } from "toastify";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import RatingService from "services/RatingsService";

import { Title, Text, Button } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import { ActionResult } from "components/molecules";
import { PageLayout } from "components/templates";
import { IReview } from "components/types";
import {
	REVIEW_STATUS_NOT_RATED,
	REVIEW_STATUS_WAITING,
} from "constants_globals/reviews";
import { ReviewForm } from "forms";

export interface IReviewProps {
	edition?: boolean;
}

export const Review: React.FC<IReviewProps> = () => {
	const { state } = useLocation();

	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { id } = useParams();

	const fetchReview = (abortSignal: AbortSignal) => {
		if (!id) return Promise.resolve({});
		return RatingService.ratingDetails(id, abortSignal);
	};

	const handleSubmit = (values: any, { resetForm }: any) => {
		if (!id) return Promise.reject(new Error("No id provided"));

		const prom = new Promise((resolve, reject) => {
			return RatingService.ratingSave(id, values).then(() => {
				return RatingService.ratingValidate(id).then((resp) => {
					resetForm();
					setTimeout(() => {
						resolve(resp);
						queryClient.invalidateQueries({
							queryKey: ["review", id],
						});
						navigate(`/reviews/view/${id}`, {
							state: {
								review: resp,
								edition: false,
								search: state?.search,
							},
							replace: true,
						});
					}, 100);
					return true;
				}, reject);
			}, reject);
		});

		promiseToast(prom, {
			success: t("reviews.edition.submited"),
		});

		return prom;
	};

	const { isLoading, isFetching, data, error } = useQuery({
		queryKey: ["review", id],
		queryFn: ({ signal }) => fetchReview(signal),
		enabled: !!id,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

	const pageTitle = [
		t("reviews.edition.title"),
		data?.contract?.tempWorkerFullName && t("reviews.edition.of"),
		data?.contract?.tempWorkerFullName,
	]
		.filter(Boolean)
		.join(" ");

	const isEditing: boolean =
		!isLoading &&
		(state?.edition || data?.status === REVIEW_STATUS_WAITING);

	return (
		<PageLayout
			tabTitle={pageTitle}
			title={
				<>
					<Title
						tag="h1"
						size="display02"
						className="max-md:!text-[2.25rem] max-md:!leading-10 dark:text-white"
					>
						{pageTitle}
					</Title>
					{!isEditing &&
						data &&
						data?.status !== REVIEW_STATUS_NOT_RATED && (
							<Text
								tag="p"
								size="paragraph01"
								color="grey"
								className="[&_strong]:text-primary-500 dark:[&_strong]:text-primary-300"
							>
								<Trans
									i18nKey="reviews.edition.submitedBy"
									values={{
										at: moment(data.updatedAt).format(
											t("dates.medium")
										),
										by: data.updatedBy,
									}}
								/>
							</Text>
						)}
					{data?.status === REVIEW_STATUS_NOT_RATED && (
						<Text
							tag="p"
							size="paragraph01"
							color="grey"
							className="[&_strong]:text-primary-500 dark:[&_strong]:text-primary-300"
						>
							{t("reviews.expired")}
						</Text>
					)}
				</>
			}
			headerProps={{
				backLabel: t("reviews.edition.backButton"),
				onBack: () => {
					navigate("/reviews" + (state?.search || ""));
				},
			}}
			className="min-h-[calc(100vh-72px)]"
		>
			<div className="flex flex-col h-full lg:gap-8 lg:flex-row">
				{isLoading || isFetching ? (
					<Spinner className="w-full" />
				) : (
					<>
						{!error && (
							<div className="h-fit w-full gap-8 top-0 p-8 bg-neutral-100 dark:bg-black lg:bg-neutral-200 col lg:rounded-r-3xl min-w-[200px] lg:max-w-[300px] max-w-full text-sm">
								<ReviewSideContent review={data} />
							</div>
						)}
						<div className="w-full h-full gap-8 p-8 text-sm bg-white shadow-xl dark:bg-black lg:rounded-tl-3xl col">
							{error ? (
								<ActionResult
									type="error"
									title={t("reviews.errors.fetchTitle")}
									text={t("reviews.errors.fetchText")}
								/>
							) : (
								<ReviewForm
									initialValues={data}
									edition={isEditing}
									onSubmit={handleSubmit}
								/>
							)}
						</div>
					</>
				)}
			</div>
		</PageLayout>
	);
};

interface IReviewSideContentProps {
	review: IReview;
}

const ReviewSideContent: React.FC<IReviewSideContentProps> = ({ review }) => {
	const { state } = useLocation();
	const navigate = useNavigate();

	const fetchOtherReviews = (abortSignal: AbortSignal) => {
		const endDate = moment(review?.endDate);

		const weekStart = endDate.clone().startOf("week");
		const weekEnd = endDate.clone().endOf("week");

		return RatingService.ratingSearch(
			{
				status: REVIEW_STATUS_WAITING,
				page: 1,
				limit: 10,
				dateBetweenStart: moment(weekStart).format(
					t("dates.apiFilter")
				),
				dateBetweenEnd: moment(weekEnd).format(t("dates.apiFilter")),
			},
			abortSignal
		).then((resp) => {
			return {
				...resp,
				results: resp.results.filter(
					(otherReview: IReview) =>
						otherReview.id !== review.id &&
						otherReview.contract.tempWorkerFullName
				),
			};
		});
	};

	const { isLoading, data } = useQuery({
		queryKey: ["otherReview", review?.id],
		queryFn: ({ signal }: any) => fetchOtherReviews(signal),
		enabled: !!review?.id,
	});

	if (!review) return null;

	const { contract } = review;

	return (
		<>
			<b className="dark:text-white">
				{t("dates.fromTo", {
					from: moment(review?.startDate).format(t("dates.medium")),
					to: moment(review?.endDate).format(t("dates.medium")),
				})}
			</b>
			<div className="dark:text-white">
				<p>{contract?.siteSocialReason}</p>
				<p>{contract?.qualification}</p>
				<b>
					<p>{`${t("contracts.contract")} ${contract.contractNumber}${
						contract.amendmentNumber !== "0"
							? `.${contract.amendmentNumber}`
							: ""
					}`}</p>
					{!!contract.orderReference && (
						<p>{contract.orderReference}</p>
					)}
				</b>
			</div>

			<p className="dark:text-white">
				{t("reviews.edition.sideBoxDescription")}
			</p>

			<div>
				<Title
					tag="h4"
					size="heading03"
					lineHeight="s"
					className="dark:text-white"
				>
					{t("reviews.edition.otherReviewsToComplete")}&nbsp;:
				</Title>

				<Text
					tag="p"
					fontWeight="bold"
					className="!mt-4 dark:text-white"
				>
					{t("dates.forWeek", {
						week: moment(new Date(review.startDate)).week(),
					})}
				</Text>

				{isLoading ? (
					<Spinner className="w-full" />
				) : (
					<>
						{!!data?.results?.length ? (
							<div className="[&>*]:mb-2 gap-4 pl-2 mt-2">
								{data?.results.map((otherReview: IReview) => {
									if (otherReview.id === review.id)
										return null;
									return (
										<Button
											key={otherReview.id}
											className="block "
											color="transparent"
											type="button"
											onClick={() => {
												navigate(
													`/reviews/edit/${otherReview.id}`,
													{
														state: {
															review: otherReview,
															edition: true,
															search: state?.search,
														},
														replace: true,
													}
												);
											}}
										>
											{
												otherReview.contract
													.tempWorkerFullName
											}
										</Button>
									);
								})}
							</div>
						) : (
							<p className="dark:text-white">
								{t("reviews.edition.noOtherReviewsToComplete")}
							</p>
						)}
					</>
				)}
			</div>
		</>
	);
};
