import React from "react";

import { t } from "i18next";
import { useLocation } from "react-router-dom";

import LoginFormContainer from "./LoginFormContainer";
import ResetPassword from "./ResetPassword";
import RetrievePassword from "./RetrievePassword";
import { Title, Text, BoxContent } from "@zolteam/react-ras-library";
import { ContactFormButton } from "components/organisms";
import { AuthLayout } from "components/templates";

import { cn } from "utils";

export const Auth: React.FC = () => {
	const { pathname } = useLocation();

	const getComponent = () => {
		const components = [
			{
				path: "/login",
				component: <LoginFormContainer />,
			},
			{
				path: "/retrieve-password",
				component: <RetrievePassword />,
			},
			{
				path: "/reset-password",
				component: <ResetPassword />,
			},
			{
				path: "/create-password",
				component: <ResetPassword isCreate />,
			},
		];

		return (
			components.find((c) => c.path === pathname)?.component || (
				<LoginFormContainer />
			)
		);
	};

	return (
		<AuthLayout>
			<div className="col justify-between h-full lg:pr-[42%]">
				<Title
					tag="h1"
					lineHeight="m"
					className="pb-6 !leading-7 md:leading-8 !text-md md:text-2xl lg:!text-3xl dark:text-neutral-300"
				>
					{t("auth.title")}
				</Title>
				<Text
					tag="p"
					className="!text-sm !leading-5 lg:!leading-6 mb-4 dark:text-neutral-400"
				>
					{t("auth.text")}
				</Text>
				<BoxContent
					borderBottomLeft
					borderBottomRight
					borderTopLeft
					borderTopRight
					backgroundColor="var(--color-white)"
					boxShadow
					className={cn([
						"relative items-center h-auto my-auto p-4 sm:p-6 md:p-10 md:sp-8 dark:!bg-neutral-900",
						"lg:max-w-[70%] lg:translate-x-1/2 lg:-translate-y-1/2 lg:absolute lg:top-1/2 lg:-right-0",
					])}
				>
					<div className="justify-center gap-4 col">
						{getComponent()}
					</div>
				</BoxContent>
				<div className="pt-4 mt-auto">
					<Text
						tag="p"
						size="paragraph02"
						lineHeight="s"
						className="mb-2 dark:text-neutral-400"
					>
						{t("auth.noIDText")}
					</Text>
					<ContactFormButton />
				</div>
			</div>
		</AuthLayout>
	);
};
