import { t } from "i18next";
import moment from "moment";

import { IContactPersonRemuneration } from "components/types";
import {
	CommercialPropositionFormInitialValues,
	ICommercialPropositionFormValues,
} from "forms/CommercialPropositionForm/CommercialPropositionFormValidation";

import { isObjectEmpty } from "utils";

import { CP_TYPES } from "constants_globals";

export const computeCPFetchedDatas = (
	data: ICommercialPropositionFormValues,
	pricingsTypes: any
) => {
	if (!data) return {};
	const giftedFromRAS = t(
		"commercialPropositions.form.sections.services.giftedFromRAS",
		"fr"
	);

	const getType = (type: string) => {
		const typeKey = CP_TYPES.find(
			(i) => t(`commercialPropositions.types.${i}`) === type
		);
		return typeKey ?? type;
	};

	const values: ICommercialPropositionFormValues = {
		...data,

		isChangeablePackagePrice:
			data.packagePrice !==
			CommercialPropositionFormInitialValues.packagePrice,
		isApplicationFeeOffered: data.applicationFee === giftedFromRAS,
		isMedicalCheckUpOffered: data.medicalCheckUp === giftedFromRAS,

		managementType:
			(data.managementType &&
				pricingsTypes?.tariffConditionsManagement?.find(
					(i: any) => i.id === data.managementType
				)) ??
			null,

		delegationType:
			(data.delegationType &&
				pricingsTypes?.tariffConditionsDelegation?.find(
					(i: any) => i.id === data.delegationType
				)) ??
			null,

		hiringFees:
			data.hiringFees &&
			pricingsTypes?.feesInCaseOfHiring?.find(
				(i: any) => i.id === data.hiringFees
			),

		billingMethod:
			data.billingMethod &&
			pricingsTypes?.billingMethod?.find(
				(i: any) => i.id === data.billingMethod
			),

		deadline:
			data.deadline &&
			pricingsTypes?.deadline?.find((i: any) => i.id === data.deadline),

		contactPersonRemunerations:
			data.interlocutors?.filter(
				(i: IContactPersonRemuneration) => i.isInvoiceInterlocutor
			) || [],
		contactPersonRemunerationsOther:
			data.interlocutors?.filter(
				(i: IContactPersonRemuneration) => !i.isInvoiceInterlocutor
			) || [],

		qualifications: data.qualifications?.map((i) => ({
			...i,
			qualification: i.qualificationName ? i : null,
		})),

		isInterlocutorSignatory:
			data.signatoryInterlocutor?.isInterlocutorSignatory ?? false,
		signatoryClientContactName:
			data.signatoryInterlocutor?.signatoryClientContactName ?? "",
		signatoryClientContactEmail:
			data.signatoryInterlocutor?.signatoryClientContactEmail ?? "",

		subject:
			data.message?.subject ?? t("commercialPropositions.form.subject"),
		body: t("commercialPropositions.form.body", {
			recipient: data.privilegedClientContactName,
			action: data.isInterlocutorSignatory
				? t("commercialPropositions.form.validate")
				: t("commercialPropositions.form.sign"),
		}),
		type: data.type,
		cpType: getType(data.type),
	};
	return values;
};

export const computeCPFormValues = (
	values: ICommercialPropositionFormValues
) => {
	const data: any = {
		...values,
		creationDate: moment(values.creationDate, t("dates.default")).format(
			t("dates.apiFull").toString()
		),
		type: t(`commercialPropositions.types.${values.cpType}`),
		agency: values.agency?.id ?? values.agency ?? null,
		agencyTempoFolder: values.agencyTempoFolder ?? null,
		delegationType: isObjectEmpty(values.delegationType)
			? null
			: values.delegationType?.id ?? values.delegationType,
		delegationTypeName: isObjectEmpty(values.delegationType)
			? null
			: values.delegationType?.name ?? values.delegationType,
		managementType: isObjectEmpty(values.managementType)
			? null
			: values.managementType?.id ?? values.managementType,
		managementTypeName: isObjectEmpty(values.managementType)
			? null
			: values.managementType?.name ?? values.managementType,

		billingMethod: values.billingMethod?.id ?? values.billingMethod,

		deadline: values.deadline?.id ?? values.deadline,
		hiringFees: values.hiringFees?.id ?? values.hiringFees,
		hiringFeesName: values.hiringFees?.name ?? (values.hiringFees || ""),

		qualifications: values.qualifications?.map((i) => ({
			...(i.qualification || {}),
			...i,
		})),

		interlocutors: [
			...(values.contactPersonRemunerations || []),
			...(values.contactPersonRemunerationsOther || []),
		].map((i) => ({
			...i,
			isInvoiceInterlocutor: i.isInvoiceInterlocutor,
		})),
	};

	delete data.signatoryInterlocutor;
	delete data.contactPersonRemunerations;
	delete data.contactPersonRemunerationsOther;

	delete data.message;

	return data;
};
