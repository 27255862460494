import { t } from "i18next";

import { TimesheetInfos } from "components/molecules";
import {
	IDashboardTile,
	ITileListItemProps,
	ITimeSheet,
} from "components/types";

import {
	TIMESHEET_EDIT_PERMISSION,
	TIMESHEET_LIST_PERMISSION,
} from "constants_globals";

export const DashboardTimeSheetTile = (): IDashboardTile => {
	const handleItemClick = (
		item: ITimeSheet,
		{ appState, navigate }: ITileListItemProps
	) => {
		if (
			!appState.auth.permissions.find(
				(permission: string) => permission === TIMESHEET_EDIT_PERMISSION
			)
		)
			return;
		navigate(`/timesheets/edit/${item.id}`, {
			state: {
				timesheet: { ...item },
			},
		});
	};
	return {
		title: t("timesheets.title"),
		subtitle: t("timesheets.tileSubtitle"),
		icon: "menuTimesheets",
		linkTo: "/timesheets",
		itemsKey: "timeSheets",
		listItem: {
			main: "tempWorkerFullName",
			secondary: "qualification",
			info: (item: ITimeSheet) => (
				<TimesheetInfos
					startDate={item.startDate}
					endDate={item.endDate}
				/>
			),
			noValuePicto: "dashboardNoData-timesheets",
			noValueText: t("timesheets.noPending"),
			onClick: handleItemClick,
		},
		chart: {
			valueKey: "timeSheetAdvancement.pendingTimeSheetNumber",
			totalKey: "timeSheetAdvancement.totalTimeSheetNumber",
			title: t("timesheets.gaugeTitle"),
			text: t("timesheets.pendingText"),
			noValueText: t("timesheets.noPending"),
			onClickLabel: "/timesheets/list?status=waiting",
		},
		permissions: [TIMESHEET_LIST_PERMISSION],
		maxItems: 3,
	};
};
