import React, { useEffect } from "react";

import { t } from "i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { promiseToast } from "toastify";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import TimesheetsService from "services/TimeSheetsService";

import useAppContext from "store/useAppContext";

import { Title } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import { TimesheetEditSideContent } from "components/organisms";
import { PageLayout } from "components/templates";
import { TimesheetEditForm } from "forms";

import {
	convertDaysBonusesHundreds,
	convertDaysBonusesString,
	defineWeekDays,
} from "utils";

import {
	HUNDREDS_MODE,
	TIMESHEET_EDIT_PERMISSION,
	TIMESHEET_ENTRY_MODE_STORAGE_KEY,
	TIMESHEET_VALIDATE_PERMISSION,
} from "constants_globals";

export interface ITimesheetEditProps {
	timesheetId?: string;
}

export const TimesheetEdit: React.FC<ITimesheetEditProps> = ({
	timesheetId,
}) => {
	const { hasPermissions } = useAppContext();
	const { state } = useLocation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const timesheet = state?.timesheet;
	const { id: timeSheetIdFromParams } = useParams();
	const [EntryMode, setEntryMode] = React.useState(
		localStorage.getItem(TIMESHEET_ENTRY_MODE_STORAGE_KEY) ?? "hours"
	);

	const currentTimeSheetId =
		timesheetId ?? timeSheetIdFromParams ?? timesheet?.id;

	const handleSave = (type: string, values: any, formikHelpers: any) => {
		const data = {
			...timesheet,
			...convertDaysBonusesHundreds(
				values.tsDays,
				values.tsBonuses,
				EntryMode === HUNDREDS_MODE
			),
			comment: values.comment,
			orderReference: values.orderReference,
			...(values?.updateComment && {
				updateComment: values.updateComment,
			}),
		};
		const prom = (
			type === "submit"
				? TimesheetsService.timesheetValidate
				: TimesheetsService.timesheetEdit
		)(currentTimeSheetId ?? timesheet.id, data).then(() => {
			queryClient.invalidateQueries({
				queryKey: ["timesheet", currentTimeSheetId],
			});
		});
		promiseToast(prom, {
			success: t("promiseToast.dataSaveSuccess"),
		});
		return prom;
	};

	useEffect(() => {
		if (!currentTimeSheetId) {
			navigate("/timesheets" + (state?.search || ""), { replace: true });
		}
	}, [currentTimeSheetId, navigate, state]);

	const fetchTimesheet = (abortSignal: AbortSignal) => {
		return TimesheetsService.getTimesheet(
			timesheet?.id ?? currentTimeSheetId,
			abortSignal
		).then((resp) => {
			if (resp.isFromSTS) {
				setEntryMode(HUNDREDS_MODE);
				localStorage.setItem(
					TIMESHEET_ENTRY_MODE_STORAGE_KEY,
					HUNDREDS_MODE
				);
			}
			return {
				...resp,
				...convertDaysBonusesString(
					resp.days,
					resp.bonuses,
					resp.isFromSTS ? true : EntryMode === HUNDREDS_MODE
				),
			};
		});
	};

	const { isLoading, isFetching, data } = useQuery({
		queryKey: ["timesheet", currentTimeSheetId],
		queryFn: ({ signal }) => fetchTimesheet(signal),
		enabled: !!currentTimeSheetId,
		refetchOnWindowFocus: false,
		placeholderData: timesheet ?? {},
	});

	return (
		<PageLayout
			title={
				<>
					<Title
						tag="h1"
						size="display02"
						className="max-md:!text-[2.25rem] max-md:!leading-10 dark:text-white"
					>
						{data?.tempWorkerFullName ??
							state?.timesheet?.tempWorkerFullName ??
							(isLoading ? <Spinner /> : "Relevé d'heures")}
					</Title>
					{(data?.isFromSTS || timesheet?.isFromSTS) && (
						<div
							className={
								"text-sm text-primary-500 font-medium dark:text-neutral-300"
							}
						>
							{t("timesheets.stsModal.isSTS")}
						</div>
					)}
				</>
			}
			headerProps={{
				backLabel: t("timesheets.back"),
				onBack: () => {
					navigate("/timesheets" + (state?.search || ""));
				},
				className:
					"pb-4 md:pb-8 [&>div]:flex [&_button]:mb-0 [&>div]:flex-wrap [&>div]:row [&>div]:gap-2",
			}}
			className="min-h-[calc(100vh-72px)]"
		>
			<div className="flex flex-col h-full xl:gap-8 xl:flex-row">
				<div className="h-fit w-full gap-8 top-0 p-8 bg-neutral-100 dark:bg-black xl:bg-neutral-200 col xl:rounded-r-3xl min-w-[200px] xl:max-w-[300px] max-w-full text-sm">
					{isLoading ? (
						<Spinner className="w-full" />
					) : (
						<TimesheetEditSideContent timesheet={data} />
					)}
				</div>

				<div className="w-full h-full gap-8 p-8 overflow-hidden text-sm bg-white shadow-xl dark:bg-black xl:rounded-tl-3xl col">
					{isLoading || isFetching ? (
						<Spinner className="w-full" />
					) : data ? (
						<TimesheetEditForm
							timesheet={data}
							weekDays={defineWeekDays(
								data?.startDate,
								data?.days
							)}
							onSubmit={(values, helpers) =>
								handleSave("submit", values, helpers)
							}
							onSave={(values, helpers) =>
								handleSave("save", values, helpers)
							}
							timeMode={EntryMode}
							onTimeModeChange={setEntryMode}
							locked={
								!hasPermissions([
									TIMESHEET_EDIT_PERMISSION,
									TIMESHEET_VALIDATE_PERMISSION,
								])
							}
						/>
					) : null}
				</div>
			</div>
		</PageLayout>
	);
};
