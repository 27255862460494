// tiles import
import { DashboardContractsTile } from "./DashboardContractsTile";
import { DashboardInvoicesTile } from "./DashboardInvoicesTile";
import { DashboardReviewsTile } from "./DashboardReviewsTile";
import { DashboardTimeSheetTile } from "./DashboardTimeSheetTile";
import { IDashboardTile } from "components/types";

// gen export
const DashboardTiles: IDashboardTile[] = [
	DashboardContractsTile(),
	DashboardTimeSheetTile(),
	DashboardReviewsTile(),
	DashboardInvoicesTile(),
];

export default DashboardTiles;
