import React from "react";

import { t } from "i18next";
import moment from "moment";

import { Picto } from "@zolteam/react-ras-library";
import { IThreadSummary } from "components/types";

import { cn } from "utils";

import { STATUS_UNREAD } from "constants_globals";

export interface IThreadListItemProps {
	thread: IThreadSummary;
	isActive?: boolean;
	onClick?: () => void;
}

export const ThreadListItem: React.FC<IThreadListItemProps> = ({
	thread,
	isActive,
	onClick,
}) => {
	return (
		<button
			onClick={onClick}
			className={
				"text-left items-center w-full gap-2 pl-0 p-4 text-sm font-light row text-neutral-600 dark:text-neutral-300 justify-between"
			}
		>
			<div className="items-center w-full gap-3 row max-w-[calc(100%-1.25rem)]">
				<div>
					<Picto
						icon="circle"
						className={cn([
							"!w-3 text-primary-500",
							thread.status === STATUS_UNREAD
								? "text-primary-500"
								: "text-transparent",
						])}
					/>
				</div>
				<div className="gap-1 overflow-hidden col">
					<p
						className={cn([
							"text-ellipsis overflow-hidden w-full whitespace-nowrap",
							isActive && "font-semibold",
						])}
					>
						{thread.subject}
					</p>
					<p>{thread.lastMessageAuthorName}</p>
					<p>{thread.agencyName}</p>
					<p className="text-xs">
						{moment(thread.lastMessageCreatedAt).format(
							t(
								`dates.${
									moment(thread.lastMessageCreatedAt).isSame(
										new Date(),
										"year"
									)
										? "shortAt"
										: "mediumAt"
								}`
							)
						)}
					</p>
				</div>
			</div>
			<div className="w-5">
				{isActive && (
					<Picto
						icon="chevronRight"
						className="w-5 text-neutral-400"
					/>
				)}
			</div>
		</button>
	);
};
