import React from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";
import { Trans } from "react-i18next";

import { useQuery } from "@tanstack/react-query";

import CommercialPropositionService from "services/CommercialPropositionsService";

import { InfoMessage, Picto } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import { ConfirmModal } from "components/molecules";
import { ICommercialPropositionFormValues } from "forms/CommercialPropositionForm/CommercialPropositionFormValidation";

import { cn } from "utils";

interface IClientInfosFileModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSelect: (option: any) => void;
}

export const ClientInfosFileModal: React.FC<IClientInfosFileModalProps> = ({
	isOpen,
	onClose,
	onSelect,
}) => {
	const { values } = useFormikContext<ICommercialPropositionFormValues>();

	const fetchClientFile = () =>
		CommercialPropositionService.getClientsContact({
			clientTempoId: values.clientCode,
			agencyTempoDirectory: values.agencyTempoFolder,
			sorting: "tempoFullName",
		}).then((resp) => resp?.items || []);

	const { isLoading, data } = useQuery({
		queryKey: ["clientsContact", values.clientCode],
		queryFn: fetchClientFile,
		enabled: !!values.clientCode,
	});

	return (
		<ConfirmModal
			title={t(
				"commercialPropositions.form.sections.company.interlocutors"
			)}
			text={t(
				"commercialPropositions.form.sections.company.interlocutorsModalInfo"
			)}
			buttons={["cancel"]}
			isOpen={isOpen}
			onClose={() => {
				onClose();
			}}
			onConfirm={() => {}}
			size="s"
			cancelText={t("global.close")}
		>
			{!values.clientCode ? (
				<div className="[&>div]:w-full">
					<InfoMessage color="error" withIcon>
						<Trans
							i18nKey={
								"commercialPropositions.form.sections.company.interlocutorsModalInfoError"
							}
						/>
					</InfoMessage>
				</div>
			) : (
				<>
					<div className="mb-4">
						{t(
							"commercialPropositions.form.sections.company.interlocutorsModalInfo"
						)}
					</div>
					{isLoading ? (
						<Spinner />
					) : (
						<div className="[&>div:not(:last-child)]:border-b [&>div:not(:last-child)]:border-neutral-100">
							{!data?.length && (
								<div className="py-1 text-sm text-neutral-500">
									{t(
										"commercialPropositions.form.sections.company.noInterlocutors"
									)}
								</div>
							)}
							{data?.map((contact: any) => {
								return (
									<div key={contact.id} className="py-1">
										<ClientInfos
											contact={contact}
											onClick={() => {
												onSelect(contact);
											}}
										/>
									</div>
								);
							})}
						</div>
					)}
				</>
			)}
		</ConfirmModal>
	);
};

export interface IClientInfos {
	fullName?: string;
	phone: string;
	email: string;
}

interface IClientInfosProps {
	onClick?: () => void;
	contact: IClientInfos;
	className?: string;
}

export const ClientInfos: React.FC<IClientInfosProps> = ({
	onClick,
	contact,
	className,
}) => {
	const Tag = onClick ? "button" : "div";
	return (
		<Tag
			className={cn([
				"items-start w-full gap-2 p-2 rounded-md hover:bg-neutral-100 dark:hover:bg-neutral-900 row",
				className,
			])}
			onClick={onClick}
		>
			<Picto
				icon="user"
				className={cn(["w-4 h-4", !!contact.fullName && "mt-1"])}
			/>
			<div className="text-left">
				<b>{contact.fullName}</b>
				<div className="text-xs text-neutral-500">
					{!!contact.phone && <div>{contact.phone}</div>}
					{!!contact.email && <div>{contact.email}</div>}
				</div>
			</div>
		</Tag>
	);
};
