export const roundNumber = (num: number, number = false) => {
	if (!num) return 0;
	const roundedNumber = num.toFixed(2);
	return !number ? roundedNumber : parseFloat(roundedNumber);
};

export function isNumber(value: any) {
	const number = Number(value);

	return !isNaN(number) && String(value) === String(number);
}
