// WILL BE USED IN A NEXT PR
import React, { useMemo } from "react";

import { Picto } from "@zolteam/react-ras-library";
import { IColHeaderProps, IRowProps } from "components/types";

import { cn } from "utils";

const genRow = (props: any, type: string, order: string, item?: any) => {
	const prop = props[type];

	if (!prop) return false; // if no prop or prop === false, return null
	if (prop && typeof prop !== "boolean" && order) {
		return typeof prop[order] === "function" // if prop is a function, call it with item as argument
			? prop[order](item)
			: prop[order] ?? false;
	}
	return props ?? false;
};

export const MemoizedHeaderRow: React.FC<IColHeaderProps> = ({
	columns,
	props,
	sortableHeader,
	sortOptions,
}) => {
	return useMemo(
		() => (
			<tr className="[&>th]:!font-light [&>th]:py-5 text-xs text-left text-neutral-400 dark:text-neutral-500 border-b border-neutral-100 dark:border-neutral-800">
				{genRow(props, "header", "before")}
				{columns.map((column) => {
					const isHidden =
						typeof column.hidden === "function"
							? column.hidden(props.rowProps)
							: column.hidden;
					const columnName = column.sortKey || column.value;
					return (
						(!isHidden && (
							<th
								key={column.value || column.name}
								className={cn(["px-2", column.headerClassName])}
							>
								<div
									className={cn([
										"items-center gap-2 row",
										sortableHeader &&
											props.onColumnClick &&
											column.sortable !== false &&
											"cursor-pointer",
										column.sortable !== false &&
											sortableHeader &&
											sortOptions?.sortBy ===
												columnName &&
											"text-primary-500",
									])}
									onClick={() =>
										column.sortable !== false &&
										props.onColumnClick?.(column)
									}
								>
									{column.headerRender
										? column.headerRender(
												column,
												props.rowProps
										  )
										: column.name}
									{sortableHeader &&
										column.sortable !== false && (
											<Picto
												icon="chevronFilter"
												className={cn([
													"w-4 h-4 duration-300",
													sortOptions?.sortBy ===
														columnName &&
														sortOptions?.sortOrder ===
															"desc" &&
														"!transform rotate-180",
												])}
											/>
										)}
								</div>
							</th>
						)) ||
						null
					);
				})}
				{genRow(props, "header", "after")}
			</tr>
		),
		[columns, props, sortableHeader, sortOptions]
	);
};

export const MemoizedBodyRow: React.FC<IRowProps> = ({
	row,
	columns,
	props,
	className,
	onRowClick,
}) => {
	return useMemo(() => {
		const rowClassName =
			typeof props.rowProps?.className === "function"
				? props.rowProps?.className(row)
				: props.rowProps?.className;

		return (
			<tr
				key={row.id}
				className={cn([
					"items-center w-full border-b [&:last-child]:border-none border-neutral-150 dark:border-neutral-800 hover:bg-neutral-50 dark:hover:bg-neutral-700 dark:text-white",
					className,
					rowClassName,
					onRowClick && "cursor-pointer",
				])}
				onClick={() => onRowClick?.(row)}
			>
				{genRow(props, "row", "before", row)}
				{columns.map((column) => {
					const isHidden =
						typeof column.hidden === "function"
							? column.hidden(props.rowProps)
							: column.hidden;
					return (
						(!isHidden && (
							<td
								key={column.value}
								className={cn([
									"py-6 px-2",
									rowClassName,
									column.className,
								])}
							>
								{column.render
									? column.render(row, props.rowProps)
									: row[column.value]}
							</td>
						)) ||
						null
					);
				})}
				{genRow(props, "row", "after", row)}
			</tr>
		);
	}, [columns, row, props, className, onRowClick]);
};
