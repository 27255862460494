import React from "react";

import { Form, Formik, useFormikContext } from "formik";
import { t } from "i18next";
import { toast } from "react-toastify";
import { promiseToast } from "toastify";

import { useQueryClient } from "@tanstack/react-query";

import UserService from "services/UserService";

import useAppContext from "store/useAppContext";

import { sortSettingsResults } from "./UserNotificationsUtils";
import { Picto, Title, Select } from "@zolteam/react-ras-library";
import { Toggle } from "components/atoms";
import { AsyncList } from "components/organisms";

import { cn, daysList } from "utils";

import { hoursList } from "constants_globals";

export interface IUserNotificationsProps {}

export interface INotificationSetting {
	active: boolean;
	code: string;
	defaultSettingId: number;
	editable: string[];
	hour: number;
	id: number;
	name: string;
	openMonthDay: number | null;
	weekDay: number;
	rowGroup?: string;
	section?: any;

	[key: string]: any; // needed to be able to index the object when updating values
}

interface INotificationSettingsFormValues {
	[key: string]: any;
}

const SettingsFormValues: INotificationSettingsFormValues = {};

export const UserNotifications: React.FC<IUserNotificationsProps> = () => {
	const queryClient = useQueryClient();
	const lastToastId = React.useRef<string | null>(null);

	const fetchedSettings = React.useRef<INotificationSetting[]>([]);
	const { hasPermissions } = useAppContext();

	const fetchSettings = () => {
		return UserService.getUserNotificationSettings().then((resp) => {
			const res = sortSettingsResults(resp, hasPermissions);
			fetchedSettings.current = res;
			return res;
		});
	};

	const updateValues = (values: any) => {
		const newValues = [...fetchedSettings.current];
		let changeCount = 0;

		if (lastToastId.current) toast.dismiss(lastToastId.current);
		for (const key in values) {
			const [code, type] = key.split("-");
			const index = newValues.findIndex((v) => v.code === code);
			if (index !== -1) {
				if (newValues[index][type] !== values[key]) changeCount++;
				newValues[index][type] = values[key];
			}
		}

		if (!changeCount) return;
		const filtered = newValues.filter((v) => !v.section);

		const prom = UserService.setUserNotificationSettings({
			notificationSettingsUser: filtered,
		}).then(() => {
			return queryClient.refetchQueries({
				queryKey: ["user-notifications"],
			});
		});

		const toastId = `user-notifications-settings${Date.now()}`;
		lastToastId.current = toastId;
		promiseToast(
			prom,
			{},
			{
				toastId: toastId,
			}
		);

		return prom;
	};

	return (
		<div className="w-full overflow-hidden col">
			<Title tag="h2" className="dark:text-white">
				{t("notificationsSettings.title")}
			</Title>

			<Formik
				initialValues={SettingsFormValues}
				onSubmit={() => {}}
				validate={(values) => {
					return updateValues(values);
				}}
			>
				{({ values, setFieldValue, isValidating }) => {
					return (
						<Form>
							<AsyncList
								className={cn([
									"[&_td]:py-3 [&_td]:w-fit [&_table]:min-w-[200px]",
								])}
								selectable={false}
								header={false}
								rowProps={{
									className: (item: any) =>
										item.section && "hover:bg-transparent",
								}}
								columns={[
									{
										name: "",
										value: "icon",
										className:
											"hidden md:table-cell w-[70px]",
										headerClassName:
											"hidden md:table-cell w-[70px]",
										render: (item) =>
											item.section && (
												<div className="pt-2">
													<Picto
														icon={
															item.section.picto
														}
													/>
												</div>
											),
									},
									{
										name: "",
										value: "name",
										className:
											"min-w-0 whitespace-break-spaces",
										render: (item) => (
											<>
												{item.section ? (
													<div className="items-center gap-8 row">
														<Picto
															className="inline-block md:hidden"
															icon={
																item.section
																	.picto
															}
														/>
														<b className="block md:pt-2">
															{item.section.text}
														</b>
													</div>
												) : (
													item.name
												)}
											</>
										),
									},
									{
										name: t(
											"notificationsSettings.beNotified"
										),
										value: "active",
										render: (item) => {
											if (item.section) return null;
											const key = `${item.code}-active`;
											return (
												<Toggle
													checked={
														values[key] ??
														item.active
													}
													disabled={
														isValidating ||
														!item.editable.includes(
															"active"
														)
													}
													name={`${item.code}-active`}
													onChange={(e) => {
														setFieldValue(
															`${item.code}-active`,
															e.target.checked
														);
													}}
												/>
											);
										},
									},
									{
										name: t(
											"notificationsSettings.sendDate"
										),
										value: "date",
										className: "min-w-[200px]",
										render: (item) =>
											!item.section && (
												<DateCell item={item} />
											),
									},
								]}
								query={{
									queryFn: fetchSettings,
									queryKey: ["user-notifications"],
									refetchOnWindowFocus: "always",
								}}
							/>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

interface IDateCellProps {
	item: INotificationSetting;
}

const getSelectTimeLabel = (setting: INotificationSetting) => {
	if (setting.openMonthDay === 1) return t("dates.firstOpenDayOfMonth");

	if (setting.editable.includes("weekDay")) return t("dates.at");

	switch (setting.weekDay) {
		case 0:
			return t("dates.everyDayAt");
		case 1:
			return t("dates.everyMondayAt");

		case 2:
			return t("dates.everyTuesdayAt");

		default:
			return t("dates.every");
	}
};

const DateCell: React.FC<IDateCellProps> = ({ item }) => {
	const { setFieldValue, isValidating } = useFormikContext<any>();

	if (!item) return null;

	const hourValue = hoursList.find((h) => h.raw === item.hour);

	const dayValue = daysList.find((d) => d.value === item.weekDay);

	return (
		<div className="gap-1 col [&_.labelSelect]:text-ellipsis [&_.labelSelect]:w-[90%] [&_.labelSelect]:overflow-hidden">
			{item.editable.includes("weekDay") && (
				<Select
					className={cn([dayValue && "activeSelect"])}
					label={t("dates.every")}
					name={`${item.code}-weekDay`}
					value={dayValue}
					onChange={(value: any) =>
						setFieldValue(`${item.code}-weekDay`, value.value)
					}
					disabled={isValidating}
					options={daysList}
				/>
			)}
			{item.editable.includes("hour") && (
				<Select
					label={getSelectTimeLabel(item)}
					className={cn([hourValue && "activeSelect"])}
					name={`${item.code}-hour`}
					value={hourValue}
					onChange={(value: any) => {
						setFieldValue(`${item.code}-hour`, value.raw);
					}}
					disabled={isValidating}
					options={hoursList}
				/>
			)}
		</div>
	);
};
