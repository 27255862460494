import React from "react";

import { t } from "i18next";
import moment from "moment";

import { Tag } from "@zolteam/react-ras-library";
import { IContract, TContractStatus } from "components/types";

import {
	CONTRACT_STATUS_CANCELLED,
	CONTRACT_STATUS_SIGNED,
	CONTRACT_STATUS_WAITING,
	CONTRACT_STATUS_WAITING_OUT_OF_CLOUD,
} from "constants_globals";

interface IContractStatusProps {
	item: IContract;
}

export const ContractStatus: React.FC<IContractStatusProps> = ({ item }) => {
	const genText = () => {
		if (item.status === CONTRACT_STATUS_SIGNED) return;
		if (
			item.status === CONTRACT_STATUS_WAITING &&
			item.signatureLateSince > 0
		)
			return (
				<div className="text-xs text-neutral-600">
					{t("contracts.daysLateSince", {
						count: item.signatureLateSince,
						date: moment(item.startDate)
							.add({
								days: 2,
							})
							.format(t("dates.default")),
					})}
				</div>
			);
	};

	return (
		<div className="gap-1 col">
			{item.format === "zoe" &&
			item.status !== CONTRACT_STATUS_CANCELLED ? (
				<a href={item.link} target="_blank" rel="noopener noreferrer">
					<ContractStatusTag item={item} />
				</a>
			) : (
				<ContractStatusTag item={item} />
			)}

			{genText()}
		</div>
	);
};

const ContractStatusTag: React.FC<{
	item: IContract;
}> = ({ item }) => {
	const getState = () => {
		const status: TContractStatus[] = [
			[
				CONTRACT_STATUS_SIGNED,
				"grey",
				item.format === "zoe"
					? t("contracts.signed")
					: t("contracts.signedOutsideMyTemp"),
			],
			[CONTRACT_STATUS_WAITING, "primary", `${t("contracts.waiting")}`],
			[
				CONTRACT_STATUS_WAITING_OUT_OF_CLOUD,
				"warning",
				t("contracts.waiting_out_of_cloud"),
			],
			[CONTRACT_STATUS_CANCELLED, "error", t("contracts.cancelled")],
		];

		const found = status.find((a) => a[0] === item.status);
		if (found)
			return {
				color: found[1],
				text: found[2],
			};
		return false;
	};

	const state = getState();
	if (!state) return null;
	return (
		<Tag className="block text-center w-fit" color={state.color}>
			{state.text}
		</Tag>
	);
};
