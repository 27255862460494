import React from "react";

import * as Sentry from "@sentry/react";
import { ThemeContextProvider } from "contexts/Theme/ThemeContext";
import moment from "moment";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { I18nextProvider } from "react-i18next";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import SessionService from "services/SessionService";

import App from "./App";
import i18n from "./i18n/i18n";
import MomentLocaleFR from "./moment/moment-fr";
import "./styles/index.scss";

import { IS_DEV } from "constants_globals";

// Sentry initialization
if (process.env.REACT_APP_SENTRY_DSN && !IS_DEV)
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_ENV,

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		tracesSampleRate: 1.0,

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		release: "myTemp@" + process.env.REACT_APP_VERSION,
	});

const queryClient = new QueryClient();

moment.updateLocale("fr", MomentLocaleFR);

// used to prevent infinite loop when connecting as a user with the new router
SessionService.isSwitchingUser(false);

// Set the lang attribute of the html tag to the current language
// This is used by the browser to determine the language of the page
/*
	Corrige aussi l'erreur "Erreur : échec de l'exécution de "insertBefore" sur "Node" :
	le noeud avant lequel le nouveau naud doit être inséré n'est pas un enfant de ce naud.",
	qui se produit lorsque google translate essaie de remplacer le contenu de la page
	alors que le contenu n'est pas encore chargé ou qu'il a changé
*/
document.documentElement.setAttribute(
	"lang",
	localStorage.getItem("i18nextLng") ?? "fr-FR"
);

// Create a root for the app
const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<QueryClientProvider client={queryClient}>
				<ThemeContextProvider>
					<HelmetProvider>
						<App />
					</HelmetProvider>
				</ThemeContextProvider>
			</QueryClientProvider>
		</I18nextProvider>
	</React.StrictMode>
);

/*
	the next lines are used to prevent the types errors comming from @zolteam/react-ras-library
	exemple: "Warning: Tooltip: Support for defaultProps will be removed from function components in a future major release. Use JavaScript default parameters instead."
*/
const error = console.error;
console.error = (...args: any) => {
	if (/defaultProps/.test(args[0])) return;
	error(...args);
};
