import * as Sentry from "@sentry/react";

import api from "./api";
import { ITimeSheet, ITimeSheetFilters, TId } from "components/types";

import {
	CONFIG_EXPORT_PDF,
	CONFIG_EXPORT_XLSX,
	CONFIG_EXPORT_ZIP,
} from "constants_globals";

// Adjust the import path as needed

class TimesheetsServiceClass {
	timesheetSearch = (data: ITimeSheetFilters, signal?: AbortSignal) =>
		api
			.post(`/timesheet/search`, data, {
				signal,
			})
			.then((resp) => resp?.data);

	timesheetEdit = (id: TId, data: ITimeSheet) =>
		api.post(`/timesheet/${id}`, data).then((resp) => resp?.data);

	timesheetValidate = (id: TId, data: ITimeSheet) =>
		api.post(`/timesheet/${id}/validate`, data).then(
			(resp) => resp?.data,
			(error) => {
				Sentry.captureException(error, {
					tags: {
						section: "datatimesheets",
					},
					extra: {
						...data,
						raw: JSON.stringify(data),
						error: error,
						rawError: JSON.stringify(error),
					},
				});
				throw error;
			}
		);

	timesheetExportBySearch = (data: ITimeSheetFilters) =>
		api.post(`/timesheet/export-xlsx-by-search`, data, CONFIG_EXPORT_XLSX);

	timesheetDownload = (id: TId) =>
		api.get(`/timesheet/${id}/download`, CONFIG_EXPORT_PDF);

	timesheetDowloadIds = (data: FormData) =>
		api.post("/timesheet/download-by-ids", data, {
			...CONFIG_EXPORT_PDF,
			headers: {
				...CONFIG_EXPORT_PDF.headers,
				"Content-Type": "application/form-data",
			},
		});

	downloadCalendar = (id: TId) =>
		api.get(`/timesheet/${id}/download-calendar`, CONFIG_EXPORT_PDF);

	downloadCalendarsZip = (id: TId) =>
		api.get(
			`/timesheet/${id}/download-multiple-calendar`,
			CONFIG_EXPORT_ZIP
		);

	timesheetC1bDownload = (id: TId) =>
		api.get(`/timesheet/${id}/download-c1b`, CONFIG_EXPORT_PDF);

	timesheetC1bMultiDownload = (id: TId) =>
		api.get(`/timesheet/${id}/download-multiple-c1b`, CONFIG_EXPORT_ZIP);

	timesheetC1bType = (id: TId) =>
		api
			.get(`/timesheet/${id}/download-c1b/type`)
			.then((resp) => resp?.data);

	getTimesheet = (id: TId, signal?: AbortSignal) =>
		api
			.get(`/timesheet/${id}`, {
				signal,
			})
			.then((resp) => resp?.data);

	getTimesheetContractBonuses = (id: TId) =>
		api.get(`/timesheet/${id}/contract-bonuses`).then((resp) => resp?.data);

	timesheetAdvancement = () =>
		api.get(`/timesheet/advancement`).then((resp) => resp?.data);

	timesheetImportFromSts = (id: TId, data: ITimeSheet) =>
		api
			.post(`/timesheet/${id}/acknowledge-import-from-sts`, data)
			.then((resp) => resp?.data);
}

const TimesheetsService = new TimesheetsServiceClass();
export default TimesheetsService;
