import React from "react";

import { t } from "i18next";
import { Trans } from "react-i18next";

import { ConfirmModal } from "components/molecules";

export interface IRemoveTimesheetBonusModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	bonusName: string | any;
}

export const RemoveTimesheetBonusModal: React.FC<
	IRemoveTimesheetBonusModalProps
> = ({ isOpen, onClose, onConfirm, bonusName }) => {
	return (
		<ConfirmModal
			title={t("timesheets.bonuses.removeTitle")}
			onConfirm={onConfirm}
			size="s"
			isOpen={isOpen}
			onClose={onClose}
		>
			<Trans
				i18nKey={"timesheets.bonuses.removeText"}
				values={{
					name: bonusName,
				}}
			/>
		</ConfirmModal>
	);
};
