import React, { useState } from "react";

import { t } from "i18next";
import moment from "moment";
import { toast } from "react-toastify";

import ContractsService from "services/ContractsService";
import ThreadService from "services/ThreadsService";

import { useList } from "hooks/useList";
import useAppContext from "store/useAppContext";

import { contractItemActions } from "./ContractItemActions";
import { CONTRACTS_COLUMNS } from "./ContractsColumns";
import { Button, Picto } from "@zolteam/react-ras-library";
import {
	AsyncList,
	ContactAgencyModal,
	ContractsFilters,
} from "components/organisms";
import { PageLayout } from "components/templates";
import { IContract } from "components/types";
import { IContactAgencyFormValues } from "forms";

import { handleFileSave, selectedItems } from "utils";

export const Contracts: React.FC = () => {
	const useListHook = useList<IContract>({
		defaultSort: "date",
	});

	const {
		Pagination,
		setPagintation,
		Filters,
		setFilter,
		changeFilters,
		getFiltersKeys,
		getPaginationKeys,
		IsExporting,
		setIsExporting,
		Selected,
	} = useListHook;

	const { appState, getAgencies, getSites } = useAppContext();

	const activeAgenciesIds = getAgencies(true).map((a) => a.id);

	const [ContractToSend, setContractToSend] = useState<IContract | undefined>(
		undefined
	);
	const [ShowContactAgencyModal, setShowContactAgencyModal] = useState(false);

	const fetchContracts = (abortSignal?: AbortSignal) => {
		return ContractsService.contractSearch(
			{
				page: Pagination.currentPage,
				limit: Pagination.pageSize,
				agencyIds: activeAgenciesIds ?? [],
				...Filters,
				freeSearch: Filters.search || undefined,
			},
			abortSignal
		).then((resp) => {
			setPagintation({
				...Pagination,
				totalCount: resp.search.total,
			});
			return resp;
		});
	};

	const handleDownloadSelected = () => {
		var bodyFormData = new FormData();
		bodyFormData.append(
			"ids",
			JSON.stringify({ ids: Selected.map((s) => s.id.toString()) })
		);
		toast.loading(t("promiseToast.fetching"), {
			toastId: `contracts-download`,
		});
		setIsExporting(true);
		const prom = ContractsService.contractDownloadIds(bodyFormData).finally(
			() => {
				setIsExporting(false);
				toast.dismiss(`contracts-download`);
			}
		);
		handleFileSave(
			prom,
			`contrats-${moment().format(t("dates.full"))}.zip`
		);
	};

	const handleExport = () => {
		const activeAgenciesIds = appState.auth.agencies
			? selectedItems(appState.auth.agencies, "active")
			: [];

		setIsExporting(true);
		const prom = ContractsService.contractExport({
			...Filters,
			freeSearch: Filters.search || undefined,
			agencyIds: activeAgenciesIds,
			page: Pagination.currentPage,
		}).finally(() => setIsExporting(false));

		handleFileSave(
			prom,
			`contrats-${moment().format(t("dates.full"))}.xlsx`
		);
	};

	const handleSendContact = (values: IContactAgencyFormValues) => {
		const prom = ThreadService.postThreadFromOther({
			...values,
		}).then(() => {
			setShowContactAgencyModal(false);
			setContractToSend(undefined);
		});

		return prom;
	};

	return (
		<PageLayout
			title={t("contracts.title")}
			headerProps={{
				className:
					"[&>div:last-child]:w-full [&>div:last-child]:xs:w-auto  [&>div:last-child]:ml-auto",
				children: (
					<Button
						outline
						color="primary"
						type="button"
						onClick={handleExport}
						className="w-full"
						isLoading={IsExporting}
					>
						<Picto icon="download" />
						{t("global.exportList")}
					</Button>
				),
			}}
		>
			<AsyncList
				query={{
					queryKey: [
						"Contracts",
						activeAgenciesIds.join(","),
						...getPaginationKeys(),
						...getFiltersKeys(),
					],
					queryFn: ({ signal }: any) => fetchContracts(signal),
					retry: 0,
				}}
				className="[&_td]:py-4"
				columns={CONTRACTS_COLUMNS}
				itemsKey="results"
				scrollParentSelector="AdminLayout__header"
				actions={[
					{
						label: t("contracts.download", {
							count: Selected?.length,
						}),
						icon: "download",
						onClick: handleDownloadSelected,
						disabled: !Selected?.length || IsExporting,
						isLoading: IsExporting,
					},
				]}
				itemActions={contractItemActions({
					handleContact: (item) => {
						setContractToSend(item);
						setShowContactAgencyModal(true);
					},
				})}
				filters={
					<ContractsFilters
						filters={Filters}
						setFilter={setFilter}
						setDates={(dates) => {
							changeFilters({
								...dates,
							});
						}}
					/>
				}
				selectable={(item) => item.format === "zoe"}
				useListHook={useListHook}
			/>
			<ContactAgencyModal
				initialValues={{
					theme: "contract",
					contractKey: `${ContractToSend?.contractNumber}${
						ContractToSend?.amendmentNumber !== "0"
							? `.${ContractToSend?.amendmentNumber}`
							: ""
					}`,
					siteId: ContractToSend?.siteId,
					agencyId: ContractToSend?.agencyId,
					tempWorker: ContractToSend?.tempWorkerFullName,
				}}
				onSubmit={handleSendContact}
				agencies={getAgencies(true)}
				sites={getSites()}
				isDisplayed={ShowContactAgencyModal}
				onClose={() => {
					setShowContactAgencyModal(false);
					setContractToSend(undefined);
				}}
			/>
		</PageLayout>
	);
};
