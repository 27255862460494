import React from "react";

import { ToastContainer } from "react-toastify";

import Router from "./router/Router";
import { AppProvider } from "store/AppContext";

const App: React.FC = () => {
	return (
		<AppProvider>
			<div className="w-full h-full App">
				<Router />
				<ToastContainer
					hideProgressBar
					pauseOnHover={false}
					closeOnClick
					limit={5}
				/>
			</div>
		</AppProvider>
	);
};

export default App;
