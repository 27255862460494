import React from "react";

import { NavLink, useLocation } from "react-router-dom";
import { isRouteActive } from "router/Router";
import { IRoute } from "router/Routes";

import useAppContext from "store/useAppContext";

import { NavItem, NavItemIcon } from "components/atoms";
import { IMenuProps } from "components/types";

import { cn } from "utils";

export const Menu: React.FC<IMenuProps> = ({ className, items }) => {
	const { hasPermissions } = useAppContext();
	// need to use this hook to force update the component when the route change
	useLocation();

	return (
		<div className={cn(["gap-2 pt-10 ml-4 col", className])}>
			{items.map((item: IRoute, index: number) => {
				if (item.hidden) return null;
				if (item.permissions && !hasPermissions(item.permissions))
					return null;
				const isActive = !!isRouteActive(item);
				const path =
					typeof item.path === "string" ? item.path : item.path[0];
				return (
					<NavItem key={index} label={item.name}>
						<NavLink
							to={path}
							className={cn([
								"pl-6 pr-10 py-5 rounded-l-full duration-300",
								isActive
									? "bg-white dark:bg-neutral-800"
									: "bg-transparent",
							])}
						>
							<NavItemIcon item={item} isActive={isActive} />
						</NavLink>
					</NavItem>
				);
			})}
		</div>
	);
};
