import React from "react";

import { Formik, Form } from "formik";
import { t } from "i18next";

import {
	ReviewFormInitialValues,
	ReviewFormValidation,
	IReviewFormValues,
} from "./ReviewFormValidation";
import { Text, Title, Button, InfoMessage } from "@zolteam/react-ras-library";
import { Toggle, Textarea } from "components/atoms";
import { RatingRange } from "components/molecules";
import { BlockNavigation, RatingRangeItem } from "components/organisms";
import { REVIEW_STATUS_NOT_RATED } from "constants_globals/reviews";

import { cn } from "utils";

import { MAX_CONTACT_LOGIN_MESSAGE_LENGTH } from "constants_globals";

interface IReviewFormProps {
	initialValues?: IReviewFormValues;
	edition?: boolean;
	onSubmit: (values: IReviewFormValues, formikHelpers?: any) => Promise<any>;
}

const rangeText = (value: number) => {
	switch (value) {
		case 1:
			return t("reviews.edition.rangeLevel.1");

		case 2:
			return t("reviews.edition.rangeLevel.2");

		case 3:
			return t("reviews.edition.rangeLevel.3");

		case 4:
			return t("reviews.edition.rangeLevel.4");

		default:
			return t("reviews.edition.rangeLevel.default");
	}
};

export const ReviewForm: React.FC<IReviewFormProps> = ({
	edition,
	initialValues,
	onSubmit,
}) => {
	return (
		<Formik
			initialValues={{ ...ReviewFormInitialValues, ...initialValues }}
			onSubmit={onSubmit}
			validationSchema={ReviewFormValidation(t)}
		>
			{({
				values,
				isValid,
				dirty,
				isSubmitting,
				errors,
				setFieldValue,
				setValues,
			}) => {
				const handleRatingRange = (
					fieldName: string,
					value: any,
					active: boolean
				) => {
					if (!edition) return;

					setValues({
						...values,
						[`${fieldName}Active`]: true,
						[fieldName]: value,
					});
				};

				const computeGlobalSatisfaction = () => {
					const fields = ["behaviorRating", "skillRating"];
					if (values.isSecurityConcerned)
						fields.push("securityRating");

					const n = fields.reduce((acc, field) => {
						return values[field] !== 0 ? acc + 1 : acc;
					}, 0);

					if (n === 0) return 0;

					return (
						fields.reduce((acc, field) => {
							return values[field] !== 0
								? acc + values[field]
								: acc;
						}, 0) / n
					);
				};

				return (
					<Form className="gap-8 col max-w-[800px] dark:text-white">
						{edition && (
							<div>
								<Text
									tag="p"
									size="paragraph01"
									fontWeight="normal"
									lineHeight="l"
									className="dark:text-white"
								>
									{t("reviews.edition.description")}
								</Text>
							</div>
						)}
						<Title
							tag="h2"
							size="heading03"
							lineHeight="s"
							className="dark:text-white"
						>
							{t("reviews.edition.subTitle")}
						</Title>
						<div className="gap-8 col [&>*]:w-full [&>*]:border-b [&>*]:border-neutral-200 dark:[&>*]:border-neutral-500 [&>*]:pb-8">
							<RatingRangeItem
								title={t("reviews.edition.knowHowToBe.title")}
								text={t(
									"reviews.edition.knowHowToBe.description"
								)}
							>
								<RatingRange
									disabled={!edition || isSubmitting}
									name="behavior"
									active={
										values.behaviorRatingActive || !edition
									}
									value={values.behaviorRating}
									onChange={(value) =>
										handleRatingRange(
											"behaviorRating",
											value,
											values.behaviorRatingActive
										)
									}
									text={rangeText(values.behaviorRating)}
								/>
							</RatingRangeItem>

							<RatingRangeItem
								title={t("reviews.edition.knowHowToDo.title")}
								text={t(
									"reviews.edition.knowHowToDo.description"
								)}
							>
								<RatingRange
									disabled={!edition || isSubmitting}
									name="skill"
									active={
										values.skillRatingActive || !edition
									}
									value={values.skillRating}
									onChange={(value) =>
										handleRatingRange(
											"skillRating",
											value,
											values.skillRatingActive
										)
									}
									text={rangeText(values.skillRating)}
								/>
							</RatingRangeItem>

							<RatingRangeItem
								title={
									<div className="gap-8 row">
										<Title
											tag="h3"
											size="heading03"
											lineHeight="s"
											className="dark:text-white"
										>
											{t(
												"reviews.edition.security.title"
											)}
										</Title>
										<label
											className={cn([
												"items-center gap-2 cursor-pointer row w-fit",
												!edition &&
													"text-neutral-500 cursor-not-allowed",
											])}
										>
											<input
												type="checkbox"
												onChange={(e) => {
													setFieldValue(
														"isSecurityConcerned",
														!e.target.checked
													);
												}}
												checked={
													!values.isSecurityConcerned
												}
												disabled={
													!edition || isSubmitting
												}
											/>
											{t(
												"reviews.edition.security.notEnabled"
											)}
										</label>
									</div>
								}
								text={t(
									"reviews.edition.knowHowToDo.description"
								)}
							>
								<RatingRange
									disabled={
										!edition ||
										!values.isSecurityConcerned ||
										isSubmitting
									}
									name="skill"
									active={
										values.securityRatingActive || !edition
									}
									value={values.securityRating}
									onChange={(value) =>
										handleRatingRange(
											"securityRating",
											value,
											values.securityRatingActive
										)
									}
									text={
										values.isSecurityConcerned
											? rangeText(values.securityRating)
											: t(
													"reviews.edition.security.notEnabled"
											  )
									}
								/>
							</RatingRangeItem>
							<div className="w-full gap-4 row max-w-[800px]">
								<Title
									tag="h2"
									size="heading03"
									lineHeight="s"
									className="dark:text-white"
								>
									{t("reviews.edition.askRenew")}
								</Title>
								<Toggle
									states={true}
									checked={values.mayHaveNewMission}
									onChange={(e) => {
										setFieldValue(
											"mayHaveNewMission",
											e.target.checked
										);
									}}
									disabled={!edition}
								/>
							</div>

							<RatingRangeItem
								title={t(
									"reviews.edition.generalSatisfaction.title"
								)}
								text={t(
									"reviews.edition.generalSatisfaction.description"
								)}
							>
								<RatingRange
									disabled
									name="global"
									active={computeGlobalSatisfaction() !== 0}
									value={Math.round(
										computeGlobalSatisfaction()
									)}
									text={rangeText(
										Math.round(computeGlobalSatisfaction())
									)}
								/>
							</RatingRangeItem>
						</div>

						<div>
							<Title
								tag="h2"
								size="heading03"
								lineHeight="s"
								className="mb-4 dark:text-white"
							>
								{t("reviews.edition.comment")}
							</Title>
							<Textarea
								name="comment"
								label={t("reviews.edition.commentPlaceholder")}
								disabled={!edition || isSubmitting}
								maxLength={
									edition
										? MAX_CONTACT_LOGIN_MESSAGE_LENGTH
										: undefined
								}
								className={cn([
									!edition &&
										"[&_textarea]:!text-neutral-800",
									"dark:[&_textarea]:bg-transparent dark:[&_textarea]:text-white dark:[&_label]:text-neutral-300",
								])}
							/>
						</div>

						<div className="pt-8 border-t border-neutral-200">
							{edition ? (
								<Button
									type="submit"
									color="primary"
									disabled={
										!isValid || !dirty || isSubmitting
									}
									isLoading={isSubmitting}
								>
									{t("reviews.edition.submit")}
								</Button>
							) : (
								<InfoMessage withIcon>
									{values.status === REVIEW_STATUS_NOT_RATED
										? t("reviews.expired")
										: t("reviews.edition.submited")}
								</InfoMessage>
							)}
						</div>
						<BlockNavigation
							forceConfirm
							shouldPrompt={!!edition && (dirty || isSubmitting)}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};
