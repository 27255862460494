import React from "react";

import { Form, Formik } from "formik";
import { t } from "i18next";

import UserAdminService from "services/UserAdminService";

import { Title, Button } from "@zolteam/react-ras-library";
import { Field } from "components/atoms";
import { IUser } from "components/types";

interface IUserNameProps {
	user: IUser;
	onSave: (values: IUser) => void;
}

export const EditableUserName: React.FC<IUserNameProps> = ({
	user,
	onSave,
}) => {
	const [IsEditing, setIsEditing] = React.useState(false);

	const handleSave = (values: IUser) => {
		return UserAdminService.userAdminPatchInformations(user.id, values)
			.then(() => {
				setIsEditing(false);
				onSave && onSave(values);
			})
			.catch((err) => {
				throw err;
			});
	};

	return (
		<div className="items-center row">
			{IsEditing ? (
				<EditUserNameForm
					initialValues={{
						id: user.id,
						firstName: user.firstName,
						lastName: user.lastName,
					}}
					onSubmit={(values) => handleSave(values)}
				/>
			) : (
				<div className="flex-wrap row">
					<Title
						tag="h1"
						size="display02"
						className="dark:text-white"
					>
						{user.firstName} {user.lastName}
					</Title>
					<Button
						color="transparent"
						type="button"
						className="ml-4 !text-primary-500 dark:!text-primary-300"
						onClick={() => setIsEditing(!IsEditing)}
					>
						{t("global.edit")}
					</Button>
				</div>
			)}
		</div>
	);
};

interface IEditUserNameFormProps {
	initialValues: IUser;
	onSubmit: (values: IUser, context: any) => void;
}

const EditUserNameForm: React.FC<IEditUserNameFormProps> = ({
	initialValues,
	onSubmit,
}) => {
	return (
		<Formik
			initialValues={{
				...initialValues,
			}}
			onSubmit={(values, ctx) => onSubmit(values, ctx)}
		>
			{({ values, handleSubmit }) => {
				return (
					<Form
						onSubmit={handleSubmit}
						className="flex flex-wrap w-full gap-2 col md:flex-row"
					>
						<Field
							className="w-full sm:w-auto"
							type="text"
							name="firstName"
							label={t("userProfile.infos.firstName")}
							value={values.firstName}
						/>
						<Field
							className="w-full sm:w-auto"
							type="text"
							name="lastName"
							label={t("userProfile.infos.lastName")}
							value={values.lastName}
						/>
						<Button
							color="transparent"
							type="button"
							className="!text-primary-500"
							onClick={handleSubmit}
						>
							{t("global.save")}
						</Button>
					</Form>
				);
			}}
		</Formik>
	);
};
