import React from "react";

import { t } from "i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Button, Picto } from "@zolteam/react-ras-library";
import { SidePanel } from "components/molecules";
import { IUserDetails } from "components/types";

interface IAdminUsersSideBoxProps {
	selected: IUserDetails[];
	setSelected: (values: IUserDetails) => void;
	clearSelection: () => void;
}

export const AdminUsersSideBox: React.FC<IAdminUsersSideBoxProps> = ({
	selected,
	setSelected,
	clearSelection,
}) => {
	const [params] = useSearchParams();
	const navigate = useNavigate();
	const { state } = useLocation();
	const hasSelected = !!selected?.length;

	const handleConfiguration = () => {
		const search = state?.URLSearchParam ?? params.get("search") ?? "";

		navigate("/admin-users/config", {
			state: {
				...state,
				users: selected,
				fromPage: "admin-users",
				backUrl: `/admin-users${search ? `?search=${search}` : ""}`,
			},
		});
	};

	return (
		<SidePanel
			title={t("adminUsers.title")}
			className="lg:max-w-[500px] flex-none"
		>
			{!hasSelected && (
				<p className="text-sm text-neutral-500">
					{t("adminUsers.addUserLabel")}
				</p>
			)}
			<div className="h-full gap-2 pr-2 -mr-2 overflow-auto col">
				{hasSelected &&
					selected.map((user: IUserDetails) => {
						return (
							<div
								key={user.id || user.email}
								className="items-center justify-between gap-3 px-3 py-2 rounded-md bg-neutral-100 dark:bg-neutral-800 bordder-b border-neutral-400 row"
							>
								<div className="col text-neutral-700 dark:text-neutral-200">
									<span className="text-sm">
										{user.email}
									</span>
								</div>
								<button onClick={() => setSelected(user)}>
									<Picto
										icon="close"
										className="!w-2 !h-2 text-primary-500 dark:text-primary-300"
									/>
								</button>
							</div>
						);
					})}
			</div>
			{hasSelected && (
				<>
					<Button
						type="button"
						color="transparentPrimary"
						className="w-full my-4 text-sm text-center text-primary-500"
						onClick={clearSelection}
					>
						{t("global.clearSelection")}
					</Button>
					<Button
						type="button"
						color="primary"
						className="w-full mt-auto"
						onClick={handleConfiguration}
					>
						{t("adminUsers.configUsers")}
					</Button>
				</>
			)}
		</SidePanel>
	);
};
