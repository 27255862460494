import * as Yup from "yup";

import {
	EMAIL_REGEX,
	MAX_CONTACT_MESSAGE_LENGTH,
	MAX_CONTACT_SUBJECT_LENGTH,
} from "constants_globals";

export interface ISendMailFormValues {
	id?: number | null;
	email: string;
	subject: string;
	content: string;
}

export const SendMailFormInitValues: ISendMailFormValues = {
	email: "",
	subject: "",
	content: "",
};

export const SendMailFormValidation = (t: any) =>
	Yup.object().shape({
		email: Yup.string()
			.required(t("forms.email.required"))
			.test("email", t("forms.email.invalid"), (value) =>
				value ? EMAIL_REGEX.test(value) : true
			),
		subject: Yup.string()
			.max(
				MAX_CONTACT_SUBJECT_LENGTH,
				t("forms.object.max", {
					max: MAX_CONTACT_SUBJECT_LENGTH,
				})
			)
			.required(t("forms.field.required")),
		content: Yup.string()
			.max(
				MAX_CONTACT_MESSAGE_LENGTH,
				t("forms.message.max", {
					max: MAX_CONTACT_MESSAGE_LENGTH,
				})
			)
			.required(t("forms.field.required")),
	});
