import secureLocalStorage from "react-secure-storage";

import SecurityService from "./SecurityService";

export interface IAuthData {
	token: string;
	user: ILoggedUser;
	roles: string[];
	permissions: string[];
}

interface ILoggedUser {
	email: string;
	firstName: string;
	id: number;
	lastName: string;
	phone: string | null;
}

class SessionServiceClass {
	initSession = ({ token, user, roles, permissions }: IAuthData) => {
		this.lastTriedEmail(user.email);
	};

	isLogged = () => (this.getAccessToken()?.token ? true : false);

	clearSession = () => {
		sessionStorage.clear();
		secureLocalStorage.removeItem("AccessToken");
	};

	// call for the logout route, but disconnect anyway if it fails
	disconnect = () =>
		SecurityService.logout().then(this.clearSession, this.clearSession);

	/* Token */
	getAccessToken() {
		const context = JSON.parse(
			secureLocalStorage.getItem("context")?.toString() ?? "{}"
		);

		return context?.auth?.token;
	}

	lastTriedEmail = (setNewMail?: string) => {
		if (setNewMail)
			secureLocalStorage.setItem("lastTriedEmail", setNewMail);
		return secureLocalStorage.getItem("lastTriedEmail")?.toString() ?? "";
	};

	isConnectedAs = () => {
		const context = JSON.parse(
			secureLocalStorage.getItem("context")?.toString() ?? "{}"
		);
		return context?.connectAs ?? false;
	};

	isSwitchingUser = (newState?: boolean) => {
		if (newState !== undefined)
			sessionStorage.setItem("isSwitching", JSON.stringify(newState));
		return JSON.parse(sessionStorage.getItem("isSwitching") ?? "false");
	};
}

const SessionService = new SessionServiceClass();
export default SessionService;
