import { t } from "i18next";
import moment from "moment";

import { Text } from "@zolteam/react-ras-library";
import { ContractNumber } from "components/atoms";
import { ContractStatus } from "components/molecules";
import { IColumn, IContract } from "components/types";

import { CONTRACT_STATUS_CANCELLED } from "constants_globals";

export const CONTRACTS_COLUMNS: IColumn[] = [
	{
		name: t("forms.tempWorker.label"),
		value: "temp_worker",
		render: (item) => {
			return (
				<div className="">
					<Text
						tag="span"
						fontWeight="bold"
						lineHeight="m"
						className="dark:!text-white"
					>
						{item.tempWorkerFullName}
					</Text>
					<p className="text-neutral-500">
						<ContractNumber
							number={`${t("contracts.contract")} ${
								item.contractNumber
							}`}
							amendmentNumber={item.amendmentNumber}
						/>
					</p>
					{!!item.orderReference && (
						<p className="text-xs text-neutral-500">
							{item.orderReference}
						</p>
					)}
				</div>
			);
		},
	},
	{
		name: t("forms.contract.contractNumber"),
		value: "contract_number",
		hidden: true,
	},
	{
		name: t("forms.sites.label"),
		value: "siteSocialReason",
		sortKey: "site",
	},
	{ name: t("forms.qualification.label"), value: "qualification" },
	{
		name: t("dates.dateStart"),
		value: "date",
		render: (item) => moment(item.startDate).format(t("dates.medium")),
		className: "font-medium",
	},
	{
		name: t("dates.dateEnd"),
		value: "endDate",
		render: (item) => moment(item.endDate).format(t("dates.medium")),
		className: "font-medium",
		sortable: false,
	},
	{
		name: t("contracts.signDate"),
		value: "signatureDate",
		render: (item) =>
			item.signatureDate &&
			item.status !== CONTRACT_STATUS_CANCELLED &&
			moment(item.signatureDate).format(t("dates.medium")),
		hidden: (props) => {
			const hasItems = props?.items?.find(
				(i: IContract) =>
					i.signatureDate && i.status !== CONTRACT_STATUS_CANCELLED
			);
			return !hasItems;
		},
		className: "font-medium",
		sortable: false,
	},
	{ name: t("forms.agencies.label"), value: "agencyName", sortable: false },
	{
		name: t("global.status"),
		value: "status",
		className: "w-[200px]",
		render: (item) => <ContractStatus item={item} />,
	},
];
