import { TBanner } from "types/TBanners";

import api from "./api";

class BannersServiceClass {
	getUserBanners = () => api.get(`/banner`).then((resp) => resp?.data);

	getBanners = (page = 1, limit = 20) => {
		const params = new URLSearchParams();
		params.append("page", page.toString());
		params.append("limit", limit.toString());
		return api.get(`/banners?${params}`).then((resp) => resp?.data);
	};

	addBanner = (data: TBanner) =>
		api.post(`/banner`, data).then((resp) => resp?.data);

	updateBanner = (data: TBanner) =>
		api.put(`/banner/${data.id}`, data).then((resp) => resp?.data);

	deleteBanner = (id: number) =>
		api.delete(`/banner/${id}`).then((resp) => resp?.data);

	readBanner = (id: number) =>
		api.patch(`/banner/${id}/read`).then((resp) => resp?.data);
}

const BannersService = new BannersServiceClass();
export default BannersService;
