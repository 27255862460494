import React, { useState } from "react";

import { t } from "i18next";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ThreadService from "services/ThreadsService";
import TimesheetsService from "services/TimeSheetsService";

import { useList } from "hooks/useList";
import useAppContext from "store/useAppContext";

import { TIMESHEETS_COLUMNS } from "./TimeSheetsColumns";
import { timesheetItemActions } from "./TimeSheetsItemsActions";
import { Button, Picto } from "@zolteam/react-ras-library";
import { TimesheetsPageHead } from "components/molecules";
import {
	AsyncList,
	ContactAgencyModal,
	TimeSheetFilters,
} from "components/organisms";
import { PageLayout } from "components/templates";
import { ITimeSheet } from "components/types";
import { IContactAgencyFormValues } from "forms";
import "styles/index.scss";

import { cleanObject, handleFileSave } from "utils";

import {
	TIMESHEET_DOWNLOAD_PERMISSION,
	TIMESHEET_STATUS_WAITING,
} from "constants_globals";

export interface ITimeSheetsListLayoutProps {}

export const TimeSheetsListLayout: React.FC<
	ITimeSheetsListLayoutProps
> = () => {
	const navigate = useNavigate();
	const useListHook = useList<ITimeSheet>({
		defaultSort: "date",
	});

	const {
		Pagination,
		setPagintation,
		Filters,
		setFilter,
		changeFilters,
		getFiltersKeys,
		getPaginationKeys,
		IsExporting,
		setIsExporting,
		Selected,
	} = useListHook;

	const { getAgencies, getSites, hasPermissions } = useAppContext();
	const activeAgenciesIds = getAgencies(true).map((agency) => agency.id);

	const [TimeSheetsToSend, setTimeSheetsToSend] = useState<
		ITimeSheet | undefined
	>(undefined);
	const [ShowContactAgencyModal, setShowContactAgencyModal] = useState(false);

	const fetchTimeSheets = async (abortSignal: AbortSignal) => {
		return TimesheetsService.timesheetSearch(
			{
				agencyIds: activeAgenciesIds,
				...cleanObject(Filters, true),
				freeSearch: Filters.search || undefined,
				page: Pagination.currentPage,
				limit: Pagination.pageSize,
			},
			abortSignal
		).then((resp) => {
			setPagintation({
				...Pagination,
				totalCount: resp.search.total,
			});
			return resp;
		});
	};

	const handleDownloadSelected = () => {
		var bodyFormData = new FormData();
		bodyFormData.append(
			"ids",
			JSON.stringify({ ids: Selected.map((s) => s.id.toString()) })
		);
		toast.loading(t("promiseToast.fetching"), {
			toastId: `timesheet-download`,
		});
		setIsExporting(true);
		const prom = TimesheetsService.timesheetDowloadIds(
			bodyFormData
		).finally(() => {
			setIsExporting(false);
			toast.dismiss(`timesheet-download`);
		});
		handleFileSave(
			prom,
			`contrats-${moment().format(t("dates.full"))}.zip`
		);
	};

	const handleSendContact = (values: IContactAgencyFormValues) => {
		const prom = ThreadService.postThreadFromOther({
			...values,
		}).then(() => {
			setShowContactAgencyModal(false);
			setTimeSheetsToSend(undefined);
		});

		return prom;
	};

	const handleContact = (timesheet: ITimeSheet) => {
		setTimeSheetsToSend(timesheet);
		setShowContactAgencyModal(true);
	};

	const handleExport = () => {
		setIsExporting(true);
		const prom = TimesheetsService.timesheetExportBySearch({
			...cleanObject(Filters),
			freeSearch: Filters.search || undefined,
		}).finally(() => setIsExporting(false));

		handleFileSave(
			prom,
			`timesheets-${moment().format(t("dates.full"))}.xlsx`
		);
	};

	const canDownload = hasPermissions([TIMESHEET_DOWNLOAD_PERMISSION]);

	return (
		<PageLayout
			title={t("timesheets.title")}
			header={
				<TimesheetsPageHead viewType={"list"}>
					<Button
						outline
						color="primary"
						type="button"
						onClick={handleExport}
						className="w-full"
						isLoading={IsExporting}
					>
						<Picto icon="download" />
						{t("global.exportList")}
					</Button>
				</TimesheetsPageHead>
			}
		>
			<AsyncList
				filters={
					<TimeSheetFilters
						filters={Filters}
						setFilter={setFilter}
						setDates={(dates) => {
							changeFilters({
								...dates,
							});
						}}
					/>
				}
				query={{
					queryKey: [
						"Timesheets",
						activeAgenciesIds.join(","),
						...getPaginationKeys(),
						...getFiltersKeys(),
					],
					queryFn: ({ signal }) => fetchTimeSheets(signal),
					retry: 0,
				}}
				columns={TIMESHEETS_COLUMNS}
				className="overflow-visible"
				itemsKey="results"
				itemActions={timesheetItemActions({
					handleContact,
					navigate,
					hasPermissions,
				})}
				rowProps={{
					className: (item: ITimeSheet) =>
						item.status !== TIMESHEET_STATUS_WAITING &&
						"[&>td]:text-neutral-400 [&>td>div>*]:!text-neutral-400",
					hasPermissions,
					navigate,
				}}
				scrollParentSelector="AdminLayout__header"
				actions={
					canDownload
						? [
								{
									label: t("timesheets.download", {
										count: Selected?.length,
									}),
									icon: "download",
									onClick: handleDownloadSelected,
									disabled: !Selected?.length || IsExporting,
									isLoading: IsExporting,
								},
						  ]
						: []
				}
				selectable={(item) =>
					canDownload && item.status !== TIMESHEET_STATUS_WAITING
				}
				useListHook={useListHook}
			/>
			<ContactAgencyModal
				initialValues={{
					theme: "time_sheet",
					contractKey: `${TimeSheetsToSend?.contractNumber}${
						TimeSheetsToSend?.amendmentNumber !== "0"
							? `.${TimeSheetsToSend?.amendmentNumber}`
							: ""
					}`,
					siteId: TimeSheetsToSend?.siteId,
					agencyId: TimeSheetsToSend?.agencyId,
					tempWorker: TimeSheetsToSend?.tempWorkerFullName,
				}}
				onSubmit={handleSendContact}
				agencies={getAgencies(true)}
				sites={getSites()}
				isDisplayed={ShowContactAgencyModal}
				onClose={() => {
					setShowContactAgencyModal(false);
					setTimeSheetsToSend(undefined);
				}}
			/>
		</PageLayout>
	);
};
