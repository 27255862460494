import React from "react";

import { Text } from "@zolteam/react-ras-library";

import { capitalize } from "utils";

export interface IWeekDayProps {
	day: string;
	month: string;
	sub?: string;
}

export const WeekDay: React.FC<IWeekDayProps> = ({ day, month, sub }) => {
	return (
		<div className="col">
			<Text
				tag="span"
				size="paragraph01"
				lineHeight="s"
				color="black"
				fontWeight="normal"
				className="dark:text-white"
			>
				{capitalize(day)}
			</Text>
			<Text tag="span" size="paragraph02" lineHeight="s" color="grey">
				{month}
			</Text>
			{sub !== "" && (
				<Text
					tag="span"
					size="paragraph02"
					fontWeight="normal"
					lineHeight="s"
					color="primary"
					className="dark:text-primary-300"
				>
					{sub}
				</Text>
			)}
		</div>
	);
};
