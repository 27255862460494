/* eslint-disable import/no-extraneous-dependencies */
import React, { PropsWithChildren } from "react";

import { PopoverCursor } from "@zolteam/react-ras-library";

interface INavItemProps extends PropsWithChildren {
	label: string;
	popoverProps?: any;
}

export const NavItem: React.FC<INavItemProps> = ({
	label,
	children,
	popoverProps,
}) => {
	return (
		<PopoverCursor
			content={
				<div
					className={
						"p-1 rounded-md shadow-md bg-white dark:bg-neutral-700 dark:text-white text-xs"
					}
				>
					{label}
				</div>
			}
			{...popoverProps}
		>
			{children}
		</PopoverCursor>
	);
};
