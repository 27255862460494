import { t } from "i18next";
import * as Yup from "yup";

import {
	EMAIL_REGEX,
	MAX_CONTACT_EMAIL_LENGTH,
	NAME_REGEX,
	PHONE_REGEX,
} from "constants_globals";

export const nameValidation = () =>
	Yup.string()
		.required(t("forms.field.required"))
		.matches(
			NAME_REGEX,
			t("commercialPropositions.form.sections.contact.nameError")
		);

export const phoneValidation = (mandatory?: boolean) =>
	(mandatory
		? Yup.string().required(t("forms.phone.required"))
		: Yup.string()
	)
		.transform((_, val) => (val ? val.replace(/\s/gi, "") : ""))
		.matches(PHONE_REGEX, t("forms.phone.invalid"));

export const mailValidation = (mandatory?: boolean) =>
	(mandatory
		? Yup.string().required(t("forms.email.required"))
		: Yup.string()
	)
		.max(
			MAX_CONTACT_EMAIL_LENGTH,
			t("forms.field.max", { max: MAX_CONTACT_EMAIL_LENGTH })
		)
		.matches(EMAIL_REGEX, t("forms.email.invalid"));
