import React from "react";

import { FieldArray, useFormikContext } from "formik";
import { t } from "i18next";
import { Trans } from "react-i18next";
import * as Yup from "yup";

import { ICommercialPropositionFormSectionProps } from "../CommercialPropositionForm";
import { mailValidation, phoneValidation } from "./sectionsValidation";
import { Title, Button } from "@zolteam/react-ras-library";
import {
	CPInterlocutor,
	ClientInfos,
	ConfirmModal,
	IClientInfos,
	PricingTypesSelect,
} from "components/molecules";
import { IContactPersonRemuneration } from "components/types";

import { CP_STATUS_NO_ACTION_TAKEN, CP_STATUS_SIGNED } from "constants_globals";

const MAX_NUMBER_OF_FILES_TO_UPLOAD = 10;

const DefaultContact: IContactPersonRemuneration = {
	interlocutorName: "",
	function: "",
	phone: "",
	email: "",
	isInvoiceInterlocutor: true,
};

interface IDeleteUserModalProps {
	interlocutor: IClientInfos;
	onConfirm: () => void;
}

export interface ICPBillingSectionValues {
	billingMethod: any;
	deadline: any;
	interlocutors?: IContactPersonRemuneration[];
	contactPersonRemunerations?: IContactPersonRemuneration[];
	contactPersonRemunerationsOther?: IContactPersonRemuneration[];
}

export const CPBillingSectionInitialValues: ICPBillingSectionValues = {
	billingMethod: null,
	deadline: null,
	contactPersonRemunerations: [
		{
			...DefaultContact,
		},
	],
	contactPersonRemunerationsOther: [
		{
			...DefaultContact,
			isInvoiceInterlocutor: false,
		},
	],
};

const interlocutorValidation = Yup.object().shape({
	interlocutorName: Yup.string().nullable(),
	function: Yup.string().nullable(),
	phone: phoneValidation().nullable(),
	email: mailValidation().nullable(),
});

export const CPBillingSectionValidation = {
	billingMethod: Yup.object().required(t("forms.field.required")),
	deadline: Yup.object().required(t("forms.field.required")),
	contactPersonRemunerations: Yup.array().of(interlocutorValidation),
	contactPersonRemunerationsOther: Yup.array().of(interlocutorValidation),
};

const MAX_INTERLOCUTOR_NAME_LENGTH = 25;

export const CPBillingSection: React.FC<
	ICommercialPropositionFormSectionProps
> = ({ locked, commercialPropositionStatus }) => {
	const [DeleteModal, setDeleteModal] = React.useState<
		IDeleteUserModalProps | undefined
	>(undefined);

	const { values, setFieldValue } =
		useFormikContext<ICPBillingSectionValues>();

	const canAddInterlocutor =
		!locked &&
		![CP_STATUS_SIGNED, CP_STATUS_NO_ACTION_TAKEN].includes(
			commercialPropositionStatus
		);

	const handleRemoveInterlocutor = (
		interlocutor: IContactPersonRemuneration,
		callback: () => void
	) => {
		if (!canAddInterlocutor) return;

		setDeleteModal({
			interlocutor: {
				fullName: interlocutor.interlocutorName,
				phone: interlocutor.phone,
				email: interlocutor.email,
			},
			onConfirm: callback,
		});
	};

	return (
		<div>
			<Title tag="h3" size="heading03" className="dark:text-white">
				{t("commercialPropositions.form.sections.billing.billing")}
			</Title>
			<div className="z-20 gap-4 mt-4 col">
				<PricingTypesSelect
					label={t(
						"commercialPropositions.form.sections.billing.billingMethod"
					)}
					name="billingMethod"
					category="billingMethod"
					required
					disabled={locked}
					onChange={(value: any) => {
						setFieldValue("billingMethod", value);
					}}
					value={values.billingMethod}
				/>
				<PricingTypesSelect
					name="deadline"
					category="deadline"
					required
					label={t(
						"commercialPropositions.form.sections.billing.deadline"
					)}
					disabled={locked}
					onChange={(value: any) => {
						setFieldValue("deadline", value);
					}}
					value={values.deadline}
				/>
			</div>
			<Title tag="h3" size="heading03" className="mt-4 dark:text-white">
				{t(
					"commercialPropositions.form.sections.billing.interlocutors"
				)}
			</Title>
			<FieldArray name="contactPersonRemunerations">
				{({ remove, push }) => (
					<div className="gap-6 col">
						{values.contactPersonRemunerations?.map(
							(
								interlocutor: IContactPersonRemuneration,
								index: number
							) => (
								<CPInterlocutor
									index={index}
									key={index}
									arrayName="contactPersonRemunerations"
									onRemove={() =>
										handleRemoveInterlocutor(
											interlocutor,
											() => remove(index)
										)
									}
									locked={locked}
									maxNameLength={MAX_INTERLOCUTOR_NAME_LENGTH}
								/>
							)
						)}
						{values.contactPersonRemunerations &&
							values.contactPersonRemunerations?.length < 3 && (
								<Button
									type="button"
									color="primary"
									className="self-start"
									onClick={() =>
										push({
											...DefaultContact,
											isInvoiceInterlocutor: true,
										})
									}
									disabled={locked || !canAddInterlocutor}
								>
									{t(
										"commercialPropositions.form.sections.billing.addInterlocutor"
									)}
								</Button>
							)}
					</div>
				)}
			</FieldArray>
			<Title tag="h3" size="heading03" className="mt-4 dark:text-white">
				{t(
					"commercialPropositions.form.sections.billing.othersInterlocutors"
				)}
			</Title>
			<FieldArray name="contactPersonRemunerationsOther">
				{({ remove, push }) => (
					<div className="gap-6 col">
						{values.contactPersonRemunerationsOther?.map(
							(
								interlocutor: IContactPersonRemuneration,
								index: number
							) => (
								<CPInterlocutor
									index={index}
									key={index}
									arrayName="contactPersonRemunerationsOther"
									onRemove={() =>
										handleRemoveInterlocutor(
											interlocutor,
											() => remove(index)
										)
									}
									locked={locked}
									maxNameLength={MAX_INTERLOCUTOR_NAME_LENGTH}
								/>
							)
						)}
						{!values.contactPersonRemunerationsOther ||
							(values.contactPersonRemunerationsOther?.length <
								MAX_NUMBER_OF_FILES_TO_UPLOAD && (
								<Button
									type="button"
									color="primary"
									className="self-start"
									onClick={() =>
										push({
											...DefaultContact,
											isInvoiceInterlocutor: false,
										})
									}
									disabled={locked || !canAddInterlocutor}
								>
									{t(
										"commercialPropositions.form.sections.billing.addInterlocutor"
									)}
								</Button>
							))}
					</div>
				)}
			</FieldArray>
			<ConfirmModal
				title={t("global.warning")}
				isOpen={DeleteModal !== undefined}
				onClose={() => {
					setDeleteModal(undefined);
				}}
				onConfirm={() => DeleteModal?.onConfirm()}
				size="s"
			>
				<Trans
					i18nKey={
						"commercialPropositions.form.sections.billing.confirmDelete"
					}
				/>
				{!!DeleteModal?.interlocutor && (
					<ClientInfos
						contact={DeleteModal?.interlocutor}
						className="mx-auto mt-2 pointer-events-none w-fit"
					/>
				)}
			</ConfirmModal>
		</div>
	);
};
