import React from "react";

import { t } from "i18next";

import { TableFilters } from "components/organisms";
import { IFiltersWithDatesProps } from "components/types";
import { REVIEWS_STATUS } from "constants_globals/reviews";

export interface IReviewsFiltersProps extends IFiltersWithDatesProps {}

export const ReviewsFilters: React.FC<IReviewsFiltersProps> = ({
	filters,
	setFilter,
	setDates,
}) => {
	return (
		<TableFilters
			status={REVIEWS_STATUS}
			filters={filters}
			onChange={(filter, value) => {
				if (filter === "dates") return setDates(value);
				setFilter(filter, value);
			}}
			searchPlaceholder={t("reviews.searchPlaceholder")}
		/>
	);
};
