import api from "./api";
import {
	IInvoicesFilters,
	IInvoicesIds,
	IInvoicesMailData,
} from "components/types";

import {
	CONFIG_EXPORT_PDF,
	CONFIG_EXPORT_XLSX,
	CONFIG_EXPORT_ZIP,
} from "constants_globals";

class InvoicesServiceClass {
	invoiceSearch(data: IInvoicesFilters, signal: AbortSignal) {
		return api
			.post("/invoice/search", data, {
				signal,
			})
			.then((resp) => resp.data);
	}

	invoiceExportBySearch(data: IInvoicesFilters) {
		return api.post("/invoice/export-xlsx-by-search", data, {
			headers: CONFIG_EXPORT_XLSX.headers,
			responseType: "arraybuffer",
		});
	}

	invoiceSendEmailByIds(data: IInvoicesMailData) {
		return api
			.post("/invoice/send-email-by-ids", data)
			.then((resp) => resp.data);
	}

	invoiceSendEmailBySearch(data: IInvoicesMailData) {
		return api
			.post("/invoice/send-email-by-search", data)
			.then((resp) => resp.data);
	}

	invoiceMarkRead(data: IInvoicesIds) {
		return api
			.post("/invoice/mark-as-read", data)
			.then((resp) => resp.data);
	}

	invoiceMarkUnread(data: IInvoicesIds) {
		return api
			.post("/invoice/mark-as-unread", data)
			.then((resp) => resp.data);
	}

	invoiceDownloadBySearch(data: IInvoicesFilters) {
		return api
			.post("/invoice/download-by-search", data, {
				headers: CONFIG_EXPORT_ZIP.headers,
				responseType: "blob",
			})
			.then((resp) => resp.data);
	}

	invoiceDownloadByIds(data: FormData) {
		return api.post("/invoice/download-by-ids", data, {
			...CONFIG_EXPORT_ZIP,
			headers: {
				...CONFIG_EXPORT_ZIP.headers,
				"Content-Type": "application/form-data",
			},
		});
	}

	invoiceDownload(id: number) {
		return api.get(`/invoice/download/${id}`, {
			headers: CONFIG_EXPORT_PDF.headers,
			responseType: "blob",
		});
	}

	invoiceAdvancement() {
		return api.get("/invoice/advancement").then((resp) => resp.data);
	}
}

const InvoicesService = new InvoicesServiceClass();
export default InvoicesService;
