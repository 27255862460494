import i18n from "i18n/i18n";

const { t } = i18n;

export const INVOICES_STATUS = [
	{
		name: t("global.all"),
		value: undefined,
	},
	{
		name: t("invoices.invoices"),
		value: "invoice",
	},
	{
		name: t("invoices.assets"),
		value: "asset",
	},
];
