import React from "react";

import { Form, Formik } from "formik";
import { t } from "i18next";
import * as Yup from "yup";

import SessionService from "services/SessionService";

import { Button, InfoMessage } from "@zolteam/react-ras-library";
import { Field } from "components/atoms";

import { EMAIL_REGEX } from "constants_globals";

export interface IRetrievePasswordFormValues {
	email: string;
}

interface IRetrievePasswordFormProps {
	isCreate?: boolean;
	onSubmit: (
		values: IRetrievePasswordFormValues,
		formikHelpers: any
	) => Promise<any>;
}

const RetrievePasswordFormValidation = () => {
	return Yup.object().shape({
		email: Yup.string()
			.required(t("forms.email.required"))
			.test("email", t("forms.email.invalid"), (value) =>
				value ? EMAIL_REGEX.test(value) : true
			),
	});
};

export const RetrievePasswordForm: React.FC<IRetrievePasswordFormProps> = ({
	isCreate,
	onSubmit,
}) => {
	return (
		<Formik
			initialValues={{
				email: SessionService.lastTriedEmail(),
				submitError: "",
			}}
			validationSchema={RetrievePasswordFormValidation}
			onSubmit={(values, formikHelpers) =>
				onSubmit(values, formikHelpers).catch((error) => {
					formikHelpers.setFieldError("submitError", error);
				})
			}
		>
			{({ isValid, isSubmitting, errors }) => {
				const submitError: any = errors.submitError; // needed because of Formik typing, submit error can be string or object
				return (
					<Form>
						<Field
							type="text"
							label={t("forms.email.label")}
							name="email"
							disabled={isSubmitting}
						/>
						{!!errors.submitError && (
							<div className="mt-2">
								<InfoMessage color="error" withIcon>
									{submitError?.response?.data.maintenance ===
									"on"
										? t("API_ERRORS.maintenance")
										: t("API_ERRORS.send")}
								</InfoMessage>
							</div>
						)}

						<Button
							type="submit"
							disabled={!isValid || isSubmitting}
							className="w-full mt-4"
							color="primary"
						>
							{t(
								isCreate
									? "createPassword.newLinkButton"
									: "passwordRetrieve.reset"
							)}
						</Button>
					</Form>
				);
			}}
		</Formik>
	);
};
