import { t } from "i18next";
import { promiseToast } from "toastify";

import ContractsService from "services/ContractsService";

import { ContractInfos } from "components/molecules";
import {
	IContract,
	IDashboardTile,
	ITileListItemProps,
} from "components/types";

import {
	CONTRACT_LIST_PERMISSION,
	CONTRACT_SIGNATURE_PERMISSION,
	ROLE_AGENCY,
} from "constants_globals";

export const DashboardContractsTile = (): IDashboardTile => {
	const handleSignZoe = (
		item: IContract,
		{ appState }: ITileListItemProps
	) => {
		if (
			!(
				appState.auth &&
				appState.auth.permissions?.find(
					(permission: string) =>
						permission === CONTRACT_SIGNATURE_PERMISSION
				) &&
				!appState.auth.roles?.find(
					(role: string) => role === ROLE_AGENCY
				) &&
				item.format === "zoe"
			)
		)
			return;

		const { siteId, id } = item;

		const prom = ContractsService.contractZoeAccess(siteId, id).then(
			(res) => {
				if (!res) return;

				const link = document.createElement("a");
				link.setAttribute("href", res.link);
				link.setAttribute("target", "_blank");
				link.setAttribute("rel", "noopener noreferrer");
				link.click();
			}
		);
		promiseToast(prom, {
			pending: t("promiseToast.fetching"),
			success: t("promiseToast.fetchSuccess"),
		});
	};

	return {
		title: t("contracts.title"),
		subtitle: t("contracts.tileSubtitle"),
		maxItems: 3,
		icon: "menuContracts",
		linkTo: "/contracts",
		itemsKey: "contracts",
		listItem: {
			main: "tempWorkerFullName",
			secondary: "qualification",
			info: (item: IContract) => <ContractInfos {...item} />,
			className: (item: IContract) =>
				item.format !== "zoe"
					? "opacity-50 pointer-events-none cursor-default"
					: "",
			onClick: handleSignZoe,
			noValueText: t("contracts.noPending"),
			noValuePicto: "dashboardNoData-contracts",
		},
		chart: {
			valueKey: "contractAdvancement.pendingContractNumber",
			totalKey: "contractAdvancement.totalContractNumber",
			text: t("contracts.pendingText"),
			noValueText: t("contracts.noPending"),
			onClickLabel: "/contracts?status=waiting",
		},
		permissions: [CONTRACT_LIST_PERMISSION],
	};
};
