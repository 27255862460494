import React from "react";

import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { promiseToast } from "toastify";

import UserService from "services/UserService";

import { Title, BackLink } from "@zolteam/react-ras-library";
import { ChangePasswordForm, IChangePasswordFormValues } from "forms";

export interface IUserEditPasswordProps {}

export const UserEditPassword: React.FC<IUserEditPasswordProps> = () => {
	const navigate = useNavigate();

	const handleSubmit = (
		values: IChangePasswordFormValues,
		{ resetForm, setErrors }: any
	) => {
		const prom = UserService.changeUserPassword(values).then(
			() => {
				resetForm();
			},
			(e) => {
				if (e.response.data.errors.previousPassword)
					setErrors({
						previousPassword: t(
							"resetPassword.invalidPreviousPassword"
						),
					});

				throw e;
			}
		);
		promiseToast(prom, {
			success: t("resetPassword.confirmChangeText"),
		});
		return prom;
	};

	return (
		<div>
			<BackLink
				to="/user-profile"
				onClick={() => {
					navigate("/user-profile/profile");
				}}
			>
				{t("userProfile.backToMyProfile")}
			</BackLink>
			<Title tag="h2" className="dark:text-white">
				{t("resetPassword.title2")}
			</Title>
			<ChangePasswordForm onSubmit={handleSubmit} />
		</div>
	);
};
