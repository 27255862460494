import React from "react";

import { cn } from "utils";

export interface ITimesheetTableRowTitleCellProps {
	title: string;
	subtitle?: string;
	className?: string;
}

export const TimesheetTableRowTitleCell: React.FC<
	ITimesheetTableRowTitleCellProps
> = ({ title, subtitle, className }) => {
	return (
		<td className={cn(["pl-4 w-[14rem] py-3 gap-1", className])}>
			<div className="dark:text-white">
				<b className="text-[0.75rem] leading-4">{title}</b>
			</div>
			{subtitle && (
				<div className="text-[0.625rem] leading-3 text-neutral-500 dark:text-neutral-400">
					{subtitle}
				</div>
			)}
		</td>
	);
};
