import { t } from "i18next";
import moment from "moment";
import { toast } from "react-toastify";

import RatingService from "services/RatingsService";

import { IReview } from "components/types";
import {
	REVIEW_STATUS_LOCKED,
	REVIEW_STATUS_NOT_RATED,
	REVIEW_STATUS_RATED,
	REVIEW_STATUS_WAITING,
} from "constants_globals/reviews";

import { handleFileSave } from "utils";

import { RATING_VALIDATE_PERMISSION } from "constants_globals";

interface IReviewsItemsActions {
	item: IReview;
	navigate: any;
	handleContactAgency: (values?: any) => void;
}

export const reviewsItemsActions = ({
	item,
	navigate,
	handleContactAgency,
}: IReviewsItemsActions) => {
	const handleReviewDownload = (id: number) => {
		toast.loading(t("promiseToast.fetching"), {
			toastId: `invoice-download-${id}`,
		});
		const prom = RatingService.ratingDownload(id).finally(() =>
			toast.dismiss(`invoice-download-${id}`)
		);
		handleFileSave(
			prom,
			`review-${id}${moment().format(t("dates.full"))}.pdf`
		);
	};

	const canRate = item.permissions.includes(RATING_VALIDATE_PERMISSION);

	if (
		[REVIEW_STATUS_LOCKED, REVIEW_STATUS_NOT_RATED].includes(item.status) ||
		(!canRate && item.status === REVIEW_STATUS_WAITING)
	)
		return [
			{
				label: t("global.contactAgency"),
				icon: "bubble",
				onClick: (item: IReview) => handleContactAgency(item),
			},
		];

	return [
		{
			label: t("reviews.review"),
			icon: "edit",
			render: (review: IReview, defaultRender: React.ReactNode) => {
				return review.status !== REVIEW_STATUS_WAITING && canRate
					? false
					: defaultRender;
			},
			linkTo: (review: IReview) => ({
				pathname: `/reviews/edit/${review.id}`,
				state: {
					review,
					edition: true,
					search: window.location.search,
				},
			}),
		},
		{
			label: t("reviews.view"),
			icon: "eye",
			render: (review: IReview, defaultRender: React.ReactNode) => {
				return review.status !== REVIEW_STATUS_RATED
					? false
					: defaultRender;
			},
			linkTo: (review: IReview) => ({
				pathname: `/reviews/view/${review.id}`,
				state: {
					review,
					edition: false,
					search: window.location.search,
				},
			}),
		},
		{
			label: t("file.download"),
			icon: "download",
			onClick: ({ id }: IReview) => handleReviewDownload(id),
			render: (review: IReview, defaultRender: React.ReactNode) => {
				return review.status !== REVIEW_STATUS_RATED
					? false
					: defaultRender;
			},
		},
		{
			label: t("global.contactAgency"),
			icon: "bubble",
			onClick: (item: IReview) => handleContactAgency(item),
		},
	];
};
