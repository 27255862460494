import React from "react";

import { ActionsListItem } from "components/atoms";
import { IActionsListItem } from "components/types";

export interface IActionsListProps {
	actions?: IActionsListItem[];
	item: any;
}

export const ActionsList: React.FC<IActionsListProps> = ({ actions, item }) => {
	return (
		<div className="[&>*]:px-4 text-left [&>*]:py-2 [&>*]:rounded-md [&>*]:text-neutral-700 dark:[&>*]:dark:text-neutral-100 dark:[&>*:hover]:dark:bg-neutral-900 [&>*:hover]:bg-neutral-100 max-w-full">
			{actions?.map((action, index) => {
				const defaultRender = (
					<ActionsListItem key={index} props={item} action={action} />
				);

				if (action.render)
					return (
						<React.Fragment key={index}>
							{action.render(item, defaultRender)}
						</React.Fragment>
					);
				return defaultRender;
			})}
		</div>
	);
};
