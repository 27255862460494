import React, { createContext, useEffect, useState } from "react";

export interface ThemeContextType {
	isDarkMode: boolean;
	toggleDarkMode: () => void;
}

interface CtxProps {
	children: React.ReactNode;
}

export const ThemeContext = createContext<ThemeContextType>({
	isDarkMode: false,
	toggleDarkMode: () => {},
});

export const ThemeContextProvider: React.FC<CtxProps> = ({ children }) => {
	const [isDarkMode, setIsDarkMode] = useState(
		JSON.parse(localStorage.getItem("isDarkMode") ?? "false") === true
	);

	const toggleDarkMode = () => {
		localStorage.setItem("isDarkMode", JSON.stringify(!isDarkMode));
		setIsDarkMode(!isDarkMode);
	};

	useEffect(() => {
		if (isDarkMode) document.querySelector("body")?.classList.add("dark");
		else document.querySelector("body")?.classList.remove("dark");
	}, [isDarkMode]);

	const value = {
		isDarkMode,
		toggleDarkMode,
	};

	return (
		<ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
	);
};
