import React from "react";

import { t } from "i18next";
import moment from "moment";

import {
	CP_STATUS_REFUSED,
	CP_STATUS_SIGNED,
	CP_STATUS_SIGNED_OUTSIDE,
} from "constants_globals";

export interface ICommercialPropositionStatusProps {
	status: string;
	signatureDate?: string;
}

const getColor = (status: string) => {
	if (status === CP_STATUS_REFUSED)
		return "bg-warning-200 text-white dark:bg-warning-400";
	if ([CP_STATUS_SIGNED, CP_STATUS_SIGNED_OUTSIDE].includes(status))
		return "bg-primary-200 text-primary-400 dark:bg-primary-300";
	return "bg-neutral-150 text-neutral-500 dark:bg-neutral-800";
};

export const CommercialPropositionStatus: React.FC<
	ICommercialPropositionStatusProps
> = ({ status, signatureDate }) => {
	return (
		<div>
			<div
				className={`rounded-md px-1 py-[0.1rem] text-center min-w-[70px] max-w-fit whitespace-nowrap ${getColor(
					status
				)}`}
			>
				{t(`commercialPropositions.status.${status}`)}
			</div>
			{[CP_STATUS_SIGNED, CP_STATUS_SIGNED_OUTSIDE].includes(status) &&
				!!signatureDate && (
					<span className="text-xs text-neutral-400">
						{moment(signatureDate).format(t("dates.theMedium"))}
					</span>
				)}
		</div>
	);
};
