import React, { useState } from "react";

import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { promiseToast } from "toastify";

import AgencyService from "services/AgencyService";

import { Text, Button, Picto, ModalV2 } from "@zolteam/react-ras-library";
import { ActionResult } from "components/molecules";
import { IContactFormValues, ContactForm } from "forms";

export const ContactFormButton: React.FC = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const [ShowForm, setShowForm] = useState(pathname === "/contact");
	const [ShowSuccess, setShowSuccess] = useState(false);

	const toggleModal = () => setShowForm((prev) => !prev);

	const handleSend = (values: IContactFormValues) => {
		const prom = AgencyService.postPublicContactAgency(values).then(() => {
			setShowSuccess(true);
			setTimeout(() => {
				setShowForm(false);
				setShowSuccess(false);
			}, 5000);
		});

		promiseToast(prom);
		return prom;
	};

	const handleClose = () => {
		if (pathname === "/contact") navigate("/");
		setShowForm(false);
		setShowSuccess(false);
	};

	return (
		<>
			<Button
				color="primary"
				className="!pl-4"
				type="button"
				onClick={toggleModal}
			>
				<Picto icon="contact" style={{ height: "2rem" }} />
				{t("global.contact")}
			</Button>
			<ModalV2
				size="s"
				isDisplayed={ShowForm}
				onClose={handleClose}
				title={t("contact.title")}
				className="dark:[&>div:first-child]:bg-neutral-900 dark:[&>div:first-child]:text-white dark:bg-neutral-900"
			>
				{ShowSuccess ? (
					<ActionResult
						onBack={handleClose}
						text={t("contact.success")}
					/>
				) : (
					<>
						<Text
							tag="p"
							lineHeight="s"
							className="mb-8 dark:text-neutral-400"
						>
							{t("contact.formText")}
						</Text>
						<ContactForm
							onSubmit={(values) => handleSend(values)}
						/>
					</>
				)}
			</ModalV2>
		</>
	);
};
