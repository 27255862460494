import React from "react";

import { saveAs } from "file-saver";
import { t } from "i18next";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { promiseToast } from "toastify";

import { useQueryClient } from "@tanstack/react-query";

import AgencyService from "services/AgencyService";
import SessionService from "services/SessionService";
import UserAdminService from "services/UserAdminService";
import UserService from "services/UserService";

import useAppContext from "store/useAppContext";

import { AdminUserPassword } from "../AdminUserPassword/AdminUserPassword";
import { EditableUserName } from "../EditableUserName/EditableUserName";
import { Picto, Button, Tag } from "@zolteam/react-ras-library";
import { IUserInformationsProps } from "components/types";

import { extractFilename } from "utils";
import { cn } from "utils";

export const AdminUserProfileHeader: React.FC<IUserInformationsProps> = ({
	user,
	className,
}) => {
	const { state } = useLocation();
	const navigate = useNavigate();
	const { dispatch, appState } = useAppContext();
	const { token } = SessionService.getAccessToken();

	const QueryClient = useQueryClient();

	const refetchInfos = () => {
		QueryClient.refetchQueries({
			queryKey: ["AdminUser", "profile", user.id.toString()],
		});
	};

	const handleAdminPermissions = () => {
		navigate(`/admin-users/permissions`, {
			state: {
				...state,
				users: [user],
				fromPage: "profile",
				backUrl: `/admin-users/profile/${user.id}`,
			},
		});
	};

	const handleLoginAs = () => {
		const { id, email, firstName, lastName } = user;
		SessionService.isSwitchingUser(true);
		UserService.getUserGlobalPermissions(token, email).then(
			(resPerm: any) => {
				if (!resPerm) return;

				dispatch({
					type: "SET_CONNECT_AS",
					payload: {
						id,
						email,
						firstName,
						lastName,
					},
				});

				const proms = [
					UserAdminService.getUserSite(email),
					AgencyService.getAgencies({
						email,
					}),
				];

				return Promise.all(proms).then((res) => {
					dispatch({
						type: "SET_AUTH",
						payload: {
							...appState.auth,
							sites: res[0] ?? [],
							agencies:
								res[1]?.map((a: any) => {
									return {
										...a,
										active: true,
									};
								}) ?? [],
							permissions: [...resPerm],
							oldState: {
								permissions: appState.auth.permissions,
								roles: appState.auth.roles,
								sites: appState.auth.sites,
								agencies: appState.auth.agencies,
							},
						},
					});

					setTimeout(() => {
						SessionService.isSwitchingUser(false);
						window.location.replace("/");
					}, 100);
				});
			}
		);
	};

	const handleLogsDownload = () => {
		const { id } = user;
		const prom = UserAdminService.userAdminLogs(id).then((res) => {
			if (!res?.data) return;
			const xlsxData = new Blob([res.data]);
			const filename = extractFilename(res);

			saveAs(
				xlsxData,
				filename ||
					`${t("userProfile.user")}-${id}-logs-${moment().format(
						t("dates.full")
					)}.xlsx`
			);
		});

		promiseToast(prom, {
			loading: t("file.generation.loading"),
			success: t("file.generation.success"),
			error: t("file.generation.error"),
		});
	};

	if (!user) return null;

	const isDisabled =
		user?.status === "automaticallyDisabled" || user?.disable;

	return (
		<div
			className={cn(["flex-wrap justify-between row gap-y-4", className])}
		>
			<div>
				<div className="relative">
					{!isDisabled && (
						<span className="absolute flex items-center justify-center w-4 h-4 text-white rounded-full md:-translate-y-1/2 -left-6 top-4 md:top-1/2 bg-primary-500 aspect-square">
							<Picto icon="check" className="w-3" />
						</span>
					)}
					<EditableUserName user={user} onSave={refetchInfos} />
				</div>
				<p className="mt-2 mb-4 text-xs text-neutral-500">
					{t("userProfile.createdBy", {
						date: moment(user.createdAt).format(t("dates.long")),
						user: user.createdBy,
					})}
				</p>
				{isDisabled && (
					<div className="flex-wrap gap-2 row">
						{user.disable && (
							<Tag color="accent" className="mb-4">
								{t("userProfile.disable.tag")}
							</Tag>
						)}
						{user.status === "automaticallyDisabled" && (
							<Tag color="accent" outline className="mb-4">
								{t("userProfile.disable.automatic")}
							</Tag>
						)}
					</div>
				)}
				<div className="mb-4 text-sm tracking-wide dark:text-white">
					{!!user.email && <p>{user.email}</p>}
					{!!user.phone && <p>{user.phone}</p>}
				</div>
				<AdminUserPassword user={user} />
			</div>
			<div>
				<div className="flex-wrap items-center gap-x-8 gap-y-4 row">
					<Button
						color="primary"
						outline
						onClick={handleAdminPermissions}
						type="button"
						size="s"
					>
						{t("userProfile.actions.permissionsEdit")}
					</Button>
					<Button
						color="transparent"
						onClick={handleLogsDownload}
						type="button"
						className="dark:!text-neutral-400"
					>
						<Picto icon="logs" />
						{t("userProfile.actions.showLogs")}
					</Button>
				</div>
				<Button
					type="button"
					color="transparent"
					onClick={handleLoginAs}
					className="mt-4 !text-primary-500 dark:!text-primary-300 font-normal items-center"
				>
					<Picto
						icon="user"
						className="[&_*]:!text-primary-500 dark:[&_*]:!text-primary-300 h-4"
					/>
					{t("userProfile.actions.connectAs")}
				</Button>
			</div>
		</div>
	);
};
