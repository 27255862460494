import React from "react";

import { t } from "i18next";

import { Picto } from "@zolteam/react-ras-library";
import {
	IPermission,
	IPermissionsTableGroupProps,
	IPermissionsTableRowProps,
} from "components/types";

import { cn } from "utils/cn";

import { PERMISSIONS_LIST } from "constants_globals";

interface IPermissionsTableProps {
	permissions?: IPermission[];
	onSelect?: (selected: IPermission[]) => void;
}

// récupére l'état des permissions pour gérer l'affichage des checkbox "selectionner tout" (coche, indéterminé, décoché)
const getState = (permissions: IPermission[]) => {
	const isAllChecked = permissions.every((permission) =>
		permission.childrens?.every((child) => child.active && !child.conflict)
	);

	const hasSomeChecked = permissions.some((permission) =>
		permission.childrens?.some((child) => child.active)
	);

	const isAllIndeterminate = !isAllChecked && hasSomeChecked;

	return {
		isAllChecked,
		hasSomeChecked,
		isAllIndeterminate,
	};
};

// fusionne les permissions par défaut et les permissions reçues en props
const mergePermissions = (permissions: IPermission[]) => {
	const mergedPermissions: IPermission[] = JSON.parse(
		JSON.stringify(PERMISSIONS_LIST)
	);

	permissions.forEach((permission) => {
		const found = mergedPermissions.find(
			(defaultPerm) => defaultPerm.code === permission.code
		);

		if (!found) return;

		found.active = permission.active;
		found.childrens?.forEach((child) => {
			const childFound = permission.childrens?.find(
				(childPerm) => childPerm.code === child.code
			);
			if (childFound) {
				// on met à jour son état
				if (childFound.conflict) child.conflict = true;
				child.active = childFound.active;
			}
		});
	});
	return mergedPermissions;
};

export const PermissionsTable: React.FC<IPermissionsTableProps> = ({
	permissions = [],
	onSelect,
}) => {
	// fusion des permissions par défaut et des permissions reçues en props
	const Permissions = mergePermissions(permissions);
	const { isAllChecked, hasSomeChecked, isAllIndeterminate } =
		getState(Permissions);

	const handleSelectAll = () => {
		const newPermissions = Permissions.map((permission) => ({
			...permission,
			active: !isAllChecked,
			childrens: permission.childrens?.map((child) => ({
				...child,
				active: !isAllChecked,
			})),
		}));
		onSelect && onSelect(newPermissions);
	};

	const handleRowClick = (id: string, forceState?: boolean) => {
		// filtrage des permissions pour ne renvoyer que celles qui ont changé
		const changedPermissions = Permissions.map((permission) => {
			if (permission.code === id)
				return {
					...permission,
					active: forceState ?? !permission.active,
					childrens: permission.childrens?.map((child) => ({
						...child,
						active: forceState ?? !permission.active,
					})),
				};
			if (permission.childrens?.some((child) => child.code === id))
				return {
					...permission,
					childrens: permission.childrens
						.map((child) =>
							child.code === id
								? {
										...child,
										active:
											forceState ??
											(child.conflict
												? true
												: !child.active),
										conflict: false,
								  }
								: false
						)
						.filter(Boolean),
				};
			return false;
		}).filter(Boolean);
		onSelect && onSelect(changedPermissions as IPermission[]);
	};

	return (
		<table className="sm:whitespace-nowrap">
			<thead className="">
				<tr>
					<th />
					<th className="w-fit" />
					<th className="text-center">
						<p className="col dark:text-neutral-200">
							{t("global.everithing")}
						</p>
						<input
							type="checkbox"
							checked={hasSomeChecked}
							onChange={handleSelectAll}
							data-indeterminate={isAllIndeterminate}
						/>
					</th>
					<th />
				</tr>
			</thead>
			<tbody>
				{Permissions.map((permission, index) => (
					<PermissionsTableGroup
						key={`${index}-${permission.code}`}
						permissions={permission}
						onRowClick={handleRowClick}
					/>
				))}
			</tbody>
		</table>
	);
};

const PermissionsTableGroup: React.FC<IPermissionsTableGroupProps> = ({
	permissions,
	onRowClick,
}) => {
	const { hasSomeChecked, isAllIndeterminate, isAllChecked } = getState([
		permissions,
	]);

	return (
		<>
			<tr>
				<td colSpan={10} className="pt-4">
					<hr />
				</td>
			</tr>
			<PermissionsTableRow
				item={{ ...permissions, active: hasSomeChecked }}
				className="[&>td]:pt-4 [&>td]:mt-8 font-bold"
				onClick={() => onRowClick(permissions.code, !isAllChecked)}
				indeterminate={isAllIndeterminate}
			/>
			{permissions.childrens?.map((child, childIndex) => (
				<PermissionsTableRow
					key={`${childIndex}-${child.code}`}
					item={child}
					className="[&>td]:py-1 text-sm"
					onClick={() => onRowClick(child.code)}
				/>
			))}
		</>
	);
};

const PermissionsTableRow: React.FC<IPermissionsTableRowProps> = ({
	item,
	indeterminate,
	className,
	onClick,
}) => (
	<tr className={cn(["dark:text-neutral-200", className])}>
		<td className="w-[60px]">
			{!!item.icon && <Picto icon={item.icon} />}
		</td>
		<td className="pr-10 sm:w-0">
			<label className="cursor-pointer" htmlFor={item.code}>
				{item.label}
			</label>
		</td>
		<td className="w-10 px-2 text-center">
			<input
				id={item.code}
				type="checkbox"
				className="align-middle"
				checked={item.active ?? false}
				onChange={() => onClick && onClick(item.code)}
				data-indeterminate={item.conflict ?? indeterminate}
			/>
		</td>
		<td />
	</tr>
);
