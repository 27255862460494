import React from "react";

import { t } from "i18next";
import moment from "moment";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import TimesheetsService from "services/TimeSheetsService";

import useAppContext from "store/useAppContext";

import { Title, Text, Picto } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import { ITimeSheet } from "components/types";

import {
	TIMESHEET_EDIT_PERMISSION,
	TIMESHEET_VALIDATE_PERMISSION,
} from "constants_globals";

interface ITimesheetEditSideContentProps {
	timesheet: ITimeSheet;
	othersToFill?: any;
}

export const TimesheetEditSideContent: React.FC<
	ITimesheetEditSideContentProps
> = ({ timesheet }) => {
	const { hasPermissions } = useAppContext();

	const fetchOtherTimesheets = (abortSignal: AbortSignal) => {
		const others = TimesheetsService.timesheetSearch(
			{
				dateBetweenStart: moment(timesheet.startDate)
					.startOf("week")
					.format(t("dates.apiFilter")),
				dateBetweenEnd: moment(timesheet.endDate)
					.endOf("week")
					.format(t("dates.apiFilter")),
				status: "waiting",
				limit: 9999,
			},
			abortSignal
		);

		const forUser = TimesheetsService.timesheetSearch(
			{
				freeSearch: timesheet.tempWorkerFullName,
				status: "waiting",
				limit: 9999,
			},
			abortSignal
		);

		return Promise.all([others, forUser]).then((values) => {
			return {
				others: values[0]?.results ?? [],
				forUser: values[1]?.results ?? [],
			};
		});
	};

	const { isLoading, data } = useQuery({
		queryKey: ["otherTimesheets", timesheet?.id],
		queryFn: ({ signal }: any) => fetchOtherTimesheets(signal),
		enabled: !!timesheet,
	});

	if (isLoading) return <Spinner className="w-full" />;

	if (!timesheet || !data) return null;

	const toCompleteForWeek = data?.others?.filter(
		(item: ITimeSheet) =>
			item.id !== timesheet.id && hasPermissions(item.permissions)
	);

	const toCompleteForUser = data?.forUser?.filter(
		(item: ITimeSheet) =>
			item.id !== timesheet.id &&
			item.tempWorkerFullName === timesheet.tempWorkerFullName &&
			hasPermissions(item.permissions)
	);

	return (
		<>
			<Title
				tag="h4"
				size="heading03"
				lineHeight="s"
				fontWeight="light"
				className="dark:text-white"
			>
				{t("timesheets.toComplete")}
			</Title>
			<div className="dark:text-white">
				<Text
					tag="p"
					size="paragraph01"
					lineHeight="m"
					className="dark:text-white"
				>
					<Trans
						i18nKey="timesheets.leftToCompleteForWeek"
						values={{
							count: toCompleteForWeek.length ?? 0,
							week: moment(timesheet.startDate).week(),
						}}
					/>
				</Text>
				<div className="mt-4 w-full gap-2 col [&>*:not(:last-child)]:border-b [&>*]:pb-2">
					{toCompleteForWeek?.slice(0, 3).map((item: ITimeSheet) => (
						<WeekListItem key={item.id} timeSheet={item} />
					))}
				</div>
			</div>

			<div>
				<Text
					tag="p"
					size="paragraph01"
					lineHeight="m"
					className="dark:text-white"
				>
					<Trans
						i18nKey="timesheets.leftToCompleteForUser"
						values={{
							count: toCompleteForUser.length ?? 0,
							user: timesheet.tempWorkerFullName,
						}}
					/>
				</Text>
				<div className="mt-4 w-full gap-2 col [&>*:not(:last-child)]:border-b [&>*]:pb-2">
					{toCompleteForUser?.slice(0, 3).map((item: ITimeSheet) => (
						<WeekListItem key={item.id} timeSheet={item} />
					))}
				</div>
			</div>
		</>
	);
};

interface IWeekListItemProps {
	timeSheet: ITimeSheet;
	onClick?: () => void;
}

const WeekListItem: React.FC<IWeekListItemProps> = ({ timeSheet, onClick }) => {
	const navigate = useNavigate();
	const isClickable =
		timeSheet.permissions.find(
			(permission) =>
				permission === TIMESHEET_EDIT_PERMISSION ||
				permission === TIMESHEET_VALIDATE_PERMISSION
		) !== undefined;

	const Wrapper = isClickable ? "button" : "div";

	const handleClick = () => {
		navigate(`/timesheets/edit/${timeSheet.id}`);
	};

	return (
		<Wrapper
			className="items-center w-full gap-4 text-left row border-neutral-300 dark:text-white"
			onClick={isClickable ? handleClick : undefined}
		>
			<Picto icon="user" className="w-4" />
			<div className="w-full">
				<Text
					tag="p"
					size="paragraph01"
					lineHeight="m"
					className="dark:text-white"
				>
					<b>{timeSheet.tempWorkerFullName}</b>
				</Text>
				<Text
					tag="p"
					size="paragraph02"
					lineHeight="m"
					className="dark:text-white"
				>
					{t("dates.fromTo", {
						from: moment(timeSheet.startDate).format(
							t("dates.shortString")
						),
						to: moment(timeSheet.endDate).format(
							t("dates.shortString")
						),
					})}
				</Text>
			</div>
			{isClickable && <Picto icon="chevronRight" className="w-6" />}
		</Wrapper>
	);
};
