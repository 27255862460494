import { t } from "i18next";

import { INotificationSetting } from "./UserNotifications";

import {
	CONTRACT_SIGNATURE_PERMISSION,
	INVOICE_DOWNLOAD_PERMISSION,
	INVOICE_LIST_PERMISSION,
	RATING_VALIDATE_PERMISSION,
	TIMESHEET_EDIT_PERMISSION,
	TIMESHEET_VALIDATE_PERMISSION,
} from "constants_globals";

const sections = [
	{
		text: t("contracts.title"),
		code: "CONTRACT",
		picto: "menuContracts",
		permissions: [CONTRACT_SIGNATURE_PERMISSION],
	},
	{
		text: t("timesheets.title"),
		code: "TIMESHEET",
		picto: "menuTimesheets",
		permissions: [TIMESHEET_EDIT_PERMISSION, TIMESHEET_VALIDATE_PERMISSION],
	},
	{
		text: t("reviews.title"),
		code: "RATING",
		picto: "menuEvaluations",
		permissions: [RATING_VALIDATE_PERMISSION],
	},
	{
		text: t("invoices.title"),
		code: "INVOICE",
		picto: "menuInvoices",
		permissions: [INVOICE_LIST_PERMISSION, INVOICE_DOWNLOAD_PERMISSION],
	},
	{
		text: t("threads.title"),
		code: "MESSAGE",
		picto: "menuMessage",
		permissions: [],
	},
];

const DefaultValues: INotificationSetting = {
	active: true,
	code: "",
	defaultSettingId: 0,
	editable: [],
	hour: 0,
	id: -1,
	name: "",
	openMonthDay: null,
	weekDay: 0,
};

export const sortSettingsResults = (results: any, hasPermissions: any) => {
	// filter by permissions and sort by section
	const res = results
		.map((item: INotificationSetting) => {
			const code = item.code.split("_")[0];
			const group = sections.find((s) => s.code === code);

			if (group && !hasPermissions(group.permissions)) return null;

			return {
				...item,
				name: t(`notificationsSettings.${item.code}`),
				rowGroup: item.code.split("_")[0],
			};
		})
		.filter((item: any) => item) // remove null
		.sort((a: INotificationSetting, b: INotificationSetting) => {
			const aCode = a.code.split("_")[0];
			const bCode = b.code.split("_")[0];

			return aCode.localeCompare(bCode);
		});

	// add section title before each section
	let withTitle: INotificationSetting[] = [];
	res.forEach((item: INotificationSetting, index: number) => {
		if (
			index === 0 ||
			(res[index - 1] && item.rowGroup !== res[index - 1].rowGroup)
		) {
			const section = sections.find((s) => s.code === item.rowGroup);
			withTitle.push({
				...item,
				...DefaultValues,
				code: section?.code || "",
				name: section?.text || "",
				section,
			});
		}
		withTitle.push({
			...item,
		});
	});

	// sort by section
	const sorted: INotificationSetting[] = [];
	sections.forEach((section) => {
		const sectionItems = withTitle.filter(
			(item) => item.rowGroup === section.code
		);
		sorted.push(...sectionItems);
	});

	return sorted;
};
