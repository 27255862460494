import React from "react";

import { t } from "i18next";

import { Tag } from "@zolteam/react-ras-library";

interface IUserStatusTagProps {
	status: string;
}

export const UserStatusTag: React.FC<IUserStatusTagProps> = ({ status }) => {
	return (
		<Tag
			color={status === "active" ? "primary" : "grey"}
			className="text-white"
		>
			{t(`users.status.${status}`)}
		</Tag>
	);
};
