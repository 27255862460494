import { t } from "i18next";
import moment from "moment";
import { promiseToast } from "toastify";

import CommercialPropositionService from "services/CommercialPropositionsService";

import { ICommercialProposition } from "components/types";

import { handleFileSave } from "utils";

import {
	CP_STATUS_NO_ACTION_TAKEN,
	CP_STATUS_SENT,
	CP_STATUS_SIGNED_OUTSIDE,
} from "constants_globals";

interface ICommercialPropositionItemActions {
	handleStatusChange: (
		item: ICommercialProposition,
		status: { status: string },
		confirm?: boolean
	) => void;
}

export const commercialPropositionItemActions = ({
	handleStatusChange,
}: ICommercialPropositionItemActions) => {
	const handleDownload = (item: ICommercialProposition) => {
		const prom =
			CommercialPropositionService.downloadCommercialPropositionPDF(
				item.id
			);
		handleFileSave(
			prom,
			`commercial-proposition-${item.id}-${moment().format(
				t("dates.full")
			)}.pdf`
		);
		promiseToast(prom, {
			success: t("promiseToast.downloadSuccess"),
			pending: t("promiseToast.fetching"),
		});
	};

	return [
		{
			label: t("commercialPropositions.itemActions.setAsNoActionTaken"),
			icon: "edit",
			render: (item: ICommercialProposition, defaultRender: any) => {
				if (item.status !== CP_STATUS_SENT) return false;
				return defaultRender;
			},
			onClick: (item: ICommercialProposition) =>
				handleStatusChange(item, { status: CP_STATUS_NO_ACTION_TAKEN }),
		},
		{
			label: t(
				"commercialPropositions.itemActions.removeAsNoActionTaken"
			),
			icon: "edit",
			render: (item: ICommercialProposition, defaultRender: any) => {
				if (item.status !== CP_STATUS_NO_ACTION_TAKEN) return false;
				return defaultRender;
			},
			onClick: (item: ICommercialProposition) =>
				handleStatusChange(item, { status: CP_STATUS_SENT }, true),
		},
		{
			label: t("commercialPropositions.itemActions.setAsSignedHorsDemat"),
			icon: "edit",
			render: (item: ICommercialProposition, defaultRender: any) => {
				if (item.status !== CP_STATUS_SENT) return false;
				return defaultRender;
			},
			onClick: (item: ICommercialProposition) =>
				handleStatusChange(item, { status: CP_STATUS_SIGNED_OUTSIDE }),
		},
		{
			label: t(
				"commercialPropositions.itemActions.removeAsSignedHorsDemat"
			),
			icon: "edit",
			render: (item: ICommercialProposition, defaultRender: any) => {
				if (item.status !== CP_STATUS_SIGNED_OUTSIDE) return false;
				return defaultRender;
			},
			onClick: (item: ICommercialProposition) =>
				handleStatusChange(item, { status: CP_STATUS_SENT }),
		},
		{
			label: t("commercialPropositions.itemActions.download"),
			icon: "download",
			onClick: (item: ICommercialProposition) => handleDownload(item),
		},
	];
};
