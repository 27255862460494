import React, { useLayoutEffect, useState } from "react";

import useTimesheetsContext from "contexts/TimesheetsContext";
import { t } from "i18next";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import TimesheetsService from "services/TimeSheetsService";

import useAppContext from "store/useAppContext";

import { Button } from "@zolteam/react-ras-library";
import {
	ConfirmModal,
	TimesheetsPageHead,
	DatePicker,
} from "components/molecules";
import { TimesheetGroupEdit } from "components/organisms";
import { PageLayout } from "components/templates";
import { ITimeSheet, IWeek } from "components/types";

import {
	TIMESHEET_ENTRY_MODE_STORAGE_KEY,
	HOURS_MODE,
	HUNDREDS_MODE,
} from "constants_globals";

export interface ITimesheetsWeekLayoutProps {}

export const TimesheetsWeekLayout: React.FC<
	ITimesheetsWeekLayoutProps
> = () => {
	const [params, setParams] = useSearchParams();
	const [IsNavigationBlocked, setIsNavigationBlocked] = useState<any>(null);
	const { dirtyTimesheets, setDirty } = useTimesheetsContext();

	const getDates = () => {
		const dateStart =
			params.get("dateStart") ??
			moment().startOf("week").format(t("dates.apiFilter"));
		const dateEnd =
			params.get("dateEnd") ??
			moment().endOf("week").format(t("dates.apiFilter"));

		const start = !isNaN(Date.parse(dateStart))
			? new Date(dateStart)
			: moment().startOf("week").toDate();

		const end = !isNaN(Date.parse(dateEnd))
			? new Date(dateEnd)
			: moment().endOf("week").toDate();

		return {
			date: start,
			start,
			end,
		};
	};

	const [CurrentWeek, setCurrentWeek] = useState<IWeek>(getDates());
	const [isEntryModeModalOpen, setIsEntryModeModalOpen] =
		React.useState(false);
	const [EntryMode, setEntryMode] = React.useState(
		localStorage.getItem(TIMESHEET_ENTRY_MODE_STORAGE_KEY) ?? "hours"
	);

	const isToggled = EntryMode === HUNDREDS_MODE;

	const toggleEntryMode = () => {
		const newMode = isToggled ? HOURS_MODE : HUNDREDS_MODE;
		setEntryMode(newMode);
		localStorage.setItem(TIMESHEET_ENTRY_MODE_STORAGE_KEY, newMode);
	};

	const toggleEntryModeModal = () => {
		setIsEntryModeModalOpen(true);
	};

	const handleDateChange = (week: any) => {
		if (dirtyTimesheets.some((d) => d))
			return setIsNavigationBlocked(() => {
				return () => setCurrentWeek(week);
			});
		setCurrentWeek(week);
	};

	useLayoutEffect(() => {
		setParams({
			dateStart: moment(CurrentWeek.start).format(t("dates.apiFilter")),
			dateEnd: moment(CurrentWeek.end).format(t("dates.apiFilter")),
		});
	}, [CurrentWeek, setParams]);

	return (
		<PageLayout
			title={t("timesheets.title")}
			header={
				<TimesheetsPageHead viewType="week">
					<div className="gap-2 row">
						<Button
							className="w-full !px-2 !py-0 min-w-fit"
							outline
							size="s"
							disabled={!isToggled}
							type="button"
							onClick={toggleEntryModeModal}
							color={!isToggled ? "primary" : "grey"}
						>
							{t("timesheets.edition.minuteMode")}
						</Button>
						<Button
							className="w-full !px-2 !py-0 min-w-fit"
							outline
							size="s"
							disabled={isToggled}
							type="button"
							onClick={toggleEntryModeModal}
							color={isToggled ? "primary" : "grey"}
						>
							{t("timesheets.edition.hourMode")}
						</Button>
					</div>
				</TimesheetsPageHead>
			}
			className="min-h-[calc(100vh-72px)]"
		>
			<div className="flex flex-col h-full xl:gap-8 xl:flex-row">
				<div className="h-fit w-full gap-8 top-0 p-8 bg-neutral-100 xl:bg-neutral-100 shadow-lg col xl:rounded-r-3xl min-w-[200px] xl:max-w-[300px] max-w-full text-sm">
					<TimesheetsDatePicker
						setCurrentWeek={setCurrentWeek}
						currentWeek={CurrentWeek}
						onDateChange={handleDateChange}
					/>
				</div>

				<div className="w-full h-full gap-8 p-8 overflow-hidden text-sm bg-white shadow-xl xl:rounded-tl-3xl col">
					<TimesheetGroupEdit
						currentWeek={CurrentWeek}
						timeMode={EntryMode}
						setTimeMode={setEntryMode}
					/>
				</div>
			</div>
			<ConfirmModal
				onConfirm={() => {
					toggleEntryMode();
					setIsEntryModeModalOpen(false);
				}}
				isOpen={isEntryModeModalOpen}
				onClose={() => {
					setIsEntryModeModalOpen(false);
				}}
				title={t("global.warning")}
				size="s"
				text={t("timesheets.entryModalText")}
			/>
			<ConfirmModal
				onConfirm={() => {
					setDirty([]);
					IsNavigationBlocked();
					setIsNavigationBlocked(false);
				}}
				isOpen={!!IsNavigationBlocked}
				onClose={() => {
					setDirty([]);
					setIsNavigationBlocked(false);
				}}
				title={t("blockNavigation.title")}
				size="s"
				text={t("blockNavigation.text")}
				confirmText={t("blockNavigation.leave")}
				cancelText={t("blockNavigation.stay")}
			/>
		</PageLayout>
	);
};

interface ITimesheetsDatePickerProps {
	setCurrentWeek: (week: IWeek) => void;
	currentWeek: IWeek;
	onDateChange?: (week: any) => void;
}

const TimesheetsDatePicker: React.FC<ITimesheetsDatePickerProps> = ({
	currentWeek,
	onDateChange,
}) => {
	const { getAgencies } = useAppContext();
	const activeAgenciesIds = getAgencies().map((a) => a.id);
	const [LocalDates, setLocalDates] = useState<IWeek>(currentWeek);

	const getDates = (date: Date) => {
		const dateStart = moment(date)
			.startOf("month")
			.subtract(1, "week")
			.format(t("dates.apiFilter"));
		const dateEnd = moment(date)
			.endOf("month")
			.add(1, "week")
			.format(t("dates.apiFilter"));

		return {
			dateBetweenStart: dateStart,
			dateBetweenEnd: dateEnd,
		};
	};

	const fetchTimesheets = async (signal: AbortSignal) => {
		return TimesheetsService.timesheetSearch({
			...getDates(LocalDates.date),
			limit: 9999,
			status: "waiting",
			agencyIds: activeAgenciesIds,
		}).then((resp) => {
			return resp?.results;
		});
	};

	const { data } = useQuery<any>({
		queryKey: ["TimesheetGroupEdit", "fetchMonth", LocalDates.date],
		queryFn: ({ signal }) => fetchTimesheets(signal),
	});

	return (
		<div className="WeekPicker [&_.ActiveWeek_.customDay]:!opacity-0">
			<DatePicker
				onChange={onDateChange}
				weekPicker
				inline
				selected={currentWeek.date}
				renderDayContents={(day, date) => {
					const hasTimesheets = data?.filter((t: ITimeSheet) => {
						const formatedDate = moment(date).format("YYYY-MM-DD");

						return (
							parseInt(t.weekNumber) === moment(date).week() &&
							moment(t.startDate).format("YYYY-MM-DD") ===
								formatedDate
						);
					});

					return (
						<div
							className="relative"
							title={
								hasTimesheets?.length
									? hasTimesheets.length === 1
										? t("timesheets.oneLeft")
										: t("timesheets.otherLeft", {
												count: hasTimesheets.length,
										  })
									: ""
							}
						>
							{day}
							{!!hasTimesheets?.length && (
								<span className="customDay absolute right-0 w-full h-[2px] rounded-full bottom-1 bg-primary-300 duration-300" />
							)}
						</div>
					);
				}}
				onMonthChange={(date) => {
					const dates = getDates(date);
					setLocalDates({
						start: new Date(dates.dateBetweenStart),
						end: new Date(dates.dateBetweenEnd),
						date,
					});
				}}
			/>
		</div>
	);
};
