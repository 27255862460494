import React from "react";

import { Picto, Text } from "@zolteam/react-ras-library";

import { cn } from "utils";

import style from "./DashboardListItem.module.css";

interface IDashboardListItemProps {
	main: string;
	secondary?: string;
	info?: () => React.ReactNode;
	onClick?: () => void;
	className?: string;
}

export const DashboardListItem: React.FC<IDashboardListItemProps> = ({
	main = "",
	secondary = "",
	info,
	onClick,
	className,
}) => {
	return (
		<li
			className={cn([
				"relative pl-6 [&:not(:last-child)]:border-b border-neutral-200 dark:border-neutral-800",
				className,
			])}
		>
			<button type="button" className={"col w-full"} onClick={onClick}>
				<div className={style.primary}>
					<span className={"absolute top-3 left-1"}>
						<Picto
							icon="circle"
							className="w-[5px] dark:text-white"
						/>
					</span>
					{!!main && (
						<Text
							tag="span"
							size="paragraph01"
							fontWeight="bold"
							lineHeight="l"
							className="dark:text-white"
						>
							{main}
						</Text>
					)}
					{!!secondary && (
						<Text
							tag="span"
							size="paragraph01"
							lineHeight="m"
							className="dark:text-neutral-300"
						>
							{secondary}
						</Text>
					)}
				</div>
				{info && info()}
			</button>
		</li>
	);
};
