import React from "react";

import { t } from "i18next";
import moment from "moment";
import { promiseToast } from "toastify";

import ThreadService from "services/ThreadsService";

import { Title, Button, Text, Picto } from "@zolteam/react-ras-library";
import { ThreadInfo } from "components/atoms";
import { IThread } from "components/types";

import {
	CONTACT_THEMES,
	STATUS_ARCHIVED,
	STATUS_READ,
	STATUS_UNREAD,
} from "constants_globals";

export interface IThreadHeadProps {
	thread: IThread;
	onStatusUpdate?: (status: string) => void;
}

const getTheme = (theme: string) => CONTACT_THEMES.find((t) => t.id === theme);

export const ThreadHead: React.FC<IThreadHeadProps> = ({
	thread,
	onStatusUpdate,
}) => {
	const [isLoading, setIsLoading] = React.useState(false);

	const handleStatusChange = (newStatus: string) => {
		setIsLoading(true);
		const prom = ThreadService.postThreadStatus(thread.id, {
			status: newStatus,
		})
			.then(() => {
				onStatusUpdate?.(newStatus);
			})
			.finally(() => setIsLoading(false));

		promiseToast(prom, {
			success: t("promiseToast.changeSuccess"),
		});

		return prom;
	};

	const contactTheme = getTheme(thread.theme);

	return (
		<>
			<div className="flex-wrap justify-between gap-4 row">
				<div className="items-end gap-4 row">
					<Title
						tag="h3"
						size="heading02"
						lineHeight="s"
						fontWeight="light"
						className="dark:text-white"
					>
						{thread.subject}
					</Title>
					{!!thread.messages[0]?.createdAt && (
						<div className="text-sm text-neutral-400">
							{moment(thread.messages[0].createdAt).format(
								t(
									`dates.${
										moment(
											thread.messages[0].createdAt
										).isSame(new Date(), "year")
											? "shortAt"
											: "mediumAt"
									}`
								)
							)}
						</div>
					)}
				</div>
				<div className="ml-auto gap-4 text-sm font-medium row [&>button]:!text-neutral-500 [&>button:hover]:!text-primary-500 flex-wrap">
					{thread.status !== STATUS_ARCHIVED && (
						<Button
							type="button"
							color="transparent"
							onClick={() => handleStatusChange(STATUS_UNREAD)}
							isLoading={isLoading}
							className="dark:!text-neutral-300"
						>
							{t("global.markAsUnread")}
						</Button>
					)}
					<Button
						type="button"
						color="transparent"
						onClick={() =>
							handleStatusChange(
								thread.status === STATUS_ARCHIVED
									? STATUS_READ
									: STATUS_ARCHIVED
							)
						}
						className="dark:!text-neutral-300"
						isLoading={isLoading}
					>
						{thread.status === STATUS_ARCHIVED
							? t("global.unarchive")
							: t("global.archive")}
					</Button>
				</div>
			</div>
			<div className="mt-2 dark:text-white">
				<ThreadInfo label={t("forms.agencies.label")}>
					{thread.agencyName}
				</ThreadInfo>
				<ThreadInfo label={t("forms.sites.label")}>
					{thread.site.socialReason}
				</ThreadInfo>
				<ThreadInfo label={t("forms.theme.label")}>
					<div className="items-center row">
						{contactTheme?.name}
						{!!contactTheme?.picto && (
							<Picto
								icon={contactTheme.picto}
								className="w-4 ml-2 text-primary-500"
							/>
						)}
					</div>
				</ThreadInfo>
				<ThreadInfo label={t("threads.lastMessage")}>
					{thread.site.socialReason}
				</ThreadInfo>
				<div className="row">
					{thread.tempWorker && (
						<Text
							tag="div"
							size="paragraph02"
							fontWeight="normal"
							color="greyDark"
							className="dark:text-neutral-300"
						>
							{thread.tempWorker}
						</Text>
					)}
					{thread.contractKey && (
						<Text
							tag="div"
							size="paragraph02"
							fontWeight="normal"
							color="greyDark"
							style={{ paddingLeft: "var(--padding-xs)" }}
							className="dark:text-neutral-300"
						>
							{thread.tempWorker && thread.contractKey
								? "- "
								: null}
							{t("contracts.contract")} {thread.contractKey}
						</Text>
					)}
				</div>
				{thread.invoiceNumber && (
					<Text
						tag="div"
						size="paragraph02"
						color="greyDark"
						lineHeight="s"
					>
						{t("invoices.invoice")} {thread.invoiceNumber}
					</Text>
				)}
			</div>
		</>
	);
};
