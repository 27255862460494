import React, { useEffect } from "react";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Title, Button, SelectLabel } from "@zolteam/react-ras-library";
import { Accordion } from "components/atoms";
import { PageLayout } from "components/templates";

import { cn } from "utils";

import { IQuestionCategory, QuestionsCategories } from "constants_globals";

export interface IFAQProps {}

export const FAQ: React.FC<IFAQProps> = () => {
	const navigate = useNavigate();
	const { category } = useParams();

	useEffect(() => {
		const getCategory = () => {
			const found = QuestionsCategories.find(
				(c: IQuestionCategory) => c.key === category
			);
			return found ? found : QuestionsCategories[0];
		};
		setActiveCategory(getCategory());
	}, [category]);

	const [ActiveCategory, setActiveCategory] =
		React.useState<IQuestionCategory>(QuestionsCategories[0]);

	return (
		<PageLayout
			title="Vous avez une question ?"
			className="min-h-[calc(100vh-4rem)]"
		>
			<div className="flex flex-col h-full gap-8 md:flex-row">
				<div className="hidden md:flex p-8 pb-16 bg-white dark:bg-black flex-col w-fit rounded-r-3xl [&>*:not(:last-child)]:border-b [&>*:not(:last-child)]:border-neutral-200 dark:[&>*:not(:last-child)]:border-neutral-600 min-h-fit h-[90%]">
					{QuestionsCategories.map((category: IQuestionCategory) => {
						return (
							<div key={category.key}>
								<Button
									type="button"
									color="transparent"
									className={cn([
										"w-[200px] items-start self-start !pl-2 !py-4 text-left whitespace-nowrap justify-start",
										category.key === ActiveCategory.key &&
											"!text-primary-500 dark:!text-white dark:underline",
									])}
									onClick={() => {
										navigate("/faq/" + category.key);
										setActiveCategory(category);
									}}
								>
									{category.name}
								</Button>
							</div>
						);
					})}
				</div>
				<div className="flex md:hidden col px-8 [&>div]:!flex-col [&>div]:items-start">
					<Title tag="h3" size="heading03">
						{t("faq.categories.title")}
					</Title>

					<SelectLabel
						name="category"
						options={[
							...QuestionsCategories.map((category) => {
								return {
									name: category.name,
									value: category.key,
								};
							}),
						]}
						value={ActiveCategory}
						onChange={({ value }: any) => {
							navigate("/faq/" + value);
							setActiveCategory(
								QuestionsCategories.find(
									(c) => c.key === value
								) ?? QuestionsCategories[0]
							);
						}}
						className="w-[250px]"
					/>
				</div>
				<div className="w-full h-full p-8 bg-white shadow-xl dark:bg-black rounded-tl-3xl">
					<AnswersList category={ActiveCategory} />
				</div>
			</div>
		</PageLayout>
	);
};

interface IAnswersListProps {
	category: IQuestionCategory;
}

const AnswersList: React.FC<IAnswersListProps> = ({ category }) => {
	const navigate = useNavigate();
	const params = useParams();

	return (
		<div className="[&>*:not(:last-child)]:border-b [&>*:not(:last-child)]:border-neutral-200 dark:[&>*:not(:last-child)]:border-neutral-600">
			{category.questions.map((question) => {
				return (
					<Accordion
						title={
							<Title
								tag="h3"
								size="heading03"
								className="!leading-5 dark:text-white"
							>
								{t(`faq.questions.${question}`)}
							</Title>
						}
						onToggle={(isOpen) => {
							if (isOpen) {
								return navigate(
									`/faq/${category.key}/${question}`,
									{
										replace: true,
									}
								);
							}
							navigate("/faq/" + category.key);
						}}
						initialState={
							category.questions.length === 1 ||
							(params.questionId
								? parseInt(params.questionId) === question
								: false)
						}
						className="py-6"
						key={`${category.key}-${question}`}
					>
						<div className="mt-4 text-sm leading-6 dark:text-white">
							<Trans i18nKey={`faq.answers.${question}`} />
						</div>
					</Accordion>
				);
			})}
		</div>
	);
};
