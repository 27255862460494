import {
	IPermission,
	IPermissionSearchResult,
	ISiteDetails,
	IUserWithSitePermissions,
} from "components/types";

import { API_PERMISSIONS_LIST, PERMISSIONS_LIST } from "constants_globals";

// used to convert permissions from API to permissions for PermissionsTable
export const convertUserSitesPermissions = (user: IUserWithSitePermissions) => {
	const convertPermissions = (permissions: IPermission[]) => {
		const converted: IPermission[] = JSON.parse(
			JSON.stringify(PERMISSIONS_LIST)
		);
		permissions.forEach((apiPerm) => {
			Object.values(converted).forEach((group) => {
				if (group.code === apiPerm.code) {
					group.active = apiPerm.active;
				} else if (
					group.parentOf &&
					group.parentOf.includes(apiPerm.code)
				) {
					group.active = group.active || apiPerm.active; // Le groupe est actif si au moins une permission enfant est active
					const childPerm = group.childrens?.find(
						(child) => child.code === apiPerm.code
					);
					if (childPerm) {
						childPerm.active = apiPerm.active;
					}
				}
			});
		});
		return converted;
	};

	return user.sites
		.map((site) => {
			return {
				...site,
				permissions: convertPermissions(site.permissions ?? []),
			};
		})
		.filter((site) => site.active);
};

export const convertUsersSitesPermissionsToAPI = (
	users: IUserWithSitePermissions[]
) => {
	const convertPermissions = (permissions: IPermission[]) => {
		const converted: IPermission[] = JSON.parse(
			JSON.stringify(API_PERMISSIONS_LIST)
		);
		permissions.forEach((perm) => {
			perm.childrens?.forEach((child) => {
				if (!child.active) return;
				const found = converted.find(
					(apiPerm) => apiPerm.code === child.code
				);
				if (found) found.active = true;
			});
		});
		return converted;
	};

	return users.map((user) => ({
		...user,
		sites: user.sites.map((site) => ({
			...site,
			permissions: convertPermissions(site.permissions),
		})),
	}));
};

// used to find a permission in a list of permissions
export const findPermission = (
	srcPerms: IPermission[] | undefined,
	id: string
) => {
	let found: IPermissionSearchResult = {
		parentIndex: -1,
		childIndex: -1,
	};

	if (!srcPerms?.length) return found;

	const parentIndex = srcPerms.findIndex((perm) => {
		if (perm.code === id) return true;
		if (perm.childrens) {
			const childIndex = perm.childrens.findIndex(
				(child) => child.code === id
			);
			if (childIndex !== -1) {
				found.childIndex = childIndex;
				return true;
			}
		}
		return false;
	});
	if (parentIndex !== -1) found.parentIndex = parentIndex;
	return found;
};

// used to get the list of all permissions with their active state
export const getActivesPermissions = (users: IUserWithSitePermissions[]) => {
	const uniquePermissions: IPermission[] = JSON.parse(
		JSON.stringify(PERMISSIONS_LIST)
	);

	uniquePermissions.forEach((permission) => {
		permission.childrens?.forEach((child) => {
			users.forEach((user) => {
				user.sites.forEach((site) => {
					if (!site.active) return;
					const found = findPermission(site.permissions, child.code);
					if (found.parentIndex !== -1 && found.childIndex !== -1) {
						const sitePermState =
							site.permissions[found.parentIndex].childrens?.[
								found.childIndex
							].active;
						if (child.active && !sitePermState)
							child.conflict = true;
						child.active = sitePermState ? true : child.active;
					} else {
						child.conflict = true;
					}
				});
			});
		});
	});

	return uniquePermissions;
};

export const updateUsersPermissions = (
	users: IUserWithSitePermissions[],
	changedPerms: IPermission[]
) => {
	const oldUsers: IUserWithSitePermissions[] = JSON.parse(
		JSON.stringify(users)
	);

	const updateSitePermissions = (
		userSite: ISiteDetails,
		changedPerms: IPermission[]
	) => {
		if (!userSite.active) return userSite;

		const sitePerms = userSite.permissions ?? [];

		changedPerms.forEach((perm) => {
			const found = findPermission(sitePerms, perm.code);

			if (found.parentIndex === -1) {
				sitePerms.push(perm);
			} else {
				updateChildrenPermissions(
					sitePerms[found.parentIndex].childrens ?? [],
					perm.childrens ?? []
				);
			}
		});

		userSite.permissions = sitePerms;
		return userSite;
	};

	const updateChildrenPermissions = (
		siteChildrens: IPermission[],
		permChildrens: IPermission[]
	) => {
		permChildrens.forEach((child) => {
			const childFound = findPermission(siteChildrens, child.code);

			if (childFound.parentIndex === -1) {
				siteChildrens.push(child);
			} else {
				siteChildrens[childFound.parentIndex].active = child.active;
			}
		});
	};

	const updatedUsers = oldUsers.map((user) => ({
		...user,
		sites: user.sites.map((userSite) =>
			updateSitePermissions(userSite, changedPerms)
		),
	}));

	return updatedUsers;
};
