import React from "react";

import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import AgencyService from "services/AgencyService";
import SecurityService from "services/SecurityService";
import SessionService, { IAuthData } from "services/SessionService";
import UserAdminService from "services/UserAdminService";
import UserService from "services/UserService";
import { handleApiErrors } from "services/api";

import useAppContext from "store/useAppContext";

import { IAgency } from "@components/types";
import { Text, Title } from "@zolteam/react-ras-library";
import { ILoginFormValues, LoginForm } from "forms";

const LoginFormContainer: React.FC = () => {
	const { dispatch } = useAppContext();
	const navigate = useNavigate();

	const handleLogin = (
		{ email, password }: ILoginFormValues,
		formikHelpers: any
	) => {
		SessionService.lastTriedEmail(email);

		return new Promise((resolve, reject) => {
			return SecurityService.login(email, password)
				.then((loginResp: IAuthData) => {
					SessionService.initSession(loginResp);

					const proms = [
						UserService.getUserGlobalPermissions(loginResp?.token),
						AgencyService.getAgencies({ token: loginResp?.token }),
						UserAdminService.getUserSite("", {
							headers: {
								"BGP-Authorization": `${loginResp?.token}`,
							},
						}),
					];

					return Promise.all(proms).then((userDatas) => {
						if (!userDatas) {
							reject(false);
							return false;
						}
						return {
							...loginResp,
							permissions: userDatas[0],
							agencies: userDatas[1].map((a: IAgency) => ({
								...a,
								active: true,
							})),
							sites: userDatas[2] || [],
						};
					}, reject);
				})
				.then((resp) => {
					dispatch({
						type: "SET_AUTH",
						payload: resp,
					});
					navigate("/");
					resolve(resp);
					return resp;
				}, reject);
		}).catch((error) => {
			formikHelpers.setFieldError(
				"submitError",
				error.response?.status === 401
					? t("auth.badCredentials")
					: handleApiErrors(error?.response?.status)
			);
			return error;
		});
	};

	return (
		<>
			<Title
				tag="h2"
				className="!text-xl text-center dark:text-neutral-100"
				lineHeight="m"
			>
				{t("auth.login")}
			</Title>
			<Text tag="p" className="mb-4 !text-sm dark:text-neutral-100">
				{t("auth.loginText")}
			</Text>

			<LoginForm
				onSubmit={handleLogin}
				email={SessionService.lastTriedEmail()}
				onPasswordForgot={() => {
					navigate("/retrieve-password");
				}}
			/>
		</>
	);
};

export default LoginFormContainer;
