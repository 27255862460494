import React from "react";

import { t } from "i18next";

import { TableFilters } from "components/organisms";
import { IFiltersWithDatesProps } from "components/types";

import { TIMESHEETS_STATUS } from "constants_globals";

export const TimeSheetFilters: React.FC<IFiltersWithDatesProps> = ({
	filters,
	setFilter,
	setDates,
}) => {
	return (
		<TableFilters
			status={TIMESHEETS_STATUS}
			filters={filters}
			onChange={(filter, value) => {
				if (filter === "dates") return setDates(value);
				setFilter(filter, value);
			}}
			searchPlaceholder={t("timesheets.searchPlaceholder")}
		/>
	);
};
