export const phoneNumberFormat = (_number: string) => {
	let number = _number.replaceAll(" ", "");
	if (number.startsWith("+")) {
		const firstPart = number.slice(0, 4);
		const firstStart = firstPart.slice(0, 3);
		const firstEnd = firstPart.length > 3 ? firstPart.slice(-1) : "";
		const slicedNumber = number.slice(4, number.length);
		const secondPart =
			slicedNumber.length > 0
				? slicedNumber.match(/.{1,2}/g)?.join(" ")
				: "";
		return firstStart + " " + firstEnd + " " + secondPart;
	}
	return number === "" ? number : number.match(/.{1,2}/g)?.join(" ");
};
