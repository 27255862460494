import React from "react";

import { BarChart as Chart, Bar, XAxis, YAxis, Legend } from "recharts";

interface IInvoicesBarChartProps {
	data: any[];
	xAxisKey: string;
	previousYearKey: string;
	currentYearKey: string;
	maxValue?: number;
	tickFormatter?: (value: any) => string;
	legendFormatter?: (props: object) => React.ReactNode;
}

const AXIS_COLOR = "#bdbdbd";
const AXIS_TICK_COLOR = "#555";
const AXIS_TICK_FONT_SIZE = "10px";
const AXIS_TICK_LETTER_SPACING = "1px";

const BAR_FILL = "#e1e1e3";
const BAR_FILL_ACTIVE = "#741b8c";
const BAR_SIZE = 10;

export const BarChart: React.FC<IInvoicesBarChartProps> = ({
	data,
	xAxisKey,
	previousYearKey,
	currentYearKey,
	maxValue,
	tickFormatter,
	legendFormatter,
}) => {
	return (
		<Chart width={500} height={240} data={data}>
			<XAxis
				dataKey={xAxisKey}
				stroke={AXIS_COLOR}
				tick={{ fontSize: AXIS_TICK_FONT_SIZE, fill: AXIS_TICK_COLOR }}
			/>
			<YAxis
				domain={[0, Math.ceil(maxValue ?? 0)]}
				stroke={AXIS_COLOR}
				tick={{
					fontSize: AXIS_TICK_FONT_SIZE,
					letterSpacing: AXIS_TICK_LETTER_SPACING,
				}}
				tickFormatter={tickFormatter}
			/>
			<Bar
				dataKey={previousYearKey}
				className={"fill-neutral-200"}
				barSize={BAR_SIZE}
				fill={BAR_FILL}
				radius={[BAR_SIZE, BAR_SIZE, BAR_SIZE, BAR_SIZE]}
			/>
			<Bar
				dataKey={currentYearKey}
				className={"fill-primary-500"}
				barSize={BAR_SIZE}
				fill={BAR_FILL_ACTIVE}
				radius={[BAR_SIZE, BAR_SIZE, BAR_SIZE, BAR_SIZE]}
			/>
			<Legend iconType="circle" content={legendFormatter} />
		</Chart>
	);
};
