import React, { useEffect, useReducer } from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import secureLocalStorage from "react-secure-storage";

// Reducers
import { appReducer, initStore } from "./AppReducer";

import { initContext } from "utils";

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

const AppProvider = ({ children }) => {
	const initialContext = initContext();
	const [state, dispatch] = useReducer(appReducer, initialContext);

	useEffect(() => {
		if (!isEmpty(state) && typeof window !== "undefined") {
			const stringifiedState = JSON.stringify({
				...state,
				lastContextUpdateTimeStamp: Date.now(),
			});
			secureLocalStorage.setItem("context", stringifiedState);
		}
	}, [state]);

	return (
		<AppStateContext.Provider value={state}>
			<AppDispatchContext.Provider value={dispatch}>
				{children}
			</AppDispatchContext.Provider>
		</AppStateContext.Provider>
	);
};

AppProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export { AppProvider, AppStateContext, AppDispatchContext, initStore };
