import React from "react";

import { t } from "i18next";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import { List } from "./List";
import { InfoMessage, Button } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import { IAsyncListProps } from "components/types";

export const AsyncList: React.FC<IAsyncListProps> = ({
	query,
	loadingMessage,
	errorMessage,
	noDataMessage,
	itemsKey,
	...props
}) => {
	const queryClient = useQueryClient();

	const forceRefetch = () => {
		queryClient.invalidateQueries({
			queryKey: query.queryKey,
		});
	};

	const genFooter = () => {
		if (isLoading) {
			return (
				<Spinner
					text={loadingMessage ?? "Chargement des données"}
					className="mt-4 text-neutral-500"
				/>
			);
		}

		if (isError) {
			return (
				<div className="flex justify-center w-full mt-8">
					<InfoMessage color="error" withIcon>
						{errorMessage ?? t("API_ERRORS.default")}
						<Button
							size="s"
							color="white"
							type="button"
							onClick={forceRefetch}
							className="ml-2 !text-red-600 outline !outline-red-500 !bg-red-100 hover:!bg-red-200"
						>
							{t("global.retry")}
						</Button>
					</InfoMessage>
				</div>
			);
		}

		if (!data || !items?.length) {
			return (
				<div className="flex justify-center w-full mt-8 text-neutral-500">
					{noDataMessage ?? "Aucune donnée à afficher"}
				</div>
			);
		}
		return false;
	};

	const { data, isLoading, isError } = useQuery({
		...query,
	});

	const items = itemsKey ? data?.[itemsKey] : data;

	return (
		<List
			header={isLoading ? "disabled" : undefined}
			{...props}
			items={items ?? data ?? []}
			footer={
				<>
					{props.footer}
					{genFooter()}
				</>
			}
		/>
	);
};
