import * as Yup from "yup";

import { MAX_CONTACT_LOGIN_MESSAGE_LENGTH } from "constants_globals";

export interface IReviewFormValues {
	behaviorRatingActive: boolean;
	behaviorRating: number;
	skillRatingActive: boolean;
	skillRating: number;
	isSecurityConcerned: boolean;
	securityRatingActive: boolean;
	securityRating: number;
	mayHaveNewMission: boolean;

	comment?: string;

	[key: string]: any;
}

export const ReviewFormInitialValues: IReviewFormValues = {
	behaviorRatingActive: false,
	behaviorRating: 0,
	skillRatingActive: false,
	skillRating: 0,
	isSecurityConcerned: true,
	securityRatingActive: false,
	securityRating: 0,
	mayHaveNewMission: true,
	comment: "",
};

export const ReviewFormValidation = (t: any) => {
	return Yup.object().shape({
		// behaviorRatingActive must be true
		behaviorRatingActive: Yup.boolean().oneOf([true]),
		behaviorRating: Yup.number()
			.min(1)
			.max(5)
			.when("behaviorRatingActive", {
				is: () => true,
				then: () =>
					Yup.number()
						.min(1)
						.max(4)
						.required(t("forms.field.required")),
			}),
		skillRatingActive: Yup.boolean().oneOf([true]),
		skillRating: Yup.number()
			.min(1)
			.max(5)
			.when("skillRatingActive", {
				is: () => true,
				then: () =>
					Yup.number()
						.min(1)
						.max(4)
						.required(t("forms.field.required")),
			}),

		securityRating: Yup.number().when("isSecurityConcerned", {
			is: (val: any) => val && val === true, // Check if isSecurityConcerned is true
			then: () => {
				return Yup.number().when("securityRatingActive", {
					is: (val: any) => val && val === true, // Check if securityRatingActive is also true
					then: () =>
						Yup.number()
							.min(1)
							.max(4)
							.required(t("forms.field.required")), // Apply validation
					otherwise: () =>
						Yup.number()
							.min(1)
							.max(4)
							.required(t("forms.field.required")), // Otherwise, allow any number except 0
				});
			},
			otherwise: () => Yup.number().nullable(),
		}),

		comment: Yup.string()
			.max(
				MAX_CONTACT_LOGIN_MESSAGE_LENGTH,
				t("forms.message.max", {
					max: MAX_CONTACT_LOGIN_MESSAGE_LENGTH,
				})
			)
			.nullable(),
	});
};
