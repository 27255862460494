import React, { useRef } from "react";

import { t } from "i18next";
import { Trans } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { promiseToast } from "toastify";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import CommercialPropositionService from "services/CommercialPropositionsService";

import useAppContext from "store/useAppContext";

import { Title, Button, Picto, Popover } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import {
	ActionsList,
	ClientInfos,
	CommercialPropositionStatus,
	ConfirmModal,
} from "components/molecules";
import { PageLayout } from "components/templates";
import { CommercialPropositionForm } from "forms";
import { CommercialPropositionFormSection } from "forms/CommercialPropositionForm/CommercialPropositionFormSections";
import { ICommercialPropositionFormValues } from "forms/CommercialPropositionForm/CommercialPropositionFormValidation";

import {
	cn,
	computeCPFetchedDatas,
	computeCPFormValues,
	getFile,
	handleFileSave,
} from "utils";

import {
	CP_STATUS_DRAFT,
	CP_STATUS_NO_ACTION_TAKEN,
	CP_STATUS_SENT,
	CP_STATUS_SIGNED,
	CP_STATUS_VALIDATED,
	CP_TYPES,
	CP_TYPE_PARTNER,
	DEFAULT_FILE_COMMERCIAL_PROPOSITION,
} from "constants_globals";

export interface ICommercialPropositionEditProps {}

export const CommercialPropositionEdit: React.FC<
	ICommercialPropositionEditProps
> = () => {
	const { id } = useParams();
	const { state } = useLocation();

	const { user } = useAppContext();
	const FormRef = useRef<any>(null);
	const [IsDownloading, setIsDownloading] = React.useState(false);
	const [ShowEditWarning, setShowEditWarning] = React.useState(false);
	const [CPType, setCPType] = React.useState(state?.type ?? CP_TYPE_PARTNER);
	const scrollableForm = useRef<HTMLDivElement | null>(null);
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const handleSave = (fromSubmit?: boolean) => {
		const values = FormRef.current?.values;
		const formData = computeCPFormValues(values);

		if (
			(values?.status === CP_STATUS_SIGNED ||
				values?.status === CP_STATUS_NO_ACTION_TAKEN) &&
			values?.id
		)
			return Promise.resolve({ id: values.id }); // no need to save if the CP is signed or no action taken

		formData.type = t(`commercialPropositions.types.${CPType}`);

		const prom = CommercialPropositionService.saveCommercialProposition(
			formData,
			values.id
		).then((resp) => {
			if (id === "new" && resp.id && !fromSubmit) {
				window.location.replace(`/commercial-proposition/${resp.id}`);
			}

			return resp;
		});

		if (fromSubmit) return prom;

		promiseToast(
			prom,
			{
				pending: t("promiseToast.saving"),
				success: t("promiseToast.dataSaveSuccess"),
			},
			{
				toastId: "commercial-proposition-save",
			}
		);

		return prom;
	};

	const handleSubmit = () => {
		let respId: any = null;
		const prom = new Promise((resole, reject) => {
			return handleSave(true)
				.then((resp) => {
					if (!resp?.id) return;

					respId = resp.id;
					return CommercialPropositionService.sendCommercialProposition(
						resp.id,
						resp
					).catch(reject);
				}, reject)
				.then((resp) => {
					queryClient.invalidateQueries({
						queryKey: ["commercial-proposition"],
					});
					if (id === "new" && respId) {
						window.location.replace(
							`/commercial-proposition/${respId}`
						);
					}
					resole(resp);
					return resp;
				}, reject);
		});

		promiseToast(
			prom,
			{},
			{
				toastId: "commercial-proposition-save",
			}
		);
		return prom;
	};

	const handleDownload = () => {
		const values = FormRef.current?.values;
		if (IsDownloading) return;

		let isNew = !values.id;
		let commercialPropId = values.id;

		setIsDownloading(true);
		const prom = handleSave(true).then((resp) => {
			if (!resp?.id) return;
			commercialPropId = resp.id;
			return CommercialPropositionService.downloadCommercialPropositionPDF(
				resp.id
			);
		});
		handleFileSave(prom, "commercial_proposition.pdf").finally(() => {
			setIsDownloading(false);
			if (isNew)
				window.location.replace(
					`/commercial-proposition/${commercialPropId}`
				);
		});

		promiseToast(prom, {
			pending: t("promiseToast.fetching"),
			success: t("promiseToast.downloadSuccess"),
		});
		return prom;
	};

	const handleFormScroll = (e: React.UIEvent<HTMLDivElement>) => {
		const scrollCont = e.currentTarget;
		const currentScrollPosition =
			scrollCont.scrollTop + scrollCont.getBoundingClientRect().top;
		const sections = scrollCont.querySelectorAll("[data-scroll-to]");
		let foundActiveSection = false;

		sections.forEach((section: any) => {
			if (foundActiveSection) return;

			const sectionTop = section.offsetTop;
			const sectionHeight = section.offsetHeight;

			// Vérifier si au moins la moitié de la section est visible
			if (
				currentScrollPosition >= sectionTop - sectionHeight / 2 &&
				currentScrollPosition < sectionTop + sectionHeight / 2
			) {
				scrollCont
					.querySelector("[data-section].active-section")
					?.classList.remove("active-section");
				const sectionName = section.getAttribute("data-scroll-to");
				const sectionNav = scrollCont?.querySelector(
					`[data-section="${sectionName}"]`
				);
				sectionNav?.classList.add("active-section");
				foundActiveSection = true;
			}
		});
	};

	const scrollTo = (section: string) => {
		const scrollCont = scrollableForm.current;
		const sectionEl = scrollCont?.querySelector(
			`[data-scroll-to="${section}"]`
		);

		const top =
			(sectionEl?.getBoundingClientRect().top || 0) +
			(scrollCont?.scrollTop || 0) -
			(scrollCont?.getBoundingClientRect().top || 0);
		scrollCont?.scrollTo({
			top: top ? top : 0,
			behavior: "smooth",
		});
	};

	const handleFetch = (defaultPjs: any) => {
		const prom = new Promise((resolve, reject) => {
			if (!id || id === "new") return resolve({});

			return CommercialPropositionService.fetchPricingConditionsType().then(
				(pricings: any) => {
					return CommercialPropositionService.fetchCommercialProposition(
						id
					).then((CP: any) => {
						const data: any = computeCPFetchedDatas(CP, pricings);
						setCPType(data.cpType);

						if (
							data.createdBy?.id !== user?.id &&
							[
								CP_STATUS_SENT,
								CP_STATUS_DRAFT,
								CP_STATUS_VALIDATED,
							].includes(data.status)
						) {
							setShowEditWarning(true);
						}
						resolve({
							...defaultPjs,
							...data,
						});
					}, reject);
				},
				reject
			);
		});
		return prom;
	};

	const fetchDefaultFiles = () => {
		const proms: any = [];

		DEFAULT_FILE_COMMERCIAL_PROPOSITION.forEach(async (filename) => {
			const prom = new Promise((resolve, reject) => {
				fetch(
					encodeURI(
						`${window.location.protocol}//${window.location.host}/files/${filename}`
					)
				)
					.then((response) => {
						return response.blob();
					}, reject)
					.then((blobed) => {
						if (!blobed || blobed.type === "text/html") {
							// if the file is not found, or if the file is a 404 page
							// text/html is the type of the 404 page
							return resolve(null);
						}
						getFile(blobed).then((result) => {
							if (!result) return;
							const data = {
								pjName: filename,
								pjFile: result,
							};

							resolve(data);
						});
					}, reject);
			});
			proms.push(prom);
		});

		return Promise.all(proms).then(
			(resp) => {
				const data = {
					commercialPropositionPjs: resp.filter((r) => r), // remove null values (files not found)
					defaultPjs: resp.filter((r) => r),
				};

				return data;
			},
			() => {
				return {};
			}
		);
	};

	const { isLoading, isRefetching, data } = useQuery<any>({
		queryKey: ["commercial-proposition", id],
		queryFn: () =>
			id === "new"
				? fetchDefaultFiles()
				: fetchDefaultFiles().then(handleFetch, handleFetch), // used to fetch the default files. Same function for success and error to avoid the error to be catched by the query if the default files are not found
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});

	return (
		<PageLayout
			title={
				<div className="flex-wrap items-center justify-between w-full gap-4 row">
					{isLoading || isRefetching ? (
						<Spinner />
					) : (
						<>
							<div className="flex-wrap items-center gap-4 row">
								{!isLoading && !isRefetching && (
									<CommercialPropositionStatus
										status={data?.status || CP_STATUS_DRAFT}
									/>
								)}
								<Title
									tag="h1"
									size="display02"
									className="max-md:!text-[2.25rem] max-md:!leading-10 dark:text-white"
								>
									{id !== "new" || data?.id
										? t("commercialPropositions.edit")
										: t("commercialPropositions.new")}
								</Title>
							</div>
							<Button
								color="transparent"
								className="ml-auto"
								type="button"
								onClick={handleDownload}
								isLoading={IsDownloading}
							>
								<Picto icon="download" />
								{t(
									"commercialPropositions.itemActions.download"
								)}
							</Button>
						</>
					)}
				</div>
			}
			tabTitle={
				id !== "new" || data?.id
					? t("commercialPropositions.edit")
					: t("commercialPropositions.new")
			}
			headerProps={{
				onBack: () => navigate("/commercial-propositions"),
				backLabel: t("commercialPropositions.backLabel"),
				className: "[&>div]:w-full border-b border-neutral-200 pb-4",
			}}
			className="h-full overflow-auto"
		>
			<div
				className={cn([
					"h-full gap-10 px-8 pt-8 overflow-auto row",
					"[&_.active-section]:text-primary-500 dark:[&_.active-section]:text-primary-300",
				])}
				onScroll={handleFormScroll}
				ref={scrollableForm}
			>
				<div className="sticky top-0 flex-col hidden gap-2 text-neutral-500 2md:flex w-[400px]">
					<div className="pb-8 text-left">
						{isRefetching ? (
							<Spinner />
						) : (
							<Title
								tag="h2"
								size="heading03"
								className={cn([
									"!leading-5 dark:text-white",
									isLoading && "!text-neutral-500",
								])}
							>
								{t(`commercialPropositions.types.${CPType}`)}
							</Title>
						)}
						{!isLoading &&
							!isRefetching &&
							data?.status !== CP_STATUS_SIGNED &&
							data?.status !== CP_STATUS_NO_ACTION_TAKEN && (
								<Popover
									content={
										<ActionsList
											actions={CP_TYPES.map((type) => ({
												label: t(
													`commercialPropositions.types.${type}`
												),
												onClick: () => {
													FormRef.current?.setFieldValue(
														"type",
														type
													);
													setCPType(type);
												},
												disabled: CPType === type,
											}))}
											item={CPType}
										/>
									}
								>
									<Button
										color="transparent"
										type="button"
										className="!self-start !justify-start w-full italic hover:underline dark:text-neutral-200"
										onClick={() => {}}
									>
										{t("commercialPropositions.editType")}
									</Button>
								</Popover>
							)}
					</div>
					{CommercialPropositionFormSection.map((section, index) => (
						<div
							key={index}
							data-section={`section-${index + 1}`}
							className="cursor-pointer"
							onClick={() => scrollTo(`section-${index + 1}`)}
						>
							{section.title}
						</div>
					))}
				</div>
				{isLoading ? (
					<Spinner />
				) : (
					<CommercialPropositionForm
						onSave={() => handleSave()}
						onSubmit={handleSubmit}
						type={CPType}
						innerRef={FormRef}
						initialValues={data as ICommercialPropositionFormValues}
						isLocked={
							data?.status === CP_STATUS_SIGNED ||
							data?.status === CP_STATUS_NO_ACTION_TAKEN ||
							IsDownloading
						}
					/>
				)}
				<ConfirmModal
					title={t("global.warning")}
					isOpen={ShowEditWarning}
					buttons={["confirm"]}
					onClose={() => setShowEditWarning(false)}
					onConfirm={() => setShowEditWarning(false)}
					size="s"
					confirmText={t("global.ok")}
					className="items-center justify-center [&_*]:text-center [&>*:not(:first-child)_button]:mx-auto"
				>
					<Trans
						i18nKey="commercialPropositions.editWarning"
						components={{
							card: (
								<ClientInfos
									contact={{
										...data?.createdBy,
									}}
									className="my-4 hover:bg-transparent !w-fit mx-auto [&_*]:text-sm items-center [&_*]:!text-black"
								/>
							),
						}}
					/>
				</ConfirmModal>
			</div>
		</PageLayout>
	);
};
