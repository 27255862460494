import * as Yup from "yup";

import { EMAIL_REGEX, PHONE_REGEX } from "constants_globals";

export const MAX_CONTACT_MESSAGE_LENGTH = 1000;

export const ContactFormValidation = (t: any) =>
	Yup.object().shape(
		{
			tempoDirectory: Yup.string()
				.nullable()
				.required(t("forms.agencies.required")),
			firstName: Yup.string().required(t("forms.firstName.required")),
			lastName: Yup.string().required(t("forms.lastName.required")),
			company: Yup.string().required(t("forms.company.required")),
			siret: Yup.string().nullable(),
			email: Yup.string()
				.when("phone", {
					is: (val: string) => !val,
					then: () =>
						Yup.string()
							.required(t("forms.email.required"))
							.test("email", t("forms.email.invalid"), (value) =>
								value ? EMAIL_REGEX.test(value) : true
							),
					otherwise: () =>
						Yup.string()
							.test("email", t("forms.email.invalid"), (value) =>
								value ? EMAIL_REGEX.test(value) : true
							)
							.nullable(),
				})
				.nullable(),
			phone: Yup.string().when("email", {
				is: (val: string) => !val,
				then: () =>
					Yup.string()
						.required(t("forms.phone.required"))
						.matches(PHONE_REGEX, t("forms.phone.invalid")),
				otherwise: () =>
					Yup.string()
						.matches(PHONE_REGEX, t("forms.phone.invalid"))
						.nullable(),
			}),
			message: Yup.string()
				.max(
					MAX_CONTACT_MESSAGE_LENGTH,
					t("forms.message.max", {
						max: MAX_CONTACT_MESSAGE_LENGTH,
					})
				)
				.required(t("forms.message.required")),
		},
		[["phone", "email"]]
	);
