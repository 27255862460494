import React, { useState } from "react";

import { Formik } from "formik";
import { t } from "i18next";
import moment from "moment";
import { toast } from "react-toastify";
import { promiseToast } from "toastify";

import { useQueryClient } from "@tanstack/react-query";

import InvoicesService from "services/InvoicesService";
import ThreadService from "services/ThreadsService";

import { useList } from "hooks/useList";
import useAppContext from "store/useAppContext";

import { INVOICES_COLUMNS } from "./InvoicesColumns";
import { invoiceItemActions } from "./InvoicesItemsActions";
import {
	Button,
	Picto,
	ModalV2,
	InfoMessage,
} from "@zolteam/react-ras-library";
import {
	AsyncList,
	ContactAgencyModal,
	InvoicesFilters,
} from "components/organisms";
import { PageLayout } from "components/templates";
import { IInvoice, ISendInvoicesByMailModal } from "components/types";
import {
	IContactAgencyFormValues,
	ISendMailFormValues,
	SendMailForm,
	SendMailFormInitValues,
	SendMailFormValidation,
} from "forms";

import { handleFileSave, cleanObject } from "utils";

export const Invoices: React.FC = () => {
	const useListHook = useList<IInvoice>({
		defaultSort: "date",
		additionalFilters: [
			{
				key: "type",
			},
		],
		pagination: {
			pageSize: 50,
		},
	});

	const {
		Pagination,
		setPagintation,
		Filters,
		setFilter,
		changeFilters,
		getFiltersKeys,
		getPaginationKeys,
		IsExporting,
		setIsExporting,
		Selected,
		setSelected,
	} = useListHook;

	const queryClient = useQueryClient();
	const { getAgencies, getSites } = useAppContext();
	const activeAgenciesIds = getAgencies(true).map((agency) => agency.id);
	const [InvoicesToSend, setInvoicesToSend] = useState<
		IInvoice[] | undefined
	>(undefined);
	const [ShowContactAgencyModal, setShowContactAgencyModal] = useState(false);

	const fetchInvoices = (abortSignal: AbortSignal) => {
		return InvoicesService.invoiceSearch(
			{
				...cleanObject(Filters, true),
				freeSearch: Filters.search || undefined,
				page: Pagination.currentPage,
				limit: Pagination.pageSize,
				agencyIds: activeAgenciesIds,
			},
			abortSignal
		).then((resp) => {
			setPagintation({
				...Pagination,
				totalCount: resp.search.total,
			});
			return resp;
		});
	};

	const handleExport = () => {
		setIsExporting(true);
		const prom = InvoicesService.invoiceExportBySearch({
			...cleanObject(Filters),
			freeSearch: Filters.search || undefined,
			page: Pagination.currentPage,
			pageSize: Pagination.pageSize,
		}).finally(() => {
			setIsExporting(false);
		});

		handleFileSave(
			prom,
			`invoices-${moment().format(t("dates.full"))}.zip`
		);
	};

	const handleSendContact = (values: IContactAgencyFormValues) => {
		const prom = ThreadService.postThreadFromOther({
			...values,
		}).then(() => {
			setShowContactAgencyModal(false);
			setInvoicesToSend(undefined);
		});

		return prom;
	};

	const handleInvoicesReadStatus = (
		items: IInvoice[],
		setAsReaded: boolean,
		showToast = true
	) => {
		const promiseCall = setAsReaded
			? InvoicesService.invoiceMarkRead
			: InvoicesService.invoiceMarkUnread;

		const promise = promiseCall({
			ids: items.map((i) => i.id),
		}).then(() => {
			setSelected([]);
			queryClient.invalidateQueries({
				queryKey: ["Invoices"],
			});
		});

		if (showToast)
			promiseToast(promise, {
				success: t(
					setAsReaded
						? "global.markedAsRead"
						: "global.markedAsUnread",
					{
						count: items?.length,
					}
				),
				error: t("global.markedAsUnread"),
			});
		return promise;
	};

	const handleDownloadSelected = () => {
		var bodyFormData = new FormData();
		bodyFormData.append(
			"ids",
			JSON.stringify({ ids: Selected.map((s) => s.id.toString()) })
		);
		toast.loading(t("promiseToast.fetching"), {
			toastId: `invoices-download`,
		});
		setIsExporting(true);
		const prom = InvoicesService.invoiceDownloadByIds(bodyFormData)
			.then((result) => {
				handleInvoicesReadStatus(Selected, true, false);
				return result;
			})
			.finally(() => {
				setIsExporting(false);
				toast.dismiss(`invoices-download`);
			});
		handleFileSave(
			prom,
			`invoices-${moment().format(t("dates.full"))}.zip`
		);
	};

	const handleInvoiceDownload = (id: number) => {
		toast.loading(t("promiseToast.fetching"), {
			toastId: `invoice-download-${id}`,
		});
		const prom = InvoicesService.invoiceDownload(id).finally(() => {
			queryClient.invalidateQueries({
				queryKey: ["Invoices"],
			});
			toast.dismiss(`invoice-download-${id}`);
		});
		handleFileSave(
			prom,
			`invoice-${id}${moment().format(t("dates.full"))}.pdf`
		);
	};

	const genDownloadLabel = () => {
		if (!Selected?.length) return t("global.download");
		const assets = Selected?.filter((i) => i.type === "asset");
		const invoices = Selected?.filter((i) => i.type === "invoice");

		if (assets.length && invoices.length)
			return t("invoices.download", {
				count: Selected?.length,
			});
		if (assets.length)
			return t("invoices.downloadAssets", {
				count: Selected?.length,
			});
		if (invoices.length)
			return t("invoices.downloadInvoices", {
				count: Selected?.length,
			});
		return t("global.download");
	};

	return (
		<PageLayout
			title={t("invoices.title")}
			headerProps={{
				className:
					"[&>div:last-child]:w-full [&>div:last-child]:xs:w-auto  [&>div:last-child]:ml-auto",
				children: (
					<Button
						outline
						color="primary"
						type="button"
						onClick={handleExport}
						className="w-full"
						isLoading={IsExporting}
					>
						<Picto icon="download" />
						{t("global.exportList")}
					</Button>
				),
			}}
		>
			<AsyncList
				filters={
					<InvoicesFilters
						filters={Filters}
						setFilter={setFilter}
						setFilters={changeFilters}
						setDates={(dates) => {
							changeFilters({
								...dates,
							});
						}}
					/>
				}
				query={{
					queryKey: [
						"Invoices",
						activeAgenciesIds.join(","),
						...getPaginationKeys(),
						...getFiltersKeys(),
					],
					queryFn: ({ signal }) => fetchInvoices(signal),
					retry: 0,
				}}
				columns={INVOICES_COLUMNS}
				className="overflow-visible font-medium"
				itemsKey="results"
				rowProps={{
					className: (item: IInvoice) =>
						item.isRead &&
						"[&>td]:text-neutral-300 [&>td>div>*]:!text-neutral-300 ",
				}}
				scrollParentSelector="AdminLayout__header"
				actions={[
					{
						label: genDownloadLabel(),
						icon: "download",
						onClick: handleDownloadSelected,
						disabled: !Selected?.length || IsExporting,
						isLoading: IsExporting,
					},
					{
						label: t("file.sendByMail"),
						icon: "mail",
						onClick: () => {
							setInvoicesToSend(Selected);
						},
						disabled: !Selected?.length,
					},
					{
						label: t(
							Selected?.some((i) => !i.isRead)
								? "global.markAsRead"
								: "global.markAsUnread",
							{
								count: Selected?.length,
							}
						),
						icon: "eye",
						onClick: () => {
							const areSomeUnReaded = !!Selected?.some(
								(i) => !i.isRead
							);
							handleInvoicesReadStatus(Selected, areSomeUnReaded);
						},
						disabled: !Selected?.length,
					},
				]}
				itemActions={invoiceItemActions({
					sendByMail: (item) => setInvoicesToSend([item]),
					handleInvoiceReadStatus: (
						item: IInvoice,
						setAsReaded: boolean
					) => handleInvoicesReadStatus([item], setAsReaded),
					handleContact: (item) => {
						setInvoicesToSend([item]);
						setShowContactAgencyModal(true);
					},
					handleInvoiceDownload,
				})}
				useListHook={useListHook}
			/>
			<SendByMailModal
				invoices={!!InvoicesToSend ? InvoicesToSend : []}
				onClose={() => setInvoicesToSend(undefined)}
			/>
			{!!InvoicesToSend?.length && (
				<ContactAgencyModal
					initialValues={{
						theme: "invoice",
						invoiceNumber: InvoicesToSend[0].number,
						siteId: InvoicesToSend[0].siteId,
						agencyId: InvoicesToSend[0].agencyId,
					}}
					onSubmit={handleSendContact}
					agencies={getAgencies(true)}
					sites={getSites()}
					isDisplayed={ShowContactAgencyModal}
					onClose={() => {
						setShowContactAgencyModal(false);
						setInvoicesToSend(undefined);
					}}
				/>
			)}
		</PageLayout>
	);
};

const SendByMailModal: React.FC<ISendInvoicesByMailModal> = ({
	invoices,
	onClose,
}) => {
	const handleSendMail = (values: ISendMailFormValues) => {
		const prom = InvoicesService.invoiceSendEmailByIds({
			message: { ...values, id: null },
			ids: invoices.map((i) => i.id),
		}).then(() => {
			onClose();
		});

		promiseToast(prom, {
			success: t("invoices.sendSuccess", {
				count: invoices?.length,
			}),
			error: t("forms.message.error"),
		});

		return prom;
	};
	return (
		<ModalV2
			isDisplayed={!!invoices?.length}
			onClose={onClose}
			title={t("file.sendByMail")}
			size="s"
			className="dark:[&>div:first-child]:bg-neutral-800 dark:[&>div:first-child]:text-white dark:bg-neutral-800 dark:text-white"
		>
			<div className="[&>div]:w-full">
				<InfoMessage color="primary" withIcon>
					{t("invoices.sendByMail", {
						count: invoices?.length,
					})}
				</InfoMessage>
			</div>
			<Formik
				initialValues={SendMailFormInitValues}
				onSubmit={(values) => handleSendMail(values)}
				validationSchema={SendMailFormValidation(t)}
			>
				{({ isSubmitting }) => {
					return (
						<div className="mt-4">
							<SendMailForm load={isSubmitting} error="" />
						</div>
					);
				}}
			</Formik>
		</ModalV2>
	);
};
