import React from "react";

export interface IContractNumberProps {
	number: string;
	amendmentNumber?: string;
}

export const ContractNumber: React.FC<IContractNumberProps> = ({
	number,
	amendmentNumber,
}) => {
	return (
		<>
			{number}
			{amendmentNumber && amendmentNumber !== "0"
				? `.${amendmentNumber}`
				: ""}
		</>
	);
};
