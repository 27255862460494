import React from "react";

import { Link } from "react-router-dom";

import { Picto, Title } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import {
	DashboardTileGauge,
	DashboardTileBarChart,
	DashboardTileList,
} from "components/molecules";
import { IDashboardTileProps } from "components/types";

import { cn } from "utils";

export const DashboardTile: React.FC<IDashboardTileProps> = (tile) => {
	const {
		title,
		icon,
		subtitle,
		isLoading,
		linkTo,
		data,
		chart,
		children,
		listItemsProps,
	} = tile;

	return (
		<div className="w-full p-4 px-6 bg-white dark:bg-black rounded-2xl">
			<Link to={linkTo ?? "#"} className="block mb-5 w-fit group">
				<div className="items-center gap-4 row">
					{!!icon && (
						<Picto
							icon={icon}
							className="w-5 duration-300 grayscale group-hover:grayscale-0 dark:brightness-[1.5]"
						/>
					)}
					<Title
						tag="h2"
						size="heading02"
						lineHeight="m"
						className="dark:text-white"
					>
						{title}
					</Title>
					<Picto
						icon="chevronRight"
						className="h-6 text-neutral-400"
					/>
				</div>
				<div className="text-xs text-neutral-600 dark:text-neutral-400">
					{subtitle}
				</div>
			</Link>
			{(isLoading && (
				<Spinner
					text={`Chargement des ${title.toLocaleLowerCase()}`}
					className="mb-8"
				/>
			)) ||
				children || (
					<div
						className={cn([
							"flex-wrap-reverse w-full gap-4 md:flex-nowrap 2md:flex-wrap-reverse 2xl:flex-nowrap row",
							chart?.type === "bar" &&
								"!flex-wrap xl:!flex-nowrap",
						])}
					>
						<DashboardTileList
							data={data?.data}
							tile={tile}
							itemsProps={listItemsProps}
						/>
						{chart?.type === "bar" ? (
							<DashboardTileBarChart data={data?.data} />
						) : (
							<DashboardTileGauge
								chartProps={chart}
								data={data?.flatten}
							/>
						)}
					</div>
				)}
		</div>
	);
};
