import React from "react";

import ThemeSwitcher from "contexts/Theme/ThemeSwitcher";
import LanguageSwitcher from "i18n/LanguageSwitcher/LanguageSwitcher";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import UserService from "services/UserService";

import useAppContext from "store/useAppContext";

import { ISite } from "@components/types";
import { Title, Button, InfoMessage } from "@zolteam/react-ras-library";
import { Spinner } from "components/atoms";
import { Table } from "components/molecules";

import { IS_PROD } from "constants_globals";

export const UserProfile: React.FC = () => {
	return (
		<>
			<UserInformations />
			<UserSitesAndAgencies />

			<DevFunctions />
		</>
	);
};

const DevFunctions: React.FC = () => {
	if (IS_PROD) return null; // Hiden in production for now

	return (
		<div>
			<Title tag="h2" className="!pb-0 !mb-0 dark:text-white !leading-8">
				{t("global.devFunctions")}
			</Title>
			<InfoMessage className="text-neutral-500" withIcon color="success">
				{t("global.notVisibleInProd")}
			</InfoMessage>
			<div className="gap-4 p-4 mt-4 rounded-lg col bg-neutral-100 dark:bg-neutral-700">
				<LanguageSection />
				<ThemeSection />
			</div>
		</div>
	);
};

const LanguageSection: React.FC = () => {
	return (
		<div>
			<Title tag="h3" size="heading03" className="-mt-4 dark:text-white">
				{t("language.title")}
			</Title>
			<LanguageSwitcher />
		</div>
	);
};

const ThemeSection: React.FC = () => {
	return (
		<div>
			<Title tag="h3" size="heading03" className="dark:text-white">
				{t("theme.title")}
			</Title>
			<ThemeSwitcher />
			<div className="[&>*]: max-w-[500px] mt-2 dark:text-white">
				<InfoMessage withIcon>{t("theme.wipWarning")}</InfoMessage>
			</div>
		</div>
	);
};

const UserInformations: React.FC = () => {
	const { user } = useAppContext();
	const navigate = useNavigate();

	return (
		<div>
			<Title tag="h2" className="dark:text-white">
				{t("profile.generalInfos")}
			</Title>
			<div className="gap-2 mt-4 col dark:text-white">
				<b>
					{[user.firstName, user.lastName].filter(Boolean).join(" ")}
				</b>
				<span>{user.email}</span>
			</div>
			<Button
				type="button"
				color="primary"
				className="mt-6"
				onClick={() => {
					navigate("/user-profile/edit-password");
				}}
			>
				{t("resetPassword.title2")}
			</Button>
		</div>
	);
};

const UserSitesAndAgencies: React.FC = () => {
	const fetchInfos = () =>
		UserService.getUserInformation().then((resp) => {
			return (resp.sites as ISite[])
				?.filter((site) => site.active)
				.sort((a, b) =>
					a.agency?.name !== b.agency?.name
						? a.agency?.name?.localeCompare(b.agency?.name)
						: a.socialReason?.localeCompare(b.socialReason)
				);
		});

	const { data, isLoading } = useQuery({
		queryKey: ["userInfos"],
		queryFn: fetchInfos,
	});

	return (
		<div>
			<Title tag="h2" className="dark:text-white">
				{t("profile.sitesAndAgenciesList")}
			</Title>
			{isLoading ? (
				<Spinner />
			) : (
				<div className="gap-2 mt-4 col">
					{!!data?.length ? (
						<Table
							columns={[
								{
									name: "Sites",
									value: "socialReason",
									className: "font-semibold",
								},
								{
									name: "Agences",
									value: "agency",
									render: (item) => item.agency?.name,
								},
							]}
							items={[...data]}
							bodyClassName="[&_tr]:hover:bg-transparent [&_td]:!py-3"
						/>
					) : (
						<div className="dark:text-white">
							{t("profile.noAgency")}
						</div>
					)}
				</div>
			)}
		</div>
	);
};
