import React from "react";

import { Popover, Button, Picto } from "@zolteam/react-ras-library";
import { Field } from "components/atoms";
import { DatePicker } from "components/molecules";

import { DATE_REGEX } from "constants_globals";

interface IDatePickerFieldProps {
	label: string;
	name: string;
	onChange: (value: Date) => void;
	value: string;
	pickerProps?: {
		[key: string]: any;
	};
	disabled?: boolean;
}

export const DatePickerField: React.FC<IDatePickerFieldProps> = ({
	label,
	name,
	onChange,
	value,
	pickerProps = {},
	disabled,
}) => {
	const buttonRef = React.useRef<HTMLButtonElement>(null);

	const [Value, setValue] = React.useState<Date>(new Date());

	const getCurDate = (date: string) => {
		if (!date?.length || !DATE_REGEX.test(date)) return Value;

		return new Date(date.split("/").reverse().join("/"));
	};

	return (
		<div className={`relative w-full`}>
			<Field
				name={name}
				label={label}
				type="text"
				disabled={disabled}
				onClick={(e: any) => {
					e.target.blur(); // remove focus from input
					if (buttonRef.current) {
						setTimeout(() => {
							// wait for blur to finish and then click the button to open the date picker
							buttonRef.current?.parentElement?.click();
						}, 10);
					}
				}}
			>
				<div className="absolute top-2 right-2 [& button svg]:!m-0">
					<Popover
						className="dark:[&>div>div>div]:bg-black"
						key={Date.now()?.toString()}
						content={
							<DatePicker
								showYearDropdown
								showMonthDropdown
								dropdownMode="select"
								inline
								selected={getCurDate(value)}
								{...pickerProps}
								disabled={disabled}
								onChange={(val: any) => {
									setValue(val);
									onChange(val);
								}}
							/>
						}
					>
						<Button
							ref={buttonRef}
							color="white"
							type="button"
							disabled={disabled}
							className="dark:[&_*]:text-white dark:border-neutral-600 dark:bg-neutral-900"
						>
							<Picto icon="calendar" className="!m-0" />
						</Button>
					</Popover>
				</div>
			</Field>
		</div>
	);
};
