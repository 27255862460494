import React, { PropsWithChildren } from "react";

import { t } from "i18next";

import { Title, BackLink } from "@zolteam/react-ras-library";

import { cn } from "utils";

export interface IPageHeadProps extends PropsWithChildren {
	title?: string | React.ReactNode;
	className?: string;
	onBack?: () => void;
	backLabel?: string;
}

export const PageHead: React.FC<IPageHeadProps> = ({
	title,
	children,
	className,
	onBack,
	backLabel,
}) => {
	return (
		<div
			className={cn([
				"flex flex-wrap items-center justify-between gap-4 pb-8 pl-8",
				className,
			])}
		>
			<div>
				{onBack && (
					<BackLink onClick={onBack} className="mb-2">
						{backLabel ?? t("global.back")}
					</BackLink>
				)}
				{typeof title === "string" ? (
					<Title
						tag="h1"
						size="display02"
						className="max-md:!text-[2.25rem] max-md:!leading-10 dark:text-white"
					>
						{title}
					</Title>
				) : (
					title
				)}
			</div>
			<div className="flex flex-wrap gap-4">{children}</div>
		</div>
	);
};
