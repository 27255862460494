import React from "react";

import { t } from "i18next";
import { Link } from "react-router-dom";

import { Tag } from "@zolteam/react-ras-library";
import { IReview } from "components/types";
import {
	REVIEW_STATUS_LOCKED,
	REVIEW_STATUS_NOT_RATED,
	REVIEW_STATUS_RATED,
	REVIEW_STATUS_WAITING,
} from "constants_globals/reviews";

import { RATING_VALIDATE_PERMISSION } from "constants_globals";

export interface IReviewStatusProps {
	item: IReview;
}

interface IStatusMap {
	[key: string]: JSX.Element;
}

export const ReviewStatus: React.FC<IReviewStatusProps> = ({ item }) => {
	const { status, permissions } = item;

	const canRate = permissions.includes(RATING_VALIDATE_PERMISSION);

	const statusMap: IStatusMap = {
		[REVIEW_STATUS_WAITING]: (
			<>
				{canRate ? (
					<Link
						to={{ pathname: `/reviews/edit/${item.id}` }}
						state={{
							review: item,
							edition: true,
							search: window.location.search,
						}}
					>
						<Tag color="primary">{t("reviews.status.waiting")}</Tag>
					</Link>
				) : (
					<Tag color="primary">{t("reviews.status.waiting")}</Tag>
				)}
				<p className="mt-1 text-xs text-neutral-500">
					{t("reviews.expireIn", {
						count: item.expiresIn,
					})}
				</p>
			</>
		),
		[REVIEW_STATUS_RATED]: (
			<Link
				to={{ pathname: `/reviews/view/${item.id}` }}
				state={{
					search: window.location.search,
				}}
			>
				<Tag color="grey">{t("reviews.status.rated")}</Tag>
			</Link>
		),
		[REVIEW_STATUS_NOT_RATED]: (
			<div className="max-w-[200px]">
				<Tag color="greyDark">{t("reviews.status.notRated")}</Tag>
				<p className="mt-1 text-xs text-neutral-500 text-balance">
					{t("reviews.expired")}
				</p>
			</div>
		),
		[REVIEW_STATUS_LOCKED]: (
			<div className="max-w-[200px]">
				<Tag color="greyDark">{t("reviews.status.notRated")}</Tag>
				<p className="mt-1 text-xs text-neutral-500 text-balance">
					{t("reviews.locked")}
				</p>
			</div>
		),
	};

	return statusMap[status] || false;
};
