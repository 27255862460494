import { t } from "i18next";

import { ContractInfos } from "components/molecules";
import { IDashboardTile, ITileListItemProps } from "components/types";

import { RATING_LIST_PERMISSION } from "constants_globals";

export const DashboardReviewsTile = (): IDashboardTile => {
	const handleItemClick = (
		item: any,
		{ appState, navigate }: ITileListItemProps
	) => {
		navigate(
			`/reviews/${item.status !== "waiting" ? "view" : "edit"}/${
				item.id
			}`,
			{
				state: {
					edition: item.status === "waiting",
					contract: {
						site: item.site.socialReason,
						qualification: item.contract.qualification,
						orderReference: item.contract.orderReference,
						contractNumber: item.contract.contractNumber,
						amendmentNumber: item.contract.amendmentNumber,
						tempWorkerFullName: item.contract.tempWorkerFullName,
						startDate: item.startDate,
						endDate: item.endDate,
						permissions: item.permissions,
					},
				},
			}
		);
	};
	return {
		title: t("reviews.title"),
		subtitle: t("reviews.tileSubtitle"),
		icon: "menuEvaluations",
		linkTo: "/reviews",
		itemsKey: "ratings",
		listItem: {
			main: (item: any) => item.contract.tempWorkerFullName,
			secondary: (item: any) => item.contract.qualification,
			info: (item: any) => <ContractInfos {...item.contract} />,
			noValuePicto: "dashboardNoData-ratings",
			noValueText: t("reviews.noPending"),
			onClick: handleItemClick,
		},
		chart: {
			valueKey: "ratingAdvancement.pendingRatingNumber",
			totalKey: "ratingAdvancement.totalRatingNumber",
			title: t("reviews.gaugeTitle"),
			text: t("reviews.pendingGauge"),
			noValueText: t("reviews.noPending"),
			onClickLabel: "/reviews?status=waiting",
		},
		permissions: [RATING_LIST_PERMISSION],
		maxItems: 3,
	};
};
