import React from "react";

import { Picto } from "@zolteam/react-ras-library";
import { INavItemIcon } from "components/types";

import { cn } from "utils";

export const NavItemIcon: React.FC<INavItemIcon> = ({ item, isActive }) => {
	let icon = item.icon;
	let className = "";

	let filter =
		(typeof item.icon === "string" || !item.icon?.default) && !isActive
			? "grayscale"
			: "";

	if (typeof item.icon !== "string") {
		icon = isActive ? item.icon?.active : item.icon?.default;
		className = item.icon?.className || "";
	}

	return <Picto className={cn(["w-7 h-7", filter, className])} icon={icon} />;
};
