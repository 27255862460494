import React from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";
import * as Yup from "yup";

import { ICommercialPropositionFormSectionProps } from "../CommercialPropositionForm";
import { InfoMessage } from "@zolteam/react-ras-library";
import { Toggle, Textarea } from "components/atoms";
import { CPPrestationRow } from "components/molecules";

import { cn } from "utils";

export interface ICPPrestationSectionValues {
	applicationFee: string;
	isApplicationFeeOffered: boolean;

	packagePrice: string;
	isChangeablePackagePrice: boolean;
	workAccidentPackage: string;

	medicalCheckUp: string;
	isMedicalCheckUpOffered: boolean;

	isChangeableTransportInsurance: boolean;
	transportInsuranceCoefficient: string;
	isDeclinedTransportInsurance: boolean;

	isChangeableInsurance: boolean;
	isDeclinedInsurance: boolean;
	insuranceCoefficient: string;

	isAssociatedService: boolean;
	associatedService: string;

	isSourcingPack: boolean;
	sourcingPack: string;

	isCustomisedTests: boolean;
	customisedTests: string;

	isRainbowTest: boolean;
	rainbowTest: string;

	supplementaryBenefitsComment: string;
}

export const CPPrestationSectionInitialValues: ICPPrestationSectionValues = {
	applicationFee: "",
	isApplicationFeeOffered: false,
	packagePrice: "30,00 €",
	isChangeablePackagePrice: false,
	workAccidentPackage: "",
	medicalCheckUp: "",
	isMedicalCheckUpOffered: false,
	isChangeableTransportInsurance: true,
	transportInsuranceCoefficient: "",
	isDeclinedTransportInsurance: false,
	isChangeableInsurance: true,
	isDeclinedInsurance: false,
	insuranceCoefficient: "0,40 €/H",
	isAssociatedService: true,
	associatedService: "",
	isSourcingPack: true,
	sourcingPack: "",
	isCustomisedTests: true,
	customisedTests: "",
	isRainbowTest: true,
	rainbowTest: "",
	supplementaryBenefitsComment: "",
};

export const CPPrestationSectionValidation = {
	applicationFee: Yup.string().when("isApplicationFeeOffered", {
		is: (value: boolean) => !value,
		then: () => Yup.string().required(t("forms.field.required")),
	}),
	packagePrice: Yup.string().when("isChangeablePackagePrice", {
		is: (value: boolean) => value,
		then: () => Yup.string().required(t("forms.field.required")),
	}),
	workAccidentPackage: Yup.string().required(t("forms.field.required")),
	medicalCheckUp: Yup.string().when("isMedicalCheckUpOffered", {
		is: (value: boolean) => !value,
		then: () => Yup.string().required(t("forms.field.required")),
	}),
	transportInsuranceCoefficient: Yup.string().when(
		["isChangeableTransportInsurance", "isDeclinedTransportInsurance"],
		{
			is: (
				isChangeableTransportInsurance: boolean,
				isDeclinedTransportInsurance: boolean
			) => {
				return (
					isChangeableTransportInsurance &&
					!isDeclinedTransportInsurance
				);
			},
			then: () => Yup.string().required(t("forms.field.required")),
			otherwise: () => Yup.string(),
		}
	),
	insuranceCoefficient: Yup.string().when("isChangeableInsurance", {
		is: (value: boolean) => value,
		then: () => Yup.string().required(t("forms.field.required")),
	}),
	associatedService: Yup.string().when("isAssociatedService", {
		is: (value: boolean) => value,
		then: () => Yup.string().required(t("forms.field.required")),
	}),
	sourcingPack: Yup.string().when("isSourcingPack", {
		is: (value: boolean) => value,
		then: () => Yup.string().required(t("forms.field.required")),
	}),
	customisedTests: Yup.string().when("isCustomisedTests", {
		is: (value: boolean) => value,
		then: () => Yup.string().required(t("forms.field.required")),
	}),
	rainbowTest: Yup.string().when("isRainbowTest", {
		is: (value: boolean) => value,
		then: () => Yup.string().required(t("forms.field.required")),
	}),
	supplementaryBenefitsComment: Yup.string().max(
		440,
		t("forms.field.max", { max: 440 })
	),
};

export const CPPrestationSection: React.FC<
	ICommercialPropositionFormSectionProps
> = ({ locked }) => {
	const { values, setFieldValue, setValues } =
		useFormikContext<ICPPrestationSectionValues>();

	return (
		<div className="gap-10 col">
			<CPPrestationRow
				title={t(
					"commercialPropositions.form.sections.services.applicationFee"
				)}
				description={t(
					"commercialPropositions.form.sections.services.applicationFeeDescription"
				)}
				fieldProps={{
					name: "applicationFee",
					label: t(
						"commercialPropositions.form.sections.services.applicationFee"
					),
					disabled: locked || values.isApplicationFeeOffered,
					required: !values.isApplicationFeeOffered,
				}}
				fieldChildren={
					<Toggle
						label={t(
							"commercialPropositions.form.sections.services.giftedFromRAS"
						)}
						name="isApplicationFeeOffered"
						className="[&_*]:!font-light"
						checked={values.isApplicationFeeOffered}
						disabled={locked}
						onChange={(e) => {
							const isChecked = e.target.checked;
							setValues({
								...values,
								applicationFee: isChecked
									? t(
											"commercialPropositions.form.sections.services.giftedFromRAS"
									  )
									: "",
								isApplicationFeeOffered: isChecked,
							});
						}}
					/>
				}
				disabled={locked}
			/>
			<CPPrestationRow
				title={t(
					"commercialPropositions.form.sections.services.packagePrice"
				)}
				description={t(
					"commercialPropositions.form.sections.services.packagePriceDescription"
				)}
				fieldProps={{
					name: "packagePrice",
					label: t(
						"commercialPropositions.form.sections.services.packagePrice"
					),
					required: values.isChangeablePackagePrice,
				}}
				disabled={locked || !values.isChangeablePackagePrice}
				fieldChildren={
					<Toggle
						label={t(
							"commercialPropositions.form.sections.services.changeablePackagePrice"
						)}
						name="isChangeablePackagePrice"
						checked={values.isChangeablePackagePrice}
						className="[&_*]:!font-light"
						onChange={(e) => {
							setValues({
								...values,
								isChangeablePackagePrice: e.target.checked,
								packagePrice: e.target.checked
									? values.packagePrice
									: CPPrestationSectionInitialValues.packagePrice,
							});
						}}
						disabled={locked}
					/>
				}
			/>
			<CPPrestationRow
				title={t(
					"commercialPropositions.form.sections.services.workAccientPackage"
				)}
				description={t(
					"commercialPropositions.form.sections.services.workAccientPackageDescription"
				)}
				fieldProps={{
					name: "workAccidentPackage",
					label: t(
						"commercialPropositions.form.sections.services.workAccientPackage"
					),
				}}
				disabled={locked}
			/>
			<CPPrestationRow
				title={t(
					"commercialPropositions.form.sections.services.medicalCheckUp"
				)}
				description={t(
					"commercialPropositions.form.sections.services.medicalCheckUpDescription"
				)}
				fieldProps={{
					name: "medicalCheckUp",
					label: t(
						"commercialPropositions.form.sections.services.medicalCheckUp"
					),
					disabled: locked || values.isMedicalCheckUpOffered,
				}}
				fieldChildren={
					<Toggle
						label={t(
							"commercialPropositions.form.sections.services.giftedFromRAS"
						)}
						className="[&_*]:!font-light"
						name="isMedicalCheckUpOffered"
						checked={values.isMedicalCheckUpOffered}
						onChange={(e) => {
							const isChecked = e.target.checked;
							setValues({
								...values,
								medicalCheckUp: isChecked
									? t(
											"commercialPropositions.form.sections.services.giftedFromRAS"
									  )
									: "",
								isMedicalCheckUpOffered: isChecked,
							});
						}}
						disabled={locked}
					/>
				}
			/>
			<CPPrestationRow
				title={t(
					"commercialPropositions.form.sections.services.transportInsurance"
				)}
				description={t(
					"commercialPropositions.form.sections.services.transportInsuranceDescription"
				)}
				toggleProps={{
					label: t(
						"commercialPropositions.form.sections.services.transportInsurance"
					),
					onChange: (e) => {
						setValues({
							...values,
							isChangeableTransportInsurance: e.target.checked,
							transportInsuranceCoefficient: "",
						});
					},
					checked: values.isChangeableTransportInsurance,
				}}
				fieldProps={{
					name: "transportInsuranceCoefficient",
					disabled: locked || !values.isChangeableTransportInsurance,
					required:
						values.isChangeableTransportInsurance &&
						!values.isDeclinedTransportInsurance,
				}}
				disabled={locked}
			>
				{() => {
					return (
						<>
							<label className="items-center gap-4 mt-2 text-sm cursor-pointer row w-fit">
								<input
									type="checkbox"
									name="isDeclinedTransportInsurance"
									checked={
										values.isDeclinedTransportInsurance
									}
									onChange={(e) => {
										setFieldValue(
											"isDeclinedTransportInsurance",
											e.target.checked
										);
									}}
									disabled={locked}
								/>
								{t(
									"commercialPropositions.form.sections.services.declinedByClient"
								)}
							</label>
							{!values.isDeclinedTransportInsurance &&
								values.isChangeableTransportInsurance && (
									<InfoMessage color="primary" withIcon>
										{t(
											"commercialPropositions.form.sections.services.contractWillBeSent"
										)}
									</InfoMessage>
								)}
						</>
					);
				}}
			</CPPrestationRow>
			<CPPrestationRow
				title={t(
					"commercialPropositions.form.sections.services.insurance"
				)}
				description={t(
					"commercialPropositions.form.sections.services.insuranceDescription"
				)}
				toggleProps={{
					onChange: (e) => {
						setFieldValue(
							"isChangeableInsurance",
							e.target.checked
						);
					},
					checked: values.isChangeableInsurance,
					disabled: locked,
				}}
				fieldProps={{
					name: "insuranceCoefficient",
					label: t(
						"commercialPropositions.form.sections.services.rate"
					),
					disabled: true,
					required: false,
				}}
			>
				{() => {
					return (
						<>
							<label className="items-center gap-4 mt-2 text-sm cursor-pointer row w-fit">
								<input
									type="checkbox"
									name="isDeclinedInsurance"
									checked={values.isDeclinedInsurance}
									onChange={(e) => {
										setFieldValue(
											"isDeclinedInsurance",
											e.target.checked
										);
									}}
									disabled={locked}
								/>
								{t(
									"commercialPropositions.form.sections.services.declinedByClient"
								)}
							</label>
							{!values.isDeclinedInsurance &&
								values.isChangeableInsurance && (
									<InfoMessage color="primary" withIcon>
										{t(
											"commercialPropositions.form.sections.services.contractWillBeSent"
										)}
									</InfoMessage>
								)}
						</>
					);
				}}
			</CPPrestationRow>
			<CPPrestationRow
				title={t(
					"commercialPropositions.form.sections.services.associatedService"
				)}
				description={t(
					"commercialPropositions.form.sections.services.associatedServiceDescription"
				)}
				toggleProps={{
					onChange: (e) => {
						setValues({
							...values,
							isAssociatedService: e.target.checked,
							associatedService: "",
						});
					},
					checked: values.isAssociatedService,
				}}
				fieldProps={{
					name: "associatedService",
					disabled: locked || !values.isAssociatedService,
					required: values.isAssociatedService,
				}}
				disabled={locked}
			/>
			<CPPrestationRow
				title={t(
					"commercialPropositions.form.sections.services.sourcingPack"
				)}
				description={t(
					"commercialPropositions.form.sections.services.sourcingPackDescription"
				)}
				toggleProps={{
					onChange: (e) => {
						setValues({
							...values,
							isSourcingPack: e.target.checked,
							sourcingPack: "",
						});
					},
					checked: values.isSourcingPack,
				}}
				fieldProps={{
					name: "sourcingPack",
					disabled: locked || !values.isSourcingPack,
					required: values.isSourcingPack,
				}}
				disabled={locked}
			/>
			<CPPrestationRow
				title={t(
					"commercialPropositions.form.sections.services.customisedTests"
				)}
				description={t(
					"commercialPropositions.form.sections.services.customisedTestsDescription"
				)}
				toggleProps={{
					onChange: (e) => {
						setValues({
							...values,
							isCustomisedTests: e.target.checked,
							customisedTests: "",
						});
					},
					checked: values.isCustomisedTests,
				}}
				fieldProps={{
					name: "customisedTests",
					disabled: locked || !values.isCustomisedTests,
					required: values.isCustomisedTests,
				}}
				disabled={locked}
			/>
			<CPPrestationRow
				title={t(
					"commercialPropositions.form.sections.services.rainbowTest"
				)}
				description={t(
					"commercialPropositions.form.sections.services.rainbowTestDescription"
				)}
				toggleProps={{
					onChange: (e) => {
						setValues({
							...values,
							isRainbowTest: e.target.checked,
							rainbowTest: "",
						});
					},
					checked: values.isRainbowTest,
				}}
				fieldProps={{
					name: "rainbowTest",
					disabled: locked || !values.isRainbowTest,
					required: values.isRainbowTest,
				}}
				disabled={locked}
			/>
			<Textarea
				name="supplementaryBenefitsComment"
				label={t(
					"commercialPropositions.form.sections.services.comment"
				)}
				maxLength={440}
				className={cn([
					"[&_textarea]:!bg-white [&_textarea]:dark:!bg-neutral-800 [&_textarea]:dark:!text-white",
				])}
				disabled={locked}
			/>
		</div>
	);
};
